import CloseIcon from '@mui/icons-material/Close';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import { StyledIconButton } from '@components/user-authentication/sections/modal-stepper/desktop/styles';
import CONFIG from '@components/workspace/config';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import ModalWrapper from '@components/workspace/setup/sections/modal-stepper/ModalWrapper';

const MODAL_WIDTH = 500;

export default function ModalStepper({ children }: React.PropsWithChildren) {
  const { activeStep, handleCloseModal } = useWorkspaceSetup();

  return (
    <ModalWrapper
      height="auto"
      width={MODAL_WIDTH}
    >
      <Stepper activeStep={activeStep}>
        {CONFIG.STEPS.map((step) => (
          <Step key={step}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <StyledIconButton onClick={handleCloseModal}>
        <CloseIcon />
      </StyledIconButton>
      {children}
    </ModalWrapper>
  );
}
