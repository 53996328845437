import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const StyledTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  fontSize: theme.typography.textSm.fontSize,
  fontWeight: theme.typography.fontWeightSemibold,
  padding: 0,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  paddingInline: theme.spacing(5),
  paddingBlockEnd: theme.spacing(5),
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBlockEnd: theme.spacing(5),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
