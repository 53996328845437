import { useContext } from 'react';

import { AppSettingsContext } from '@providers/AppSettings';

export default function useAppSettings() {
  const context = useContext(AppSettingsContext);

  if (context === null) {
    throw new Error('useAppSettings must be used within a AppSettingsProvider');
  }

  return context;
}
