import CloseIcon from '@mui/icons-material/Close';

import StyledCloseButton from '@components/source-preview/components/close-button/styles';
import useSourcePreview from '@components/source-preview/hooks/useSourcePreview';

export default function CloseButton() {
  const { closeSourcePreviewCard } = useSourcePreview();

  return (
    <StyledCloseButton
      onClick={() => {
        closeSourcePreviewCard();
      }}
    >
      <CloseIcon />
    </StyledCloseButton>
  );
}
