import { UiComponent } from 'sequential-workflow-designer';

export default function useAddPackageButtonExtension(
  workFlowDesignRef: React.MutableRefObject<HTMLDivElement | null>,
) {
  return {
    create: (container: HTMLDivElement): UiComponent => {
      setTimeout(() => {
        const toolbox = container.querySelector('.sqd-toolbox-body');
        if (!toolbox) return;

        const newDiv = document.createElement('div');
        const firstChild = toolbox?.firstChild;
        const secondChild = firstChild?.nextSibling;

        if (secondChild) {
          toolbox.insertBefore(newDiv, secondChild);
          workFlowDesignRef.current = newDiv;
        }
      });

      return {
        updateLayout: () => {
          // Dummy
        },
        destroy: () => {
          // toolbarPortal.current = null;
        },
      };
    },
  };
}
