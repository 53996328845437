import { useRef, useState } from 'react';

import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';

import { StyledPopper } from '@components/dropdown/styles';
import UserAuthentication from '@components/user-authentication';

import calculateFullName from '@lib/calculateFullName';

import useAuth from '@hooks/useAuth';
import useGetCredit from '@hooks/useGetCredit';
import useThemeSettings from '@hooks/useThemeSettings';

import { useTranslation } from '@desygner/ui-common-translation';

import CreditUsage from '@layouts/components/credit-usage';
import CreditCircularProgressWithAvatar from '@layouts/main/sections/header/sections/user-account-popover/credit-circular-progress-with-avatar';
import {
  StyledListItemText,
  StyledPaper,
} from '@layouts/main/sections/header/sections/user-account-popover/styles';
import { StyledStack } from '@layouts/main/sections/header/styles';
import ThemeMode from '@layouts/main/sections/header/them-mode';
import paths from '@router/paths';

const AVATAR_SIZE = 40;

const SHARED_PROPS: ListItemProps = {
  disablePadding: true,
  disableGutters: true,
  sx: {
    paddingBlock: 1,
  },
};

export default function UserAccountPopover() {
  const { t } = useTranslation();

  const { me } = useAuth();

  const [openSettings, setOpenSettings] = useState(false);

  const { creditsData } = useGetCredit();

  const handleClose = (event: Event | React.SyntheticEvent) => {
    const isClickingInside = anchorRef.current?.contains(
      event.target as HTMLElement,
    );
    isClickingInside || setOpenSettings(false);
  };

  const hasSecuredAccount =
    me && (me.auth_type === 'email' || me.auth_type === 'oauth2');

  const anchorRef = useRef<HTMLDivElement>(null);

  const { toggleThemeMode, themeMode } = useThemeSettings();

  function handleThemeChange() {
    toggleThemeMode(themeMode === 'light' ? 'dark' : 'light');
  }

  const fullName = calculateFullName({
    firstName: me?.first_name,
    lastName: me?.last_name,
  });

  return (
    <>
      <CreditUsage
        variant="tooltip"
        hideTooltip={openSettings}
      >
        <CreditCircularProgressWithAvatar
          ref={anchorRef}
          onClick={() => setOpenSettings(!openSettings)}
          size={AVATAR_SIZE}
          src={me?.profile_picture}
          alt={`${fullName} avatar`}
          credits={creditsData?.creditAsPercentage}
        />
      </CreditUsage>

      <StyledPopper
        anchorEl={anchorRef.current}
        open={openSettings}
        keepMounted
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
        ]}
        placement="bottom-end"
      >
        <Grow in>
          <StyledPaper square>
            <ClickAwayListener onClickAway={handleClose}>
              <Box>
                <ListItem {...SHARED_PROPS}>
                  <CreditUsage />
                </ListItem>

                <ListItem {...SHARED_PROPS}>
                  <ListItemButton
                    disableRipple
                    disableTouchRipple
                    onClick={handleThemeChange}
                    sx={{ justifyContent: 'start', gap: 1 }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <StyledStack
                        direction="row"
                        gap={2}
                      >
                        {themeMode === 'light' ? (
                          <WbSunnyOutlinedIcon sx={{ fontSize: '1.35em' }} />
                        ) : (
                          <DarkModeOutlinedIcon sx={{ fontSize: '1.35em' }} />
                        )}
                        <StyledListItemText
                          primary={t('main.sections.header.theme', {
                            defaultValue: 'Theme',
                          })}
                          primaryTypographyProps={{
                            variant: 'textSm',
                          }}
                        />
                      </StyledStack>
                      <ThemeMode />
                    </Stack>
                  </ListItemButton>
                </ListItem>

                <ListItem {...SHARED_PROPS}>
                  <ListItemButton
                    href={paths.tos.pathname}
                    target="_blank"
                  >
                    <StyledStack
                      direction="row"
                      gap={2}
                    >
                      <ContentPasteIcon sx={{ fontSize: '1.35em' }} />
                      <StyledListItemText
                        primary={t('main.sections.userAccount.tos', {
                          defaultValue: 'Terms of Service',
                        })}
                        primaryTypographyProps={{
                          variant: 'textSm',
                        }}
                      />
                    </StyledStack>
                  </ListItemButton>
                </ListItem>

                {hasSecuredAccount && (
                  <>
                    <Divider />
                    <ListItem {...SHARED_PROPS}>
                      <UserAuthentication />
                    </ListItem>
                  </>
                )}
              </Box>
            </ClickAwayListener>
          </StyledPaper>
        </Grow>
      </StyledPopper>
    </>
  );
}
