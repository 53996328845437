import { styled } from '@mui/material';
import Card from '@mui/material/Card';

const StyledImageCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.main,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    width: '20%',
  },
  [theme.breakpoints.up('md')]: {
    width: '10%',
  },
  maxHeight: '20%',
}));

export default StyledImageCard;
