/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';

import {
  WorkspaceRequestType,
  WorkspaceResponseType,
} from '@shared-types/workspace';

export const workspaces = {
  createOne: (body: WorkspaceRequestType) =>
    requests(axiosApiInstance).post<WorkspaceResponseType>(
      '/v1/entities/workspaces',
      body,
    ),
  getAll: () =>
    requests(axiosApiInstance).get<WorkspaceResponseType[]>(
      '/v1/entities/workspaces',
    ),
  updateOneById: ({ id, ...body }: WorkspaceRequestType) =>
    requests(axiosApiInstance).patch<WorkspaceResponseType>(
      `/v1/entities/workspaces/${id}`,
      body,
    ),
};
