import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(318),
  [theme.breakpoints.up('md')]: {
    width: theme.typography.pxToRem(519),
  },
}));
