import { workspaces } from '@lib/agent';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';

export default function useGetWorkspaces(id?: string) {
  const {
    data: workspaceData,
    isError: hasWorkspaceError,
    refetch: refetchWorkspaces,
    isFetchedAfterMount,
    isFetching: isWorkspaceFetching,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['workspaces'],
    queryFn: async () => {
      const { data } = await workspaces.getAll();

      return data || [];
    },
    options: {
      enabled: !!id,
    },
  });

  return {
    isFetchedAfterMount,
    refetchWorkspaces,
    workspaceData: workspaceData!,
    hasWorkspaceError,
    isWorkspaceFetching,
  };
}
