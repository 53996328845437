import { useContext } from 'react';

import { RoomMetadataContext } from '@components/pages/live-session/providers/RoomMetadataProvider';

export default function useRoomMetadata() {
  const context = useContext(RoomMetadataContext);

  if (!context) {
    throw new Error(
      'useRoomMetadata must be used within a RoomMetadataProvider',
    );
  }

  return context;
}
