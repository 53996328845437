import { Fragment, useCallback, useMemo, useRef } from 'react';

import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';

import LiveListItem from '@components/pages/live/sections/history/LiveListItem';
import {
  StyledEmptyHistoryStack,
  StyledList,
} from '@components/pages/live/sections/history/styles';
import StartRecording from '@components/pages/live/sections/start-recording';
import Text from '@components/text';

import useGetInfiniteThreadsLazily from '@hooks/useGetInfiniteThreadsLazily';

import { GetAllLiveThreadsResponseType } from '@shared-types/threads';

import { formatRelative } from 'date-fns';

type DateGroupType = {
  date: Date;
  threads: GetAllLiveThreadsResponseType[];
};

function isSameDay(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export default function History() {
  const {
    data,
    isLoading,
    hasNextPage,
    isError,
    error,
    fetchNextPage,
    isFetching,
  } = useGetInfiniteThreadsLazily<GetAllLiveThreadsResponseType>({
    type: 'live',
  });

  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: HTMLAnchorElement) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        entries[0].isIntersecting &&
          hasNextPage &&
          !isFetching &&
          fetchNextPage();
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchNextPage, hasNextPage, isFetching, isLoading],
  );

  const items = useMemo(() => {
    const items: DateGroupType[] = [];
    let currentGroup: DateGroupType | null = null;

    for (const page of data?.pages || []) {
      for (const thread of page.data) {
        const threadDate = new Date(thread.lastInteraction); // TODO: use createdAt instead (once available)

        if (!currentGroup || !isSameDay(currentGroup.date, threadDate)) {
          currentGroup = {
            date: threadDate,
            threads: [],
          };

          items.push(currentGroup);
        }

        currentGroup.threads.push(thread);
      }
    }

    return items;
  }, [data?.pages]);

  if (!data) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
      >
        <LinearProgress sx={{ width: '80%' }} />
      </Stack>
    );
  }

  if (!items.length) {
    return (
      <StyledEmptyHistoryStack>
        {isError && (
          <Alert severity="error">
            {error?.message ||
              'Something when wrong while loading previous sessions'}
          </Alert>
        )}
        <StartRecording />
      </StyledEmptyHistoryStack>
    );
  }

  return (
    <StyledList
      component={Stack}
      gap={6}
      sx={{ paddingBottom: 4 }}
    >
      {items.map(({ threads, date }, index) => (
        <Fragment key={index}>
          <Text
            variant="textSm"
            weight="medium"
            sx={{ textTransform: 'capitalize' }}
          >
            {formatRelative(date, new Date()).replace(/at \d.+$/, '')}
          </Text>
          {threads.map((item) => (
            <LiveListItem
              key={item.id}
              {...item}
              ref={lastElementRef}
            />
          ))}
        </Fragment>
      ))}
      {hasNextPage && isFetching && (
        <Stack sx={{ alignItems: 'center' }}>
          <CircularProgress />
        </Stack>
      )}
    </StyledList>
  );
}
