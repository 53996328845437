import { Theme } from '@mui/material';

export default function FormHelperText(theme: Theme) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.textSm.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          lineHeight: theme.typography.textSm.lineHeight,
        },
      },
    },
  };
}
