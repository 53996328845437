/* eslint-disable no-case-declarations */
import { queryClient } from '@providers/ReactQueryProvider';

import askAIFetcher from '@state/middleware/utils/askAiFetcher';
import subscribeToThread from '@state/middleware/utils/subscriber';
import { socketInstance } from '@state/middleware/websocket';
import { askAIErrored, userUpdated } from '@state/slices/assistant-result';
import { WSLoaded } from '@state/slices/websocket';
import { RootType } from '@state/store';

import { AssistantHistoryType } from '@shared-types/assistants';

import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Action, Middleware } from 'redux';

const assistantResultMiddleware: Middleware = ({ dispatch, getState }) => {
  let subscribeThread: string | null = null;

  return (next) => (action) => {
    const { type } = action as Action<
      'assistantResult/askAIEmitted' | 'assistantResult/streaming/finished'
    >;

    switch (type) {
      case 'assistantResult/askAIEmitted':
        dispatch(WSLoaded(true));
        socketInstance.socketMessageType = 'assistant';
        const { payload } = action;

        if (payload == null) return;

        (async function askAI() {
          try {
            subscribeThread = subscribeToThread(
              payload.thread,
              subscribeThread,
            );

            dispatch(
              userUpdated({
                text: payload.prompt,
                owner: {
                  firstName: '',
                  id: '',
                  lastName: '',
                  profilePicture: '',
                },
              }),
            );

            await askAIFetcher({
              type: 'assistant',
              thread: payload.thread,
              prompt: payload.prompt,
              use_public_knowledge: true,
            });
          } catch (error) {
            if (isAxiosError(error)) {
              dispatch(askAIErrored({ status: error.response!.status }));
              dispatch(WSLoaded(false));
              if (error.response!.status === 429) {
                toast.error('Too many requests. Please try again later.'); //TODO: we can not use useTranslation here because we are in a react component
                return;
              }
              if (error.response!.status === 402) {
                queryClient.invalidateQueries({
                  queryKey: ['credits'],
                });
                return;
              }
              toast.error(
                'An unexpected error occurred. Please try again later.',
              );
              console.error('Error asking AI', error);
              return;
            }
          }
        })();
        break;
      case 'assistantResult/streaming/finished':
        //? store the assistant result in the cache
        const threadId = new URL(window.location.href).searchParams.get('t');

        const WSResponse = (getState() as RootType).websocket.WSResponse;
        const user = (getState() as RootType).assistantResult.user;

        //? store data in a cache
        if (threadId == null || subscribeThread !== threadId) return;
        queryClient.setQueryData(
          ['historyBlocks', threadId],
          (oldHistoryBlocks: AssistantHistoryType[]) => {
            const newHistoryBlocks =
              oldHistoryBlocks == null ? [] : oldHistoryBlocks;

            return [
              ...newHistoryBlocks,
              {
                text: user.text,
                owner: user.owner,
              },
              {
                text: WSResponse.assistant.text,
              },
            ];
          },
        );
        break;
    }
    next(action);
  };
};

export default assistantResultMiddleware;
