import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  fontSize: theme.typography.textXs.fontSize,
  width: 'fit-content',
  height: 30,
  padding: theme.spacing(1.5),
}));

export default StyledChip;
