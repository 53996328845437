import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetCommandBlockDefinitions } from '@components/pages/command-designer/hooks/useCommandBlocks';
import { getStaticGroups } from '@components/pages/command-designer/lib/helpers';

import { CommandBlockType } from '@lib/agent';

import { useGetCommand } from '@hooks/useCommands';

import { ReactToolboxConfiguration } from 'sequential-workflow-designer-react';

export default function useStepsDefinition() {
  const { id } = useParams();
  const { commandData, isLoadingCommand } = useGetCommand(id!);
  const command = commandData?.data;
  const [toolboxConfiguration, setToolboxConfiguration] = useState<
    ReactToolboxConfiguration | false
  >({
    groups: [...getStaticGroups()],
  });
  const { mutateAsync: fetchBlockDefinitions, isPending: isLoadingBlocks } =
    useGetCommandBlockDefinitions();

  const [isLoading, setIsLoading] = useState(false);

  const updateToolboxConfiguration = async (blocks: CommandBlockType[]) => {
    setIsLoading(true);
    let blocksData = await fetchBlockDefinitions(blocks);

    blocksData = blocksData
      .filter((block: any) => block.status === 'fulfilled')
      .map((block: any) => block.value)
      .map((block: any) => block.data);

    setToolboxConfiguration({
      groups: [
        ...getStaticGroups(),
        {
          name: 'default',
          steps: blocksData.map((step: any) => ({
            ...step,
            id: undefined,
            isExpanded: step.type === 'block' ? false : undefined,
          })),
        },
      ],
    });
    setTimeout(() => setIsLoading(false));
  };

  useEffect(() => {
    if (commandData) {
      const command = commandData?.data;
      updateToolboxConfiguration(command?.commandBlocks || []);
    }
  }, [commandData]);

  const isLoadingStepsDefinition =
    isLoadingCommand || isLoadingBlocks || isLoading;

  return {
    command,
    isLoadingStepsDefinition,
    toolboxConfiguration,
    updateToolboxConfiguration,
  };
}
