import MuiList from '@mui/material/List';
import Stack from '@mui/material/Stack';

import ChatHistory from '@components/pages/assistants-session/chat-history';
import MessageField from '@components/pages/assistants-session/message-field';
import StreamingChatBubble from '@components/pages/assistants-session/streaming-chat';
import WelcomeMessage from '@components/pages/assistants-session/welcome-message';
import SourcePreviewProvider from '@components/source-preview/providers/SourcePreviewProvider';

import { convertPxToRem } from '@lib/fonts';

import useAssistantsSession from '@layouts/assistants-session/hooks/useAssistantsSession';
import CONFIG_HEADER from '@layouts/main/config';

export default function AssistantsSession() {
  const { isHistoryDrawerOpen } = useAssistantsSession();

  return (
    <SourcePreviewProvider>
      <Stack
        direction="column"
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          ...(isHistoryDrawerOpen && {
            overflow: {
              xs: 'unset',
              md: 'hidden',
            },
          }),
        }}
      >
        <Stack
          gap={6}
          sx={{
            height: `calc(100vh - ${convertPxToRem(
              CONFIG_HEADER.HEADER.HEIGHT + CONFIG_HEADER.HEADER.PADDING_Y * 4,
            )})`,
            ...(isHistoryDrawerOpen && {
              paddingRight: 2,
              overflow: {
                xs: 'unset',
                md: 'auto',
              },
            }),
          }}
        >
          <WelcomeMessage />
          <Stack
            sx={{
              paddingBlockEnd: 30, //? to make sure the last item is not overlapped by the message field
            }}
          >
            <MuiList
              disablePadding
              component={Stack}
              gap={6}
              role="list"
            >
              <ChatHistory />
              <StreamingChatBubble />
            </MuiList>
          </Stack>
        </Stack>
        <MessageField />
      </Stack>
    </SourcePreviewProvider>
  );
}
