import Container from '@mui/material/Container';

import Commands from '@components/pages/commands/Commands';
import {
  StyledCenteredContent,
  StyledViewportHeightStack,
} from '@components/pages/styles';

export default function CommandsIndex() {
  return (
    <Container>
      <StyledViewportHeightStack>
        <StyledCenteredContent gap={8}>
          <Commands />
        </StyledCenteredContent>
      </StyledViewportHeightStack>
    </Container>
  );
}
