import { useMemo } from 'react';

import useAuth from '@hooks/useAuth';

export type UserFunnelType = 'visitor' | 'free' | 'pack' | 'subscribed';

export default function useUserFunnelType() {
  const { me } = useAuth();

  const userFunnelType = useMemo((): UserFunnelType => {
    if (me == null) return 'visitor';
    if (me.subscription) return 'subscribed';
    if (me.paid) return 'pack';
    if (me.auth_type === 'web_fingerprint') return 'visitor';

    return 'free';
  }, [me]);

  return { userFunnelType };
}
