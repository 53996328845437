import AnswerBlock from '@components/pages/search/components/shared-blocks/answer';

import { RootType } from '@state/store';

import { AnswerType } from '@shared-types/search-result/types';

import { useSelector } from 'react-redux';

export default function StreamingAnswer() {
  const answer = useSelector<RootType, AnswerType>(
    (state) => state.websocket.WSResponse.searchResult.layout.answer,
  );

  if (answer == null) return null;

  return (
    <AnswerBlock
      type="websocket"
      answer={answer.text}
      id={answer.id}
    />
  );
}
