import { useContext } from 'react';

import { PopoverContext } from '@layouts/main/sections/toolbar/components/knowledge-base-popover/providers/PopoverProvider';

export default function usePopover() {
  const context = useContext(PopoverContext);

  if (!context) {
    throw new Error('usePopover must be used within a PopoverProvider');
  }

  return context;
}
