/* eslint-disable import/prefer-default-export */
import requests, { responseBody } from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import axiosAwsInstance from '@lib/agent/services/aws/axiosInstance';

import {
  CredentialsType,
  DeleteFileType,
  FileUploadType,
  PolicyRequestType,
  PolicyType,
} from '@shared-types/storage';

export const storage = {
  createPolicy: <T>(body: PolicyRequestType<T>) =>
    requests(axiosApiInstance).post<PolicyType>(
      '/v1/actions/storage/policy',
      body,
    ),
  getCredentials: (s3Key: string) => {
    return requests(axiosApiInstance).get<CredentialsType>(
      `/v1/actions/storage/credentials?key=${s3Key}`,
    );
  },

  uploadFile: ({ url, contentType, body, config }: FileUploadType) =>
    axiosAwsInstance
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .put<any>(url, body, {
        headers: { 'Content-Type': contentType },
        ...config,
      })
      .then(responseBody),

  deleteFile: ({ key }: DeleteFileType) =>
    requests(axiosApiInstance).delete('/v1/actions/storage', {
      data: {
        key: key,
      },
    }),
};
