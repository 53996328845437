import { useEffect } from 'react';

import useCollectAllSmartTags from '@components/pages/live/hooks/useGetAllSmartTags';

import useAppDispatch from '@hooks/useAppDispatch';
import useLocalStorage from '@hooks/useLocalStorage';

import {
  groupedTrainingSetsUpdated,
  InitialStateType,
} from '@state/slices/live';

export default function useAutoSelectAllTagsOnMount() {
  const dispatch = useAppDispatch();
  const { collectedSmartTags, areCollectedSmartTagsSuccessfullyLoaded } =
    useCollectAllSmartTags();
  const { value: live } = useLocalStorage<InitialStateType | null>('live');

  useEffect(() => {
    if (live) return;
    if (areCollectedSmartTagsSuccessfullyLoaded) {
      dispatch(
        groupedTrainingSetsUpdated({
          actionPointsLogger: collectedSmartTags.actionPointsLogger.items.map(
            (item) => item.id,
          ),
          conversationModeration:
            collectedSmartTags.conversationModeration.items.map(
              (item) => item.id,
            ),
          minutesTracker: collectedSmartTags.minutesTracker.items.map(
            (item) => item.id,
          ),
          salesConversationAnalyser:
            collectedSmartTags.salesConversationAnalyser.items.map(
              (item) => item.id,
            ),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areCollectedSmartTagsSuccessfullyLoaded]);
}
