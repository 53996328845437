import LoadingDropdown from '@layouts/main/sections/toolbar/components/knowledge-base-popover/sections/content/tabs/tab-panels/components/LoadingDropdown';
import useGetInvitedWorkspaces from '@layouts/main/sections/toolbar/components/knowledge-base-popover/sections/content/tabs/tab-panels/hooks/useGetInvitedWorkspaces';
import Dropdown from '@layouts/main/sections/toolbar/components/knowledge-base-popover/sections/content/tabs/tab-panels/sections/invited-to/dropdown';

export default function InvitedTo() {
  const { invitedWorkspacesData, isWorkspaceFetching } =
    useGetInvitedWorkspaces();

  if (isWorkspaceFetching) {
    return <LoadingDropdown />;
  }

  return <Dropdown options={invitedWorkspacesData} />;
}
