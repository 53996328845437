import { useState } from 'react';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import EllipsisText from '@components/ellipsis-typography';
import {
  StyledListItem,
  StyledListItemImage,
} from '@components/pages/sso/styles';

import serviceBranding from '@lib/auth/serviceBranding';

import { useTranslation } from '@desygner/ui-common-translation';

const ICON_SIZE = 84;

export default function SsoListItem({
  accessConfiguration: { name, provider },
  onOpen,
  onRemove,
}: {
  accessConfiguration: { name: string; provider: string };
  onOpen: (e: React.MouseEvent) => void;
  onRemove: (e: React.MouseEvent) => void;
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  return (
    <StyledListItem
      slots={{ root: 'div' }}
      onClick={(event) => isOpen || setAnchorEl(event.currentTarget)}
    >
      <StyledListItemImage
        sx={{ mt: 3 }}
        src={serviceBranding[provider || 'custom'].icon}
        alt={name}
        loading="lazy"
        height={ICON_SIZE}
      />
      <Box sx={{ p: 3 }}>
        <EllipsisText
          variant="textMd"
          fontWeight={600}
          component="h2"
          lineClamp={2}
          title={name}
        >
          {name}
        </EllipsisText>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={(event) => {
            setAnchorEl(null);
            onOpen(event);
          }}
        >
          {t('page.sso.open', { defaultValue: 'Edit Configuration' })}
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            setAnchorEl(null);
            onRemove(event);
          }}
        >
          {t('page.sso.delete', { defaultValue: 'Delete Configuration' })}
        </MenuItem>
      </Menu>
    </StyledListItem>
  );
}
