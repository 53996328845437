import HubIcon from '@mui/icons-material/Hub';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';

import { ROLES } from '@lib/roles';

import { SetupModeType } from '@providers/WorkspaceProvider';

import paths from '@router/paths';
import BubbleMessageSmile from '~icons/knowz-iconify/bubble-message-smile';
import Clock from '~icons/knowz-iconify/clock-fast-forward';
import Cube from '~icons/knowz-iconify/cube';
import SearchSmart from '~icons/knowz-iconify/search-smart';

const DELAY_HIDE_EXPANDED_BUTTON = 3500;

type ToolbarItemType = {
  isAlpha?: boolean;
  setupMode: SetupModeType[];
  mappedLabel: string;
  defaultLabel: string;
  icon: JSX.Element;
  url: string;
};

const ICON_FONT_SIZE = '0.9em';

const TOOLBAR_ITEMS: ToolbarItemType[] = [
  {
    setupMode: ROLES.SEARCH,
    mappedLabel: 'layout.toolbar.buttons.search',
    defaultLabel: 'Search',
    icon: <SearchSmart fontSize={ICON_FONT_SIZE} />,
    url: paths.home.pathname,
  },
  {
    setupMode: ROLES.LIVE,
    mappedLabel: 'layout.toolbar.buttons.live',
    defaultLabel: 'Live',
    icon: <BubbleMessageSmile fontSize={ICON_FONT_SIZE} />,
    url: paths.live.pathname,
  },
  {
    isAlpha: true,
    setupMode: ROLES.ASSISTANTS,
    mappedLabel: 'layout.toolbar.buttons.assistants',
    defaultLabel: 'Assistants',
    icon: <SmartToyOutlinedIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
    url: paths.assistants.pathname,
  },
  {
    // TODO: consider removing section and handle in each other section instead
    setupMode: ROLES.HISTORY,
    mappedLabel: 'layout.toolbar.buttons.history',
    defaultLabel: 'History',
    icon: <Clock fontSize={ICON_FONT_SIZE} />,
    url: paths.history.pathname,
  },
  {
    isAlpha: true,
    setupMode: ROLES.COMMANDS,
    mappedLabel: 'layout.toolbar.buttons.commands',
    defaultLabel: 'Commands',
    icon: <KeyboardCommandKeyIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
    url: paths.commands.pathname,
  },
  {
    isAlpha: true,
    setupMode: ROLES.CONNECTORS,
    mappedLabel: 'layout.toolbar.buttons.connectors',
    defaultLabel: 'Connectors',
    icon: <HubIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
    url: paths.connectors.pathname,
  },
  {
    isAlpha: true,
    setupMode: ROLES.USERS,
    mappedLabel: 'layout.toolbar.buttons.users',
    icon: <ManageAccountsIcon sx={{ fontSize: ICON_FONT_SIZE }} />,
    defaultLabel: 'Users',
    url: paths.users.pathname,
  },
  // {
  //   isAlpha: false,
  //   setupMode: ROLES.KNOWLEDGE_BASE, // TODO: member and individual
  //   mappedLabel: 'layout.toolbar.buttons.knowledgeBase',
  //   defaultLabel: 'Knowledge Base',
  //   icon: <Cube fontSize={ICON_FONT_SIZE} />,
  //   url: paths.knowledgeBase.pathname,
  // },
];

const IS_WORKSPACE_SELECTOR_ALPHA = true;

const CONFIG = {
  PATH_TO_SHOW_COLLAPSING_BUTTON: 20,
  TOOLBAR_MINI: {
    DESKTOP: {
      WIDTH: 50,
    },
    MOBILE: {
      WIDTH: 20,
      HEIGHT: 60,
    },
  },
  CREDIT_USAGE: {
    DESKTOP: {
      WIDTH: 44,
    },
    MOBILE: {
      HEIGHT: 44,
    },
  },
  WIDTH_EXPANDED: 295,
  TOOLBAR_ITEMS,
  IS_WORKSPACE_SELECTOR_ALPHA,
  DELAY_HIDE_EXPANDED_BUTTON,
} as const;

export default CONFIG;
