import { styled } from '@mui/material';

import { StyledContainerForFixedHeader } from '@components/pages/styles';

import CONFIG_ASSISTANTS_SESSION from '@layouts/assistants-session/config';

type Props = {
  open?: boolean;
};

const StyledContainer = styled(StyledContainerForFixedHeader)<Props>(
  ({ theme, open }) => ({
    ...(open && {
      width: `calc(100% - ${CONFIG_ASSISTANTS_SESSION.DRAWER.WIDTH}px)`,
      marginRight: `${CONFIG_ASSISTANTS_SESSION.DRAWER.WIDTH}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
    },
  }),
);

export default StyledContainer;
