import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledWrapperStack = styled(Stack)<{ checked: boolean }>(
  ({ theme, checked }) => ({
    backgroundColor: theme.palette.background.secondary,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2,
    outlineWidth: '1px',
    outlineStyle: 'solid',
    outlineColor: checked
      ? theme.palette.primary.main
      : theme.palette.background.secondary,
    '&:hover': {
      outlineColor: checked
        ? theme.palette.primary.main
        : theme.palette.grey[300],
    },
    '& fieldset': {
      borderColor: `${theme.palette.grey[300]}!important`, //TODO: talk to Jamie about this
    },
  }),
);

export const StyledInnerStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
}));
