import Stack from '@mui/material/Stack';

import Wrapper from '@layouts/assistants-session/components/wrapper';
import AssistantsSessionProvider from '@layouts/assistants-session/providers/AssistantsSessionProvider';
import ToolbarProvider from '@layouts/main/sections/toolbar/providers/ToolbarProvider';

export default function AssistantsSessionLayout({
  children,
}: React.PropsWithChildren) {
  return (
    <ToolbarProvider>
      <AssistantsSessionProvider>
        <AssistantsSessionProvider.Header />
        <Stack direction="row">
          <Wrapper>
            <AssistantsSessionProvider.Main>
              {children}
            </AssistantsSessionProvider.Main>
            <AssistantsSessionProvider.Drawer />
          </Wrapper>
        </Stack>
      </AssistantsSessionProvider>
    </ToolbarProvider>
  );
}
