import StatusLabel from '@components/pages/knowledge-base/components/status-label';
import calculateSyncStatusLabel from '@components/pages/knowledge-base/components/table/utils/calculateSyncStatusLabel';

import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';

import { CellContext } from '@tanstack/react-table';

export default function SyncStatus({
  row,
}: CellContext<FolderType | SourceType, unknown>) {
  const isItAFolder = 'breadcrumb' in row.original;
  if (isItAFolder) return null;
  const source = row.original as SourceType;

  return (
    <span>
      <StatusLabel
        status={calculateSyncStatusLabel({
          isDigested: source.isDigested,
          isDigestedFail: source.isDigestedFail,
        })}
      />
    </span>
  );
}
