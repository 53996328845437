import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import { StyledBgGradient } from '@components/pages/styles';

import ToolbarProvider from '@layouts/main/sections/toolbar/providers/ToolbarProvider';
import { StyledToolbarBox } from '@layouts/main/sections/toolbar/styles';

export default function FullScreenLayout({
  children,
}: React.PropsWithChildren) {
  return (
    <Container
      maxWidth={false}
      disableGutters
    >
      <StyledBgGradient aria-hidden />
      <ToolbarProvider>
        <Stack direction="row">
          <StyledToolbarBox>
            <ToolbarProvider.NavbarDrawer />
          </StyledToolbarBox>

          <Box sx={{ width: '100%' }}>
            <ToolbarProvider.Content>{children}</ToolbarProvider.Content>
          </Box>
        </Stack>
      </ToolbarProvider>
    </Container>
  );
}
