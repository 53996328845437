import Stack from '@mui/material/Stack';

import GroupWrapper from '@components/pages/knowledge-base/components/table/action-drawer/panels/details/group-wrapper';

import { useTranslation } from '@desygner/ui-common-translation';

type Props = {
  name: React.ReactNode;
  format: React.ReactNode;
  location: React.ReactNode;
  createdBy: React.ReactNode;
  size: React.ReactNode;
  updatedAt: React.ReactNode;
  createdAt: React.ReactNode;
};

export default function Slot({
  createdAt,
  createdBy,
  format,
  location,
  name,
  size,
  updatedAt,
}: Props) {
  const { t } = useTranslation();

  return (
    <Stack gap={4}>
      <GroupWrapper
        title={t('page.knowledgeBase.admin.header.actionDrawer.tab1.name', {
          defaultValue: 'Name',
        })}
      >
        {name}
      </GroupWrapper>
      <GroupWrapper
        title={t('page.knowledgeBase.admin.header.actionDrawer.tab1.format', {
          defaultValue: 'Format',
        })}
      >
        {format}
      </GroupWrapper>
      <GroupWrapper
        title={t('page.knowledgeBase.admin.header.actionDrawer.tab1.location', {
          defaultValue: 'Location',
        })}
      >
        {location}
      </GroupWrapper>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.createdBy',
          {
            defaultValue: 'Created By',
          },
        )}
      >
        {createdBy}
      </GroupWrapper>
      <GroupWrapper
        title={t('page.knowledgeBase.admin.header.actionDrawer.tab1.size', {
          defaultValue: 'Size',
        })}
      >
        {size}
      </GroupWrapper>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.updatedAt',
          {
            defaultValue: 'Updated At',
          },
        )}
      >
        {updatedAt}
      </GroupWrapper>
      <GroupWrapper
        title={t(
          'page.knowledgeBase.admin.header.actionDrawer.tab1.createdAt',
          {
            defaultValue: 'Created At',
          },
        )}
      >
        {createdAt}
      </GroupWrapper>
    </Stack>
  );
}
