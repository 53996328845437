import { folders } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import { FolderType } from '@shared-types/folders';

import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

type Props = {
  id?: number | null;
};

export default function useDeleteFolderById({ id: parentFolderId }: Props) {
  const { t } = useTranslation();
  const { getNamespacedQueryKey } = useWorkspace();
  const calculateFolderId =
    typeof parentFolderId === 'undefined' ? null : parentFolderId;
  const queryKey = getNamespacedQueryKey('folders', calculateFolderId);

  const { mutate: mutateFolder } = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      const { data } = await folders.deleteOne({
        id,
      });
      return data;
    },
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      queryClient.setQueryData(queryKey, (oldFolders: FolderType[] | null) => {
        return oldFolders?.filter((folder) => folder.id !== id);
      });
    },
    onError: (error) => {
      if (
        isAxiosError(error) &&
        error.response?.status === 400 &&
        error.response?.data?.message === 'Folder has sources'
      ) {
        toast.error(
          t(
            'page.knowledgeBase.admin.actions.delete.folder.toast.deleteConstraint',
            {
              defaultValue: 'Folder has got some sources in it.',
            },
          ),
        );
        return;
      }
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Unknown error. Please try again later.',
        }),
      );
      console.error(`An error occurred while deleting a folder: ${error}`);
    },
    onSuccess: () => {
      toast.success(
        t('page.knowledgeBase.admin.actions.delete.folder.toast.success', {
          defaultValue: 'Folder has been deleted successfully.',
        }),
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getNamespacedQueryKey('folders', parentFolderId),
      });
    },
  });

  return {
    mutateFolder,
  };
}
