import { ContainerOwnProps, ContainerProps, Theme } from '@mui/material';

type Props = ContainerProps & {
  ownerState: ContainerOwnProps;
};

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: (Props: Props) => ({
          [theme.breakpoints.up('xs')]: !Props.ownerState.disableGutters && {
            paddingInlineStart: theme.spacing(4),
            paddingInlineEnd: theme.spacing(4),
          },
        }),
      },
    },
  };
}
