import { useEffect, useRef } from 'react';

import noSleep from 'nosleep.js';

type Props = {
  enabled: boolean;
};

export default function useStayAwake({ enabled }: Props) {
  const { current: noSleepInstance } = useRef(new noSleep());

  useEffect(() => {
    if (enabled) {
      noSleepInstance.enable();
      return;
    }

    noSleepInstance.disable();

    return () => {
      noSleepInstance.disable();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
}
