import { styled } from '@mui/material';
import ListItem from '@mui/material/ListItem';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  flex: 1,
  paddingBlockStart: theme.spacing(6),
  paddingBlockEnd: theme.spacing(6),
  alignItems: 'flex-start',
  minHeight: 320,
}));

export default StyledListItem;
