import { useEffect, useMemo, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import RedoIcon from '@mui/icons-material/Redo';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import UndoIcon from '@mui/icons-material/Undo';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Modal from '@components/modal';
import { StyledProperties } from '@components/pages/command-designer/sections/step-editor/styles';
import Text from '@components/text';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';

import {
  closeRun,
  decreaseStep,
  increaseStep,
  setIsAutoPlay,
} from '@state/slices/flow-run';

import { JSONTree } from 'react-json-tree';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function trimStringsRecursively(obj: any) {
  const maxStringLength = 130;

  if (typeof obj === 'string') {
    return obj.length > maxStringLength
      ? obj.substring(0, maxStringLength) + '...'
      : obj;
  }

  if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      obj[key] = trimStringsRecursively(obj[key]);
    });
  }

  return obj;
}

export default function Runner() {
  const {
    runnerStatus,
    currentEvent: event,
    isAutoPlay,
    isFirstStep,
    isLastStep,
  } = useAppSelector((state) => state.flowRun);
  const dispatch = useAppDispatch();
  const [outcome, commandOutcome] = useState<'success' | 'error' | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const trimmedDataBag = useMemo(() => {
    if (!event?.dataBag) return null;
    return trimStringsRecursively(JSON.parse(JSON.stringify(event.dataBag)));
  }, [event?.dataBag]);

  const handleNextStep = () => {
    isAutoPlay && dispatch(setIsAutoPlay(false));
    dispatch(increaseStep());
  };

  const handlePreviousStep = () => {
    isAutoPlay && dispatch(setIsAutoPlay(false));
    dispatch(decreaseStep());
  };

  const jsonTheme = useMemo(
    () => ({
      base00: 'transparent',
      base01: '#383830',
      base02: '#49483e',
      base03: '#75715e',
      base04: '#a59f85',
      base05: '#f8f8f2',
      base06: '#f5f4f1',
      base07: '#f9f8f5',
      base08: '#f92672',
      base09: '#fd971f',
      base0A: '#f4bf75',
      base0B: '#a6e22e',
      base0C: '#a1efe4',
      base0D: '#66d9ef',
      base0E: '#ae81ff',
      base0F: '#cc6633',
    }),
    [],
  );

  useEffect(() => {
    const endStates = ['FINISHED', 'FAILED'];
    const states = event?.state || [];

    if (endStates.includes(states[0])) {
      commandOutcome(states[0] === 'FINISHED' ? 'success' : 'error');
    }
  }, [event]);

  return (
    <StyledProperties justifyContent="space-between">
      <Modal
        paddingType="sm"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        width="90vw"
        height="90vh"
      >
        <Box overflow="scroll">
          <JSONTree
            hideRoot={true}
            theme={jsonTheme}
            data={event?.dataBag}
            shouldExpandNodeInitially={() => true}
          />
        </Box>
      </Modal>

      <Stack>
        {outcome === 'success' && (
          <Alert
            sx={{ mb: 2 }}
            icon={<CheckIcon />}
            severity="success"
          >
            The Command has been successfully executed.
          </Alert>
        )}

        {outcome === 'error' && (
          <Alert
            sx={{ mb: 2 }}
            icon={<CloseIcon />}
            severity="error"
          >
            The Command has failed to execute.
          </Alert>
        )}

        {outcome === null && isAutoPlay && runnerStatus === 'triggering' && (
          <Alert
            sx={{ mb: 2 }}
            icon={<RocketLaunchIcon />}
            severity="info"
          >
            Triggering Command...
          </Alert>
        )}

        {outcome === null && isAutoPlay && runnerStatus === 'running' && (
          <Alert
            sx={{ mb: 2 }}
            icon={<CircularProgress size="21px" />}
          >
            Running Command...
          </Alert>
        )}

        {outcome === null && !isAutoPlay && (
          <Alert
            sx={{ mb: 2 }}
            icon={<TroubleshootIcon />}
            severity="info"
          >
            Reviewing Command execution
          </Alert>
        )}

        <Stack
          sx={{ mb: 2 }}
          spacing={1}
          direction="row"
          justifyContent="center"
        >
          <Button
            sx={{ width: '50%' }}
            variant="outlined"
            startIcon={<UndoIcon />}
            onClick={handlePreviousStep}
            disabled={isFirstStep}
          >
            Previous step
          </Button>

          <Button
            sx={{ width: '50%' }}
            variant="outlined"
            endIcon={<RedoIcon />}
            onClick={handleNextStep}
            disabled={isLastStep || isAutoPlay}
          >
            Next step
          </Button>
        </Stack>

        {(outcome !== null || !isAutoPlay) && (
          <Button
            onClick={() => dispatch(closeRun())}
            variant="outlined"
            color="error"
          >
            Close Command Runner
          </Button>
        )}

        {event?.dataBag && (
          <>
            <Stack
              direction="row"
              sx={{ mt: 2 }}
              alignItems="center"
            >
              <Text variant="displayXs">Data trace</Text>
              <IconButton
                sx={{ ml: 1 }}
                onClick={() => setIsModalOpen(true)}
                disabled={!event?.dataBag}
              >
                <ManageSearchIcon />
              </IconButton>
            </Stack>
            <JSONTree
              hideRoot={true}
              theme={jsonTheme}
              data={trimmedDataBag}
              shouldExpandNodeInitially={(keyPath, data, level) => {
                return level === 1;
              }}
            />
          </>
        )}

        {outcome === 'error' && (
          <>
            <Text
              variant="displayXs"
              sx={{ mt: 4 }}
            >
              Error
            </Text>
            <JSONTree
              hideRoot={true}
              theme={jsonTheme}
              data={event?.error}
            />
          </>
        )}
      </Stack>
    </StyledProperties>
  );
}
