import { Theme } from '@mui/material';

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: {
                size: 'fit',
              },
              style: {
                borderRadius: '7px',
                border: '1px solid #CCD4FF', // TODO: design system
                background: '#F0F2FF', // TODO: design system
                padding: '5px',

                '& svg': {
                  fill: '#3F3FF8', // TODO: design system
                },

                '&:hover': {
                  background: '#F0F2FF', // TODO: design system
                },
              },
            },
          ],
        },
      },
    },
  };
}
