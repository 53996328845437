import { NavLinkProps } from 'react-router-dom';

import { styled } from '@mui/material';
import ListItem, { ListItemProps } from '@mui/material/ListItem';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&.active': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.card.main,
    '& svg': {
      color: theme.palette.text.primary,
    },
  },
  color: theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 600],
  borderRadius: theme.shape.borderRadius * 2,
  padding: 0,
  margin: theme.spacing(0.5, 0),
  fontSize: '1.3em',
})) as React.ComponentType<ListItemProps & NavLinkProps>;

export default StyledListItem;
