import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';

import Text from '@components/text';

export const LEFT_COLUMN_WIDTH = {
  md: 320,
  lg: 420,
  xl: 600,
};

export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: '1px solid',
  borderColor: theme.palette.divider,
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
  height: `calc(100% - 80px)`, // Recorder height + gap
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.default,

  '& > :first-of-type': {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: LEFT_COLUMN_WIDTH.md,

    [theme.breakpoints.up('lg')]: {
      flexBasis: LEFT_COLUMN_WIDTH.lg,
    },

    [theme.breakpoints.up('xl')]: {
      flexBasis: LEFT_COLUMN_WIDTH.xl,
    },
  },
})) as typeof Paper;

export const StyledBox = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  padding: theme.spacing(4),
}));

export const StyledText = styled(Text)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  paddingInlineEnd: 0,
}));
