/**
 * These paths are used by the router to navigate to different parts of the app
 * and also exported to be used at infrastructure level for routing.
 **/
const rawAppPaths = {
  connectors: '/app/settings/connectors',
  commands: '/app/commands',
  editCommand: '/app/commands/editor/:id',
  assistants: '/app/assistants',
  assistantsSession: '/app/assistants/session',
  home: '/',
  search: '/app/search',
  history: '/app/history',
  live: '/app/live',
  liveSession: '/app/live/session',
  sso: '/app/settings/sso',
  editSso: '/app/settings/sso/:id',
  knowledgeBase: '/app/settings/knowledge-base',
  users: '/app/workspace/users',
  getTheApp: '/get-the-app',
  upgradeAccount: '/app/upgrade-account',
};

export default rawAppPaths;
