import { Link, useLocation } from 'react-router-dom';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import usePagination from '@mui/material/usePagination';

import {
  StyledList,
  StyledNav,
  StyledNextAndPrevButton,
  StyledPageButton,
} from '@components/pagination/styles';

import useQueryParams from '@hooks/useQueryParams';

import qs from 'qs';

type Props = {
  pageCount?: number;
};

export default function Pagination({ pageCount }: Props) {
  const { getQueryParamByKey, allQueryParams } = useQueryParams();

  const { items } = usePagination({
    count: pageCount,
    page: parseInt(getQueryParamByKey('page')) || 1,
  });

  const { pathname } = useLocation();

  return (
    <StyledNav>
      <StyledList>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <StyledPageButton
                LinkComponent={Link}
                to={{
                  pathname,
                  search: qs.stringify({
                    ...allQueryParams,
                    page: (page || 1).toString(),
                  }),
                }}
                isSelected={selected}
                {...item}
              >
                {page as number}
              </StyledPageButton>
            );
          } else {
            children = (
              <StyledNextAndPrevButton
                LinkComponent={Link}
                to={{
                  pathname,
                  search: qs.stringify({
                    ...allQueryParams,
                    page: (page || 1).toString(),
                  }),
                }}
                {...item}
              >
                {type === 'next' ? <ChevronRight /> : <ChevronLeft />}
              </StyledNextAndPrevButton>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </StyledList>
    </StyledNav>
  );
}
