import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';

import StyledChip from '@components/pages/live-session/sections/content/components/feed/list-item/summary/styles';
import Text from '@components/text';

import { TimeType } from '@shared-types/live';

type Props = {
  subTitle: string;
  avatar: string;
  name: string;
  time: TimeType;
};

const AVATAR_SIZE = 24;

export const getOutcomeLabel = (label: string) => {
  return (
    {
      true: 'True Claim',
      false: 'False Claim',
      mixed: 'Mixed Claim',
      unverifiable: 'Unverifiable Claim',
    }[label] || label
  );
};

export default function Summary({ subTitle, avatar, time, name }: Props) {
  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Avatar
        src={avatar}
        sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
      />

      <Text
        variant="textMd"
        color="text.secondary"
        weight="medium"
        sx={{ marginInlineStart: 3 }}
      >
        {name}
      </Text>

      <Text
        variant="textXs"
        color="text.disabled"
        component="time"
        sx={{ marginInlineStart: 2 }}
      >
        {time.minutes}:{time.seconds}
      </Text>

      <StyledChip
        label={getOutcomeLabel(subTitle)}
        size="small"
        variant="outlined"
      />
    </AccordionSummary>
  );
}
