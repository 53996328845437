import { useRef, useState } from 'react';

import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { getStepPropertiesDefinition } from '@components/pages/command-designer/config';
import ToolbarContents, {
  ToolbarOptionType,
} from '@components/pages/command-designer/dialogs/smart-prompt-editor/ToolbarContents';
import { StyledTextArea } from '@components/pages/command-designer/sections/step-editor/styles';
import Text from '@components/text';

import { convertPxToRem } from '@lib/fonts';
import { StepType } from '@lib/step/types';

import { DialogProps } from '@toolpad/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { anOldHope } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useStepEditor } from 'sequential-workflow-designer-react';
import { CONTEXT_HEIGHT } from '../smart-prompt-editor';

export function CodeViewer({
  code,
  onClick,
}: {
  code: string;
  onClick: () => void;
}) {
  const theme = useTheme();

  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: (theme) =>
          alpha(
            theme.palette.mode === 'light'
              ? theme.palette.common.black
              : theme.palette.common.white,
            0.05,
          ),
        borderRadius: 2,
        cursor: 'pointer',
        overflow: 'auto',
        height: '10em',
        lineHeight: '1.2em',
        boxSizing: 'content-box',
        border: (theme) =>
          `1px solid ${alpha(theme.palette.text.primary, 0.23)}`,

        '&:hover': {
          borderColor: (theme) => theme.palette.neutral.border,
        },
      }}
    >
      <SyntaxHighlighter
        language="javascript"
        wrapLines={false}
        wrapLongLines={true}
        showLineNumbers={false}
        showInlineLineNumbers={false}
        style={anOldHope}
        customStyle={{
          flex: '1',
          color: theme.palette.text.primary,
          background: 'transparent',
        }}
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  );
}

type PayloadType = {
  code: string;
  propertyId: string;
};

type CursorPositionType = {
  start: number;
  end: number;
};

export function CodeEditorDialog({
  open,
  onClose,
  payload: { code, propertyId },
}: DialogProps<PayloadType, string | null>) {
  const [lastCursor, setLastCursor] = useState<CursorPositionType>({
    start: 0,
    end: 0,
  });

  const [updatedCode, setUpdatedCode] = useState(code);
  const theme = useTheme();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { step } = useStepEditor();
  const stepPropertiesDefinition = getStepPropertiesDefinition(
    step as StepType,
  );

  const property = stepPropertiesDefinition.find((p) => p.id === propertyId);

  function handleOptionsClick({ value, type, name }: ToolbarOptionType) {
    const { start, end } = lastCursor;

    const option = name
      ? `:secret[${name}]`
      : `:context[${value}]{type="${type}"}`;

    setUpdatedCode(
      updatedCode.substring(0, start) + option + updatedCode.substring(end),
    );

    setTimeout(() => {
      textareaRef.current!.selectionStart = textareaRef.current!.selectionEnd =
        start + option.length;
    });
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(_, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose(null);
      }}
      sx={{
        '& .MuiDialog-container': {
          '& > .MuiPaper-root': {
            width: '90vw',
            maxWidth: '1080px',
            height: '90vh',
            maxHeight: '800px',
          },
        },
      }}
    >
      <DialogTitle>
        <Text
          variant="displaySm"
          component="span"
          sx={{ px: 2 }}
        >
          {property?.name}
        </Text>
        <Text
          variant="textMd"
          sx={{ px: 2 }}
        >
          {property?.description}
        </Text>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            background: (theme) => theme.palette.background.paper,
            border: (theme) =>
              `1px solid ${theme.palette.background.card.main}`,
            borderRadius: (theme) =>
              convertPxToRem(theme.shape.borderRadius * 2.5),
          }}
        >
          <Box
            role="button"
            tabIndex={0}
            onKeyDown={() => textareaRef.current?.focus()}
            onClick={() => textareaRef.current?.focus()}
            sx={{
              position: 'relative',
              height: `calc(100% - ${CONTEXT_HEIGHT})`,
              overflow: 'auto',
              minWidth: '100%',
            }}
          >
            <StyledTextArea
              ref={textareaRef}
              value={updatedCode}
              onBlur={(e) => {
                if (textareaRef.current) {
                  const start = textareaRef.current.selectionStart;
                  const end = textareaRef.current.selectionEnd;
                  setLastCursor({ start, end });
                }
              }}
              onKeyDown={(e) => {
                if (e.code === 'Tab' && textareaRef.current) {
                  e.preventDefault();
                  const start = textareaRef.current.selectionStart;
                  const end = textareaRef.current.selectionEnd;

                  setUpdatedCode(
                    updatedCode.substring(0, start) +
                      '  ' +
                      updatedCode.substring(end),
                  );

                  setTimeout(() => {
                    textareaRef.current!.selectionStart =
                      textareaRef.current!.selectionEnd = start + 2;
                  });
                }
              }}
              onChange={(e) => setUpdatedCode(e.target.value)}
              data-gramm="false"
              data-gramm_editor="false"
              data-enable-grammarly="false"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />

            <SyntaxHighlighter
              language="javascript"
              wrapLines={false}
              wrapLongLines={true}
              showLineNumbers={false}
              showInlineLineNumbers={false}
              style={anOldHope}
              customStyle={{
                flex: '1',
                padding: '0.7rem',
                fontSize: '16px',
                color: theme.palette.text.primary,
                background: 'transparent',
              }}
            >
              {updatedCode}
            </SyntaxHighlighter>
          </Box>

          <Box sx={{ height: CONTEXT_HEIGHT }}>
            <ToolbarContents
              step={step as StepType}
              onClick={handleOptionsClick}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ mt: 3 }}>
        <Button
          onClick={() => onClose(null)}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => onClose(updatedCode)}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
