import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { convertPxToRem } from '@lib/fonts';

import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import { StyledGlobalToolbar } from '@layouts/main/sections/toolbar/styles';

export const StyledExpandedToolbar = styled(StyledGlobalToolbar)(
  ({ theme }) => ({
    width: convertPxToRem(CONFIG_TOOLBAR.WIDTH_EXPANDED),
    backgroundColor: theme.palette.background.card.light,
    borderRadius: theme.shape.borderRadius * 4,
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
  }),
);

export const StyledStack = styled(Stack)(() => ({
  height: '100%',
  justifyContent: 'space-between',
  overflow: 'auto',
  width: '100%',
}));

export const StyledButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  justifyContent: 'start',
  padding: theme.spacing(4, 6),
  marginBlockEnd: theme.spacing(2),
}));
