import { useCallback, useState } from 'react';

import { payments } from '@lib/agent';

export default function usePaymentWithStripe() {
  const [isStripeRequestPending, setIsStripeRequestPending] = useState(false);
  const [isManagingPaymentLoading, setIsManagingPaymentLoading] =
    useState(false);

  const handleRedirectToStripe = useCallback(
    async ({
      priceId,
      mode,
    }: {
      priceId: string;
      mode: 'subscription' | 'payment';
    }) => {
      setIsStripeRequestPending(true);

      try {
        //? https://www.abeautifulsite.net/posts/opening-a-new-window-after-an-async-operation/
        const newWindow = window.open('about:blank', '_blank');
        if (newWindow) {
          const response = await payments.getStripePaymentLink({
            priceId,
            mode,
          });
          newWindow.location.href = response?.data.url;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsStripeRequestPending(false);
      }
    },
    [],
  );

  const managePayments = useCallback(async () => {
    setIsManagingPaymentLoading(true);
    //? https://www.abeautifulsite.net/posts/opening-a-new-window-after-an-async-operation/
    const newWindow = window.open('about:blank', '_blank');
    if (newWindow) {
      const response = await payments.getStripePortalLink();
      newWindow.location.href = response.data.url;
    }
    setIsManagingPaymentLoading(false);
  }, []);

  return {
    handleRedirectToStripe,
    managePayments,
    isManagingPaymentLoading,
    isStripeRequestPending,
  };
}
