import { secrets } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import useWorkspace from '@hooks/useWorkspace';

import { TFunction, useTranslation } from '@desygner/ui-common-translation';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

export function useCreateOneSecret() {
  const { t } = useTranslation();
  const { getNamespacedQueryKey } = useWorkspace();

  return useMutation({
    mutationFn: secrets.createOneSecret,
    mutationKey: ['createOneSecret'],
    onError: (error) => onError(t, error),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getNamespacedQueryKey('secrets'),
      });
    },
  });
}

export function useGetAllSecrets() {
  const { t } = useTranslation();

  const { data, isLoading: isLoadingSecrets } = useQueryAfterWorkspaceLoaded({
    queryKey: ['secrets'],
    queryFn: async () => {
      try {
        return await secrets.getAllSecrets();
      } catch (error) {
        onError(t, error);
      }
    },
  });

  return { secrets: data, isLoadingSecrets };
}

function onError(t: TFunction, error: unknown) {
  toast.error(
    t('response.errors.code.520', {
      defaultValue: 'Unknown error. Please try again later.',
    }),
  );
  console.error(error);
}
