import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Stack from '@mui/material/Stack';

import EllipsisText from '@components/ellipsis-typography';
import { StyledChip } from '@components/pages/live/sections/history/styles';
import Text from '@components/text';

import { GetAllLiveThreadsResponseType } from '@shared-types/threads';

import paths from '@router/paths';
import { format } from 'date-fns';

const TAGS_LENGTH_LIMIT = 4;

const LiveListItem = forwardRef<
  HTMLAnchorElement,
  GetAllLiveThreadsResponseType
>(({ id, name, description, createdAt, lastInteraction, live }, ref) => {
  const duration =
    new Date(lastInteraction).getTime() - new Date(createdAt).getTime();
  const durationHours = Math.floor(duration / 3600000) + '';
  const durationMinutes = Math.floor((duration % 3600000) / 60000) + '';
  const durationSeconds = Math.floor((duration % 60000) / 1000) + '';
  const tags = live?.classifications || [];

  return (
    <Stack
      gap={2}
      ref={ref}
      key={id}
      component={Link}
      to={`${paths.liveSession.pathname}?t=${id}`}
      sx={{ cursor: 'pointer', '& *': { color: 'text.secondary' } }}
    >
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
      >
        <EllipsisText
          lineClamp={1}
          variant="textSm"
          weight="bold"
          title={name}
        >
          {name || 'New Recording'}
        </EllipsisText>

        <Stack
          direction="row"
          gap={2}
          sx={{ flexShrink: 0 }}
        >
          <Text
            variant="textSm"
            weight="bold"
          >
            {format(new Date(lastInteraction), 'hh:mm aa')}
          </Text>

          <HourglassEmptyIcon fontSize="small" />

          <Text
            variant="textSm"
            weight="medium"
          >
            {durationHours.padStart(2, '0')}:{durationMinutes.padStart(2, '0')}:
            {durationSeconds.padStart(2, '0')}
          </Text>
        </Stack>
      </Stack>

      <Text
        variant="textSm"
        weight="medium"
      >
        {description || '<summary missing>'}
      </Text>

      <Stack
        gap={1}
        sx={{ marginTop: 2 }}
        direction="row"
        flexWrap="wrap"
      >
        {tags.slice(0, TAGS_LENGTH_LIMIT).map((tag, index) => (
          <StyledChip
            key={`${id}-tag-${index}`}
            label={<Text variant="textXs">{tag}</Text>}
          />
        ))}

        {tags.length > TAGS_LENGTH_LIMIT && (
          <StyledChip
            label={
              <Text variant="textXs">+ {tags.length - TAGS_LENGTH_LIMIT}</Text>
            }
          />
        )}
      </Stack>
    </Stack>
  );
});

LiveListItem.displayName = 'LiveListItem';

export default LiveListItem;
