import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import CONFIG from '@components/pages/assistants/config';
import { StyledCardStack } from '@components/pages/assistants/styles';

export default function CardSkeleton() {
  return (
    <StyledCardStack
      component={Stack}
      gap={2}
      sx={{ width: '100%', height: CONFIG.ASSISTANTS.CARD.HEIGHT }}
    >
      <Skeleton
        sx={{ m: 'auto' }}
        variant="rectangular"
        width={CONFIG.ASSISTANTS.IMAGE.SIZE}
        height={CONFIG.ASSISTANTS.IMAGE.SIZE}
      />
      <Divider />
      <Skeleton
        variant="rectangular"
        sx={{ width: 100, height: 10 }}
      />
      <Stack gap={2}>
        {Array.from({ length: 2 }, (_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            sx={{ width: '100%', height: 10 }}
          />
        ))}
      </Stack>
    </StyledCardStack>
  );
}
