import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import { StyledBgGradient } from '@components/pages/styles';

export default function Main({ children }: React.PropsWithChildren) {
  return (
    <Container maxWidth={false}>
      <StyledBgGradient aria-hidden />
      <Stack
        sx={{
          flexDirection: {
            xs: 'none',
            md: 'row',
          },
          justifyContent: 'center',
        }}
      >
        {children}
      </Stack>
    </Container>
  );
}
