import useAssistantsDrawer from '@components/pages/assistants/hooks/useAssistantsDrawer';
import { SubmitDataType } from '@components/pages/assistants/sections/drawer/add-assistants/types';

import { assistants } from '@lib/agent';

import { queryClient } from '@providers/ReactQueryProvider';

import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

export default function useCreateNewStyledAssistant() {
  const { t } = useTranslation();
  const { handleResetDrawers } = useAssistantsDrawer();
  const { getNamespacedQueryKey } = useWorkspace();

  return useMutation({
    mutationKey: ['createNewAssistants'],
    mutationFn: async (body: SubmitDataType) => {
      const {
        data: { id },
      } = await assistants.createStyle({
        instructions: body.description,
        personality: body.personality,
        response_style: body.response_style,
        voice: body.voice,
      });

      return await assistants.createNew({
        description: body.description,
        name: body.name,
        style: String(id),
        supportsPublicDefault: body.supportsPublicDefault || false,
        supportsPublicFallback: body.supportsPublicFallback || false,
      });
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast.info(
          t('response.errors.code.429', {
            defaultValue: 'Too many requests. Please try again later.',
          }),
        );
        return;
      }
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Unknown error. Please try again later.',
        }),
      );
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getNamespacedQueryKey('assistants'),
      });
      toast.success(
        t('response.success.createAssistants', {
          defaultValue: 'You have successfully created an assistant',
        }),
      );
      handleResetDrawers();
    },
  });
}
