import { Theme } from '@mui/material';

export default function ListItemButton(theme: Theme) {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius * 2,
          paddingInline: theme.spacing(3),
        },
      },
    },
  };
}
