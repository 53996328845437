import { forwardRef, memo } from 'react';

import Accordion from '@components/pages/live-session/sections/content/components/feed/list-item/accordion';
import { StyledCard } from '@components/pages/live-session/sections/content/components/feed/styles';

import { FeedBlockType, TimeType } from '@shared-types/live';

import { motion } from 'framer-motion';

const Card = memo(
  forwardRef<
    HTMLDivElement,
    FeedBlockType & {
      time: TimeType;
    }
  >(({ blockId, time, feedItems }, ref) => {
    return (
      <StyledCard
        ref={ref}
        key={blockId}
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {feedItems.map(
          ({ sources, avatarUrl, content, subTitle, title }, _index) =>
            content.map(({ text, contentBlockId }) => (
              <Accordion
                key={contentBlockId}
                ref={ref}
                time={time}
                body={text}
                subTitle={subTitle}
                sources={sources}
                avatar={avatarUrl}
                name={title}
              />
            )),
        )}
      </StyledCard>
    );
  }),
);

Card.displayName = 'Card';

export default Card;
