import { useCallback, useEffect, useMemo } from 'react';

import Timer from '@components/pages/live-session/utils/timer';

export default function useTimer() {
  const timer = useMemo(() => new Timer(), []);

  const startTimer = useCallback(() => {
    timer.startTimer();
  }, [timer]);

  const resetTimer = useCallback(() => {
    timer.resetTimer();
  }, [timer]);

  useEffect(() => {
    startTimer();

    return () => resetTimer();
  }, [resetTimer, startTimer]);

  return timer;
}
