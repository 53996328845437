/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import Card from '@mui/material/Card';

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'block',
  minHeight: 144,
  padding: theme.spacing(3),
  border: '1px solid',
  borderColor: theme.palette.background.secondary,
})) as typeof Card;
