import Stack from '@mui/material/Stack';

import Text from '@components/text';

import useGetCredit from '@hooks/useGetCredit';

import { useTranslation } from '@desygner/ui-common-translation';

export default function Heading() {
  const { creditsData } = useGetCredit();
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      sx={{ justifyContent: 'space-between' }}
    >
      <Text
        variant="textSm"
        weight="bold"
      >
        {t('page.knowledgeBase.capacityIndicator.tooltip.heading', {
          defaultValue: 'Available Knowledge Capacity',
        })}
      </Text>
      <Text
        variant="textSm"
        weight="bold"
      >
        {creditsData && Math.round(creditsData?.knowledgeCapacityPercentage)}%
      </Text>
    </Stack>
  );
}
