import { StyledContainerForFixedHeader } from '@components/pages/styles';
import StyledBox from '@components/pages/upgrade-account/styles';
import Plans from '@components/plans';

import { convertPxToRem } from '@lib/fonts';

import CONFIG from '@layouts/main/sections/toolbar/config';

export default function UpgradeAccount() {
  return (
    <StyledContainerForFixedHeader
      sx={{
        pb: {
          xs: convertPxToRem(CONFIG.TOOLBAR_MINI.MOBILE.HEIGHT),
          md: 0,
        },
      }}
    >
      <StyledBox>
        <Plans />
      </StyledBox>
    </StyledContainerForFixedHeader>
  );
}
