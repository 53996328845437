import { Theme } from '@mui/material';

import Accordion from '@theme/overrides/Accordion';
import AppBar from '@theme/overrides/AppBar';
import Autocomplete from '@theme/overrides/Autocomplete';
import Button from '@theme/overrides/Button';
import Card from '@theme/overrides/Card';
import Chip from '@theme/overrides/Chip';
import Container from '@theme/overrides/Container';
import CssBaseline from '@theme/overrides/CssBaseline';
import Dialog from '@theme/overrides/Dialog';
import Drawer from '@theme/overrides/Drawer';
import FormControlLabel from '@theme/overrides/FormControlLabel';
import FormHelperText from '@theme/overrides/FormHelperText';
import IconButton from '@theme/overrides/IconButton';
import ImageListItem from '@theme/overrides/ImageListItem';
import Input from '@theme/overrides/Input';
import Link from '@theme/overrides/Link';
import ListItemButton from '@theme/overrides/ListItemButton';
import ListItemIcon from '@theme/overrides/ListItemIcon';
import MenuItem from '@theme/overrides/MenuItem';
import Paper from '@theme/overrides/Paper';
import Radio from '@theme/overrides/Radio';
import Skeleton from '@theme/overrides/Skeleton';
import Tab from '@theme/overrides/Tab';
import Table from '@theme/overrides/Table';
import Toolbar from '@theme/overrides/Toolbar';
import Tooltip from '@theme/overrides/Tooltip';
import Typography from '@theme/overrides/Typography';

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    Paper(theme),
    MenuItem(theme),
    Input(theme),
    AppBar(theme),
    Link(theme),
    ListItemIcon(theme),
    ListItemButton(theme),
    Tooltip(theme),
    Drawer(theme),
    CssBaseline(theme),
    Card(theme),
    IconButton(theme),
    ImageListItem(theme),
    Toolbar(),
    Dialog(),
    Autocomplete(),
    Accordion(theme),
    Table(theme),
    Tab(theme),
    FormControlLabel(theme),
    Skeleton(theme),
    Container(theme),
    Typography(),
    Accordion(theme),
    Radio(theme),
    FormHelperText(theme),
    Chip(theme),
  );
}
