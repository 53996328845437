import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import usePopover from '@layouts/main/sections/toolbar/components/knowledge-base-popover/hooks/usePopover';

export default function Footer() {
  const { handleClose, handleNavigateToSelectedWorkspace } = usePopover();
  function handleNavigateToWorkspace() {
    handleNavigateToSelectedWorkspace();
    handleClose();
  }

  return (
    <>
      <Divider />
      <Stack
        sx={{
          padding: 5,
        }}
        gap={3}
      >
        <Button
          size="large"
          variant="contained"
          onClick={handleNavigateToWorkspace}
          aria-label="Go to Knowledge Base"
        >
          Go to Knowledge Base
        </Button>
        <Button
          size="large"
          variant="outlined"
          onClick={handleClose}
          aria-label="Done"
        >
          Done
        </Button>
      </Stack>
    </>
  );
}
