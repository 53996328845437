import KNOWLEDGE_BASE_CONFIG from '@components/pages/knowledge-base/config';

import { sources } from '@lib/agent';
import getDataWithPagination from '@lib/getDataWithPagination';

import { queryClient } from '@providers/ReactQueryProvider';

import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import { SourceWithMetaDataType } from '@shared-types/sources';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

type Props = {
  id?: number;
};

export default function useMoveSourceByCurrentFolderId({ id }: Props) {
  const { getNamespacedQueryKey } = useWorkspace();
  const { getQueryParamByKey } = useQueryParams();
  const currentPage = parseInt(getQueryParamByKey('page', '1'));
  const { t } = useTranslation();

  const getQueryKeyById = (id?: number) =>
    getNamespacedQueryKey('sources', {
      page: currentPage,
      ...(id && {
        folderId: id,
      }),
    });

  return useMutation({
    mutationKey: ['Dnd', 'source'],
    mutationFn,
    onMutate: (variables) => {
      const LIMIT = KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT;

      queryClient.cancelQueries({
        queryKey: getQueryKeyById(id),
      });

      const lastSources =
        queryClient.getQueryData<SourceWithMetaDataType | null>(
          getQueryKeyById(id),
        );

      if (lastSources == null) return;

      const calculatedPage = Math.ceil(
        lastSources.meta.pagination.total - 1 / LIMIT,
      );

      if (calculatedPage > currentPage && lastSources.data.length === 1) {
        queryClient.invalidateQueries({
          queryKey: getQueryKeyById(id),
        });
      }

      queryClient.setQueryData(getQueryKeyById(id), () => {
        const dataWithPagination = getDataWithPagination({
          data: lastSources.data.filter(
            (oldItem) => oldItem.id !== variables.id,
          ),
          totalCount: String(lastSources.meta.pagination.total - 1),
          limit: LIMIT,
          pageParam: calculatedPage,
        });

        return dataWithPagination;
      });
    },
    onSuccess: (_data, { name }) => {
      toast.success(
        `${t(
          'page.knowledgeBase.admin.actions.dragAndDrop.success.source.segment1',
          {
            defaultValue: 'Source',
          },
        )} "${name}" ${t(
          'page.knowledgeBase.admin.actions.dragAndDrop.success.source.segment2',
          {
            defaultValue: 'successfully moved',
          },
        )}`,
      );
    },
    onError: (error, { name }) => {
      toast.error(
        `${t(
          'page.knowledgeBase.admin.actions.dragAndDrop.error.source.segment1',
          {
            defaultValue: 'Source',
          },
        )} "${name}" ${t(
          'page.knowledgeBase.admin.actions.dragAndDrop.error.source.segment2',
          {
            defaultValue: 'could not be moved, please try again',
          },
        )}`,
      );
      console.error(error);
    },
    onSettled: (_data, _error, { folderToMove }) => {
      queryClient.invalidateQueries({
        queryKey: getQueryKeyById(id),
      });

      queryClient.invalidateQueries({
        queryKey: getQueryKeyById(folderToMove),
      });
    },
  });
}

type MutationFnProps = {
  id: number;
  folderToMove: number;
  name: string;
};

async function mutationFn({ folderToMove, id }: MutationFnProps) {
  return await sources.updateOne({ id, folder: folderToMove });
}
