import { workspaces } from '@lib/agent';

import { useTranslation } from '@desygner/ui-common-translation';

import {
  WorkspaceRequestType,
  WorkspaceResponseType,
} from '@shared-types/workspace';

import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

export default function useUpdateWorkspaceById(
  onSuccess:
    | ((
        data: WorkspaceResponseType,
        variables: WorkspaceRequestType,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined,
) {
  const { t } = useTranslation();

  const {
    isPending: isUpdateWorkspacePending,
    mutateAsync: mutateUpdateWorkspaceAsync,
  } = useMutation({
    mutationKey: ['updateNewWorkspace'],
    mutationFn: async ({ name, id }: WorkspaceRequestType) => {
      const { data } = await workspaces.updateOneById({ name, id });

      return data;
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast.error(
          t('response.errors.code.429', {
            defaultValue: 'Too many requests. Please try again later.',
          }),
        );
        return;
      }
      console.error(error);
    },
    onSuccess,
  });

  return {
    isUpdateWorkspacePending,
    mutateUpdateWorkspaceAsync,
  };
}
