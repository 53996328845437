import { threads } from '@lib/agent';
import getDataWithPagination from '@lib/getDataWithPagination';

import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';

import { GetAllThreadsParamsType } from '@shared-types/threads';

import { useInfiniteQuery } from '@tanstack/react-query';

const LIMIT = 10;
const ORDER = 'DESC';

export default function useGetInfiniteThreadsLazily<T>({
  type,
}: Omit<GetAllThreadsParamsType, 'limit' | 'order' | 'page'>) {
  const { getNamespacedQueryKey } = useWorkspace();

  const { isUserAuthenticated } = useAuth();

  return useInfiniteQuery({
    queryKey: getNamespacedQueryKey('threads', type),
    queryFn: async ({ pageParam }) => {
      const { data, headers } = await threads.getAll<T>({
        limit: LIMIT,
        order: ORDER,
        page: pageParam,
        type,
      });

      const totalCount = headers['x-total-count'] as string;

      const dataWithPagination = getDataWithPagination({
        data,
        totalCount,
        pageParam,
        limit: LIMIT,
      });
      return dataWithPagination;
    },
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      if (lastPage.data.length === 0) return undefined;

      if (!('pagination' in lastPage.meta)) return undefined;

      if (lastPageParam === lastPage.meta.pagination.pageCount)
        return undefined;

      return lastPageParam + 1;
    },
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => {
      if (firstPageParam <= 2) return undefined;

      return firstPageParam - 1;
    },
    initialPageParam: 1,
    enabled: isUserAuthenticated,
    staleTime: Infinity,
  });
}
