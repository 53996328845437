import LinkIcon from '@mui/icons-material/Link';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import SectionHeading from '@components/pages/search/components/SectionHeading';

import { useTranslation } from '@desygner/ui-common-translation';

const NUMBER_OF_FOLLOWUPS = 5;
const HEIGHT = 60;

export default function Followups() {
  const { t } = useTranslation();

  return (
    <Card>
      <SectionHeading
        icon={
          <Icon>
            <LinkIcon />
          </Icon>
        }
        title={t('page.search.sections.relatedQuestions', {
          defaultValue: 'Related Questions',
        })}
      >
        <Stack
          direction="column"
          gap={5}
        >
          {Array.from({ length: NUMBER_OF_FOLLOWUPS }, (_, i) => {
            return (
              <Skeleton
                variant="rounded"
                height={HEIGHT}
                key={i}
              />
            );
          })}
        </Stack>
      </SectionHeading>
    </Card>
  );
}
