import { forwardRef, useState } from 'react';

import Details from '@components/pages/live-session/sections/content/components/feed/list-item/details';
import Summary from '@components/pages/live-session/sections/content/components/feed/list-item/summary';
import { StyledAccordion } from '@components/pages/live-session/sections/content/components/feed/styles';

import { FeedBlockType, TimeType } from '@shared-types/live';

type Props = {
  avatar: string;
  name: string;
  time: TimeType;
  subTitle: string;
  body: string;
  sources: FeedBlockType['feedItems'][number]['sources'];
};

const Accordion = forwardRef<HTMLDivElement, Props>(
  ({ body, subTitle, avatar, time, name, sources }, ref) => {
    const [isExpandedLocal, setIsExpandedLocal] = useState(true);

    return (
      <StyledAccordion
        disableGutters
        expanded={isExpandedLocal}
        onChange={(_, isExpanded) => setIsExpandedLocal(isExpanded)}
      >
        <Summary
          time={time}
          subTitle={subTitle}
          avatar={avatar}
          name={name}
        />
        <Details reasoning={body} />
      </StyledAccordion>
    );
  },
);

Accordion.displayName = 'Accordion';

export default Accordion;
