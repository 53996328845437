/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';

import {
  DeleteManyRequestType,
  SourceDetailsType,
  SourceRequestType,
  SourceType,
  UpdateOneRequestType,
} from '@shared-types/sources';

import qs from 'qs';

type SharedSourceRequestType = {
  id: number;
};

export const sources = {
  getAll: ({
    limit,
    page = 1,
    folder = null, // null is used to get all sources in the root folder
    query = '',
  }: Partial<SourceRequestType>) => {
    return requests(axiosApiInstance).get<SourceType[]>(
      `/v1/entities/sources?limit=${limit}&page=${page}&folder=${folder}&query=${query}`,
    );
  },
  getAllAs: ({
    limit,
    page,
    folder,
    query,
    ownerId,
  }: Partial<SourceRequestType> & { ownerId: string }) => {
    const stringifiedQuery = qs.stringify({
      limit,
      page,
      folder,
      query,
    });

    return requests(axiosApiInstance).get<SourceType[]>(
      `/v1/entities/sources?${stringifiedQuery}`,
      {
        headers: {
          'Knowz-Impersonate-Owner': ownerId,
        },
      },
    );
  },
  deleteOne: ({ id }: SharedSourceRequestType) =>
    requests(axiosApiInstance).delete(`/v1/entities/sources/${id}`),
  getOneById: ({ id }: SharedSourceRequestType) =>
    requests(axiosApiInstance).get<SourceDetailsType>(
      `/v1/entities/sources/${id}`,
    ),
  updateOne: ({ id, ...body }: UpdateOneRequestType) =>
    requests(axiosApiInstance).patch(`/v1/entities/sources/${id}`, {
      ...body,
    }),
  deleteMany: (body: DeleteManyRequestType) =>
    requests(axiosApiInstance).delete('/v1/actions/bulk', {
      data: body,
    }),
};
