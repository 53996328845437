import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import DataLoader from '@components/data-loader';
import Text from '@components/text';

import useGetAllSourceConfigurations from '@hooks/useGetAllSourceConfigurations';
import useSourceConfigurationConfig from '@hooks/useSourceConfigurationConfig';
import { useCreateOneSourceConfiguration } from '@hooks/useSourceConfigurations';

type SourceConfigurationsPopupProps = {
  connect: (handler: string) => void;
};

const SourceConfigurationsPopup: React.FC<SourceConfigurationsPopupProps> = ({
  connect,
}) => {
  const {
    sourceConfigs = [],
    isLoading,
    isError,
    refetch: refetchSourceConfigs,
  } = useGetAllSourceConfigurations();
  const emailConfig = Array.isArray(sourceConfigs)
    ? sourceConfigs.find((config) => config.handler === 'email')
    : null;
  const { sourceConfig, refetch } = useSourceConfigurationConfig({
    id: emailConfig?.id || 0,
    handler: emailConfig ? 'email' : undefined,
  });
  const { mutateAsync: mutateCreateOneSourceConfigurationAsync } =
    useCreateOneSourceConfiguration();

  const handleCreate = async (handler: string) => {
    try {
      await mutateCreateOneSourceConfigurationAsync({ handler });
      refetchSourceConfigs();
      refetch();
    } catch (error) {
      console.error('Error creating source configuration:', error);
    }
  };

  return (
    <>
      <DataLoader
        loading={{ isLoading, description: 'Loading...' }}
        error={{ isError, title: 'Error loading the data.' }}
        data={{
          emptyMessage: 'No email config found',
          length: sourceConfigs.length,
        }}
      >
        {sourceConfigs &&
          sourceConfigs.map((config) =>
            config.handler === 'email' && sourceConfig?.email ? (
              <Box
                key={config.id}
                onClick={() => connect(config.handler)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Text variant="textSm">Email: {sourceConfig.email}</Text>
              </Box>
            ) : null,
          )}
      </DataLoader>
      {(!sourceConfigs || sourceConfigs.length === 0 || !emailConfig) && (
        <Button
          variant="contained"
          onClick={() => handleCreate('email')}
        >
          Create Email Configuration
        </Button>
      )}
    </>
  );
};

export default SourceConfigurationsPopup;
