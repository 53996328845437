import { styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';

const StyledAvatar = styled(Avatar)(() => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
}));

export default StyledAvatar;
