export default function formatBreadcrumbPath(
  folderPath: string | null,
  fileName: string,
): { parts: string[] } {
  const rootFolder = ['knowledge base'];

  if (!folderPath) {
    //? Case: root path - just return knowledge base > fileName

    return {
      parts: [...rootFolder, fileName],
    };
  }

  const folders = folderPath.split(/\\?\//).filter(Boolean);

  if (folders.length === 0) {
    //? Empty path case

    return {
      parts: [...rootFolder, fileName],
    };
  }

  if (folders.length === 1) {
    //? Single folder case: knowledge base > folder > fileName
    return {
      parts: [...rootFolder, folders[0], fileName],
    };
  }

  //? Multiple folders case: knowledge base > first folder > ... > fileName
  return {
    parts: [...rootFolder, folders[0], '...', fileName],
  };
}
