import { Theme } from '@mui/material';

export default function Link(theme: Theme) {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.neutral.grey
              : theme.palette.neutral.black,
        },
      },
      defaultProps: {
        underline: 'none',
      },
    },
  };
}
