import { useTheme } from '@mui/material';

import {
  StyledContainerForFixedHeader,
  StyledViewportHeightStack,
} from '@components/pages/styles';

import { convertPxToRem } from '@lib/fonts';

import MAIN_LAYOUT_CONFIG from '@layouts/main/config';

export default function Wrapper({ children }: React.PropsWithChildren) {
  const theme = useTheme();

  return (
    <StyledContainerForFixedHeader
      disableGutters
      maxWidth={false}
      sx={{ paddingBlockEnd: 0 }}
    >
      <StyledViewportHeightStack
        gap={2.5}
        sx={{
          height: `calc(100svh - ${convertPxToRem(
            MAIN_LAYOUT_CONFIG.HEADER.HEIGHT +
              MAIN_LAYOUT_CONFIG.HEADER.PADDING_Y * 2,
          )})`,
          paddingBottom: theme.spacing(4),
        }}
      >
        {children}
      </StyledViewportHeightStack>
    </StyledContainerForFixedHeader>
  );
}
