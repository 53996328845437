import LoadingDropdown from '@layouts/main/sections/toolbar/components/knowledge-base-popover/sections/content/tabs/tab-panels/components/LoadingDropdown';
import useGetOwnedWorkspaces from '@layouts/main/sections/toolbar/components/knowledge-base-popover/sections/content/tabs/tab-panels/hooks/useGetOwnedWorkspaces';
import Dropdown from '@layouts/main/sections/toolbar/components/knowledge-base-popover/sections/content/tabs/tab-panels/sections/knowledge-bases/dropdown';

export default function KnowledgeBases() {
  const { ownedWorkspacesData, isWorkspaceFetching } = useGetOwnedWorkspaces();

  if (isWorkspaceFetching) {
    return <LoadingDropdown />;
  }

  return <Dropdown options={ownedWorkspacesData} />;
}
