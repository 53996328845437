import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  gap: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'left',
    gap: theme.spacing(10),
  },
}));

export default StyledStack;
