import Switch from '@components/switch';

import useThemeSettings from '@hooks/useThemeSettings';

export default function ThemeMode() {
  const { toggleThemeMode, themeMode } = useThemeSettings();

  function handleThemeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const isThemeModeDark = e.currentTarget.checked ? 'dark' : 'light';
    toggleThemeMode(isThemeModeDark);
  }

  function switchGate() {
    return (
      <Switch
        checked={themeMode === 'dark'}
        onChange={handleThemeChange}
      />
    );
  }

  return switchGate();
}
