import { Link } from 'react-router-dom';

import { styled } from '@mui/material';

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.mode === 'dark' ? '#F7F7F7' : '#181D27', //TODO: design system
  borderRadius: theme.shape.borderRadius * 10,
  padding: theme.spacing(1, 2),
  background:
    theme.palette.mode === 'dark'
      ? 'radial-gradient(circle at 0%, #53B1ED2B, #3C1AF92B, #0C0E121A)' //TODO: design system
      : 'linear-gradient(to right, #53B1ED2B, #3C1AF92B)', //TODO: design system
}));

export default StyledLink;
