import { live } from '@lib/agent/endpoints/live';

import { UpdateSpeakerNameByReferenceRequestType } from '@shared-types/live';

import { useMutation } from '@tanstack/react-query';

export default function useUpdateSpeakerName() {
  return useMutation({
    mutationFn: async (data: UpdateSpeakerNameByReferenceRequestType) =>
      live.updateSpeakerNameByReference(data),
    mutationKey: ['updateSpeakerNameByReference'],
    onError: (error) => {
      console.error(error);
    },
  });
}
