import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import MAIN_LAYOUT_CONFIG from '@layouts/main/config';
import TOOLBAR_CONFIG from '@layouts/main/sections/toolbar/config';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 5,
  overflow: 'auto',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    maxHeight: '100%',
  },
  maxHeight: `calc(100% - ${
    TOOLBAR_CONFIG.TOOLBAR_MINI.MOBILE.HEIGHT +
    MAIN_LAYOUT_CONFIG.HEADER.HEIGHT +
    MAIN_LAYOUT_CONFIG.HEADER.PADDING_Y * 2
  }px)`,
}));

export default StyledBox;
