import { useRef } from 'react';

import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import Markdown from '@components/markdown';
import CONFIG from '@components/pages/assistants-session/config';
import StyledStack from '@components/pages/assistants-session/styles';

import useScrollIntoView from '@hooks/useScrollIntoView';

import { WSResponseType } from '@state/middleware/types';
import { RootType } from '@state/store';

import { useSelector } from 'react-redux';

export default function StreamingChat() {
  const WSResponse = useSelector<RootType, WSResponseType>(
    (state) => state.websocket.WSResponse,
  );
  const prompt = useSelector(
    (state: RootType) => state.assistantResult.user.text,
  );

  const isWSLoading = useSelector(
    (state: RootType) => state.websocket.isWSLoading,
  );
  const chatRef = useRef(null);
  useScrollIntoView(chatRef, [WSResponse.assistant.text.length]);

  if (prompt === '') return;

  return (
    <Stack gap={6}>
      <StyledStack isAi={false}>
        <Avatar
          alt="user"
          sx={{
            width: CONFIG.CHAT.AVATAR_SIZE,
            height: CONFIG.CHAT.AVATAR_SIZE,
          }}
        />
        <Markdown
          sx={{
            backgroundColor: 'background.card.light',
            p: 4,
            maxWidth: {
              xs: '100%',
              md: CONFIG.CHAT.MAX_WIDTH,
            },
            borderRadius: 2,
            borderTopRightRadius: 0,
          }}
        >
          {prompt}
        </Markdown>
      </StyledStack>

      <StyledStack
        isAi
        ref={chatRef}
      >
        {isWSLoading ? (
          <CircularProgress
            size={30}
            disableShrink
          />
        ) : (
          <Markdown
            sx={{
              backgroundColor: 'background.card.light',
              p: 4,
              maxWidth: {
                xs: '100%',
                md: CONFIG.CHAT.MAX_WIDTH,
              },
              borderRadius: 2,
              borderTopLeftRadius: 0,
            }}
          >
            {WSResponse.assistant.text}
          </Markdown>
        )}
      </StyledStack>
    </Stack>
  );
}
