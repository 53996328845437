import { createContext, useCallback, useState } from 'react';

type PortalContextType = {
  navbarMain: React.RefObject<HTMLElement> | null;
  handleSetNavbarMain: (value: React.RefObject<HTMLElement> | null) => void;
};

export const PortalContext = createContext<PortalContextType | null>(null);

export default function PortalProvider({ children }: React.PropsWithChildren) {
  const [navbarMain, setNavbarMain] =
    useState<React.RefObject<HTMLElement> | null>(null);

  const handleSetNavbarMain = useCallback(
    (value: React.RefObject<HTMLElement> | null) => {
      setNavbarMain(value);
    },
    [],
  );

  return (
    <PortalContext.Provider value={{ navbarMain, handleSetNavbarMain }}>
      {children}
    </PortalContext.Provider>
  );
}
