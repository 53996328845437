import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';

import StyledChip from '@components/pages/knowledge-base/components/status-label/styles';

type Props = {
  status: 'in_progress' | 'sync' | 'error';
};

const STATUS: {
  [key in Props['status']]: {
    color: 'info' | 'success' | 'error';
    label: string;
    icon: JSX.Element;
  };
} = {
  in_progress: {
    color: 'info',
    label: 'In Progress',
    icon: <PendingOutlinedIcon />,
  },
  sync: {
    color: 'success',
    label: 'Synced',
    icon: <CheckCircleOutlineOutlinedIcon />,
  },
  error: {
    color: 'error',
    label: 'Error',
    icon: <CancelOutlinedIcon />,
  },
};

export default function StatusLabel({ status }: Props) {
  return (
    <StyledChip
      color={STATUS[status].color}
      variant="outlined"
      label={STATUS[status].label}
      size="small"
      icon={STATUS[status].icon}
    />
  );
}
