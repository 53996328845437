import { Theme } from '@mui/material';

export default function ListItemIcon(theme: Theme) {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: theme.spacing(8),
        },
      },
    },
  };
}
