import { AppBarProps } from '@mui/material';

import HideOnScroll from '@layouts/components/header-wrapper/hide-on-scroll';
import StyledStack from '@layouts/components/header-wrapper/styles';
import CONFIG from '@layouts/main/sections/toolbar/config';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { StyledAppBar, StyledToolbar } from '@layouts/styles';

const CONTAINER_PADDING = 16;
const GAP_BETWEEN_TOOLBAR_AND_CONTENT = 16;

export default function HeaderWrapper({
  children,
  position = 'fixed',
  isDetailedMode = true,
}: React.PropsWithChildren<{
  position?: AppBarProps['position'];
  isDetailedMode?: boolean;
}>) {
  const { toolbarExpansionStatus } = useToolbar();
  const calculatedWidth =
    toolbarExpansionStatus === 'on' && isDetailedMode
      ? `calc(100% - ${CONFIG.WIDTH_EXPANDED + CONTAINER_PADDING + GAP_BETWEEN_TOOLBAR_AND_CONTENT}px)`
      : '100%';

  return (
    <HideOnScroll>
      <StyledAppBar
        position={position}
        calculatedWidth={calculatedWidth}
      >
        <StyledStack>
          <StyledToolbar component="nav">{children}</StyledToolbar>
        </StyledStack>
      </StyledAppBar>
    </HideOnScroll>
  );
}
