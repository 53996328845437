import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';

import FormProvider from '@components/react-hook-form/FormProvider';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import Heading from '@components/workspace/setup/components/heading';
import useCreateWorkspace from '@components/workspace/setup/hooks/useCreateWorkspace';
import useUpdateWorkspaceById from '@components/workspace/setup/hooks/useUpdateWorkspaceById';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import createWorkspaceSchema from '@components/workspace/setup/schema';

import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import { WorkspaceResponseType } from '@shared-types/workspace';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function CreateWorkspace() {
  const { t } = useTranslation();

  const {
    handleNext,
    currentCreatedWorkspaceDetails,
    handleSetCurrentCreatedWorkspaceDetails,
  } = useWorkspaceSetup();

  const { handleSetWorkspace, handleSetupMode } = useWorkspace();

  function updateState(data: WorkspaceResponseType) {
    handleNext();
    handleSetWorkspace(data);
    handleSetupMode('on');
    handleSetCurrentCreatedWorkspaceDetails({
      ...currentCreatedWorkspaceDetails,
      name: data.name,
      id: data.id,
    });
  }

  const { isCreateWorkspacePending, mutateCreateWorkspaceAsync } =
    useCreateWorkspace((data) => {
      updateState(data);
    });

  const { isUpdateWorkspacePending, mutateUpdateWorkspaceAsync } =
    useUpdateWorkspaceById((data) => {
      //TODO: we need to revalidate react query data as well
      updateState(data);
    });

  const defaultValues = {
    name: currentCreatedWorkspaceDetails?.name ?? '',
  };

  const methods = useForm({
    resolver: yupResolver(createWorkspaceSchema),
    defaultValues,
  });

  const { handleSubmit, watch } = methods;

  async function onSubmit(data: { name: string }) {
    const name = data.name;

    //? it has already created with same name
    if (name === currentCreatedWorkspaceDetails?.name) {
      handleNext();
      return;
    }

    //? trying to edit the workspace that just created
    if (currentCreatedWorkspaceDetails) {
      await mutateUpdateWorkspaceAsync({
        name,
        id: currentCreatedWorkspaceDetails.id!,
      });
      return;
    }

    await mutateCreateWorkspaceAsync({ name });
  }

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Container disableGutters>
        <Heading
          title={t(
            'page.workspace.createWorkspaceModal.step1.form.name.label',
            {
              defaultValue: 'Workspace Name',
            },
          )}
          subtitle={t(
            'page.workspace.createWorkspaceModal.step1.form.name.description',
            {
              defaultValue: 'Establish the name of your Knowz workspace.',
            },
          )}
        />
        <RHFInputField
          autoFocus
          name="name"
          placeholder={t(
            'page.workspace.createWorkspaceModal.step1.form.name.placeholder',
            {
              defaultValue: 'Ex: Marketing',
            },
          )}
        />
      </Container>
      <LoadingButton
        loading={isCreateWorkspacePending || isUpdateWorkspacePending}
        disabled={watch().name.length === 0}
        variant="contained"
        onClick={handleSubmit(onSubmit)}
        sx={{
          margin: '0 0 0 auto',
          mt: 6,
          display: 'block',
        }}
      >
        {t('page.workspace.createWorkspaceModal.nextButton', {
          defaultValues: 'Next',
        })}
      </LoadingButton>
    </FormProvider>
  );
}
