import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';

import useCollectAllSmartTags from '@components/pages/live/hooks/useGetAllSmartTags';
import {
  StyledStack,
  StyledWrapperStack,
} from '@components/pages/live/sections/cards/smart-tags-card/kinds/category/drawer/styles';
import Text from '@components/text';

type Props = {
  title: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  checkbox: React.ReactNode;
};

const BUTTON_SIZE = 45;

const DISABLED_STYLES = {
  opacity: 0.8,
  pointerEvents: 'none',
  color: 'text.disabled',
};

export default function Drawer({ title, onClick, checkbox }: Props) {
  const { areCollectedSmartTagsSuccessfullyLoaded } = useCollectAllSmartTags();

  return (
    <StyledWrapperStack
      direction="row"
      sx={
        !areCollectedSmartTagsSuccessfullyLoaded ? DISABLED_STYLES : undefined
      }
    >
      <ButtonBase
        disableRipple
        disableTouchRipple
        sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }}
        onClick={onClick}
      >
        <ExpandMoreIcon />
      </ButtonBase>
      <Divider
        flexItem
        orientation="vertical"
      />
      <StyledStack direction="row">
        <Text
          variant="textSm"
          weight="semibold"
        >
          {title}
        </Text>
        {checkbox}
      </StyledStack>
    </StyledWrapperStack>
  );
}
