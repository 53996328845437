import Markdown from '@components/markdown';
import { StyledAccordionDetails } from '@components/pages/live-session/sections/content/components/feed/styles';

type Props = {
  reasoning: string;
};

export default function Details({ reasoning }: Props) {
  return (
    <StyledAccordionDetails>
      <Markdown>{reasoning}</Markdown>
    </StyledAccordionDetails>
  );
}
