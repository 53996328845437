import { styled } from '@mui/material';
import ListItem from '@mui/material/ListItem';

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  borderRadius: theme.shape.borderRadius * 2,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.card.main,
}));

export default StyledListItem;
