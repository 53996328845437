//TODO: we might need to consider lazy loading the knowledge base component
import AdminKnowledgeBase from '@components/pages/knowledge-base/admin';
import IndividualKnowledgeBase from '@components/pages/knowledge-base/individual';
import MemberKnowledgeBase from '@components/pages/knowledge-base/member';
import { StyledContainerForFixedHeader } from '@components/pages/styles';

import useWorkspace from '@hooks/useWorkspace';

const KNOWLEDGE_BASE_TYPES = {
  admin: <AdminKnowledgeBase />,
  member: <MemberKnowledgeBase />,
  individual: <IndividualKnowledgeBase />,
};

export default function KnowledgeBase() {
  const { setupMode } = useWorkspace();

  return (
    <StyledContainerForFixedHeader
      disableGutters
      maxWidth={false}
      sx={{ pb: 0 }}
    >
      {
        KNOWLEDGE_BASE_TYPES[
          setupMode === 'on'
            ? 'admin'
            : setupMode === 'off'
              ? 'member'
              : 'individual'
        ]
      }
    </StyledContainerForFixedHeader>
  );
}
