import { useRadioGroup } from '@mui/material';

import RadioGroupContainer from '@components/pages/live-session/sections/content/components/transcript/card/dropdown/voice-match/modal/match/forms/radio-group-container';
import RHFInputField from '@components/react-hook-form/RHFInputField';

import UserPlusIcon from '~icons/knowz-iconify/user-plus';

const FORM = {
  title: 'Name guest speaker',
  field: {
    value: 'guestSpeaker',
    placeholder: 'Name Guest Speaker',
  },
};

export default function GuestSpeakerField() {
  const radioGroup = useRadioGroup();

  return (
    <RadioGroupContainer
      title={FORM.title}
      value={FORM.field.value}
    >
      <RHFInputField
        disabled={radioGroup?.value !== FORM.field.value}
        type="text"
        name="name"
        autoFocus
        placeholder="Name Guest Speaker"
        startAdornment={<UserPlusIcon />}
      />
    </RadioGroupContainer>
  );
}
