import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SearchIcon from '@mui/icons-material/Search';

import { SearchModeType } from '@components/search-form/types';

import CameraIcon from '~icons/knowz-iconify/camera';
import UploadIcon from '~icons/knowz-iconify/upload';

const Types = {
  ADVANCED: 'advanced',
  FLUID: 'fluid',
  QUICK: 'quick',
} as const;

const MODES = [Types.ADVANCED, Types.FLUID, Types.QUICK];

const SEARCH_MODES: SearchModeType = {
  advanced: {
    name: Types.ADVANCED,
    title: 'Genius',
    description:
      'Ideal for deep dives and the most thorough, accurate results.',
    icon: (
      <AutoAwesomeIcon
        sx={{
          fontSize: '1.3em',
          marginInline: (theme) => theme.spacing(2.8),
        }}
      />
    ),
  },
  fluid: {
    name: Types.FLUID,
    icon: (
      <SearchIcon
        sx={{
          fontSize: '1.3em',
          marginInline: (theme) => theme.spacing(2.8),
        }}
      />
    ),
    title: 'Fluid',
    description:
      'Ideal for finding balanced, accurate results with both speed and depth.',
  },
  quick: {
    name: Types.QUICK,
    icon: (
      <RocketLaunchIcon
        sx={{
          fontSize: '1.3em',
          marginInline: (theme) => theme.spacing(2.8),
        }}
      />
    ),
    title: 'Fast',
    description:
      'Perfect for quick answers when you need information in a flash.',
  },
};

const UPLOAD = {
  camera: {
    icon: <CameraIcon />,
    description: 'Take a photo',
  },
  upload: {
    icon: <UploadIcon />,
    description: 'Upload files',
  },
};

const CONFIG = {
  MODES,
  SEARCH_MODES,
  UPLOAD,
} as const;

export default CONFIG;
