const SHARED_VALUES = {
  currency: 'USD',
  symbol: '$',
} as const;

const PLANS = {
  [String(import.meta.env.VITE_GENIUS_SEARCH_PLAN_YEARLY)]: {
    price: 85.85,
    title: 'Genius Search Plan monthly',
    ...SHARED_VALUES,
  },
  [String(import.meta.env.VITE_GENIUS_SEARCH_PLAN_MONTHLY)]: {
    price: 7.95,
    title: 'Genius Search Plan yearly',
    ...SHARED_VALUES,
  },
  [String(import.meta.env.VITE_CREDIT_PACK)]: {
    price: 19.95,
    title: 'Credit Pack',
    ...SHARED_VALUES,
  },
  [String(import.meta.env.VITE_GENIUS_UNLIMITED)]: {
    price: 199.95,
    title: 'Genius Unlimited',
    ...SHARED_VALUES,
  },
} as const;

export default PLANS;
