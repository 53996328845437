import { createContext, useCallback, useMemo, useReducer } from 'react';

import ActiveStep from '@components/workspace/setup/components/ActiveStep';
import DesktopModalStepper from '@components/workspace/setup/sections/modal-stepper/desktop';
import MobileModalStepper from '@components/workspace/setup/sections/modal-stepper/mobile';

import useQueryParams from '@hooks/useQueryParams';

import { RoleType } from '@shared-types/memberships';
import { ActionMap } from '@shared-types/utils';

import { Meta, UppyFile } from '@uppy/core';

type MemberToInviteType = {
  email: string;
  role: RoleType;
};

type MembersToInviteType = Array<MemberToInviteType>;

type WorkspaceDetailsType = {
  activeStep: number; //? between 0 | 1 | 2
  currentCreatedWorkspaceDetails: Partial<{
    id: string | null;
    name: string | null;
    avatarBlob: UppyFile<Meta, Record<string, never>> | null;
  }> | null;
  membersToInvite: MembersToInviteType;
};

type ContextType = {
  activeStep: number; //? between 0 | 1 | 2
  handleBack: VoidFunction;
  handleNext: VoidFunction;
  handleReset: VoidFunction;
  handleSetCurrentCreatedWorkspaceDetails: (
    details: WorkspaceDetailsType['currentCreatedWorkspaceDetails'],
  ) => void;
  currentCreatedWorkspaceDetails: WorkspaceDetailsType['currentCreatedWorkspaceDetails'];
  membersToInvite: WorkspaceDetailsType['membersToInvite'];
  handleDeleteMember: (email: string) => void;
  handleSetMemberToInvite: (payload: MemberToInviteType) => void;
  handleUpdateMember: (payload: MemberToInviteType) => void;
  handleCloseModal: VoidFunction;
};

export const WorkspaceSetupContext = createContext<ContextType | null>(null);

enum Types {
  RESET = 'SET_RESET',
  NEXT = 'SET_NEXT',
  BACK = 'SET_BACK',
  CURRENT_CREATED_WORKSPACE_DETAILS = 'SET_CURRENT_CREATED_WORKSPACE_DETAILS',
  SET_MEMBER = 'SET_MEMBER',
  DELETE_MEMBER = 'SET_DELETE_MEMBER',
  UPDATE_MEMBER = 'SET_UPDATE_MEMBER',
}

type WorkspaceDetailsPayload = {
  [Types.RESET]: undefined;
  [Types.NEXT]: {
    activeStep: number;
  };
  [Types.BACK]: {
    activeStep: number;
  };
  [Types.CURRENT_CREATED_WORKSPACE_DETAILS]: WorkspaceDetailsType['currentCreatedWorkspaceDetails'];
  [Types.DELETE_MEMBER]: {
    email: string;
  };
  [Types.SET_MEMBER]: MemberToInviteType;
  [Types.UPDATE_MEMBER]: MemberToInviteType;
};

export type WorkspaceDetailsActions =
  ActionMap<WorkspaceDetailsPayload>[keyof ActionMap<WorkspaceDetailsPayload>];

const initialState: WorkspaceDetailsType = {
  activeStep: 0,
  currentCreatedWorkspaceDetails: null,
  membersToInvite: [],
};

function reducer(state: WorkspaceDetailsType, action: WorkspaceDetailsActions) {
  switch (action.type) {
    case Types.RESET:
      return initialState;
    case Types.NEXT:
      return { ...state, activeStep: action.payload.activeStep };
    case Types.BACK:
      return { ...state, activeStep: state.activeStep - 1 };
    case Types.CURRENT_CREATED_WORKSPACE_DETAILS:
      return {
        ...state,
        currentCreatedWorkspaceDetails: {
          id: action.payload?.id,
          name: action.payload?.name,
          avatarBlob: action.payload?.avatarBlob,
        },
      };
    case Types.DELETE_MEMBER:
      return {
        ...state,
        membersToInvite: state.membersToInvite.filter(
          (m) => m.email !== action.payload.email,
        ),
      };
    case Types.SET_MEMBER:
      return {
        ...state,
        membersToInvite: [action.payload, ...state.membersToInvite],
      };
    case Types.UPDATE_MEMBER: {
      const index = state.membersToInvite.findIndex(
        (member) => member.email === action.payload.email,
      );
      return {
        ...state,
        membersToInvite: [
          ...state.membersToInvite.slice(0, index),
          action.payload,
          ...state.membersToInvite.slice(index + 1),
        ],
      };
    }
    default:
      return state;
  }
}

export default function WorkspaceSetupProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { getQueryParamByKey, removeQueryParamByKey } = useQueryParams();

  const handleBack = useCallback(() => {
    dispatch({
      type: Types.BACK,
      payload: {
        activeStep: state.activeStep - 1,
      },
    });
  }, [state.activeStep]);

  const handleNext = useCallback(() => {
    dispatch({
      type: Types.NEXT,
      payload: {
        activeStep: state.activeStep + 1,
      },
    });
  }, [state.activeStep]);

  function handleReset() {
    dispatch({
      type: Types.RESET,
    });
  }

  function handleSetCurrentCreatedWorkspaceDetails(
    details: WorkspaceDetailsType['currentCreatedWorkspaceDetails'],
  ) {
    dispatch({
      type: Types.CURRENT_CREATED_WORKSPACE_DETAILS,
      payload: details,
    });
  }

  function handleDeleteMember(email: string) {
    dispatch({
      type: Types.DELETE_MEMBER,
      payload: {
        email,
      },
    });
  }

  function handleSetMemberToInvite(payload: MemberToInviteType) {
    dispatch({
      type: Types.SET_MEMBER,
      payload,
    });
  }

  function handleUpdateMember(payload: MemberToInviteType) {
    dispatch({
      type: Types.UPDATE_MEMBER,
      payload,
    });
  }

  const handleCloseModal = useCallback(() => {
    handleReset();
    getQueryParamByKey('action');
    removeQueryParamByKey('action');
  }, [getQueryParamByKey, removeQueryParamByKey]);

  const value = useMemo(
    () => ({
      ...state,
      handleBack,
      handleNext,
      handleReset,
      handleSetCurrentCreatedWorkspaceDetails,
      handleDeleteMember,
      handleSetMemberToInvite,
      handleUpdateMember,
      handleCloseModal,
    }),
    [handleBack, handleCloseModal, handleNext, state],
  );

  return (
    <WorkspaceSetupContext.Provider value={value}>
      {children}
    </WorkspaceSetupContext.Provider>
  );
}

WorkspaceSetupProvider.DesktopModalStepper = DesktopModalStepper;
WorkspaceSetupProvider.ActiveStep = ActiveStep;

WorkspaceSetupProvider.MobileModalStepper = MobileModalStepper;
