import { useEffect, useState } from 'react';

import Tooltip from '@mui/material/Tooltip';

import StyledButton from '@components/pages/live-session/sections/content/components/transcript/components/resume-button/styles';

import { useRoomContext } from '@livekit/components-react';
import PauseIcon from '~icons/knowz-iconify/pause-only';
import PlayIcon from '~icons/knowz-iconify/play';

export default function ResumeButton() {
  const room = useRoomContext();
  const [isMuted, setIsMuted] = useState(false);

  const localParticipant = room.localParticipant;

  useEffect(() => {
    setIsMuted(!localParticipant.isMicrophoneEnabled);

    const handleMicrophoneStateChange = () => {
      setIsMuted(!localParticipant.isMicrophoneEnabled);
    };

    localParticipant.on('localTrackPublished', handleMicrophoneStateChange);

    return () => {
      localParticipant.off('localTrackPublished', handleMicrophoneStateChange);
    };
  }, [localParticipant]);

  if (!room || !room.localParticipant) {
    console.error('Room or local participant is not available.');
    return null;
  }

  const handleToggleMicMute = () => {
    try {
      const newMutedState = !localParticipant.isMicrophoneEnabled;
      localParticipant.setMicrophoneEnabled(newMutedState);
      setIsMuted(!newMutedState);
    } catch (error) {
      console.error('Failed to toggle microphone:', error);
    }
  };

  return (
    <Tooltip
      title="Resume Transcript & Feed?"
      placement="top"
      arrow
    >
      <StyledButton onClick={handleToggleMicMute}>
        {isMuted ? <PlayIcon /> : <PauseIcon />}
      </StyledButton>
    </Tooltip>
  );
}
