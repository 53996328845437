import { Fragment } from 'react';

import formatBreadcrumbPath from '@components/source-preview/components/utils/pathBreadcrumbs';
import { StyledPreviewFilePath } from '@components/source-preview/styles';

import { SourceDetailsType } from '@shared-types/sources';

import ChevronRightIcon from '~icons/knowz-iconify/chev-right';

type Props = {
  sourceDetailsData: SourceDetailsType;
};

export default function PathBreadcrumb({ sourceDetailsData }: Props) {
  const { parts } = formatBreadcrumbPath(
    sourceDetailsData?.folder?.path || null,
    sourceDetailsData?.name,
  );

  return (
    <StyledPreviewFilePath
      weight="medium"
      variant="textXs"
    >
      {parts.map((part, index) => (
        <Fragment key={`${part}-${index}`}>
          {index > 0 && <ChevronRightIcon fontSize="0.8rem" />}
          <span>{part}</span>
        </Fragment>
      ))}
    </StyledPreviewFilePath>
  );
}
