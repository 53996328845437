import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';

import { CellContext } from '@tanstack/react-table';

export default function Format({
  getValue,
  row,
}: CellContext<FolderType | SourceType, unknown>) {
  const fileType = getValue<string>();
  const isItAFolder = 'breadcrumb' in row.original;
  if (isItAFolder) return null;

  return <span>{fileType}</span>;
}
