/**
 * This file is generated by the script. Do not edit it manually.
 * If you want to update this file, checkout apps/ds-mapper/src/main.js
 **/

export const PRIMITIVES = {
  'Colors/Base/white': '#ffffff',
  'Colors/Base/black': '#000000',
  'Colors/Base/transparent': 'rgba(255, 255, 255, 0)',
  'Colors/Gray (light mode)/25': '#fdfdfd',
  'Colors/Gray (light mode)/50': '#fafafa',
  'Colors/Gray (light mode)/100': '#f5f5f5',
  'Colors/Gray (light mode)/200': '#e9eaeb',
  'Colors/Gray (light mode)/300': '#d5d7da',
  'Colors/Gray (light mode)/400': '#a4a7ae',
  'Colors/Gray (light mode)/500': '#717680',
  'Colors/Gray (light mode)/600': '#535862',
  'Colors/Gray (light mode)/700': '#414651',
  'Colors/Gray (light mode)/800': '#252b37',
  'Colors/Gray (light mode)/900': '#181d27',
  'Colors/Gray (light mode)/950': '#0a0d12',
  'Colors/Gray (dark mode)/25': '#fafafa',
  'Colors/Gray (dark mode)/50': '#f7f7f7',
  'Colors/Gray (dark mode)/100': '#f0f0f1',
  'Colors/Gray (dark mode)/200': '#ececed',
  'Colors/Gray (dark mode)/300': '#cecfd2',
  'Colors/Gray (dark mode)/400': '#94979c',
  'Colors/Gray (dark mode)/500': '#85888e',
  'Colors/Gray (dark mode)/600': '#61656c',
  'Colors/Gray (dark mode)/700': '#373a41',
  'Colors/Gray (dark mode)/800': '#22262f',
  'Colors/Gray (dark mode)/900': '#13161b',
  'Colors/Gray (dark mode)/950': '#0c0e12',
  'Colors/Gray (dark mode alpha)/25': 'rgba(255, 255, 255, 98)',
  'Colors/Gray (dark mode alpha)/50': 'rgba(255, 255, 255, 96)',
  'Colors/Gray (dark mode alpha)/100': 'rgba(255, 255, 255, 94)',
  'Colors/Gray (dark mode alpha)/200': 'rgba(255, 255, 255, 92)',
  'Colors/Gray (dark mode alpha)/300': 'rgba(255, 255, 255, 80)',
  'Colors/Gray (dark mode alpha)/400': 'rgba(255, 255, 255, 56.00000000000001)',
  'Colors/Gray (dark mode alpha)/500': 'rgba(255, 255, 255, 50)',
  'Colors/Gray (dark mode alpha)/600': 'rgba(255, 255, 255, 35)',
  'Colors/Gray (dark mode alpha)/700': 'rgba(255, 255, 255, 16)',
  'Colors/Gray (dark mode alpha)/800': 'rgba(255, 255, 255, 8)',
  'Colors/Gray (dark mode alpha)/900': 'rgba(255, 255, 255, 4)',
  'Colors/Gray (dark mode alpha)/950': 'rgba(255, 255, 255, 0)',
  'Colors/Brand/25': '#f5f6ff',
  'Colors/Brand/50': '#f0f2ff',
  'Colors/Brand/100': '#e5eaff',
  'Colors/Brand/200': '#ccd4ff',
  'Colors/Brand/300': '#adbcff',
  'Colors/Brand/400': '#8a9dff',
  'Colors/Brand/500': '#6778fe',
  'Colors/Brand/600': '#3f3ff8',
  'Colors/Brand/700': '#3c1af9',
  'Colors/Brand/800': '#2305c7',
  'Colors/Brand/900': '#18048b',
  'Colors/Brand/950': '#0f0359',
  'Colors/Error/25': '#fffbfa',
  'Colors/Error/50': '#fef3f2',
  'Colors/Error/100': '#fee4e2',
  'Colors/Error/200': '#fecdca',
  'Colors/Error/300': '#fda29b',
  'Colors/Error/400': '#f97066',
  'Colors/Error/500': '#f04438',
  'Colors/Error/600': '#d92d20',
  'Colors/Error/700': '#b42318',
  'Colors/Error/800': '#912018',
  'Colors/Error/900': '#7a271a',
  'Colors/Error/950': '#55160c',
  'Colors/Warning/25': '#fffcf5',
  'Colors/Warning/50': '#fffaeb',
  'Colors/Warning/100': '#fef0c7',
  'Colors/Warning/200': '#fedf89',
  'Colors/Warning/300': '#fec84b',
  'Colors/Warning/400': '#fdb022',
  'Colors/Warning/500': '#f79009',
  'Colors/Warning/600': '#dc6803',
  'Colors/Warning/700': '#b54708',
  'Colors/Warning/800': '#93370d',
  'Colors/Warning/900': '#7a2e0e',
  'Colors/Warning/950': '#4e1d09',
  'Colors/Success/25': '#f6fef9',
  'Colors/Success/50': '#ecfdf3',
  'Colors/Success/100': '#dcfae6',
  'Colors/Success/200': '#abefc6',
  'Colors/Success/300': '#75e0a7',
  'Colors/Success/400': '#47cd89',
  'Colors/Success/500': '#17b26a',
  'Colors/Success/600': '#079455',
  'Colors/Success/700': '#067647',
  'Colors/Success/800': '#085d3a',
  'Colors/Success/900': '#074d31',
  'Colors/Success/950': '#053321',
  'Colors/Gray blue/25': '#fcfcfd',
  'Colors/Gray blue/50': '#f8f9fc',
  'Colors/Gray blue/100': '#eaecf5',
  'Colors/Gray blue/200': '#d5d9eb',
  'Colors/Gray blue/300': '#b3b8db',
  'Colors/Gray blue/400': '#717bbc',
  'Colors/Gray blue/500': '#4e5ba6',
  'Colors/Gray blue/600': '#3e4784',
  'Colors/Gray blue/700': '#363f72',
  'Colors/Gray blue/800': '#293056',
  'Colors/Gray blue/900': '#101323',
  'Colors/Gray blue/950': '#0d0f1c',
  'Colors/Gray cool/25': '#fcfcfd',
  'Colors/Gray cool/50': '#f9f9fb',
  'Colors/Gray cool/100': '#eff1f5',
  'Colors/Gray cool/200': '#dcdfea',
  'Colors/Gray cool/300': '#b9c0d4',
  'Colors/Gray cool/400': '#7d89b0',
  'Colors/Gray cool/500': '#5d6b98',
  'Colors/Gray cool/600': '#4a5578',
  'Colors/Gray cool/700': '#404968',
  'Colors/Gray cool/800': '#30374f',
  'Colors/Gray cool/900': '#111322',
  'Colors/Gray cool/950': '#0e101b',
  'Colors/Gray modern/25': '#fcfcfd',
  'Colors/Gray modern/50': '#f8fafc',
  'Colors/Gray modern/100': '#eef2f6',
  'Colors/Gray modern/200': '#e3e8ef',
  'Colors/Gray modern/300': '#cdd5df',
  'Colors/Gray modern/400': '#9aa4b2',
  'Colors/Gray modern/500': '#697586',
  'Colors/Gray modern/600': '#4b5565',
  'Colors/Gray modern/700': '#364152',
  'Colors/Gray modern/800': '#202939',
  'Colors/Gray modern/900': '#121926',
  'Colors/Gray modern/950': '#0d121c',
  'Colors/Gray neutral/25': '#fcfcfd',
  'Colors/Gray neutral/50': '#f9fafb',
  'Colors/Gray neutral/100': '#f3f4f6',
  'Colors/Gray neutral/200': '#e5e7eb',
  'Colors/Gray neutral/300': '#d2d6db',
  'Colors/Gray neutral/400': '#9da4ae',
  'Colors/Gray neutral/500': '#6c737f',
  'Colors/Gray neutral/600': '#4d5761',
  'Colors/Gray neutral/700': '#384250',
  'Colors/Gray neutral/800': '#1f2a37',
  'Colors/Gray neutral/900': '#111927',
  'Colors/Gray neutral/950': '#0d121c',
  'Colors/Gray iron/25': '#fcfcfc',
  'Colors/Gray iron/50': '#fafafa',
  'Colors/Gray iron/100': '#f4f4f5',
  'Colors/Gray iron/200': '#e4e4e7',
  'Colors/Gray iron/300': '#d1d1d6',
  'Colors/Gray iron/400': '#a0a0ab',
  'Colors/Gray iron/500': '#70707b',
  'Colors/Gray iron/600': '#51525c',
  'Colors/Gray iron/700': '#3f3f46',
  'Colors/Gray iron/800': '#26272b',
  'Colors/Gray iron/900': '#1a1a1e',
  'Colors/Gray iron/950': '#131316',
  'Colors/Gray true/25': '#fcfcfc',
  'Colors/Gray true/50': '#f7f7f7',
  'Colors/Gray true/100': '#f5f5f5',
  'Colors/Gray true/200': '#e5e5e5',
  'Colors/Gray true/300': '#d6d6d6',
  'Colors/Gray true/400': '#a3a3a3',
  'Colors/Gray true/500': '#737373',
  'Colors/Gray true/600': '#525252',
  'Colors/Gray true/700': '#424242',
  'Colors/Gray true/800': '#292929',
  'Colors/Gray true/900': '#141414',
  'Colors/Gray true/950': '#0f0f0f',
  'Colors/Gray warm/25': '#fdfdfc',
  'Colors/Gray warm/50': '#fafaf9',
  'Colors/Gray warm/100': '#f5f5f4',
  'Colors/Gray warm/200': '#e7e5e4',
  'Colors/Gray warm/300': '#d7d3d0',
  'Colors/Gray warm/400': '#a9a29d',
  'Colors/Gray warm/500': '#79716b',
  'Colors/Gray warm/600': '#57534e',
  'Colors/Gray warm/700': '#44403c',
  'Colors/Gray warm/800': '#292524',
  'Colors/Gray warm/900': '#1c1917',
  'Colors/Gray warm/950': '#171412',
  'Colors/Moss/25': '#fafdf7',
  'Colors/Moss/50': '#f5fbee',
  'Colors/Moss/100': '#e6f4d7',
  'Colors/Moss/200': '#ceeab0',
  'Colors/Moss/300': '#acdc79',
  'Colors/Moss/400': '#86cb3c',
  'Colors/Moss/500': '#669f2a',
  'Colors/Moss/600': '#4f7a21',
  'Colors/Moss/700': '#3f621a',
  'Colors/Moss/800': '#335015',
  'Colors/Moss/900': '#2b4212',
  'Colors/Moss/950': '#1a280b',
  'Colors/Green light/25': '#fafef5',
  'Colors/Green light/50': '#f3fee7',
  'Colors/Green light/100': '#e3fbcc',
  'Colors/Green light/200': '#d0f8ab',
  'Colors/Green light/300': '#a6ef67',
  'Colors/Green light/400': '#85e13a',
  'Colors/Green light/500': '#66c61c',
  'Colors/Green light/600': '#4ca30d',
  'Colors/Green light/700': '#3b7c0f',
  'Colors/Green light/800': '#326212',
  'Colors/Green light/900': '#2b5314',
  'Colors/Green light/950': '#15290a',
  'Colors/Green/25': '#f6fef9',
  'Colors/Green/50': '#edfcf2',
  'Colors/Green/100': '#d3f8df',
  'Colors/Green/200': '#aaf0c4',
  'Colors/Green/300': '#73e2a3',
  'Colors/Green/400': '#3ccb7f',
  'Colors/Green/500': '#16b364',
  'Colors/Green/600': '#099250',
  'Colors/Green/700': '#087443',
  'Colors/Green/800': '#095c37',
  'Colors/Green/900': '#084c2e',
  'Colors/Green/950': '#052e1c',
  'Colors/Teal/25': '#f6fefc',
  'Colors/Teal/50': '#f0fdf9',
  'Colors/Teal/100': '#ccfbef',
  'Colors/Teal/200': '#99f6e0',
  'Colors/Teal/300': '#5fe9d0',
  'Colors/Teal/400': '#2ed3b7',
  'Colors/Teal/500': '#15b79e',
  'Colors/Teal/600': '#0e9384',
  'Colors/Teal/700': '#107569',
  'Colors/Teal/800': '#125d56',
  'Colors/Teal/900': '#134e48',
  'Colors/Teal/950': '#0a2926',
  'Colors/Cyan/25': '#f5feff',
  'Colors/Cyan/50': '#ecfdff',
  'Colors/Cyan/100': '#cff9fe',
  'Colors/Cyan/200': '#a5f0fc',
  'Colors/Cyan/300': '#67e3f9',
  'Colors/Cyan/400': '#22ccee',
  'Colors/Cyan/500': '#06aed4',
  'Colors/Cyan/600': '#088ab2',
  'Colors/Cyan/700': '#0e7090',
  'Colors/Cyan/800': '#155b75',
  'Colors/Cyan/900': '#164c63',
  'Colors/Cyan/950': '#0d2d3a',
  'Colors/Blue light/25': '#f5fbff',
  'Colors/Blue light/50': '#f0f9ff',
  'Colors/Blue light/100': '#e0f2fe',
  'Colors/Blue light/200': '#b9e6fe',
  'Colors/Blue light/300': '#7cd4fd',
  'Colors/Blue light/400': '#36bffa',
  'Colors/Blue light/500': '#0ba5ec',
  'Colors/Blue light/600': '#0086c9',
  'Colors/Blue light/700': '#026aa2',
  'Colors/Blue light/800': '#065986',
  'Colors/Blue light/900': '#0b4a6f',
  'Colors/Blue light/950': '#062c41',
  'Colors/Blue/25': '#f5faff',
  'Colors/Blue/50': '#eff8ff',
  'Colors/Blue/100': '#d1e9ff',
  'Colors/Blue/200': '#b2ddff',
  'Colors/Blue/300': '#84caff',
  'Colors/Blue/400': '#53b1fd',
  'Colors/Blue/500': '#2e90fa',
  'Colors/Blue/600': '#1570ef',
  'Colors/Blue/700': '#175cd3',
  'Colors/Blue/800': '#1849a9',
  'Colors/Blue/900': '#194185',
  'Colors/Blue/950': '#102a56',
  'Colors/Blue dark/25': '#f5f8ff',
  'Colors/Blue dark/50': '#eff4ff',
  'Colors/Blue dark/100': '#d1e0ff',
  'Colors/Blue dark/200': '#b2ccff',
  'Colors/Blue dark/300': '#84adff',
  'Colors/Blue dark/400': '#528bff',
  'Colors/Blue dark/500': '#2970ff',
  'Colors/Blue dark/600': '#155eef',
  'Colors/Blue dark/700': '#004eeb',
  'Colors/Blue dark/800': '#0040c1',
  'Colors/Blue dark/900': '#00359e',
  'Colors/Blue dark/950': '#002266',
  'Colors/Indigo/25': '#f5f8ff',
  'Colors/Indigo/50': '#eef4ff',
  'Colors/Indigo/100': '#e0eaff',
  'Colors/Indigo/200': '#c7d7fe',
  'Colors/Indigo/300': '#a4bcfd',
  'Colors/Indigo/400': '#8098f9',
  'Colors/Indigo/500': '#6172f3',
  'Colors/Indigo/600': '#444ce7',
  'Colors/Indigo/700': '#3538cd',
  'Colors/Indigo/800': '#2d31a6',
  'Colors/Indigo/900': '#2d3282',
  'Colors/Indigo/950': '#1f235b',
  'Colors/Violet/25': '#fbfaff',
  'Colors/Violet/50': '#f5f3ff',
  'Colors/Violet/100': '#ece9fe',
  'Colors/Violet/200': '#ddd6fe',
  'Colors/Violet/300': '#c3b5fd',
  'Colors/Violet/400': '#a48afb',
  'Colors/Violet/500': '#875bf7',
  'Colors/Violet/600': '#7839ee',
  'Colors/Violet/700': '#6927da',
  'Colors/Violet/800': '#5720b7',
  'Colors/Violet/900': '#491c96',
  'Colors/Violet/950': '#2e125e',
  'Colors/Purple/25': '#fafaff',
  'Colors/Purple/50': '#f4f3ff',
  'Colors/Purple/100': '#ebe9fe',
  'Colors/Purple/200': '#d9d6fe',
  'Colors/Purple/300': '#bdb4fe',
  'Colors/Purple/400': '#9b8afb',
  'Colors/Purple/500': '#7a5af8',
  'Colors/Purple/600': '#6938ef',
  'Colors/Purple/700': '#5925dc',
  'Colors/Purple/800': '#4a1fb8',
  'Colors/Purple/900': '#3e1c96',
  'Colors/Purple/950': '#27115f',
  'Colors/Fuchsia/25': '#fefaff',
  'Colors/Fuchsia/50': '#fdf4ff',
  'Colors/Fuchsia/100': '#fbe8ff',
  'Colors/Fuchsia/200': '#f6d0fe',
  'Colors/Fuchsia/300': '#eeaafd',
  'Colors/Fuchsia/400': '#e478fa',
  'Colors/Fuchsia/500': '#d444f1',
  'Colors/Fuchsia/600': '#ba24d5',
  'Colors/Fuchsia/700': '#9f1ab1',
  'Colors/Fuchsia/800': '#821890',
  'Colors/Fuchsia/900': '#6f1877',
  'Colors/Fuchsia/950': '#47104c',
  'Colors/Pink/25': '#fef6fb',
  'Colors/Pink/50': '#fdf2fa',
  'Colors/Pink/100': '#fce7f6',
  'Colors/Pink/200': '#fcceee',
  'Colors/Pink/300': '#faa7e0',
  'Colors/Pink/400': '#f670c7',
  'Colors/Pink/500': '#ee46bc',
  'Colors/Pink/600': '#dd2590',
  'Colors/Pink/700': '#c11574',
  'Colors/Pink/800': '#9e165f',
  'Colors/Pink/900': '#851651',
  'Colors/Pink/950': '#4e0d30',
  'Colors/Rosé/25': '#fff5f6',
  'Colors/Rosé/50': '#fff1f3',
  'Colors/Rosé/100': '#ffe4e8',
  'Colors/Rosé/200': '#fecdd6',
  'Colors/Rosé/300': '#fea3b4',
  'Colors/Rosé/400': '#fd6f8e',
  'Colors/Rosé/500': '#f63d68',
  'Colors/Rosé/600': '#e31b54',
  'Colors/Rosé/700': '#c01048',
  'Colors/Rosé/800': '#a11043',
  'Colors/Rosé/900': '#89123e',
  'Colors/Rosé/950': '#510b24',
  'Colors/Orange dark/25': '#fff9f5',
  'Colors/Orange dark/50': '#fff4ed',
  'Colors/Orange dark/100': '#ffe6d5',
  'Colors/Orange dark/200': '#ffd6ae',
  'Colors/Orange dark/300': '#ff9c66',
  'Colors/Orange dark/400': '#ff692e',
  'Colors/Orange dark/500': '#ff4405',
  'Colors/Orange dark/600': '#e62e05',
  'Colors/Orange dark/700': '#bc1b06',
  'Colors/Orange dark/800': '#97180c',
  'Colors/Orange dark/900': '#771a0d',
  'Colors/Orange dark/950': '#57130a',
  'Colors/Orange/25': '#fefaf5',
  'Colors/Orange/50': '#fef6ee',
  'Colors/Orange/100': '#fdead7',
  'Colors/Orange/200': '#f9dbaf',
  'Colors/Orange/300': '#f7b27a',
  'Colors/Orange/400': '#f38744',
  'Colors/Orange/500': '#ef6820',
  'Colors/Orange/600': '#e04f16',
  'Colors/Orange/700': '#b93815',
  'Colors/Orange/800': '#932f19',
  'Colors/Orange/900': '#772917',
  'Colors/Orange/950': '#511c10',
  'Colors/Yellow/25': '#fefdf0',
  'Colors/Yellow/50': '#fefbe8',
  'Colors/Yellow/100': '#fef7c3',
  'Colors/Yellow/200': '#feee95',
  'Colors/Yellow/300': '#fde272',
  'Colors/Yellow/400': '#fac515',
  'Colors/Yellow/500': '#eaaa08',
  'Colors/Yellow/600': '#ca8504',
  'Colors/Yellow/700': '#a15c07',
  'Colors/Yellow/800': '#854a0e',
  'Colors/Yellow/900': '#713b12',
  'Colors/Yellow/950': '#542c0d',
  'Spacing/0․5 (2px)': '2',
  'Spacing/1 (4px)': '4',
  'Spacing/1․5 (6px)': '6',
  'Spacing/2 (8px)': '8',
  'Spacing/3 (12px)': '12',
  'Spacing/4 (16px)': '16',
  'Spacing/5 (20px)': '20',
  'Spacing/6 (24px)': '24',
  'Spacing/8 (32px)': '32',
  'Spacing/10 (40px)': '40',
  'Spacing/12 (48px)': '48',
  'Spacing/16 (64px)': '64',
  'Spacing/20 (80px)': '80',
  'Spacing/24 (96px)': '96',
  'Spacing/32 (128px)': '128',
  'Spacing/40 (160px)': '160',
  'Spacing/48 (192px)': '192',
  'Spacing/56 (224px)': '224',
  'Spacing/64 (256px)': '256',
  'Spacing/80 (320px)': '320',
  'Spacing/96 (384px)': '384',
  'Spacing/120 (480px)': '480',
  'Spacing/140 (560px)': '560',
  'Spacing/160 (640px)': '640',
  'Spacing/180 (720px)': '720',
  'Spacing/192 (768px)': '768',
  'Spacing/256 (1,024px)': '1024',
  'Spacing/320 (1,280px)': '1280',
  'Spacing/360 (1,440px)': '1440',
  'Spacing/400 (1,600px)': '1600',
  'Spacing/480 (1,920px)': '1920',
};

export const LIGHT_COLORS = {
  'Colors/Text/text-primary (900)': PRIMITIVES['Colors/Gray (light mode)/900'],
  'Colors/Text/text-primary_on-brand': PRIMITIVES['Colors/Base/white'],
  'Colors/Text/text-secondary (700)':
    PRIMITIVES['Colors/Gray (light mode)/700'],
  'Colors/Text/text-secondary_hover':
    PRIMITIVES['Colors/Gray (light mode)/800'],
  'Colors/Text/text-secondary_on-brand': PRIMITIVES['Colors/Brand/200'],
  'Colors/Text/text-tertiary (600)': PRIMITIVES['Colors/Gray (light mode)/600'],
  'Colors/Text/text-tertiary_hover': PRIMITIVES['Colors/Gray (light mode)/700'],
  'Colors/Text/text-tertiary_on-brand': PRIMITIVES['Colors/Brand/200'],
  'Colors/Text/text-quaternary (500)':
    PRIMITIVES['Colors/Gray (light mode)/500'],
  'Colors/Text/text-quaternary_on-brand': PRIMITIVES['Colors/Brand/300'],
  'Colors/Text/text-white': PRIMITIVES['Colors/Base/white'],
  'Colors/Text/text-disabled': PRIMITIVES['Colors/Gray (light mode)/500'],
  'Colors/Text/text-placeholder': PRIMITIVES['Colors/Gray (light mode)/500'],
  'Colors/Text/text-placeholder_subtle':
    PRIMITIVES['Colors/Gray (light mode)/300'],
  'Colors/Text/text-brand-primary (900)': PRIMITIVES['Colors/Brand/900'],
  'Colors/Text/text-brand-secondary (700)': PRIMITIVES['Colors/Brand/700'],
  'Colors/Text/text-brand-tertiary (600)': PRIMITIVES['Colors/Brand/600'],
  'Colors/Text/text-brand-tertiary_alt': PRIMITIVES['Colors/Brand/600'],
  'Colors/Text/text-error-primary (600)': PRIMITIVES['Colors/Error/600'],
  'Colors/Text/text-warning-primary (600)': PRIMITIVES['Colors/Warning/600'],
  'Colors/Text/text-success-primary (600)': PRIMITIVES['Colors/Success/600'],
  'Colors/Border/border-primary': PRIMITIVES['Colors/Gray (light mode)/300'],
  'Colors/Border/border-secondary': PRIMITIVES['Colors/Gray (light mode)/200'],
  'Colors/Border/border-tertiary': PRIMITIVES['Colors/Gray (light mode)/100'],
  'Colors/Border/border-disabled': PRIMITIVES['Colors/Gray (light mode)/300'],
  'Colors/Border/border-disabled_subtle':
    PRIMITIVES['Colors/Gray (light mode)/200'],
  'Colors/Border/border-brand': PRIMITIVES['Colors/Brand/500'],
  'Colors/Border/border-brand_alt': PRIMITIVES['Colors/Brand/600'],
  'Colors/Border/border-error': PRIMITIVES['Colors/Error/500'],
  'Colors/Border/border-error_subtle': PRIMITIVES['Colors/Error/300'],
  'Colors/Foreground/fg-primary (900)':
    PRIMITIVES['Colors/Gray (light mode)/900'],
  'Colors/Foreground/fg-secondary (700)':
    PRIMITIVES['Colors/Gray (light mode)/700'],
  'Colors/Foreground/fg-secondary_hover':
    PRIMITIVES['Colors/Gray (light mode)/800'],
  'Colors/Foreground/fg-tertiary (600)':
    PRIMITIVES['Colors/Gray (light mode)/600'],
  'Colors/Foreground/fg-tertiary_hover':
    PRIMITIVES['Colors/Gray (light mode)/700'],
  'Colors/Foreground/fg-quaternary (500)':
    PRIMITIVES['Colors/Gray (light mode)/500'],
  'Colors/Foreground/fg-quaternary_hover':
    PRIMITIVES['Colors/Gray (light mode)/600'],
  'Colors/Foreground/fg-quinary (400)':
    PRIMITIVES['Colors/Gray (light mode)/400'],
  'Colors/Foreground/fg-quinary_hover':
    PRIMITIVES['Colors/Gray (light mode)/500'],
  'Colors/Foreground/fg-senary (300)':
    PRIMITIVES['Colors/Gray (light mode)/300'],
  'Colors/Foreground/fg-white': PRIMITIVES['Colors/Base/white'],
  'Colors/Foreground/fg-disabled': PRIMITIVES['Colors/Gray (light mode)/400'],
  'Colors/Foreground/fg-disabled_subtle':
    PRIMITIVES['Colors/Gray (light mode)/300'],
  'Colors/Foreground/fg-brand-primary (600)': PRIMITIVES['Colors/Brand/600'],
  'Colors/Foreground/fg-brand-primary_alt': PRIMITIVES['Colors/Brand/600'],
  'Colors/Foreground/fg-brand-secondary (500)': PRIMITIVES['Colors/Brand/500'],
  'Colors/Foreground/fg-error-primary': PRIMITIVES['Colors/Error/600'],
  'Colors/Foreground/fg-error-secondary': PRIMITIVES['Colors/Error/500'],
  'Colors/Foreground/fg-warning-primary': PRIMITIVES['Colors/Warning/600'],
  'Colors/Foreground/fg-warning-secondary': PRIMITIVES['Colors/Warning/500'],
  'Colors/Foreground/fg-success-primary': PRIMITIVES['Colors/Success/600'],
  'Colors/Foreground/fg-success-secondary': PRIMITIVES['Colors/Success/500'],
  'Colors/Background/bg-primary': PRIMITIVES['Colors/Base/white'],
  'Colors/Background/bg-primary_alt': PRIMITIVES['Colors/Base/white'],
  'Colors/Background/bg-primary_hover':
    PRIMITIVES['Colors/Gray (light mode)/50'],
  'Colors/Background/bg-primary-solid':
    PRIMITIVES['Colors/Gray (light mode)/950'],
  'Colors/Background/bg-secondary': PRIMITIVES['Colors/Gray (light mode)/50'],
  'Colors/Background/bg-secondary_alt':
    PRIMITIVES['Colors/Gray (light mode)/50'],
  'Colors/Background/bg-secondary_hover':
    PRIMITIVES['Colors/Gray (light mode)/100'],
  'Colors/Background/bg-secondary_subtle':
    PRIMITIVES['Colors/Gray (light mode)/25'],
  'Colors/Background/bg-secondary-solid':
    PRIMITIVES['Colors/Gray (light mode)/600'],
  'Colors/Background/bg-tertiary': PRIMITIVES['Colors/Gray (light mode)/100'],
  'Colors/Background/bg-quaternary': PRIMITIVES['Colors/Gray (light mode)/200'],
  'Colors/Background/bg-active': PRIMITIVES['Colors/Gray (light mode)/50'],
  'Colors/Background/bg-disabled': PRIMITIVES['Colors/Gray (light mode)/100'],
  'Colors/Background/bg-disabled_subtle':
    PRIMITIVES['Colors/Gray (light mode)/50'],
  'Colors/Background/bg-overlay': PRIMITIVES['Colors/Gray (light mode)/950'],
  'Colors/Background/bg-brand-primary': PRIMITIVES['Colors/Brand/50'],
  'Colors/Background/bg-brand-primary_alt': PRIMITIVES['Colors/Brand/50'],
  'Colors/Background/bg-brand-secondary': PRIMITIVES['Colors/Brand/100'],
  'Colors/Background/bg-brand-solid': PRIMITIVES['Colors/Brand/600'],
  'Colors/Background/bg-brand-solid_hover': PRIMITIVES['Colors/Brand/700'],
  'Colors/Background/bg-brand-section': PRIMITIVES['Colors/Brand/800'],
  'Colors/Background/bg-brand-section_subtle': PRIMITIVES['Colors/Brand/700'],
  'Colors/Background/bg-error-primary': PRIMITIVES['Colors/Error/50'],
  'Colors/Background/bg-error-secondary': PRIMITIVES['Colors/Error/100'],
  'Colors/Background/bg-error-solid': PRIMITIVES['Colors/Error/600'],
  'Colors/Background/bg-warning-primary': PRIMITIVES['Colors/Warning/50'],
  'Colors/Background/bg-warning-secondary': PRIMITIVES['Colors/Warning/100'],
  'Colors/Background/bg-warning-solid': PRIMITIVES['Colors/Warning/600'],
  'Colors/Background/bg-success-primary': PRIMITIVES['Colors/Success/50'],
  'Colors/Background/bg-success-secondary': PRIMITIVES['Colors/Success/100'],
  'Colors/Background/bg-success-solid': PRIMITIVES['Colors/Success/600'],
  'Colors/Effects/Focus rings/focus-ring': PRIMITIVES['Colors/Brand/500'],
  'Colors/Effects/Focus rings/focus-ring-error': PRIMITIVES['Colors/Error/500'],
  'Colors/Effects/Shadows/shadow-xs': 'rgba(10, 13, 18, 5)',
  'Colors/Effects/Shadows/shadow-sm_01': 'rgba(10, 13, 18, 10)',
  'Colors/Effects/Shadows/shadow-sm_02': 'rgba(10, 13, 18, 10)',
  'Colors/Effects/Shadows/shadow-md_01': 'rgba(10, 13, 18, 10)',
  'Colors/Effects/Shadows/shadow-md_02': 'rgba(10, 13, 18, 6)',
  'Colors/Effects/Shadows/shadow-lg_01': 'rgba(10, 13, 18, 8)',
  'Colors/Effects/Shadows/shadow-lg_02': 'rgba(10, 13, 18, 3)',
  'Colors/Effects/Shadows/shadow-lg_03': 'rgba(10, 13, 18, 4)',
  'Colors/Effects/Shadows/shadow-xl_01': 'rgba(10, 13, 18, 8)',
  'Colors/Effects/Shadows/shadow-xl_02': 'rgba(10, 13, 18, 3)',
  'Colors/Effects/Shadows/shadow-xl_03': 'rgba(10, 13, 18, 4)',
  'Colors/Effects/Shadows/shadow-2xl_01': 'rgba(10, 13, 18, 18)',
  'Colors/Effects/Shadows/shadow-2xl_02': 'rgba(10, 13, 18, 4)',
  'Colors/Effects/Shadows/shadow-3xl_01':
    'rgba(10, 13, 18, 14.000000000000002)',
  'Colors/Effects/Shadows/shadow-3xl_02': 'rgba(10, 13, 18, 4)',
  'Colors/Effects/Shadows/shadow-skeumorphic-inner': 'rgba(10, 13, 18, 5)',
  'Colors/Effects/Shadows/shadow-skeumorphic-inner-border':
    'rgba(10, 13, 18, 18)',
  'Colors/Effects/Portfolio mockups/shadow-main-centre-md':
    'rgba(10, 13, 18, 14.000000000000002)',
  'Colors/Effects/Portfolio mockups/shadow-main-centre-lg':
    'rgba(10, 13, 18, 18)',
  'Colors/Effects/Portfolio mockups/shadow-overlay-lg': 'rgba(10, 13, 18, 12)',
  'Colors/Effects/Portfolio mockups/shadow-grid-md': 'rgba(10, 13, 18, 8)',
  'Component colors/Alpha/alpha-white-10': 'rgba(255, 255, 255, 10)',
  'Component colors/Alpha/alpha-white-20': 'rgba(255, 255, 255, 20)',
  'Component colors/Alpha/alpha-white-30': 'rgba(255, 255, 255, 30)',
  'Component colors/Alpha/alpha-white-40': 'rgba(255, 255, 255, 40)',
  'Component colors/Alpha/alpha-white-50': 'rgba(255, 255, 255, 50)',
  'Component colors/Alpha/alpha-white-60': 'rgba(255, 255, 255, 60)',
  'Component colors/Alpha/alpha-white-70': 'rgba(255, 255, 255, 70)',
  'Component colors/Alpha/alpha-white-80': 'rgba(255, 255, 255, 80)',
  'Component colors/Alpha/alpha-white-90': 'rgba(255, 255, 255, 90)',
  'Component colors/Alpha/alpha-white-100': '#ffffff',
  'Component colors/Alpha/alpha-black-10': 'rgba(0, 0, 0, 10)',
  'Component colors/Alpha/alpha-black-20': 'rgba(0, 0, 0, 20)',
  'Component colors/Alpha/alpha-black-30': 'rgba(0, 0, 0, 30)',
  'Component colors/Alpha/alpha-black-40': 'rgba(0, 0, 0, 40)',
  'Component colors/Alpha/alpha-black-50': 'rgba(0, 0, 0, 50)',
  'Component colors/Alpha/alpha-black-60': 'rgba(0, 0, 0, 60)',
  'Component colors/Alpha/alpha-black-70': 'rgba(0, 0, 0, 70)',
  'Component colors/Alpha/alpha-black-80': 'rgba(0, 0, 0, 80)',
  'Component colors/Alpha/alpha-black-90': 'rgba(0, 0, 0, 90)',
  'Component colors/Alpha/alpha-black-100': '#000000',
  'Component colors/Utility/Gray/utility-gray-50':
    PRIMITIVES['Colors/Gray (light mode)/50'],
  'Component colors/Utility/Gray/utility-gray-100':
    PRIMITIVES['Colors/Gray (light mode)/100'],
  'Component colors/Utility/Gray/utility-gray-200':
    PRIMITIVES['Colors/Gray (light mode)/200'],
  'Component colors/Utility/Gray/utility-gray-300':
    PRIMITIVES['Colors/Gray (light mode)/300'],
  'Component colors/Utility/Gray/utility-gray-400':
    PRIMITIVES['Colors/Gray (light mode)/400'],
  'Component colors/Utility/Gray/utility-gray-500':
    PRIMITIVES['Colors/Gray (light mode)/500'],
  'Component colors/Utility/Gray/utility-gray-600':
    PRIMITIVES['Colors/Gray (light mode)/600'],
  'Component colors/Utility/Gray/utility-gray-700':
    PRIMITIVES['Colors/Gray (light mode)/700'],
  'Component colors/Utility/Gray/utility-gray-800':
    PRIMITIVES['Colors/Gray (light mode)/800'],
  'Component colors/Utility/Gray/utility-gray-900':
    PRIMITIVES['Colors/Gray (light mode)/900'],
  'Component colors/Utility/Brand/utility-brand-50':
    PRIMITIVES['Colors/Brand/50'],
  'Component colors/Utility/Brand/utility-brand-50_alt':
    PRIMITIVES['Colors/Brand/50'],
  'Component colors/Utility/Brand/utility-brand-100':
    PRIMITIVES['Colors/Brand/100'],
  'Component colors/Utility/Brand/utility-brand-100_alt':
    PRIMITIVES['Colors/Brand/100'],
  'Component colors/Utility/Brand/utility-brand-200':
    PRIMITIVES['Colors/Brand/200'],
  'Component colors/Utility/Brand/utility-brand-200_alt':
    PRIMITIVES['Colors/Brand/200'],
  'Component colors/Utility/Brand/utility-brand-300':
    PRIMITIVES['Colors/Brand/300'],
  'Component colors/Utility/Brand/utility-brand-300_alt':
    PRIMITIVES['Colors/Brand/300'],
  'Component colors/Utility/Brand/utility-brand-400':
    PRIMITIVES['Colors/Brand/400'],
  'Component colors/Utility/Brand/utility-brand-400_alt':
    PRIMITIVES['Colors/Brand/400'],
  'Component colors/Utility/Brand/utility-brand-500':
    PRIMITIVES['Colors/Brand/500'],
  'Component colors/Utility/Brand/utility-brand-500_alt':
    PRIMITIVES['Colors/Brand/500'],
  'Component colors/Utility/Brand/utility-brand-600':
    PRIMITIVES['Colors/Brand/600'],
  'Component colors/Utility/Brand/utility-brand-600_alt':
    PRIMITIVES['Colors/Brand/600'],
  'Component colors/Utility/Brand/utility-brand-700':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Utility/Brand/utility-brand-700_alt':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Utility/Brand/utility-brand-800':
    PRIMITIVES['Colors/Brand/800'],
  'Component colors/Utility/Brand/utility-brand-800_alt':
    PRIMITIVES['Colors/Brand/800'],
  'Component colors/Utility/Brand/utility-brand-900':
    PRIMITIVES['Colors/Brand/900'],
  'Component colors/Utility/Brand/utility-brand-900_alt':
    PRIMITIVES['Colors/Brand/900'],
  'Component colors/Utility/Error/utility-error-50':
    PRIMITIVES['Colors/Error/50'],
  'Component colors/Utility/Error/utility-error-100':
    PRIMITIVES['Colors/Error/100'],
  'Component colors/Utility/Error/utility-error-200':
    PRIMITIVES['Colors/Error/200'],
  'Component colors/Utility/Error/utility-error-300':
    PRIMITIVES['Colors/Error/300'],
  'Component colors/Utility/Error/utility-error-400':
    PRIMITIVES['Colors/Error/400'],
  'Component colors/Utility/Error/utility-error-500':
    PRIMITIVES['Colors/Error/500'],
  'Component colors/Utility/Error/utility-error-600':
    PRIMITIVES['Colors/Error/600'],
  'Component colors/Utility/Error/utility-error-700':
    PRIMITIVES['Colors/Error/700'],
  'Component colors/Utility/Warning/utility-warning-50':
    PRIMITIVES['Colors/Warning/50'],
  'Component colors/Utility/Warning/utility-warning-100':
    PRIMITIVES['Colors/Warning/100'],
  'Component colors/Utility/Warning/utility-warning-200':
    PRIMITIVES['Colors/Warning/200'],
  'Component colors/Utility/Warning/utility-warning-300':
    PRIMITIVES['Colors/Warning/300'],
  'Component colors/Utility/Warning/utility-warning-400':
    PRIMITIVES['Colors/Warning/400'],
  'Component colors/Utility/Warning/utility-warning-500':
    PRIMITIVES['Colors/Warning/500'],
  'Component colors/Utility/Warning/utility-warning-600':
    PRIMITIVES['Colors/Warning/600'],
  'Component colors/Utility/Warning/utility-warning-700':
    PRIMITIVES['Colors/Warning/700'],
  'Component colors/Utility/Success/utility-success-50':
    PRIMITIVES['Colors/Success/50'],
  'Component colors/Utility/Success/utility-success-100':
    PRIMITIVES['Colors/Success/100'],
  'Component colors/Utility/Success/utility-success-200':
    PRIMITIVES['Colors/Success/200'],
  'Component colors/Utility/Success/utility-success-300':
    PRIMITIVES['Colors/Success/300'],
  'Component colors/Utility/Success/utility-success-400':
    PRIMITIVES['Colors/Success/400'],
  'Component colors/Utility/Success/utility-success-500':
    PRIMITIVES['Colors/Success/500'],
  'Component colors/Utility/Success/utility-success-600':
    PRIMITIVES['Colors/Success/600'],
  'Component colors/Utility/Success/utility-success-700':
    PRIMITIVES['Colors/Success/700'],
  'Component colors/Utility/Gray blue/utility-gray-blue-50':
    PRIMITIVES['Colors/Gray blue/50'],
  'Component colors/Utility/Gray blue/utility-gray-blue-100':
    PRIMITIVES['Colors/Gray blue/100'],
  'Component colors/Utility/Gray blue/utility-gray-blue-200':
    PRIMITIVES['Colors/Gray blue/200'],
  'Component colors/Utility/Gray blue/utility-gray-blue-300':
    PRIMITIVES['Colors/Gray blue/300'],
  'Component colors/Utility/Gray blue/utility-gray-blue-400':
    PRIMITIVES['Colors/Gray blue/400'],
  'Component colors/Utility/Gray blue/utility-gray-blue-500':
    PRIMITIVES['Colors/Gray blue/500'],
  'Component colors/Utility/Gray blue/utility-gray-blue-600':
    PRIMITIVES['Colors/Gray blue/600'],
  'Component colors/Utility/Gray blue/utility-gray-blue-700':
    PRIMITIVES['Colors/Gray blue/700'],
  'Component colors/Utility/Green/utility-green-50':
    PRIMITIVES['Colors/Green/50'],
  'Component colors/Utility/Green/utility-green-100':
    PRIMITIVES['Colors/Green/100'],
  'Component colors/Utility/Green/utility-green-200':
    PRIMITIVES['Colors/Green/200'],
  'Component colors/Utility/Green/utility-green-300':
    PRIMITIVES['Colors/Green/300'],
  'Component colors/Utility/Green/utility-green-400':
    PRIMITIVES['Colors/Green/400'],
  'Component colors/Utility/Green/utility-green-500':
    PRIMITIVES['Colors/Green/500'],
  'Component colors/Utility/Green/utility-green-600':
    PRIMITIVES['Colors/Green/600'],
  'Component colors/Utility/Green/utility-green-700':
    PRIMITIVES['Colors/Green/700'],
  'Component colors/Utility/Blue light/utility-blue-light-50':
    PRIMITIVES['Colors/Blue light/50'],
  'Component colors/Utility/Blue light/utility-blue-light-100':
    PRIMITIVES['Colors/Blue light/100'],
  'Component colors/Utility/Blue light/utility-blue-light-200':
    PRIMITIVES['Colors/Blue light/200'],
  'Component colors/Utility/Blue light/utility-blue-light-300':
    PRIMITIVES['Colors/Blue light/300'],
  'Component colors/Utility/Blue light/utility-blue-light-400':
    PRIMITIVES['Colors/Blue light/400'],
  'Component colors/Utility/Blue light/utility-blue-light-500':
    PRIMITIVES['Colors/Blue light/500'],
  'Component colors/Utility/Blue light/utility-blue-light-600':
    PRIMITIVES['Colors/Blue light/600'],
  'Component colors/Utility/Blue light/utility-blue-light-700':
    PRIMITIVES['Colors/Blue light/700'],
  'Component colors/Utility/Blue/utility-blue-50': PRIMITIVES['Colors/Blue/50'],
  'Component colors/Utility/Blue/utility-blue-100':
    PRIMITIVES['Colors/Blue/100'],
  'Component colors/Utility/Blue/utility-blue-200':
    PRIMITIVES['Colors/Blue/200'],
  'Component colors/Utility/Blue/utility-blue-300':
    PRIMITIVES['Colors/Blue/300'],
  'Component colors/Utility/Blue/utility-blue-400':
    PRIMITIVES['Colors/Blue/400'],
  'Component colors/Utility/Blue/utility-blue-500':
    PRIMITIVES['Colors/Blue/500'],
  'Component colors/Utility/Blue/utility-blue-600':
    PRIMITIVES['Colors/Blue/600'],
  'Component colors/Utility/Blue/utility-blue-700':
    PRIMITIVES['Colors/Blue/700'],
  'Component colors/Utility/Blue dark/utility-blue-dark-50':
    PRIMITIVES['Colors/Blue dark/50'],
  'Component colors/Utility/Blue dark/utility-blue-dark-100':
    PRIMITIVES['Colors/Blue dark/100'],
  'Component colors/Utility/Blue dark/utility-blue-dark-200':
    PRIMITIVES['Colors/Blue dark/200'],
  'Component colors/Utility/Blue dark/utility-blue-dark-300':
    PRIMITIVES['Colors/Blue dark/300'],
  'Component colors/Utility/Blue dark/utility-blue-dark-400':
    PRIMITIVES['Colors/Blue dark/400'],
  'Component colors/Utility/Blue dark/utility-blue-dark-500':
    PRIMITIVES['Colors/Blue dark/500'],
  'Component colors/Utility/Blue dark/utility-blue-dark-600':
    PRIMITIVES['Colors/Blue dark/600'],
  'Component colors/Utility/Blue dark/utility-blue-dark-700':
    PRIMITIVES['Colors/Blue dark/700'],
  'Component colors/Utility/Indigo/utility-indigo-50':
    PRIMITIVES['Colors/Indigo/50'],
  'Component colors/Utility/Indigo/utility-indigo-100':
    PRIMITIVES['Colors/Indigo/100'],
  'Component colors/Utility/Indigo/utility-indigo-200':
    PRIMITIVES['Colors/Indigo/200'],
  'Component colors/Utility/Indigo/utility-indigo-300':
    PRIMITIVES['Colors/Indigo/300'],
  'Component colors/Utility/Indigo/utility-indigo-400':
    PRIMITIVES['Colors/Indigo/400'],
  'Component colors/Utility/Indigo/utility-indigo-500':
    PRIMITIVES['Colors/Indigo/500'],
  'Component colors/Utility/Indigo/utility-indigo-600':
    PRIMITIVES['Colors/Indigo/600'],
  'Component colors/Utility/Indigo/utility-indigo-700':
    PRIMITIVES['Colors/Indigo/700'],
  'Component colors/Utility/Purple/utility-purple-50':
    PRIMITIVES['Colors/Purple/50'],
  'Component colors/Utility/Purple/utility-purple-100':
    PRIMITIVES['Colors/Purple/100'],
  'Component colors/Utility/Purple/utility-purple-200':
    PRIMITIVES['Colors/Purple/200'],
  'Component colors/Utility/Purple/utility-purple-300':
    PRIMITIVES['Colors/Purple/300'],
  'Component colors/Utility/Purple/utility-purple-400':
    PRIMITIVES['Colors/Purple/400'],
  'Component colors/Utility/Purple/utility-purple-500':
    PRIMITIVES['Colors/Purple/500'],
  'Component colors/Utility/Purple/utility-purple-600':
    PRIMITIVES['Colors/Purple/600'],
  'Component colors/Utility/Purple/utility-purple-700':
    PRIMITIVES['Colors/Purple/700'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-50':
    PRIMITIVES['Colors/Fuchsia/50'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-100':
    PRIMITIVES['Colors/Fuchsia/100'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-200':
    PRIMITIVES['Colors/Fuchsia/200'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-300':
    PRIMITIVES['Colors/Fuchsia/300'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-400':
    PRIMITIVES['Colors/Fuchsia/400'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-500':
    PRIMITIVES['Colors/Fuchsia/500'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-600':
    PRIMITIVES['Colors/Fuchsia/600'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-700':
    PRIMITIVES['Colors/Fuchsia/700'],
  'Component colors/Utility/Pink/utility-pink-50': PRIMITIVES['Colors/Pink/50'],
  'Component colors/Utility/Pink/utility-pink-100':
    PRIMITIVES['Colors/Pink/100'],
  'Component colors/Utility/Pink/utility-pink-200':
    PRIMITIVES['Colors/Pink/200'],
  'Component colors/Utility/Pink/utility-pink-300':
    PRIMITIVES['Colors/Pink/300'],
  'Component colors/Utility/Pink/utility-pink-400':
    PRIMITIVES['Colors/Pink/400'],
  'Component colors/Utility/Pink/utility-pink-500':
    PRIMITIVES['Colors/Pink/500'],
  'Component colors/Utility/Pink/utility-pink-600':
    PRIMITIVES['Colors/Pink/600'],
  'Component colors/Utility/Pink/utility-pink-700':
    PRIMITIVES['Colors/Pink/700'],
  'Component colors/Utility/Orange dark/utility-orange-dark-50':
    PRIMITIVES['Colors/Orange dark/50'],
  'Component colors/Utility/Orange dark/utility-orange-dark-100':
    PRIMITIVES['Colors/Orange dark/100'],
  'Component colors/Utility/Orange dark/utility-orange-dark-200':
    PRIMITIVES['Colors/Orange dark/200'],
  'Component colors/Utility/Orange dark/utility-orange-dark-300':
    PRIMITIVES['Colors/Orange dark/300'],
  'Component colors/Utility/Orange dark/utility-orange-dark-400':
    PRIMITIVES['Colors/Orange dark/400'],
  'Component colors/Utility/Orange dark/utility-orange-dark-500':
    PRIMITIVES['Colors/Orange dark/500'],
  'Component colors/Utility/Orange dark/utility-orange-dark-600':
    PRIMITIVES['Colors/Orange dark/600'],
  'Component colors/Utility/Orange dark/utility-orange-dark-700':
    PRIMITIVES['Colors/Orange dark/700'],
  'Component colors/Utility/Orange/utility-orange-50':
    PRIMITIVES['Colors/Orange/50'],
  'Component colors/Utility/Orange/utility-orange-100':
    PRIMITIVES['Colors/Orange/100'],
  'Component colors/Utility/Orange/utility-orange-200':
    PRIMITIVES['Colors/Orange/200'],
  'Component colors/Utility/Orange/utility-orange-300':
    PRIMITIVES['Colors/Orange/300'],
  'Component colors/Utility/Orange/utility-orange-400':
    PRIMITIVES['Colors/Orange/400'],
  'Component colors/Utility/Orange/utility-orange-500':
    PRIMITIVES['Colors/Orange/500'],
  'Component colors/Utility/Orange/utility-orange-600':
    PRIMITIVES['Colors/Orange/600'],
  'Component colors/Utility/Orange/utility-orange-700':
    PRIMITIVES['Colors/Orange/700'],
  'Component colors/Utility/Yellow/utility-yellow-50':
    PRIMITIVES['Colors/Yellow/50'],
  'Component colors/Utility/Yellow/utility-yellow-100':
    PRIMITIVES['Colors/Yellow/100'],
  'Component colors/Utility/Yellow/utility-yellow-200':
    PRIMITIVES['Colors/Yellow/200'],
  'Component colors/Utility/Yellow/utility-yellow-300':
    PRIMITIVES['Colors/Yellow/300'],
  'Component colors/Utility/Yellow/utility-yellow-400':
    PRIMITIVES['Colors/Yellow/400'],
  'Component colors/Utility/Yellow/utility-yellow-500':
    PRIMITIVES['Colors/Yellow/500'],
  'Component colors/Utility/Yellow/utility-yellow-600':
    PRIMITIVES['Colors/Yellow/600'],
  'Component colors/Utility/Yellow/utility-yellow-700':
    PRIMITIVES['Colors/Yellow/700'],
  'Component colors/Components/App store badges/app-store-badge-border':
    '#a6a6a6',
  'Component colors/Components/Application navigation/nav-item-button-icon-fg':
    PRIMITIVES['Colors/Gray (light mode)/500'],
  'Component colors/Components/Application navigation/nav-item-button-icon-fg_active':
    PRIMITIVES['Colors/Gray (light mode)/700'],
  'Component colors/Components/Application navigation/nav-item-icon-fg':
    PRIMITIVES['Colors/Gray (light mode)/500'],
  'Component colors/Components/Application navigation/nav-item-icon-fg_active':
    PRIMITIVES['Colors/Gray (light mode)/500'],
  'Component colors/Components/Avatars/avatar-bg':
    PRIMITIVES['Colors/Gray (light mode)/100'],
  'Component colors/Components/Avatars/avatar-profile-photo-border':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Avatars/avatar-contrast-border':
    'rgba(0, 0, 0, 8)',
  'Component colors/Components/Avatars/avatar-styles-bg-neutral': '#e0e0e0',
  'Component colors/Components/Breadcrumbs/breadcrumb-fg':
    PRIMITIVES['Colors/Gray (light mode)/600'],
  'Component colors/Components/Breadcrumbs/breadcrumb-fg_hover':
    PRIMITIVES['Colors/Gray (light mode)/700'],
  'Component colors/Components/Breadcrumbs/breadcrumb-bg_hover':
    PRIMITIVES['Colors/Gray (light mode)/50'],
  'Component colors/Components/Breadcrumbs/breadcrumb-brand-fg_hover':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Components/Breadcrumbs/breadcrumb-brand-bg_hover':
    PRIMITIVES['Colors/Brand/50'],
  'Component colors/Components/Breadcrumbs/breadcrumb-icon-fg':
    PRIMITIVES['Colors/Gray (light mode)/500'],
  'Component colors/Components/Breadcrumbs/breadcrumb-icon-fg_hover':
    PRIMITIVES['Colors/Gray (light mode)/700'],
  'Component colors/Components/Breadcrumbs/breadcrumb-brand-icon-fg_hover':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Components/Buttons/Primary/button-primary-fg':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Primary/button-primary-fg_hover':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Primary/button-primary-bg':
    PRIMITIVES['Colors/Brand/600'],
  'Component colors/Components/Buttons/Primary/button-primary-bg_hover':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Components/Buttons/Secondary/button-secondary-fg':
    PRIMITIVES['Colors/Gray (light mode)/700'],
  'Component colors/Components/Buttons/Secondary/button-secondary-fg_hover':
    PRIMITIVES['Colors/Gray (light mode)/800'],
  'Component colors/Components/Buttons/Secondary/button-secondary-bg':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Secondary/button-secondary-bg_hover':
    PRIMITIVES['Colors/Gray (light mode)/50'],
  'Component colors/Components/Buttons/Secondary/button-secondary-border':
    PRIMITIVES['Colors/Gray (light mode)/300'],
  'Component colors/Components/Buttons/Secondary/button-secondary-border_hover':
    PRIMITIVES['Colors/Gray (light mode)/300'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-fg':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-fg_hover':
    PRIMITIVES['Colors/Brand/800'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-bg':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-bg_hover':
    PRIMITIVES['Colors/Brand/50'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-border':
    PRIMITIVES['Colors/Brand/300'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-border_hover':
    PRIMITIVES['Colors/Brand/300'],
  'Component colors/Components/Buttons/Tertiary/button-tertiary-fg':
    PRIMITIVES['Colors/Gray (light mode)/600'],
  'Component colors/Components/Buttons/Tertiary/button-tertiary-fg_hover':
    PRIMITIVES['Colors/Gray (light mode)/700'],
  'Component colors/Components/Buttons/Tertiary/button-tertiary-bg_hover':
    PRIMITIVES['Colors/Gray (light mode)/50'],
  'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-fg':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-fg_hover':
    PRIMITIVES['Colors/Brand/800'],
  'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-bg_hover':
    PRIMITIVES['Colors/Brand/50'],
  'Component colors/Components/Buttons/Primary error/button-primary-error-fg':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Primary error/button-primary-error-fg_hover':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Primary error/button-primary-error-bg':
    PRIMITIVES['Colors/Error/600'],
  'Component colors/Components/Buttons/Primary error/button-primary-error-bg_hover':
    PRIMITIVES['Colors/Error/700'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-fg':
    PRIMITIVES['Colors/Error/700'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-fg_hover':
    PRIMITIVES['Colors/Error/800'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-bg':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-bg_hover':
    PRIMITIVES['Colors/Error/50'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-border':
    PRIMITIVES['Colors/Error/300'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-border_hover':
    PRIMITIVES['Colors/Error/300'],
  'Component colors/Components/Buttons/Tertiary error/button-tertiary-error-fg':
    PRIMITIVES['Colors/Error/700'],
  'Component colors/Components/Buttons/Tertiary error/button-tertiary-error-fg_hover':
    PRIMITIVES['Colors/Error/800'],
  'Component colors/Components/Buttons/Tertiary error/button-tertiary-error-bg_hover':
    PRIMITIVES['Colors/Error/50'],
  'Component colors/Components/Footers/footer-button-fg':
    PRIMITIVES['Colors/Brand/200'],
  'Component colors/Components/Footers/footer-button-fg_hover':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Footers/footer-badge-fg':
    PRIMITIVES['Colors/Success/700'],
  'Component colors/Components/Footers/footer-badge-bg':
    PRIMITIVES['Colors/Success/50'],
  'Component colors/Components/Footers/footer-badge-border':
    PRIMITIVES['Colors/Success/200'],
  'Component colors/Components/Header sections/header-abstract-50-bg':
    PRIMITIVES['Colors/Brand/50'],
  'Component colors/Components/Header sections/header-abstract-100-bg':
    PRIMITIVES['Colors/Brand/100'],
  'Component colors/Components/Header sections/header-abstract-200-bg':
    PRIMITIVES['Colors/Brand/200'],
  'Component colors/Components/Header sections/header-abstract-300-bg':
    PRIMITIVES['Colors/Brand/300'],
  'Component colors/Components/Icons/Icons/icon-fg-brand':
    PRIMITIVES['Colors/Brand/600'],
  'Component colors/Components/Icons/Icons/icon-fg-brand_on-brand':
    PRIMITIVES['Colors/Brand/200'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-brand':
    PRIMITIVES['Colors/Brand/600'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-gray':
    PRIMITIVES['Colors/Gray (light mode)/500'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-error':
    PRIMITIVES['Colors/Error/600'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-warning':
    PRIMITIVES['Colors/Warning/600'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-success':
    PRIMITIVES['Colors/Success/600'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-brand':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-gray':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-error':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-warning':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-success':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Featured icons/Modern/featured-icon-modern-border':
    PRIMITIVES['Colors/Gray (light mode)/200'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-x': '#242e36',
  'Component colors/Components/Icons/Social icons/social-icon-fg-instagram':
    '#000100',
  'Component colors/Components/Icons/Social icons/social-icon-fg-apple':
    PRIMITIVES['Colors/Base/black'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-github':
    PRIMITIVES['Colors/Base/black'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-angellist':
    PRIMITIVES['Colors/Base/black'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-tumblr':
    '#001935',
  'Component colors/Components/Mockups/screen-mockup-border':
    PRIMITIVES['Colors/Gray (light mode)/900'],
  'Component colors/Components/Sliders/slider-handle-bg':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Sliders/slider-handle-border':
    PRIMITIVES['Colors/Brand/600'],
  'Component colors/Components/Thumbnail/thumbnail-badge-brand-fg':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Components/Thumbnail/thumbnail-badge-success-fg':
    PRIMITIVES['Colors/Success/700'],
  'Component colors/Components/Toggles/toggle-button-fg_disabled':
    PRIMITIVES['Colors/Gray (light mode)/50'],
  'Component colors/Components/Toggles/toggle-border':
    PRIMITIVES['Colors/Gray (light mode)/300'],
  'Component colors/Components/Toggles/toggle-slim-border_pressed':
    PRIMITIVES['Colors/Brand/600'],
  'Component colors/Components/Toggles/toggle-slim-border_pressed-hover':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Components/Tooltips/tooltip-supporting-text':
    PRIMITIVES['Colors/Gray (light mode)/300'],
  'Component colors/Components/WYSIWYG editor/wysiwyg-editor-icon-fg':
    PRIMITIVES['Colors/Gray (light mode)/400'],
  'Component colors/Components/WYSIWYG editor/wysiwyg-editor-icon-fg_active':
    PRIMITIVES['Colors/Gray (light mode)/500'],
};

export const DARK_COLORS = {
  'Colors/Text/text-primary (900)': PRIMITIVES['Colors/Gray (dark mode)/50'],
  'Colors/Text/text-primary_on-brand': PRIMITIVES['Colors/Gray (dark mode)/50'],
  'Colors/Text/text-secondary (700)': PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Colors/Text/text-secondary_hover': PRIMITIVES['Colors/Gray (dark mode)/200'],
  'Colors/Text/text-secondary_on-brand':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Colors/Text/text-tertiary (600)': PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Colors/Text/text-tertiary_hover': PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Colors/Text/text-tertiary_on-brand':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Colors/Text/text-quaternary (500)':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Colors/Text/text-quaternary_on-brand':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Colors/Text/text-white': PRIMITIVES['Colors/Base/white'],
  'Colors/Text/text-disabled': PRIMITIVES['Colors/Gray (dark mode)/500'],
  'Colors/Text/text-placeholder': PRIMITIVES['Colors/Gray (dark mode)/500'],
  'Colors/Text/text-placeholder_subtle':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Colors/Text/text-brand-primary (900)':
    PRIMITIVES['Colors/Gray (dark mode)/50'],
  'Colors/Text/text-brand-secondary (700)':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Colors/Text/text-brand-tertiary (600)':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Colors/Text/text-brand-tertiary_alt':
    PRIMITIVES['Colors/Gray (dark mode)/50'],
  'Colors/Text/text-error-primary (600)': PRIMITIVES['Colors/Error/400'],
  'Colors/Text/text-warning-primary (600)': PRIMITIVES['Colors/Warning/400'],
  'Colors/Text/text-success-primary (600)': PRIMITIVES['Colors/Success/400'],
  'Colors/Border/border-primary': PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Colors/Border/border-secondary': PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Colors/Border/border-tertiary': PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Colors/Border/border-disabled': PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Colors/Border/border-disabled_subtle':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Colors/Border/border-brand': PRIMITIVES['Colors/Brand/400'],
  'Colors/Border/border-brand_alt': PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Colors/Border/border-error': PRIMITIVES['Colors/Error/400'],
  'Colors/Border/border-error_subtle': PRIMITIVES['Colors/Error/400'],
  'Colors/Foreground/fg-primary (900)': PRIMITIVES['Colors/Base/white'],
  'Colors/Foreground/fg-secondary (700)':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Colors/Foreground/fg-secondary_hover':
    PRIMITIVES['Colors/Gray (dark mode)/200'],
  'Colors/Foreground/fg-tertiary (600)':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Colors/Foreground/fg-tertiary_hover':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Colors/Foreground/fg-quaternary (500)':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Colors/Foreground/fg-quaternary_hover':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Colors/Foreground/fg-quinary (400)':
    PRIMITIVES['Colors/Gray (dark mode)/500'],
  'Colors/Foreground/fg-quinary_hover':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Colors/Foreground/fg-senary (300)':
    PRIMITIVES['Colors/Gray (dark mode)/600'],
  'Colors/Foreground/fg-white': PRIMITIVES['Colors/Base/white'],
  'Colors/Foreground/fg-disabled': PRIMITIVES['Colors/Gray (dark mode)/500'],
  'Colors/Foreground/fg-disabled_subtle':
    PRIMITIVES['Colors/Gray (dark mode)/600'],
  'Colors/Foreground/fg-brand-primary (600)': PRIMITIVES['Colors/Brand/500'],
  'Colors/Foreground/fg-brand-primary_alt':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Colors/Foreground/fg-brand-secondary (500)': PRIMITIVES['Colors/Brand/500'],
  'Colors/Foreground/fg-error-primary': PRIMITIVES['Colors/Error/500'],
  'Colors/Foreground/fg-error-secondary': PRIMITIVES['Colors/Error/400'],
  'Colors/Foreground/fg-warning-primary': PRIMITIVES['Colors/Warning/500'],
  'Colors/Foreground/fg-warning-secondary': PRIMITIVES['Colors/Warning/400'],
  'Colors/Foreground/fg-success-primary': PRIMITIVES['Colors/Success/500'],
  'Colors/Foreground/fg-success-secondary': PRIMITIVES['Colors/Success/400'],
  'Colors/Background/bg-primary': PRIMITIVES['Colors/Gray (dark mode)/950'],
  'Colors/Background/bg-primary_alt': PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Colors/Background/bg-primary_hover':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Colors/Background/bg-primary-solid':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Colors/Background/bg-secondary': PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Colors/Background/bg-secondary_alt':
    PRIMITIVES['Colors/Gray (dark mode)/950'],
  'Colors/Background/bg-secondary_hover':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Colors/Background/bg-secondary_subtle':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Colors/Background/bg-secondary-solid':
    PRIMITIVES['Colors/Gray (dark mode)/600'],
  'Colors/Background/bg-tertiary': PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Colors/Background/bg-quaternary': PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Colors/Background/bg-active': PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Colors/Background/bg-disabled': PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Colors/Background/bg-disabled_subtle':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Colors/Background/bg-overlay': PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Colors/Background/bg-brand-primary': PRIMITIVES['Colors/Brand/500'],
  'Colors/Background/bg-brand-primary_alt':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Colors/Background/bg-brand-secondary': PRIMITIVES['Colors/Brand/600'],
  'Colors/Background/bg-brand-solid': PRIMITIVES['Colors/Brand/600'],
  'Colors/Background/bg-brand-solid_hover': PRIMITIVES['Colors/Brand/500'],
  'Colors/Background/bg-brand-section':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Colors/Background/bg-brand-section_subtle':
    PRIMITIVES['Colors/Gray (dark mode)/950'],
  'Colors/Background/bg-error-primary': PRIMITIVES['Colors/Error/500'],
  'Colors/Background/bg-error-secondary': PRIMITIVES['Colors/Error/600'],
  'Colors/Background/bg-error-solid': PRIMITIVES['Colors/Error/600'],
  'Colors/Background/bg-warning-primary': PRIMITIVES['Colors/Warning/500'],
  'Colors/Background/bg-warning-secondary': PRIMITIVES['Colors/Warning/600'],
  'Colors/Background/bg-warning-solid': PRIMITIVES['Colors/Warning/600'],
  'Colors/Background/bg-success-primary': PRIMITIVES['Colors/Success/500'],
  'Colors/Background/bg-success-secondary': PRIMITIVES['Colors/Success/600'],
  'Colors/Background/bg-success-solid': PRIMITIVES['Colors/Success/600'],
  'Colors/Effects/Focus rings/focus-ring': PRIMITIVES['Colors/Brand/500'],
  'Colors/Effects/Focus rings/focus-ring-error': PRIMITIVES['Colors/Error/500'],
  'Colors/Effects/Shadows/shadow-xs': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-sm_01': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-sm_02': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-md_01': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-md_02': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-lg_01': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-lg_02': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-lg_03': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-xl_01': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-xl_02': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-xl_03': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-2xl_01': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-2xl_02': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-3xl_01': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-3xl_02': PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Shadows/shadow-skeumorphic-inner': 'rgba(12, 14, 18, 5)',
  'Colors/Effects/Shadows/shadow-skeumorphic-inner-border':
    'rgba(12, 14, 18, 18)',
  'Colors/Effects/Portfolio mockups/shadow-main-centre-md':
    PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Portfolio mockups/shadow-main-centre-lg':
    PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Portfolio mockups/shadow-overlay-lg':
    PRIMITIVES['Colors/Base/transparent'],
  'Colors/Effects/Portfolio mockups/shadow-grid-md':
    PRIMITIVES['Colors/Base/transparent'],
  'Component colors/Alpha/alpha-white-10': 'rgba(12, 14, 18, 10)',
  'Component colors/Alpha/alpha-white-20': 'rgba(12, 14, 18, 20)',
  'Component colors/Alpha/alpha-white-30': 'rgba(12, 14, 18, 30)',
  'Component colors/Alpha/alpha-white-40': 'rgba(12, 14, 18, 40)',
  'Component colors/Alpha/alpha-white-50': 'rgba(12, 14, 18, 50)',
  'Component colors/Alpha/alpha-white-60': 'rgba(12, 14, 18, 60)',
  'Component colors/Alpha/alpha-white-70': 'rgba(12, 14, 18, 70)',
  'Component colors/Alpha/alpha-white-80': 'rgba(12, 14, 18, 80)',
  'Component colors/Alpha/alpha-white-90': 'rgba(12, 14, 18, 90)',
  'Component colors/Alpha/alpha-white-100':
    PRIMITIVES['Colors/Gray (dark mode)/950'],
  'Component colors/Alpha/alpha-black-10': 'rgba(255, 255, 255, 10)',
  'Component colors/Alpha/alpha-black-20': 'rgba(255, 255, 255, 20)',
  'Component colors/Alpha/alpha-black-30': 'rgba(255, 255, 255, 30)',
  'Component colors/Alpha/alpha-black-40': 'rgba(255, 255, 255, 40)',
  'Component colors/Alpha/alpha-black-50': 'rgba(255, 255, 255, 50)',
  'Component colors/Alpha/alpha-black-60': 'rgba(255, 255, 255, 60)',
  'Component colors/Alpha/alpha-black-70': 'rgba(255, 255, 255, 70)',
  'Component colors/Alpha/alpha-black-80': 'rgba(255, 255, 255, 80)',
  'Component colors/Alpha/alpha-black-90': 'rgba(255, 255, 255, 90)',
  'Component colors/Alpha/alpha-black-100': '#ffffff',
  'Component colors/Utility/Gray/utility-gray-50':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Component colors/Utility/Gray/utility-gray-100':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Utility/Gray/utility-gray-200':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Utility/Gray/utility-gray-300':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Utility/Gray/utility-gray-400':
    PRIMITIVES['Colors/Gray (dark mode)/600'],
  'Component colors/Utility/Gray/utility-gray-500':
    PRIMITIVES['Colors/Gray (dark mode)/500'],
  'Component colors/Utility/Gray/utility-gray-600':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Component colors/Utility/Gray/utility-gray-700':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Utility/Gray/utility-gray-800':
    PRIMITIVES['Colors/Gray (dark mode)/200'],
  'Component colors/Utility/Gray/utility-gray-900':
    PRIMITIVES['Colors/Gray (dark mode)/100'],
  'Component colors/Utility/Brand/utility-brand-50':
    PRIMITIVES['Colors/Brand/950'],
  'Component colors/Utility/Brand/utility-brand-50_alt':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Component colors/Utility/Brand/utility-brand-100':
    PRIMITIVES['Colors/Brand/900'],
  'Component colors/Utility/Brand/utility-brand-100_alt':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Utility/Brand/utility-brand-200':
    PRIMITIVES['Colors/Brand/800'],
  'Component colors/Utility/Brand/utility-brand-200_alt':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Utility/Brand/utility-brand-300':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Utility/Brand/utility-brand-300_alt':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Utility/Brand/utility-brand-400':
    PRIMITIVES['Colors/Brand/600'],
  'Component colors/Utility/Brand/utility-brand-400_alt':
    PRIMITIVES['Colors/Gray (dark mode)/600'],
  'Component colors/Utility/Brand/utility-brand-500':
    PRIMITIVES['Colors/Brand/500'],
  'Component colors/Utility/Brand/utility-brand-500_alt':
    PRIMITIVES['Colors/Gray (dark mode)/500'],
  'Component colors/Utility/Brand/utility-brand-600':
    PRIMITIVES['Colors/Brand/400'],
  'Component colors/Utility/Brand/utility-brand-600_alt':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Component colors/Utility/Brand/utility-brand-700':
    PRIMITIVES['Colors/Brand/300'],
  'Component colors/Utility/Brand/utility-brand-700_alt':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Utility/Brand/utility-brand-800':
    PRIMITIVES['Colors/Brand/200'],
  'Component colors/Utility/Brand/utility-brand-800_alt':
    PRIMITIVES['Colors/Gray (dark mode)/200'],
  'Component colors/Utility/Brand/utility-brand-900':
    PRIMITIVES['Colors/Brand/100'],
  'Component colors/Utility/Brand/utility-brand-900_alt':
    PRIMITIVES['Colors/Gray (dark mode)/100'],
  'Component colors/Utility/Error/utility-error-50':
    PRIMITIVES['Colors/Error/950'],
  'Component colors/Utility/Error/utility-error-100':
    PRIMITIVES['Colors/Error/900'],
  'Component colors/Utility/Error/utility-error-200':
    PRIMITIVES['Colors/Error/800'],
  'Component colors/Utility/Error/utility-error-300':
    PRIMITIVES['Colors/Error/700'],
  'Component colors/Utility/Error/utility-error-400':
    PRIMITIVES['Colors/Error/600'],
  'Component colors/Utility/Error/utility-error-500':
    PRIMITIVES['Colors/Error/500'],
  'Component colors/Utility/Error/utility-error-600':
    PRIMITIVES['Colors/Error/400'],
  'Component colors/Utility/Error/utility-error-700':
    PRIMITIVES['Colors/Error/300'],
  'Component colors/Utility/Warning/utility-warning-50':
    PRIMITIVES['Colors/Warning/950'],
  'Component colors/Utility/Warning/utility-warning-100':
    PRIMITIVES['Colors/Warning/900'],
  'Component colors/Utility/Warning/utility-warning-200':
    PRIMITIVES['Colors/Warning/800'],
  'Component colors/Utility/Warning/utility-warning-300':
    PRIMITIVES['Colors/Warning/700'],
  'Component colors/Utility/Warning/utility-warning-400':
    PRIMITIVES['Colors/Warning/600'],
  'Component colors/Utility/Warning/utility-warning-500':
    PRIMITIVES['Colors/Warning/500'],
  'Component colors/Utility/Warning/utility-warning-600':
    PRIMITIVES['Colors/Warning/400'],
  'Component colors/Utility/Warning/utility-warning-700':
    PRIMITIVES['Colors/Warning/300'],
  'Component colors/Utility/Success/utility-success-50':
    PRIMITIVES['Colors/Success/950'],
  'Component colors/Utility/Success/utility-success-100':
    PRIMITIVES['Colors/Success/900'],
  'Component colors/Utility/Success/utility-success-200':
    PRIMITIVES['Colors/Success/800'],
  'Component colors/Utility/Success/utility-success-300':
    PRIMITIVES['Colors/Success/700'],
  'Component colors/Utility/Success/utility-success-400':
    PRIMITIVES['Colors/Success/600'],
  'Component colors/Utility/Success/utility-success-500':
    PRIMITIVES['Colors/Success/500'],
  'Component colors/Utility/Success/utility-success-600':
    PRIMITIVES['Colors/Success/400'],
  'Component colors/Utility/Success/utility-success-700':
    PRIMITIVES['Colors/Success/300'],
  'Component colors/Utility/Gray blue/utility-gray-blue-50':
    PRIMITIVES['Colors/Gray blue/950'],
  'Component colors/Utility/Gray blue/utility-gray-blue-100':
    PRIMITIVES['Colors/Gray blue/900'],
  'Component colors/Utility/Gray blue/utility-gray-blue-200':
    PRIMITIVES['Colors/Gray blue/800'],
  'Component colors/Utility/Gray blue/utility-gray-blue-300':
    PRIMITIVES['Colors/Gray blue/700'],
  'Component colors/Utility/Gray blue/utility-gray-blue-400':
    PRIMITIVES['Colors/Gray blue/600'],
  'Component colors/Utility/Gray blue/utility-gray-blue-500':
    PRIMITIVES['Colors/Gray blue/500'],
  'Component colors/Utility/Gray blue/utility-gray-blue-600':
    PRIMITIVES['Colors/Gray blue/400'],
  'Component colors/Utility/Gray blue/utility-gray-blue-700':
    PRIMITIVES['Colors/Gray blue/300'],
  'Component colors/Utility/Green/utility-green-50':
    PRIMITIVES['Colors/Green/950'],
  'Component colors/Utility/Green/utility-green-100':
    PRIMITIVES['Colors/Green/900'],
  'Component colors/Utility/Green/utility-green-200':
    PRIMITIVES['Colors/Green/800'],
  'Component colors/Utility/Green/utility-green-300':
    PRIMITIVES['Colors/Green/700'],
  'Component colors/Utility/Green/utility-green-400':
    PRIMITIVES['Colors/Green/600'],
  'Component colors/Utility/Green/utility-green-500':
    PRIMITIVES['Colors/Green/500'],
  'Component colors/Utility/Green/utility-green-600':
    PRIMITIVES['Colors/Green/400'],
  'Component colors/Utility/Green/utility-green-700':
    PRIMITIVES['Colors/Green/300'],
  'Component colors/Utility/Blue light/utility-blue-light-50':
    PRIMITIVES['Colors/Blue light/950'],
  'Component colors/Utility/Blue light/utility-blue-light-100':
    PRIMITIVES['Colors/Blue light/900'],
  'Component colors/Utility/Blue light/utility-blue-light-200':
    PRIMITIVES['Colors/Blue light/800'],
  'Component colors/Utility/Blue light/utility-blue-light-300':
    PRIMITIVES['Colors/Blue light/700'],
  'Component colors/Utility/Blue light/utility-blue-light-400':
    PRIMITIVES['Colors/Blue light/600'],
  'Component colors/Utility/Blue light/utility-blue-light-500':
    PRIMITIVES['Colors/Blue light/500'],
  'Component colors/Utility/Blue light/utility-blue-light-600':
    PRIMITIVES['Colors/Blue light/400'],
  'Component colors/Utility/Blue light/utility-blue-light-700':
    PRIMITIVES['Colors/Blue light/300'],
  'Component colors/Utility/Blue/utility-blue-50':
    PRIMITIVES['Colors/Blue/950'],
  'Component colors/Utility/Blue/utility-blue-100':
    PRIMITIVES['Colors/Blue/900'],
  'Component colors/Utility/Blue/utility-blue-200':
    PRIMITIVES['Colors/Blue/800'],
  'Component colors/Utility/Blue/utility-blue-300':
    PRIMITIVES['Colors/Blue/700'],
  'Component colors/Utility/Blue/utility-blue-400':
    PRIMITIVES['Colors/Blue/600'],
  'Component colors/Utility/Blue/utility-blue-500':
    PRIMITIVES['Colors/Blue/500'],
  'Component colors/Utility/Blue/utility-blue-600':
    PRIMITIVES['Colors/Blue/400'],
  'Component colors/Utility/Blue/utility-blue-700':
    PRIMITIVES['Colors/Blue/300'],
  'Component colors/Utility/Blue dark/utility-blue-dark-50':
    PRIMITIVES['Colors/Blue dark/950'],
  'Component colors/Utility/Blue dark/utility-blue-dark-100':
    PRIMITIVES['Colors/Blue dark/900'],
  'Component colors/Utility/Blue dark/utility-blue-dark-200':
    PRIMITIVES['Colors/Blue dark/800'],
  'Component colors/Utility/Blue dark/utility-blue-dark-300':
    PRIMITIVES['Colors/Blue dark/700'],
  'Component colors/Utility/Blue dark/utility-blue-dark-400':
    PRIMITIVES['Colors/Blue dark/600'],
  'Component colors/Utility/Blue dark/utility-blue-dark-500':
    PRIMITIVES['Colors/Blue dark/500'],
  'Component colors/Utility/Blue dark/utility-blue-dark-600':
    PRIMITIVES['Colors/Blue dark/400'],
  'Component colors/Utility/Blue dark/utility-blue-dark-700':
    PRIMITIVES['Colors/Blue dark/300'],
  'Component colors/Utility/Indigo/utility-indigo-50':
    PRIMITIVES['Colors/Indigo/950'],
  'Component colors/Utility/Indigo/utility-indigo-100':
    PRIMITIVES['Colors/Indigo/900'],
  'Component colors/Utility/Indigo/utility-indigo-200':
    PRIMITIVES['Colors/Indigo/800'],
  'Component colors/Utility/Indigo/utility-indigo-300':
    PRIMITIVES['Colors/Indigo/700'],
  'Component colors/Utility/Indigo/utility-indigo-400':
    PRIMITIVES['Colors/Indigo/600'],
  'Component colors/Utility/Indigo/utility-indigo-500':
    PRIMITIVES['Colors/Indigo/500'],
  'Component colors/Utility/Indigo/utility-indigo-600':
    PRIMITIVES['Colors/Indigo/400'],
  'Component colors/Utility/Indigo/utility-indigo-700':
    PRIMITIVES['Colors/Indigo/300'],
  'Component colors/Utility/Purple/utility-purple-50':
    PRIMITIVES['Colors/Purple/950'],
  'Component colors/Utility/Purple/utility-purple-100':
    PRIMITIVES['Colors/Purple/900'],
  'Component colors/Utility/Purple/utility-purple-200':
    PRIMITIVES['Colors/Purple/800'],
  'Component colors/Utility/Purple/utility-purple-300':
    PRIMITIVES['Colors/Purple/700'],
  'Component colors/Utility/Purple/utility-purple-400':
    PRIMITIVES['Colors/Purple/600'],
  'Component colors/Utility/Purple/utility-purple-500':
    PRIMITIVES['Colors/Purple/500'],
  'Component colors/Utility/Purple/utility-purple-600':
    PRIMITIVES['Colors/Purple/400'],
  'Component colors/Utility/Purple/utility-purple-700':
    PRIMITIVES['Colors/Purple/300'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-50':
    PRIMITIVES['Colors/Fuchsia/950'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-100':
    PRIMITIVES['Colors/Fuchsia/900'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-200':
    PRIMITIVES['Colors/Fuchsia/800'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-300':
    PRIMITIVES['Colors/Fuchsia/700'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-400':
    PRIMITIVES['Colors/Fuchsia/600'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-500':
    PRIMITIVES['Colors/Fuchsia/500'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-600':
    PRIMITIVES['Colors/Fuchsia/400'],
  'Component colors/Utility/Fuchsia/utility-fuchsia-700':
    PRIMITIVES['Colors/Fuchsia/300'],
  'Component colors/Utility/Pink/utility-pink-50':
    PRIMITIVES['Colors/Pink/950'],
  'Component colors/Utility/Pink/utility-pink-100':
    PRIMITIVES['Colors/Pink/900'],
  'Component colors/Utility/Pink/utility-pink-200':
    PRIMITIVES['Colors/Pink/800'],
  'Component colors/Utility/Pink/utility-pink-300':
    PRIMITIVES['Colors/Pink/700'],
  'Component colors/Utility/Pink/utility-pink-400':
    PRIMITIVES['Colors/Pink/600'],
  'Component colors/Utility/Pink/utility-pink-500':
    PRIMITIVES['Colors/Pink/500'],
  'Component colors/Utility/Pink/utility-pink-600':
    PRIMITIVES['Colors/Pink/400'],
  'Component colors/Utility/Pink/utility-pink-700':
    PRIMITIVES['Colors/Pink/300'],
  'Component colors/Utility/Orange dark/utility-orange-dark-50':
    PRIMITIVES['Colors/Orange dark/950'],
  'Component colors/Utility/Orange dark/utility-orange-dark-100':
    PRIMITIVES['Colors/Orange dark/900'],
  'Component colors/Utility/Orange dark/utility-orange-dark-200':
    PRIMITIVES['Colors/Orange dark/800'],
  'Component colors/Utility/Orange dark/utility-orange-dark-300':
    PRIMITIVES['Colors/Orange dark/700'],
  'Component colors/Utility/Orange dark/utility-orange-dark-400':
    PRIMITIVES['Colors/Orange dark/600'],
  'Component colors/Utility/Orange dark/utility-orange-dark-500':
    PRIMITIVES['Colors/Orange dark/500'],
  'Component colors/Utility/Orange dark/utility-orange-dark-600':
    PRIMITIVES['Colors/Orange dark/400'],
  'Component colors/Utility/Orange dark/utility-orange-dark-700':
    PRIMITIVES['Colors/Orange dark/300'],
  'Component colors/Utility/Orange/utility-orange-50':
    PRIMITIVES['Colors/Orange/950'],
  'Component colors/Utility/Orange/utility-orange-100':
    PRIMITIVES['Colors/Orange/900'],
  'Component colors/Utility/Orange/utility-orange-200':
    PRIMITIVES['Colors/Orange/800'],
  'Component colors/Utility/Orange/utility-orange-300':
    PRIMITIVES['Colors/Orange/700'],
  'Component colors/Utility/Orange/utility-orange-400':
    PRIMITIVES['Colors/Orange/600'],
  'Component colors/Utility/Orange/utility-orange-500':
    PRIMITIVES['Colors/Orange/500'],
  'Component colors/Utility/Orange/utility-orange-600':
    PRIMITIVES['Colors/Orange/400'],
  'Component colors/Utility/Orange/utility-orange-700':
    PRIMITIVES['Colors/Orange/300'],
  'Component colors/Utility/Yellow/utility-yellow-50':
    PRIMITIVES['Colors/Yellow/950'],
  'Component colors/Utility/Yellow/utility-yellow-100':
    PRIMITIVES['Colors/Yellow/900'],
  'Component colors/Utility/Yellow/utility-yellow-200':
    PRIMITIVES['Colors/Yellow/800'],
  'Component colors/Utility/Yellow/utility-yellow-300':
    PRIMITIVES['Colors/Yellow/700'],
  'Component colors/Utility/Yellow/utility-yellow-400':
    PRIMITIVES['Colors/Yellow/600'],
  'Component colors/Utility/Yellow/utility-yellow-500':
    PRIMITIVES['Colors/Yellow/500'],
  'Component colors/Utility/Yellow/utility-yellow-600':
    PRIMITIVES['Colors/Yellow/400'],
  'Component colors/Utility/Yellow/utility-yellow-700':
    PRIMITIVES['Colors/Yellow/300'],
  'Component colors/Components/App store badges/app-store-badge-border':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Application navigation/nav-item-button-icon-fg':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Component colors/Components/Application navigation/nav-item-button-icon-fg_active':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/Application navigation/nav-item-icon-fg':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Component colors/Components/Application navigation/nav-item-icon-fg_active':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/Avatars/avatar-bg':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Components/Avatars/avatar-profile-photo-border':
    PRIMITIVES['Colors/Gray (dark mode)/950'],
  'Component colors/Components/Avatars/avatar-contrast-border':
    'rgba(255, 255, 255, 12)',
  'Component colors/Components/Avatars/avatar-styles-bg-neutral': '#e0e0e0',
  'Component colors/Components/Breadcrumbs/breadcrumb-fg':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/Breadcrumbs/breadcrumb-fg_hover':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Breadcrumbs/breadcrumb-bg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Components/Breadcrumbs/breadcrumb-brand-fg_hover':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Breadcrumbs/breadcrumb-brand-bg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Components/Breadcrumbs/breadcrumb-icon-fg':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Component colors/Components/Breadcrumbs/breadcrumb-icon-fg_hover':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Breadcrumbs/breadcrumb-brand-icon-fg_hover':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Primary/button-primary-fg':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Primary/button-primary-fg_hover':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Primary/button-primary-bg':
    PRIMITIVES['Colors/Brand/600'],
  'Component colors/Components/Buttons/Primary/button-primary-bg_hover':
    PRIMITIVES['Colors/Brand/700'],
  'Component colors/Components/Buttons/Secondary/button-secondary-fg':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/Buttons/Secondary/button-secondary-fg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/100'],
  'Component colors/Components/Buttons/Secondary/button-secondary-bg':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Component colors/Components/Buttons/Secondary/button-secondary-bg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Components/Buttons/Secondary/button-secondary-border':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Components/Buttons/Secondary/button-secondary-border_hover':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-fg':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-fg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/100'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-bg':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-bg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-border':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Components/Buttons/Secondary color/button-secondary-color-border_hover':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Components/Buttons/Tertiary/button-tertiary-fg':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Component colors/Components/Buttons/Tertiary/button-tertiary-fg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/200'],
  'Component colors/Components/Buttons/Tertiary/button-tertiary-bg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-fg':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-fg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/100'],
  'Component colors/Components/Buttons/Tertiary color/button-tertiary-color-bg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Components/Buttons/Primary error/button-primary-error-fg':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Primary error/button-primary-error-fg_hover':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Buttons/Primary error/button-primary-error-bg':
    PRIMITIVES['Colors/Error/600'],
  'Component colors/Components/Buttons/Primary error/button-primary-error-bg_hover':
    PRIMITIVES['Colors/Error/700'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-fg':
    PRIMITIVES['Colors/Error/200'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-fg_hover':
    PRIMITIVES['Colors/Error/100'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-bg':
    PRIMITIVES['Colors/Error/950'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-bg_hover':
    PRIMITIVES['Colors/Error/900'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-border':
    PRIMITIVES['Colors/Error/800'],
  'Component colors/Components/Buttons/Secondary error/button-secondary-error-border_hover':
    PRIMITIVES['Colors/Error/700'],
  'Component colors/Components/Buttons/Tertiary error/button-tertiary-error-fg':
    PRIMITIVES['Colors/Error/300'],
  'Component colors/Components/Buttons/Tertiary error/button-tertiary-error-fg_hover':
    PRIMITIVES['Colors/Error/200'],
  'Component colors/Components/Buttons/Tertiary error/button-tertiary-error-bg_hover':
    PRIMITIVES['Colors/Error/900'],
  'Component colors/Components/Footers/footer-button-fg':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/Footers/footer-button-fg_hover':
    PRIMITIVES['Colors/Gray (dark mode)/100'],
  'Component colors/Components/Footers/footer-badge-fg':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Footers/footer-badge-bg':
    'rgba(255, 255, 255, 10)',
  'Component colors/Components/Footers/footer-badge-border':
    'rgba(255, 255, 255, 30)',
  'Component colors/Components/Header sections/header-abstract-50-bg':
    PRIMITIVES['Colors/Gray (dark mode)/900'],
  'Component colors/Components/Header sections/header-abstract-100-bg':
    PRIMITIVES['Colors/Gray (dark mode)/800'],
  'Component colors/Components/Header sections/header-abstract-200-bg':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Components/Header sections/header-abstract-300-bg':
    PRIMITIVES['Colors/Gray (dark mode)/600'],
  'Component colors/Components/Icons/Icons/icon-fg-brand':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Component colors/Components/Icons/Icons/icon-fg-brand_on-brand':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-brand':
    PRIMITIVES['Colors/Brand/200'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-gray':
    PRIMITIVES['Colors/Gray (dark mode)/200'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-error':
    PRIMITIVES['Colors/Error/200'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-warning':
    PRIMITIVES['Colors/Warning/200'],
  'Component colors/Components/Icons/Featured icons/Light/featured-icon-light-fg-success':
    PRIMITIVES['Colors/Success/200'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-brand':
    PRIMITIVES['Colors/Brand/200'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-gray':
    PRIMITIVES['Colors/Gray (dark mode)/200'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-error':
    PRIMITIVES['Colors/Error/200'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-warning':
    PRIMITIVES['Colors/Warning/200'],
  'Component colors/Components/Icons/Featured icons/Dark/featured-icon-dark-fg-success':
    PRIMITIVES['Colors/Success/200'],
  'Component colors/Components/Icons/Featured icons/Modern/featured-icon-modern-border':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-x':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-instagram':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-apple':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-github':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-angellist':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Icons/Social icons/social-icon-fg-tumblr':
    PRIMITIVES['Colors/Base/white'],
  'Component colors/Components/Mockups/screen-mockup-border':
    PRIMITIVES['Colors/Gray (dark mode)/700'],
  'Component colors/Components/Sliders/slider-handle-bg':
    PRIMITIVES['Colors/Brand/500'],
  'Component colors/Components/Sliders/slider-handle-border':
    PRIMITIVES['Colors/Gray (dark mode)/950'],
  'Component colors/Components/Thumbnail/thumbnail-badge-brand-fg':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/Thumbnail/thumbnail-badge-success-fg':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/Toggles/toggle-button-fg_disabled':
    PRIMITIVES['Colors/Gray (dark mode)/600'],
  'Component colors/Components/Toggles/toggle-border':
    PRIMITIVES['Colors/Base/transparent'],
  'Component colors/Components/Toggles/toggle-slim-border_pressed':
    PRIMITIVES['Colors/Base/transparent'],
  'Component colors/Components/Toggles/toggle-slim-border_pressed-hover':
    PRIMITIVES['Colors/Base/transparent'],
  'Component colors/Components/Tooltips/tooltip-supporting-text':
    PRIMITIVES['Colors/Gray (dark mode)/300'],
  'Component colors/Components/WYSIWYG editor/wysiwyg-editor-icon-fg':
    PRIMITIVES['Colors/Gray (dark mode)/400'],
  'Component colors/Components/WYSIWYG editor/wysiwyg-editor-icon-fg_active':
    PRIMITIVES['Colors/Base/white'],
};
