import { Theme } from '@mui/material';

export default function Drawer(theme: Theme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          padding: 0,
          backgroundColor: theme.palette.background.default,
        },
      },
    },
  };
}
