import { styled } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const StyledWrapperStack = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
  marginBlockEnd: theme.spacing(5),
}));

export const StyledCloseDrawerStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
}));

export const StyledSmartTagCardStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingInline: theme.spacing(4),
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  marginInlineStart: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 0.5,
}));
