import { forwardRef } from 'react';

import { SwitchProps } from '@mui/material';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';

import StyledSwitch from '@components/switch/styles';

type Props = SwitchProps & {
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  label?: FormControlLabelProps['label'];
};

const Switch = forwardRef<FormControlLabelProps, Props>(function Switch(
  { label, labelPlacement = 'start', sx, ...otherProps },
  ref,
) {
  return (
    <Stack
      direction="row"
      gap={2}
      sx={{
        userSelect: 'none',
        ...sx,
      }}
    >
      <FormControlLabel
        labelPlacement={labelPlacement}
        control={<StyledSwitch {...otherProps} />}
        ref={ref}
        label={label}
      />
    </Stack>
  );
});

Switch.displayName = 'Switch';
export default Switch;
