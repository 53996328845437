import { useState } from 'react';

import {
  alpha,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Input from '@components/input';
import Text from '@components/text';

import { useTranslation } from '@desygner/ui-common-translation';

import { WorkspaceResponseType } from '@shared-types/workspace';

import ChevDownIcon from '~icons/knowz-iconify/chev-down';
import ChevTopIcon from '~icons/knowz-iconify/chev-up';
import SearchIcon from '~icons/knowz-iconify/search';

import usePopover from '@layouts/main/sections/toolbar/components/knowledge-base-popover/hooks/usePopover';
import {
  StyledAutocomplete,
  StyledAutocompletePaper,
  StyledAutocompletePopper,
  StyledBox,
  StyledListbox,
  StyledPopper,
  StyledStackListItem,
} from '@layouts/main/sections/toolbar/components/knowledge-base-popover/sections/content/tabs/tab-panels/sections/invited-to/dropdown/styles';
import StyledAvatar from '@layouts/main/sections/toolbar/components/knowledge-base-popover/sections/content/tabs/tab-panels/styles';

type Props = {
  options: WorkspaceResponseType[];
};

export default function Dropdown({ options = [] }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const hasUserInvitedToAnyWorkspaces = options.length > 0;
  const { handleSetSelectedWorkspaceId } = usePopover();
  const [value, setValue] = useState<WorkspaceResponseType | null>(options[0]);
  const hasValue = value !== null;
  const { t } = useTranslation();
  const isPopoverOpen = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAutocompleteChange(
    _event: React.SyntheticEvent,
    newValue: unknown,
    _reason: AutocompleteChangeReason,
    _details?: AutocompleteChangeDetails<unknown> | undefined,
  ) {
    const typedNewValue = newValue as typeof value;
    if (!typedNewValue) return;
    handleSetSelectedWorkspaceId(typedNewValue.id);
    setValue(typedNewValue);
    handleClose();
  }

  return (
    <>
      <Input
        fullWidth
        endAdornment={isPopoverOpen ? <ChevTopIcon /> : <ChevDownIcon />}
        startAdornment={
          <StyledAvatar
            src={
              hasValue && value?.profilePicture
                ? value.profilePicture
                : undefined
            }
          />
        }
        readOnly
        disabled={!hasUserInvitedToAnyWorkspaces}
        inputProps={{
          sx: {
            cursor: hasUserInvitedToAnyWorkspaces ? 'pointer' : 'default',
          },
        }}
        placeholder={t('layout.toolbar.popover.tabs.invitedTo.empty', {
          defaultValue: 'nothing here yet',
        })}
        value={value?.name}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <StyledPopper
        open={isPopoverOpen}
        anchorEl={anchorEl}
        placement="bottom"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <StyledBox>
            <StyledAutocomplete
              slots={{
                listbox: StyledListbox,
                popper: (props) => (
                  <StyledAutocompletePopper
                    {...props}
                    disablePortal
                  />
                ),
                paper: (props) => <StyledAutocompletePaper {...props} />,
              }}
              value={value}
              disableCloseOnSelect
              readOnly={!hasUserInvitedToAnyWorkspaces}
              disabled={!hasUserInvitedToAnyWorkspaces}
              renderTags={() => null}
              noOptionsText={
                <Text
                  variant="textSm"
                  weight="semibold"
                >
                  {t('layout.toolbar.popover.tabs.invitedTo.noResults', {
                    defaultValue: 'No results found',
                  })}
                </Text>
              }
              onChange={handleAutocompleteChange}
              renderInput={(params) => (
                <Input
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  fullWidth
                  startAdornment={<SearchIcon />}
                  placeholder={t(
                    'layout.toolbar.popover.tabs.invitedTo.search',
                    {
                      defaultValue: 'Search invited knowledge bases',
                    },
                  )}
                  sx={{
                    mt: 3,
                  }}
                />
              )}
              open
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                const typedOption = option as typeof value;
                return (
                  <StyledStackListItem
                    key={`${key}-${typedOption?.id || ''}`}
                    {...optionProps}
                    sx={{
                      backgroundColor: (theme) =>
                        selected
                          ? alpha(theme.palette.primary.main, 0.1)
                          : 'transparent',
                    }}
                  >
                    <StyledAvatar
                      src={
                        hasValue && typedOption && typedOption.profilePicture
                          ? typedOption.profilePicture
                          : undefined
                      }
                    />
                    <Text
                      variant="textSm"
                      weight="semibold"
                    >
                      {typedOption ? typedOption.name : '-'}
                    </Text>
                  </StyledStackListItem>
                );
              }}
              options={options}
              getOptionLabel={(option) => {
                const typedOption = option as typeof value;
                return typedOption?.name || '-';
              }}
            />
          </StyledBox>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
}
