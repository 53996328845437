import { createContext, useCallback, useMemo, useReducer } from 'react';

import { ActionMap } from '@shared-types/utils';

type RoomStatus = 'loading' | 'loaded' | 'active' | 'error' | 'closed';

type LiveMetadata = {
  roomStatus: RoomStatus;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modes: { [key: string]: any }; // TODO
  threadId: string;
  sources: number[];
  isRecording: boolean;
  jwt: string;
};

type RoomMetadataContextType = {
  roomStatus: RoomStatus;
  error: string | null;
  metadata: LiveMetadata | null;
  handleSetMetadata: (args: string) => void;
  handleSetRoomStatus: (args: string) => void;
};

type RoomMetadataType = {
  roomStatus: RoomMetadataContextType['roomStatus'];
  error: RoomMetadataContextType['error'];
  metadata: RoomMetadataContextType['metadata'];
};

enum Types {
  SET_METADATA = 'SET_METADATA',
  SET_ROOM_STATUS = 'SET_ROOM_STATUS',
}

type RoomMetadataayload = {
  [Types.SET_METADATA]: string;
  [Types.SET_ROOM_STATUS]: RoomMetadataContextType['roomStatus'];
};

export type RoomMetadataAction =
  ActionMap<RoomMetadataayload>[keyof ActionMap<RoomMetadataayload>];

export const RoomMetadataContext =
  createContext<RoomMetadataContextType | null>(null);

const initialState: RoomMetadataType = {
  roomStatus: 'loading',
  error: null,
  metadata: null,
};

export default function RoomMetadataProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSetMetadata = useCallback((payload: string) => {
    dispatch({ type: Types.SET_METADATA, payload });
  }, []);

  const handleSetRoomStatus = useCallback(
    (payload: RoomMetadataContextType['roomStatus']) => {
      dispatch({ type: Types.SET_ROOM_STATUS, payload });
    },
    [],
  );

  const memoizedValue = useMemo(
    () => ({
      ...state,
      handleSetMetadata,
      handleSetRoomStatus,
    }),
    [state, handleSetMetadata, handleSetRoomStatus],
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <RoomMetadataContext.Provider value={memoizedValue}>
      {children}
    </RoomMetadataContext.Provider>
  );
}

function reducer(
  state: RoomMetadataType,
  action: RoomMetadataAction,
): RoomMetadataType {
  switch (action.type) {
    case Types.SET_ROOM_STATUS: {
      return {
        ...state,
        roomStatus: action.payload,
      };
    }
    case Types.SET_METADATA: {
      const metadata = JSON.parse(action.payload || '{}') as LiveMetadata;
      const newState: { metadata: LiveMetadata } & Partial<{
        roomStatus: RoomMetadataContextType['roomStatus'];
        error: RoomMetadataContextType['error'];
      }> = { metadata };

      if (metadata?.roomStatus && metadata.roomStatus !== state.roomStatus) {
        newState.roomStatus = metadata.roomStatus;
      }

      if (metadata?.error && metadata.error !== state.error) {
        newState.error = metadata.error;
      }

      return {
        ...state,
        ...newState,
      };
    }
    default:
      return state;
  }
}
