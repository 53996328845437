import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';

const StyledLinkLoadingButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'transparent!important',
  '&, :hover': {
    textDecoration: 'underline',
  },
}));

export default StyledLinkLoadingButton;
