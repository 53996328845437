import { MinimalFolderType } from '@components/pages/knowledge-base/admin/types';

import { sources } from '@lib/agent';
import getDataWithPagination from '@lib/getDataWithPagination';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';

import { PaginationType } from '@shared-types/pagination';
import { SourceWithMetaDataType } from '@shared-types/sources';

import { Query, QueryKey } from '@tanstack/react-query';

type Props = PaginationType & {
  folderId: MinimalFolderType['folderId'];
  refetchInterval?:
    | number
    | false
    | ((
        query: Query<
          SourceWithMetaDataType,
          unknown,
          SourceWithMetaDataType,
          QueryKey
        >,
      ) => number | false | undefined)
    | undefined;
};

export default function useGetSourcesDataByFolderId({
  folderId,
  limit,
  page,
  refetchInterval,
}: Props) {
  const {
    data: sourcesData,
    isLoading: isSourcesDataLoading,
    isError: isSourcesDataError,
    isPending: isSourcesDataPending,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['sources', { page, folderId }],
    queryFn: async () => {
      try {
        const { data, headers } = await sources.getAll({
          folder: folderId,
          page,
          limit,
        });

        const totalCount = headers['x-total-count'] as string;

        const dataWithPagination = getDataWithPagination({
          data,
          totalCount,
          pageParam: page,
          limit,
        });

        return dataWithPagination;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching sources: ${error}`);
      }
    },
    options: {
      refetchInterval,
    },
  });

  return {
    sourcesData,
    isSourcesDataLoading,
    isSourcesDataError,
    isSourcesDataPending,
  };
}
