import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import CONFIG from '@components/pages/assistants-session/message-field/config';
import RHFTextarea from '@components/react-hook-form/RHFTextarea';

export const StyledTextarea = styled(RHFTextarea)(({ theme }) => ({
  resize: 'none',
  maxHeight: CONFIG.TEXTAREA.HEIGHT,
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: CONFIG.TEXTAREA.WIDTH.MD,
  },
  marginTop: 'auto',
  borderRadius: theme.shape.borderRadius * 6, //? fully rounded
  paddingInline: theme.spacing(9.5),
  paddingBlock: theme.spacing(3),
  backgroundColor: theme.palette.background.card.light,
  borderColor: 'transparent',
  color: theme.palette.text.secondary,
  '&:focus': {
    outline: `1px solid ${theme.palette.primary.main}!important`,
  },
  ':focus-visible': {
    outline: 'transparent',
  },
}));

export const StyledFormStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: CONFIG.TEXTAREA.WIDTH.MD,
  },
  margin: 'auto',
  alignItems: 'end',
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  bottom: 2,
}));
