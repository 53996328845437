import { threads } from '@lib/agent';

import { useTranslation } from '@desygner/ui-common-translation';

import { CreateAIThreadRequestType } from '@shared-types/threads';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const MAX_THREAD_NAME_LENGTH = 60;
const DOTS_LENGTH = 3;

const createOneThread = async (args: CreateAIThreadRequestType) => {
  let truncatedName = undefined as string | undefined;

  if (args.type === 'assistant') {
    const { name, bot, type } = args;
    if (name.length > MAX_THREAD_NAME_LENGTH) {
      truncatedName =
        name.slice(0, MAX_THREAD_NAME_LENGTH - DOTS_LENGTH) + '...';
    }
    return await threads.createOneThread({
      name: truncatedName || name,
      type,
      bot,
    });
  }

  //? type === 'search'
  const { name, type } = args;

  if (name.length > MAX_THREAD_NAME_LENGTH) {
    truncatedName = name.slice(0, MAX_THREAD_NAME_LENGTH - DOTS_LENGTH) + '...';
  }
  return await threads.createOneThread({
    name: truncatedName || name,
    type,
  });
};

export default function useCreateOneThread() {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: createOneThread,
    mutationKey: ['createOneThread'],
    onError: (error) => {
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Unknown error. Please try again later.',
        }),
      );
      console.error(error);
    },
  });
}
