import { Skeleton } from '@mui/material';

import StyledProgressBar from '@components/pages/knowledge-base/components/capacity-indicator/tooltip/progress-bar/styles';

import useGetCredit from '@hooks/useGetCredit';

const LOW_CREDIT_NUMBER = 10;
const SKELETON_WIDTH = 200;
const SKELETON_HEIGHT = 4;

export default function ProgressBar() {
  const { creditsData, isCreditLoading } = useGetCredit();
  const isCreditLow =
    (creditsData &&
      creditsData.knowledgeCapacityPercentage <= LOW_CREDIT_NUMBER) ??
    false;

  return isCreditLoading ? (
    <Skeleton
      width={SKELETON_WIDTH}
      height={SKELETON_HEIGHT}
    />
  ) : (
    <StyledProgressBar
      disabled
      isCreditLow={isCreditLow}
      defaultValue={creditsData?.knowledgeCapacityPercentage}
    />
  );
}
