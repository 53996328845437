/* eslint-disable import/prefer-default-export */
import { getStepDefinition } from '@components/pages/command-designer/config';

import * as StepsDefinitions from '@lib/step';
import {
  KnowzStepDefinitionType,
  StepsDefinitionsType,
  StepType,
} from '@lib/step/types';

export function getStaticGroups() {
  const groups = [];

  for (const key in StepsDefinitions) {
    const def: KnowzStepDefinitionType = (
      StepsDefinitions as StepsDefinitionsType
    )[key];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let group: any = groups.find((group) => group.name === def.group); //TODO

    if (!group) {
      group = {
        name: def.group,
        steps: [],
      };

      groups.push(group);
    }

    group.steps.push({ ...def.step, properties: {} });
  }

  groups.sort((a, b) =>
    getGroupOrder(a.name) > getGroupOrder(b.name) ? 1 : -1,
  );

  for (const group of groups) {
    group.steps.sort((a: StepType, b: StepType) =>
      getStepOrder(a) > getStepOrder(b) ? 1 : -1,
    );
  }

  return groups;
}

function getStepOrder(step: StepType): number {
  const def = getStepDefinition(step);
  return def?.order || 0;
}

function getGroupOrder(group: string): number {
  const stepKey = Object.keys(StepsDefinitions).find(
    (key) => (StepsDefinitions as StepsDefinitionsType)[key].group === group,
  );

  return stepKey
    ? (StepsDefinitions as StepsDefinitionsType)[stepKey].order
    : 0;
}
