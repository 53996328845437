import { styled } from '@mui/material';
import Drawer from '@mui/material/Drawer';

import CONFIG from '@components/pages/live-session/config';

const GAP = 10;

const StyledDrawer = styled(Drawer)(({ theme, open }) => {
  return {
    flex: open ? `0 0 ${CONFIG.DRAWER.WIDTH - GAP}px` : '0 0 0',
    transition: theme.transitions.create('flex', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };
});

export default StyledDrawer;
