import { WSResponseType } from '@state/middleware/types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type WebSocket = {
  WSResponse: WSResponseType;
  isWSConnected: boolean;
  isWSErrored: unknown;
  isWSLoading: boolean;
  WSMessageStatus: 'started' | 'finished' | null;
};

const initialState: WebSocket = {
  WSResponse: {
    assistant: {
      composed: [],
      text: '',
    },
    searchResult: {
      smartLoading: {
        type: null,
        stream_counter: 0,
        response: null,
        composed: [],
      },
      layout: {
        photo: {
          type: 'photos',
          id: '',
          query: '',
          photos: [],
          isLoading: null,
        },
        infos: {
          infosData: [],
          isLoading: null,
        },
        answer: {
          type: 'answer',
          composed: [],
          user_query: '',
          text: '',
          search_query: '',
          id: '',
          isLoading: null,
        },
        link: {
          id: '',
          type: 'links',
          links: [],
          isLoading: null,
        },
      },
      followups: {
        followupsData: [],
        isLoading: null,
      },
      TOS: null,
    },
  },
  isWSConnected: false,
  isWSErrored: null,
  isWSLoading: false,
  WSMessageStatus: null,
};

const websocket = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    WSConnected: (state) => {
      return { ...state, isWSConnected: true };
    },
    WSDisconnected: (state) => {
      return { ...state, isWSConnected: false };
    },
    WSErrored: (state, action) => {
      return { ...state, isWSErrored: action.payload };
    },
    WSResponseUpdated: (state, action) => {
      return { ...state, WSResponse: action.payload };
    },
    WSLoaded: (state, action: PayloadAction<boolean>) => {
      return { ...state, isWSLoading: action.payload };
    },
    WSMessageStatusUpdated: (
      state,
      action: PayloadAction<'started' | 'finished'>,
    ) => {
      return { ...state, WSMessageStatus: action.payload };
    },
    WSTOSUpdated: (state, action) => {
      return {
        ...state,
        WSResponse: { ...state.WSResponse, TOS: action.payload },
      };
    },
    reset: (state) => {
      return {
        ...state,
        ...initialState,
        WSMessageStatus: 'finished',
      };
    },
  },
});

export const {
  WSConnected,
  WSDisconnected,
  WSErrored,
  WSResponseUpdated,
  WSLoaded,
  WSMessageStatusUpdated,
  reset,
} = websocket.actions;

export default websocket.reducer;
