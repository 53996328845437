import rawAppPaths from '@router/rawAppPaths';

type PathValueType = {
  pathname: string;
  title?: string;
  icon?: React.ReactNode;
};

const baseUrl = import.meta.env.VITE_KNOWZ_WEBSITE_URL;

export type PathsType = Record<string, PathValueType>;

const paths = {
  connectors: {
    pathname: rawAppPaths.connectors,
    title: 'Connectors',
  },
  commands: {
    pathname: rawAppPaths.commands,
    title: 'Commands',
  },
  editCommand: {
    pathname: rawAppPaths.editCommand,
    title: 'Edit Command',
  },
  home: {
    pathname: rawAppPaths.home,
  },
  search: {
    pathname: rawAppPaths.search,
    title: 'Search',
  },
  history: {
    pathname: rawAppPaths.history,
    title: 'history',
  },
  live: {
    pathname: rawAppPaths.live,
    title: 'live',
  },
  liveSession: {
    pathname: rawAppPaths.liveSession,
    title: 'live Session',
  },
  assistants: {
    pathname: rawAppPaths.assistants,
    title: 'Assistants',
  },
  assistantsSession: {
    pathname: rawAppPaths.assistantsSession,
    title: 'Assistants Session',
  },
  sso: {
    pathname: rawAppPaths.sso,
    title: 'Single Sign On',
  },
  editSso: {
    pathname: rawAppPaths.editSso,
    title: 'Edit Single Sign On',
  },
  knowledgeBase: {
    pathname: rawAppPaths.knowledgeBase,
    title: 'Knowledge Base',
  },
  users: {
    pathname: rawAppPaths.users,
    title: 'Users',
  },
  getTheApp: {
    pathname: rawAppPaths.getTheApp,
    title: 'Get the App',
  },
  upgradeAccount: {
    pathname: rawAppPaths.upgradeAccount,
    title: 'Upgrade Account',
  },
  //? Hubspot pages
  tos: {
    pathname: `${baseUrl}/terms-of-service`,
  },
  pp: {
    pathname: `${baseUrl}/privacy-policy`,
  },
  enterprise: {
    pathname: `${baseUrl}/ai-enterprise-search-software`,
    title: 'Enterprise',
  },
};

export default paths;
