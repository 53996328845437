import { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Heading from '@components/workspace/setup/components/heading';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import CONFIG from '@components/workspace/setup/sections/steps/upload-avatar-workspace/config';
import PreviewUppyImageFile from '@components/workspace/setup/sections/steps/upload-avatar-workspace/preview';

import { storage } from '@lib/agent';

import { useTranslation } from '@desygner/ui-common-translation';

import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { useUppyEvent } from '@uppy/react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { toast } from 'react-toastify';

export default function UploadAvatarWorkspace() {
  const { t } = useTranslation();
  const {
    handleNext,
    handleBack,
    handleSetCurrentCreatedWorkspaceDetails,
    currentCreatedWorkspaceDetails,
  } = useWorkspaceSetup();

  const [isUploading, setIsUploading] = useState(false);

  const [uppy] = useState(() =>
    new Uppy({
      restrictions: { allowedFileTypes: CONFIG.FILE_TYPES },
      allowMultipleUploadBatches: false,
    })
      .use(ThumbnailGenerator, {
        thumbnailWidth: CONFIG.THUMBNAIL_SIZE,
        thumbnailHeight: CONFIG.THUMBNAIL_SIZE,
      })
      .use(AwsS3, {
        async getUploadParameters(file) {
          const name = file.data.name;
          const size = file.data.size;
          const sizeInKB = size / 1024;
          const type = file.type;

          const { data } = await storage.createPolicy<{
            usage: 'profile_picture';
          }>({
            key: name,
            size: sizeInKB,
            type,
            context: {
              usage: 'profile_picture',
            },
          });

          return {
            method: 'PUT',
            fields: [],
            headers: {
              'Content-Type': type,
            },
            url: data.url,
          };
        },
      }),
  );

  useUppyEvent(uppy, 'thumbnail:generated', (file) =>
    handleSetCurrentCreatedWorkspaceDetails({
      ...currentCreatedWorkspaceDetails,
      avatarBlob: file,
    }),
  );

  useUppyEvent(uppy, 'upload-success', (file) => {
    handleSetCurrentCreatedWorkspaceDetails({
      ...currentCreatedWorkspaceDetails,
      avatarBlob: file,
    });
  });

  useUppyEvent(uppy, 'upload-error', () => {
    toast.success(
      t('page.workspace.createWorkspaceModal.step2.form.upload.failure', {
        defaultValue: 'Image uploaded failed for some reason',
      }),
    );
  });

  async function uploadHandler() {
    try {
      setIsUploading(true);
      await uppy.upload();
      handleNext();
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <>
      <Heading
        isOptional
        title={t('page.workspace.createWorkspaceModal.step2.form.file.label', {
          defaultValue: 'Your workspace image',
        })}
        subtitle={t(
          'page.workspace.createWorkspaceModal.step2.form.file.description',
          {
            defaultValue:
              'Make sure your workspace stands out with a personal image.',
          },
        )}
      />

      <PreviewUppyImageFile
        avatarBlob={currentCreatedWorkspaceDetails?.avatarBlob}
        uppy={uppy}
      />

      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          width: '100%',
          marginBlockStart: 6,
        }}
      >
        <Button
          variant="outlined"
          onClick={handleBack}
        >
          {t('page.workspace.createWorkspaceModal.backButton', {
            defaultValue: 'Back',
          })}
        </Button>
        {currentCreatedWorkspaceDetails?.avatarBlob ? (
          <LoadingButton
            loading={isUploading}
            variant="contained"
            onClick={uploadHandler}
          >
            {t('page.workspace.createWorkspaceModal.nextButton', {
              defaultValues: 'Next',
            })}
          </LoadingButton>
        ) : (
          <Button
            variant="contained"
            onClick={handleNext}
          >
            {t('pages.workspace.createWorkspaceModal.skipButton', {
              defaultValue: 'Skip',
            })}
          </Button>
        )}
      </Stack>
    </>
  );
}
