import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import ToolbarExpansionButton from '@layouts/main/sections/toolbar/components/expand-button';
import KnowledgeBasePopover from '@layouts/main/sections/toolbar/components/knowledge-base-popover';
import CONFIG from '@layouts/main/sections/toolbar/config';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import {
  StyledDesktopMiniToolbar,
  StyledList,
} from '@layouts/main/sections/toolbar/mini/desktop/styles';
import { StyledListItem } from '@layouts/main/sections/toolbar/mini/styles';
import {
  StyledMiniDesktopToolbarGridItem,
  StyledNavLink,
} from '@layouts/main/sections/toolbar/styles';

export default function DesktopMiniToolbar() {
  const { t } = useTranslation();
  const {
    toolbarExpansionStatus,
    handleDisplayExpandedButton,
    handleHideExpandedButton,
  } = useToolbar();
  const { me } = useAuth();
  const { setupMode } = useWorkspace();
  const isToolbarExpanded = toolbarExpansionStatus === 'off';

  return (
    <StyledMiniDesktopToolbarGridItem
      component={Fade}
      in={isToolbarExpanded}
      onMouseEnter={handleDisplayExpandedButton}
      onMouseLeave={handleHideExpandedButton}
    >
      <StyledDesktopMiniToolbar disableGutters>
        <StyledList
          disablePadding
          component={Stack}
        >
          <KnowledgeBasePopover type="collapsed toolbar" />
          {CONFIG.TOOLBAR_ITEMS.filter(({ isAlpha }) => me?.alpha || !isAlpha)
            .filter(({ setupMode: singleSetupMode }) =>
              singleSetupMode.includes(setupMode),
            )
            .map(({ defaultLabel, icon, mappedLabel, url }, i) => (
              <Tooltip
                key={i}
                arrow
                placement="right"
                title={t(mappedLabel, { defaultValue: defaultLabel })}
              >
                <StyledListItem
                  disablePadding
                  disableGutters
                  component={StyledNavLink}
                  to={url}
                >
                  {icon}
                </StyledListItem>
              </Tooltip>
            ))}
        </StyledList>
        <ToolbarExpansionButton />
      </StyledDesktopMiniToolbar>
    </StyledMiniDesktopToolbarGridItem>
  );
}
