import StyledLink from '@components/enterprise-button/styles';
import Text from '@components/text';

import { useTranslation } from '@desygner/ui-common-translation';

import paths from '@router/paths';
import ChevRightIcon from '~icons/knowz-iconify/chev-right';
import ZKnowzIcon from '~icons/knowz-iconify/knowz-z';

export default function EnterpriseButton() {
  const { t } = useTranslation();

  return (
    <StyledLink
      to={paths.enterprise.pathname}
      target="_blank"
    >
      <ZKnowzIcon fontSize="1.2em" />
      <Text
        variant="textSm"
        weight="medium"
      >
        {t('page.home.hero.overlineButton', {
          defaultValue: 'Enterprise Solutions',
        })}
      </Text>
      <ChevRightIcon />
    </StyledLink>
  );
}
