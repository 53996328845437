import Stack from '@mui/material/Stack';

import EllipsisText from '@components/ellipsis-typography';
import Text from '@components/text';

import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

export default function WorkspaceOwner() {
  const { t } = useTranslation();
  const { workspace } = useWorkspace();

  return (
    <Stack
      direction="row"
      sx={{ justifyContent: 'space-between' }}
      gap={4}
    >
      <EllipsisText
        variant="textMd"
        color="text.secondary"
        lineClamp={1}
        sx={{ wordBreak: 'break-word' }}
      >
        {workspace?.createdBy.email}
      </EllipsisText>
      <Text
        variant="textMd"
        color="text.secondary"
      >
        {t('page.workspace.createWorkspaceModal.step3.owner', {
          defaultValue: 'Owner',
        })}
      </Text>
    </Stack>
  );
}
