import { RouterProvider } from 'react-router-dom';

import ToastifyContainer from '@components/toastify';

import Providers from '@providers/index';

import 'react-toastify/dist/ReactToastify.css';

import CookiesNotice from '@layouts/components/cookies-notice';
import router from '@router/index';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    availableLanguages: ['en', 'hi'],
    defaultLanguage: 'en',
    fallbackLanguage: 'en',

    // for development
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,
    projectId: import.meta.env.VITE_APP_TOLGEE_PROJECT_ID,

    // for production
    staticData: {
      // en: () => import('../i18n/en.json'),
      // hi: () => import('../i18n/hi.json'),
    },
  });

export function App() {
  return (
    <Providers>
      <TolgeeProvider
        tolgee={tolgee}
        // fallback="Loading..." // loading fallback
      >
        <ToastifyContainer />
        <CookiesNotice />
        <RouterProvider router={router} />
      </TolgeeProvider>
    </Providers>
  );
}

export default App;
