import { useMemo } from 'react';

import useAuth from '@hooks/useAuth';

import useGetWorkspaces from '@layouts/main/sections/toolbar/hooks/useGetAllWorkspacesById';

export default function useGetInvitedWorkspaces() {
  const { me } = useAuth();

  const { workspaceData, isWorkspaceFetching, refetchWorkspaces } =
    useGetWorkspaces(me?.uuid);

  const invitedWorkspacesData = useMemo(
    () =>
      (workspaceData || []).filter((workspace) => workspace.role !== 'owner'),
    [workspaceData],
  );

  return {
    invitedWorkspacesData,
    isWorkspaceFetching,
  };
}
