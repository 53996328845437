import { useState } from 'react';

import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';

import {
  StyledTab,
  StyledTabList,
  StyledTabPanel,
} from '@components/pages/knowledge-base/components/table/action-drawer/styles';
import { MOBILE_HEIGHT } from '@components/pages/live/sections/recorder';
import Recorder from '@components/pages/live-session/recorder/mobile';
import Feed from '@components/pages/live-session/sections/content/components/feed';
import Transcript from '@components/pages/live-session/sections/content/components/transcript';

type TabValueType = 'Transcript' | 'Feed';

export default function Content() {
  const [value, setValue] = useState<TabValueType>('Transcript');

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: TabValueType,
  ) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        paddingBottom: `${MOBILE_HEIGHT}px`,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <TabContext value={value}>
        <Box sx={{ textAlign: 'center', lineHeight: 0 }}>
          <StyledTabList
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                bottom: '50%',
              },
              sx: {
                height: 'calc(100% - 5px)',
                transform: 'translateY(50%)',
                backgroundColor: 'background.default',
                borderRadius: 4.5,
              },
            }}
          >
            <StyledTab
              label={'Transcript'}
              value="Transcript"
            />
            <StyledTab
              label={'Feed'}
              value="Feed"
            />
          </StyledTabList>
        </Box>

        <StyledTabPanel
          value="Transcript"
          sx={{
            height: '100%',
            mt: 0,
          }}
        >
          <Transcript />
        </StyledTabPanel>

        <StyledTabPanel
          value="Feed"
          sx={{
            height: '100%',
            mt: 0,
          }}
        >
          <Feed />
        </StyledTabPanel>

        <Recorder />
      </TabContext>
    </Box>
  );
}
