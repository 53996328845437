import FacebookIcon from '~icons/knowz-iconify/facebook-rounded';
import InstagramIcon from '~icons/knowz-iconify/instagram-square';
import LinkedInIcon from '~icons/knowz-iconify/linkedin-square';

const SOCIALS = [
  {
    title: 'Facebook',
    href: 'https://www.facebook.com/knowzit',
    icon: <FacebookIcon fontSize="0.8em" />,
  },
  {
    title: 'Instagram',
    href: 'https://www.instagram.com/knowzai',
    icon: <InstagramIcon fontSize="0.8em" />,
  },
  {
    title: 'LinkedIn',
    href: 'https://www.linkedin.com/company/knowzsearch',
    icon: <LinkedInIcon fontSize="0.8em" />,
  },
];

export default SOCIALS;
