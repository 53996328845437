import { styled } from '@mui/material';

import { SharedStyledSlide } from '@components/pages/styles';

const StyledProgressBar = styled(SharedStyledSlide, {
  shouldForwardProp: (props) => props !== 'isCreditLow',
})<{ isCreditLow: boolean }>(({ theme, isCreditLow }) => ({
  width: 300,
  height: theme.spacing(2),
  '& .MuiSlider-track': {
    background: isCreditLow
      ? 'linear-gradient(to left,#FF1E1E, #FF48A0)' //TODO: design system
      : 'linear-gradient(to left,#36BFFA, #0040C1)', //TODO: design system
    border: 0,
  },
}));

export default StyledProgressBar;
