import { auth } from '@lib/agent';

import { useTranslation } from '@desygner/ui-common-translation';

import { EmailLookupBodyType } from '@shared-types/auth';

import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

const lookupUserEmail = async (data: EmailLookupBodyType) =>
  await auth.emailLookup(data);

export default function useLookupUserEmail() {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: lookupUserEmail,
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast.error(
          t('response.errors.code.429', {
            defaultValue: 'Too many requests. Please try again later.',
          }),
        );
        return;
      }
      console.error(error);
    },
  });
}
