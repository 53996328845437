import assistantResultMiddleware from '@state/middleware/assistant-result';
import searchResultMiddleware from '@state/middleware/search-result';
import socketMiddleware from '@state/middleware/websocket';
import assistantResultSlice from '@state/slices/assistant-result';
import flowRunSlice, {
  onRunCommandListener,
  tickListener,
} from '@state/slices/flow-run';
import liveSlice from '@state/slices/live';
import liveSessionSlice, {
  onThreadIdListener,
} from '@state/slices/live-session';
import searchResultSlice from '@state/slices/search-result';
import websocketSlice from '@state/slices/websocket';

import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer: {
    assistantResult: assistantResultSlice,
    searchResult: searchResultSlice,
    live: liveSlice,
    websocket: websocketSlice,
    flowRun: flowRunSlice,
    liveSession: liveSessionSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([
      socketMiddleware,
      assistantResultMiddleware,
      searchResultMiddleware,
      onRunCommandListener.middleware,
      tickListener.middleware,
      onThreadIdListener.middleware,
    ]);
  },
});

//TODO: it is better to put it in a separate file and import it here in case of adding more logics to it
store.subscribe(() => {
  const state = store.getState();
  const live = state.live;
  window.localStorage.setItem('live', JSON.stringify(live));
});

export type RootType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
