import CONFIG from '@components/search-form/config';

import * as Yup from 'yup';

const searchFormSchema = Yup.object().shape({
  name: Yup.string().trim().required(),
  mode: Yup.string().oneOf(CONFIG.MODES).required(),
});

export default searchFormSchema;
