import Stack from '@mui/material/Stack';

import Text from '@components/text';
import StyledBox from '@components/workspace/setup/components/heading/styles';

import { useTranslation } from '@desygner/ui-common-translation';

type Props = {
  title: string;
  subtitle?: string;
  isOptional?: boolean;
};

const SHARED_PROPS: {
  variant: React.ComponentProps<typeof Text>['variant'];
  color: React.ComponentProps<typeof Text>['color'];
} = {
  variant: 'textMd',
  color: 'text.disabled',
};

export default function Heading({ title, subtitle, isOptional }: Props) {
  const { t } = useTranslation();

  return (
    <StyledBox
      sx={{
        my: 4,
        mx: 0,
        marginBlockStart: !subtitle ? 0 : undefined,
      }}
    >
      <Stack
        gap={2}
        direction="row"
      >
        <Text variant="textSm">{title}</Text>
        {isOptional && (
          <Text {...SHARED_PROPS}>
            {t(
              'page.knowledgeBase.workspace.createWorkspaceModal.optionalLabel',
              {
                defaultValue: '(Optional)',
              },
            )}
          </Text>
        )}
      </Stack>
      {subtitle && <Text {...SHARED_PROPS}>{subtitle}</Text>}
    </StyledBox>
  );
}
