import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { alpha } from '@mui/material';
import MuiCard from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import Grid2 from '@mui/material/Grid2';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';

import ImageDialog from '@components/pages/search/components/image-dialog';
import SectionHeading from '@components/pages/search/components/SectionHeading';
import Card from '@components/pages/search/components/shared-blocks/links/sections/card';
import StyledImageCard from '@components/pages/search/components/shared-blocks/links/styles';
import Text from '@components/text';

import useGetCredentialByKey from '@hooks/useGetCredentialByKey';
import useGetUploadedImageForSearchByThreadId from '@hooks/useGetUploadedImageForSearch';
import useQueryParams from '@hooks/useQueryParams';

import { useTranslation } from '@desygner/ui-common-translation';

import { LinkType } from '@shared-types/search-result/streaming';

const DISPLAY_LIMIT = 3;
const COLS_IN_MD_SCREEN = 3;
const COLS_IN_XS_SCREEN = 6;
const SPACE_BETWEEN = 4;
const DESKTOP_DRAWER_WIDTH = 700;
const MOBILE_DRAWER_WIDTH = 320;

type ImageFileType =
  | {
      file:
        | {
            key: string;
          }
        | undefined;
      preview: string | undefined;
    }
  | undefined;

type Props = {
  links: LinkType[];
  uploadedImageKey?: string | undefined;
  files?: ImageFileType;
};

export default function Links({ links, uploadedImageKey }: Props) {
  const { getQueryParamByKey } = useQueryParams();
  const [files] = useState<ImageFileType | undefined>(
    useGetUploadedImageForSearchByThreadId(getQueryParamByKey('t')),
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { credentialData, isCredentialDataLoading } = useGetCredentialByKey({
    key: files?.preview ? undefined : (uploadedImageKey ?? undefined),
  });

  const { state } = useLocation();

  const { t } = useTranslation();

  const prompt = state?.name as string | undefined;

  const filteredLinks = useMemo(
    () => links.filter((link) => link && link.kind === 'customsearch#result'),
    [links],
  );

  function toggleMoreLinks() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  if (filteredLinks.length === 0) return null;

  return (
    <SectionHeading
      title={t('page.search.sections.sources', {
        defaultValue: 'Sources',
      })}
      icon={<FormatListBulletedIcon fontSize="small" />}
    >
      <Stack gap={5}>
        <Grid2
          container
          spacing={{
            xs: 6,
            sm: 6,
            md: 3,
          }}
          columnSpacing={SPACE_BETWEEN}
        >
          {(files?.preview || isCredentialDataLoading || credentialData) && (
            <StyledImageCard>
              {isCredentialDataLoading ? (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <CircularProgress />
                </Stack>
              ) : (
                <ImageDialog
                  url={files?.preview ?? credentialData?.data.url}
                  alt=""
                />
              )}
            </StyledImageCard>
          )}

          {filteredLinks.slice(0, DISPLAY_LIMIT).map((link, i) => (
            <Grid2
              component="a"
              target="_blank"
              href={link.link}
              rel="noreferrer"
              md={COLS_IN_MD_SCREEN}
              xs={COLS_IN_XS_SCREEN}
              key={i}
            >
              <Card link={link} />
            </Grid2>
          ))}
          {filteredLinks.length > DISPLAY_LIMIT && (
            <Grid2
              md={COLS_IN_MD_SCREEN}
              xs={COLS_IN_XS_SCREEN}
            >
              <MuiCard
                sx={{ height: '100%' }}
                disableRipple
                disableTouchRipple
                focusRipple={false}
                component={ListItemButton}
                onClick={toggleMoreLinks}
              >
                <Text variant="textMd">
                  View {filteredLinks.length - DISPLAY_LIMIT} More
                </Text>
              </MuiCard>
            </Grid2>
          )}
        </Grid2>
        <Drawer
          variant="temporary"
          PaperProps={{
            sx: {
              padding: 6,
              width: {
                xs: MOBILE_DRAWER_WIDTH,
                md: DESKTOP_DRAWER_WIDTH,
              },
              backgroundColor: (theme) =>
                alpha(theme.palette.common.white, 0.05),
              backdropFilter: 'blur(90px)',
            },
          }}
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleMoreLinks}
        >
          <Stack gap={5}>
            <Stack>
              <Text
                component="h2"
                variant="displaySm"
              >
                {filteredLinks.length} Sources
              </Text>
              <Text
                variant="textMd"
                color="neutral.grey"
              >
                {prompt}
              </Text>
            </Stack>
            <Stack gap={2}>
              {filteredLinks.slice(DISPLAY_LIMIT).map(
                (link, i) =>
                  link && (
                    <Grid2
                      size={{
                        md: COLS_IN_MD_SCREEN,
                        xs: COLS_IN_XS_SCREEN,
                      }}
                      key={i}
                    >
                      <Link
                        to={link.link!}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Card
                          link={link}
                          showDescription
                        />
                      </Link>
                    </Grid2>
                  ),
              )}
            </Stack>
          </Stack>
        </Drawer>
      </Stack>
    </SectionHeading>
  );
}
