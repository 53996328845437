import { useState } from 'react';

import DirectiveLabel from '@components/pages/command-designer/dialogs/smart-prompt-editor/DirectiveLabel';

import { DirectiveDescriptor, useNestedEditorContext } from '@mdxeditor/editor';

export const contextRegex = /:context\[([^\]]+)\]{type="([^"]+)"}/g;

export const ContextDirectiveDescriptor: DirectiveDescriptor = {
  name: 'context',
  testNode: (node) => node.name === 'context',
  hasChildren: true,
  attributes: [],
  type: 'textDirective',
  Editor: ({ mdastNode }) => {
    const { parentEditor, lexicalNode } = useNestedEditorContext();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const [initialValue] = useState(mdastNode.children[0]?.value); //TODO

    return (
      <DirectiveLabel
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        component="span" //TODO
        color="purple"
        suppressContentEditableWarning={true}
        contentEditable="true"
        onInput={(e) => {
          const node = structuredClone(mdastNode);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          node.children[0].value = e.currentTarget.textContent; //TODO

          parentEditor.update(
            () => {
              lexicalNode.setMdastNode({ ...mdastNode, ...node });
            },
            { discrete: true },
          );
        }}
      >
        {initialValue || '<missing context>'}
      </DirectiveLabel>
    );
  },
};
