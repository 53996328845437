import { KnowzStepDefinitionType } from '@lib/step/types';

const CodeStepDefinition: KnowzStepDefinitionType = {
  order: 301,
  group: 'Logic',
  step: {
    componentType: 'task',
    type: 'code',
    name: 'Code Snippet',
  },
  contextTypes: [
    'ref-json',
    'ref-string',
    'ref-source',
    'ref-thread',
    'ref-history-block',
  ],
  propertyTypes: [
    {
      id: 'code',
      name: 'Smart JS code',
      description:
        'Write JavaScript code to be executed in a sandbox with the selected scope. You can use and modify the scope variables directly. You can also define a return output.',
      usage: 'definition',
      variant: 'code',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'output',
      name: 'Result reference',
      description: 'Name the resulting data',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-json'],
      isOptional: true,
    },
  ],
};

export default CodeStepDefinition;
