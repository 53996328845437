import React, { useEffect, useState } from 'react';

import { TextField as MUITextField } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import useSourceConfigurationConfig from '@hooks/useSourceConfigurationConfig';

interface WebsiteConnectorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (websiteUrl: string, scanInterval: string) => void;
  onDelete: (id: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sourceConfigs: any[];
}

const WebsiteConnectorDialog: React.FC<WebsiteConnectorDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  onDelete,
  sourceConfigs = [],
}) => {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [scanInterval, setScanInterval] = useState('');

  const websiteConfig =
    sourceConfigs.length > 0
      ? sourceConfigs.find((config) => config.handler === 'website')
      : null;

  const {
    sourceConfig,
    isLoading: isConfigLoading,
    isError: isConfigError,
  } = useSourceConfigurationConfig({
    id: websiteConfig?.id || 0,
    handler: websiteConfig ? 'website' : undefined,
  });

  useEffect(() => {
    if (isOpen && websiteConfig && sourceConfig) {
      setWebsiteUrl(sourceConfig.website || '');
      setScanInterval(sourceConfig.websiteInterval || '');
    } else if (!websiteConfig) {
      setWebsiteUrl('');
      setScanInterval('');
    }
  }, [isOpen, websiteConfig, sourceConfig]);

  const handleSubmit = () => {
    onSubmit(websiteUrl, scanInterval);
    setWebsiteUrl('');
    setScanInterval('');
  };

  const handleDelete = () => {
    if (websiteConfig) {
      onDelete(websiteConfig.id);
      setWebsiteUrl('');
      setScanInterval('');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Website Connector</DialogTitle>
      <DialogContent>
        {websiteConfig ? (
          isConfigLoading ? (
            <CircularProgress />
          ) : isConfigError ? (
            <div>Error loading website configuration.</div>
          ) : (
            <div>
              <p>
                <strong>Website URL:</strong> {sourceConfig?.website}
              </p>
              <p>
                <strong>Scan Interval:</strong> {sourceConfig?.websiteInterval}{' '}
                minutes
              </p>
            </div>
          )
        ) : (
          <>
            <MUITextField
              label="Website URL"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              fullWidth
              margin="normal"
            />
            <MUITextField
              label="Scan Interval (in hours)"
              value={scanInterval}
              onChange={(e) => setScanInterval(e.target.value)}
              fullWidth
              margin="normal"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {websiteConfig && <Button onClick={handleDelete}>Delete</Button>}
        {!websiteConfig && (
          <Button
            onClick={handleSubmit}
            color="primary"
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default WebsiteConnectorDialog;
