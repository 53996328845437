import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';

const StyledChip = styled(Chip)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.spacing(4),
  borderColor: '#D5D9EB', // TODO: design system
  background: `
      linear-gradient(
        to right,
        rgba(83, 177, 237, 0.17),
        rgba(137, 95, 242, 0.17),
        rgba(30, 26, 249, 0.17)
      )`,
}));

export default StyledChip;
