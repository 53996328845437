import { auth } from '@lib/agent';
import { signUp } from '@lib/auth/fingerprint';

import { useTranslation } from '@desygner/ui-common-translation';

import { AuthResponseType, SignUpRequestType } from '@shared-types/auth';

import { useMutation } from '@tanstack/react-query';
import { AxiosResponse, isAxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';

type Props = {
  onSuccess:
    | ((
        data: AxiosResponse<AuthResponseType, unknown> | undefined,
        variables: SignUpRequestType,
        context: unknown,
      ) => Promise<unknown> | unknown)
    | undefined;
};

export default function usePromotiveSignUp({ onSuccess }: Props) {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    mutateAsync: mutatePromotiveSignUpAsync,
    isPending: isPromotiveSignUpPending,
  } = useMutation({
    mutationFn: async (data: SignUpRequestType) => {
      if (!executeRecaptcha) return;

      const grecaptcha = await executeRecaptcha();

      let token = window.localStorage.getItem('token');

      if (!token) {
        const response = await signUp(grecaptcha);

        if (response?.data.status === 'Ok') {
          token = response.data.token;
        }
      }

      return await auth.promotiveSignUp(data, token!);
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 409) {
        toast.error(
          t('response.errors.code.409', {
            defaultValue: 'User already exists. Please try to login.',
          }),
        );
        return;
      }
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Something went wrong. Please try again later.',
        }),
      );
      console.error(error);
    },
    onSuccess,
  });

  return {
    mutatePromotiveSignUpAsync,
    isPromotiveSignUpPending,
  };
}
