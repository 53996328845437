import { createContext, useCallback } from 'react';

import useLocalStorage from '@hooks/useLocalStorage';

type ThemeModeType = 'dark' | 'light';

type ThemeSettingsContextType = {
  themeMode: ThemeModeType;
  toggleThemeMode: (value: ThemeModeType) => void;
};

export const ThemeSettingsContext =
  createContext<ThemeSettingsContextType | null>(null);

const DEFAULT_THEME_MODE = 'dark';

export default function ThemeSettingsProvider({
  children,
}: React.PropsWithChildren) {
  const { value: themeMode, setValue: setThemeMode } =
    useLocalStorage<ThemeModeType>('mode', DEFAULT_THEME_MODE);

  const toggleThemeMode = useCallback((value: ThemeModeType) => {
    setThemeMode(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeSettingsContext.Provider value={{ themeMode, toggleThemeMode }}>
      {children}
    </ThemeSettingsContext.Provider>
  );
}
