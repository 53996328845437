import { memberships } from '@lib/agent';
import getDataWithPagination from '@lib/getDataWithPagination';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';

import { PaginationType } from '@shared-types/pagination';

const LIMIT = 10;

export default function useGetAllMemberships({
  limit = LIMIT,
  page,
}: PaginationType) {
  const {
    data: allMembershipsData,
    isLoading: areAllMembershipsLoading,
    isError: areAllMembershipsError,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['allMemberships', `page: ${page}`],
    queryFn: async () => {
      try {
        const { data, headers } = await memberships.getAll({
          limit,
          page,
        });

        const totalCount = headers['x-total-count'] as string;

        const dataWithPagination = getDataWithPagination({
          data,
          totalCount,
          pageParam: page,
          limit: LIMIT,
        });

        return dataWithPagination;
      } catch (error) {
        throw new Error(
          `An error occurred while fetching memberships: ${error}`,
        );
      }
    },
  });

  return {
    allMembershipsData,
    areAllMembershipsLoading,
    areAllMembershipsError,
  };
}
