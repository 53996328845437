import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { Controller,useFormContext } from 'react-hook-form';

type Props = TextFieldProps & {
  name: string;
  element: string;
  label: string;
};

export default function FormField({ name, element, label, ...props }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          {element === 'text' && (
            <TextField
              {...field}
              sx={{ mt: 3 }}
              fullWidth
              label={label}
              size="small"
              error={!!error}
              helperText={error?.message}
              {...props}
            />
          )}
          {element === 'checkbox' && (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={!!field.value}
                />
              }
              label={label}
              {...props}
            />
          )}
        </>
      )}
    />
  );
}
