import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

import { LEFT_COLUMN_WIDTH } from '@components/pages/live/styles';

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  border: '1px solid',
  borderColor: theme.palette.divider, // TODO: this should be #E9EAEB, but it is rgba(0, 0, 0, 0.12)
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 1px 2px 0px ${theme.palette.divider}`, // TODO: this should be #E9EAEB, but it is rgba(0, 0, 0, 0.12)
  backdropFilter: 'blur(10px)',

  '& > :first-of-type': {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: LEFT_COLUMN_WIDTH.md,

    [theme.breakpoints.up('lg')]: {
      flexBasis: LEFT_COLUMN_WIDTH.lg,
    },

    [theme.breakpoints.up('xl')]: {
      flexBasis: LEFT_COLUMN_WIDTH.xl,
    },
  },
})) as typeof Card;

export const StyledMobileCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  border: '1px solid',
  borderColor: theme.palette.divider, // TODO: this should be #E9EAEB, but it is rgba(0, 0, 0, 0.12)
  borderBottom: 'none',
  backgroundColor: theme.palette.background.default,
  backdropFilter: 'blur(10px)',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.drawer,
  borderRadius: '24px 24px 0 0 !important',
  boxShadow: '0px 1px 45px 0px #0A0D1240',
})) as typeof Card;

export const StyledIcon = styled('div')(({ theme }) => ({
  width: 36,
  height: 36,
  border: '2px solid',
  borderColor: theme.palette.divider,
  borderRadius: '50%',
  position: 'relative',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 15,
    height: 15,
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(311.63deg, #FF1E1E -6.62%, #FF48A0 100%)',
  },

  '&.playing': {
    borderRadius: '30%',
    borderColor: '#FF1E1E',

    '&::before': {
      width: 20,
      height: 20,
      borderRadius: '30%',
    },
  },
}));
