/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';

export type SecretType = {
  id: number;
  name: string;
  lastAccess: string;
};

export type SecretCreationType = {
  name: string;
  value: string;
};

export const secrets = {
  getAllSecrets: () =>
    requests(axiosApiInstance).get<SecretType[]>('/v1/entities/secrets'),

  createOneSecret: (body: SecretCreationType) =>
    requests(axiosApiInstance).post<SecretType>('/v1/entities/secrets', body),
};
