import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Stack from '@mui/material/Stack';

import ActionButton from '@components/pages/knowledge-base/components/action-button';
import useDeleteFolderById from '@components/pages/knowledge-base/hooks/useDeleteFolderById';
import useDeleteSourceById from '@components/pages/knowledge-base/hooks/useDeleteSourceById';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import SourcePreview from '@components/source-preview';

import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';

import { CellContext } from '@tanstack/react-table';
import { useConfirm } from 'material-ui-confirm';

export default function Action({
  row,
  handleToggleActionDrawer,
}: CellContext<FolderType | SourceType, unknown> & {
  handleToggleActionDrawer: (id: number | null) => void;
}) {
  const id = row.original.id;
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { getQueryParamByKey } = useQueryParams();
  const path = getQueryParamByKey<'path' | null>('path');
  const { handleOpenRenameModal } = useKnowledgeBaseData();
  const { folderData } = useGetFolderDataByPath({
    path,
  });

  const { mutateFolder } = useDeleteFolderById({ id: folderData?.id });

  const { mutateSource } = useDeleteSourceById({
    parentFolderId: folderData?.id,
  });

  const { setupMode } = useWorkspace();

  function handleDeleteSourceOrFolder({
    id,
    type,
  }: {
    id: number;
    type: 'folder' | 'source';
  }) {
    if (type === 'folder') {
      confirm({
        title: t('page.knowledgeBase.admin.actions.delete.folder.title', {
          defaultValue: 'Delete Folder',
        }),
        description: t(
          'page.knowledgeBase.admin.actions.delete.folder.content',
          {
            defaultValue: 'Are you sure you want to delete this folder?',
          },
        ),
      })
        .then(() => {
          mutateFolder({
            id,
          });
        })
        .catch((error) => console.error(error));
      return;
    }

    //? then it is a source
    confirm({
      title: t('page.knowledgeBase.admin.actions.delete.source.title', {
        defaultValue: 'Delete Source',
      }),
      description: t('page.knowledgeBase.admin.actions.delete.source.content', {
        defaultValue: 'Are you sure you want to delete this source?',
      }),
    })
      .then(() => {
        mutateSource({
          id,
        });
      })
      .catch((error) => console.error(error));
  }

  if (row.original.isCollected && !('breadcrumb' in row.original)) {
    return (
      <SourcePreview
        sourceId={id}
        shouldShowButton={true}
      />
    );
  }
  //? if it is a folder
  if ('breadcrumb' in row.original) {
    //? non admin users can not create or delete folders
    if (setupMode === 'off') return '-';

    return (
      <Stack
        direction="row"
        gap={2}
      >
        <ActionButton
          aria-label="delete"
          title="delete"
          onClick={() => {
            handleDeleteSourceOrFolder({
              id,
              type: 'folder',
            });
          }}
        >
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </ActionButton>
        <ActionButton onClick={() => handleOpenRenameModal(id)}>
          <DriveFileRenameOutlineIcon fontSize="small" />
        </ActionButton>
      </Stack>
    );
  }

  //? then it is a source
  const isItWorkspaceOwnerButNotAdmin =
    row.original.owner.ownerType === 'workspace' && setupMode !== 'on';
  return (
    <Stack
      direction="row"
      gap={2}
    >
      <ActionButton
        aria-label="settings"
        onClick={() => handleToggleActionDrawer(id)}
        title="settings"
      >
        <SettingsOutlinedIcon fontSize="small" />
      </ActionButton>
      {isItWorkspaceOwnerButNotAdmin ? null : (
        <ActionButton
          aria-label="delete"
          title="delete"
          onClick={() => {
            handleDeleteSourceOrFolder({
              id,
              type: 'source',
            });
          }}
        >
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </ActionButton>
      )}
      <ActionButton onClick={() => handleOpenRenameModal(id)}>
        <DriveFileRenameOutlineIcon fontSize="small" />
      </ActionButton>
      <SourcePreview
        sourceId={id}
        shouldShowButton={true}
      />
    </Stack>
  );
}
