import Card from '@mui/material/Card';
import ImageList from '@mui/material/ImageList';

import ImageDialog from '@components/pages/search/components/image-dialog';
import StyledImageListItem from '@components/pages/search/components/shared-blocks/photos/styles';

import { PhotoType } from '@shared-types/search-result/types';

type Props = {
  photosData: {
    photos: Array<PhotoType> | null;
  } | null;
  hasAnswer: boolean;
  hasInfo: boolean;
};

export default function SinglePhotosCol({
  hasAnswer,
  hasInfo,
  photosData,
}: Props) {
  //? we are not going to show single photo photos if there is an answer or an info block
  if (hasAnswer !== false || hasInfo !== false || photosData == null) {
    return null;
  }

  return (
    photosData.photos &&
    photosData.photos.length > 0 && (
      <Card>
        <ImageList
          gap={8}
          cols={3}
        >
          {photosData.photos.slice(0, 10).map((photo, i: number) => (
            <StyledImageListItem key={i}>
              <ImageDialog {...photo} />
            </StyledImageListItem>
          ))}
        </ImageList>
      </Card>
    )
  );
}
