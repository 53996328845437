import { MinimalFolderType } from '@components/pages/knowledge-base/admin/types';

import { folders } from '@lib/agent';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';

type Props = {
  folderId: MinimalFolderType['folderId'];
};

export default function useGetFoldersDataByIdLazily({ folderId }: Props) {
  const {
    data: foldersData,
    isLoading: isFoldersDataLoading,
    isError: isFoldersDataError,
    isPending: isFoldersDataPending,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['folders', folderId],
    queryFn: async () => {
      try {
        const { data } = await folders.getAll({
          parent: folderId,
        });

        return data;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching folders: ${error}`);
      }
    },
    options: {
      enabled: typeof folderId !== 'undefined',
    },
  });

  return {
    foldersData,
    isFoldersDataLoading,
    isFoldersDataError,
    isFoldersDataPending,
  };
}
