import { alpha, useTheme } from '@mui/material';
import Stack, { StackProps } from '@mui/material/Stack';

import MatrixChart from '@components/pages/users/engagement/MatrixChart';
import PolarAreaChart from '@components/pages/users/engagement/PolarAreaChart';

type Props = {
  membershipId: string | null;
} & StackProps;

const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
};

export default function Engagement(props: Props) {
  const { membershipId, ...rest } = props;
  const theme = useTheme();
  const borderColor = [
    theme.palette.background.card.main,
    theme.palette.background.card.main,
    theme.palette.background.card.main,
  ];

  return (
    <Stack
      gap={4}
      {...rest}
      sx={{ justifyContent: 'center' }}
    >
      <PolarAreaChart
        title="Features Usage"
        sx={{ height: '350px', display: 'flex', justifyContent: 'center' }}
        data={{
          labels: ['Search', 'Assistants', 'Live'],
          datasets: [
            {
              label: 'Monthly usage',
              data: [30, 23, 38],
              borderWidth: 0.5,
              borderColor,
              backgroundColor: [
                alpha(CHART_COLORS.red, 0.5),
                alpha(CHART_COLORS.green, 0.5),
                alpha(CHART_COLORS.purple, 0.5),
              ],
            },
            {
              label: 'Workspace users average',
              data: [24, 25, 22],
              borderWidth: 0.5,
              borderColor,
              backgroundColor: [
                alpha(CHART_COLORS.orange, 0.5),
                alpha(CHART_COLORS.blue, 0.5),
                alpha(CHART_COLORS.grey, 0.5),
              ],
            },
          ],
        }}
      />

      <MatrixChart
        title="Weekly activity (last 3 months)"
        data={{
          datasets: [
            {
              label: 'Platform usage on',
              //@ts-expect-error typescript not picking date scale type
              data: generateData(),
              backgroundColor(c) {
                const value = c.dataset.data[c.dataIndex].v;
                return alpha(CHART_COLORS.purple, 0.1 + value * 0.9);
              },
              borderColor(c) {
                const value = c.dataset.data[c.dataIndex].v;
                return alpha(CHART_COLORS.purple, 0.1 + value * 0.9);
              },
              borderWidth: 1,
              hoverBackgroundColor: CHART_COLORS.green,
              hoverBorderColor: 'yellowgreen',
              width(c) {
                const a = c.chart.chartArea || {};
                // return (a.right - a.left) / 53 - 1;
                return (a.right - a.left) / 13 - 1;
              },
              height(c) {
                const a = c.chart.chartArea || {};
                return (a.bottom - a.top) / 7 - 1;
              },
            },
          ],
        }}
      />
    </Stack>
  );
}

function generateData() {
  const data = [];
  const end = startOfToday();
  // let dt = new Date(new Date().setDate(end.getDate() - 365));
  let dt = new Date(new Date().setDate(end.getDate() - 91));

  while (dt <= end) {
    const iso = dt.toISOString().substr(0, 10);

    data.push({
      x: iso,
      y: isoDayOfWeek(dt),
      d: iso,
      v: Math.random(),
    });

    dt = new Date(dt.setDate(dt.getDate() + 1));
  }

  return data;
}

function isoDayOfWeek(dt: Date) {
  let wd = dt.getDay(); // 0..6, from sunday
  wd = ((wd + 6) % 7) + 1; // 1..7 from monday
  return '' + wd; // string so it gets parsed
}

function startOfToday() {
  const d = new Date();
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
}
