import { sourceConfigurations } from '@lib/agent';

import { SourceConfigurationType } from '@shared-types/sources';

import { useQuery } from '@tanstack/react-query';

type UseSourceConfigurationConfigParams = {
  id?: number;
  handler?: string;
};

type UseSourceConfigurationConfigReturn = {
  sourceConfig: SourceConfigurationType | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
};

export default function useSourceConfigurationConfig({
  id,
  handler,
}: UseSourceConfigurationConfigParams): UseSourceConfigurationConfigReturn {
  const {
    data: sourceConfig,
    isLoading,
    isError,
    refetch,
  } = useQuery<SourceConfigurationType, Error>({
    queryKey: ['sourceConfiguration', id, handler].filter(Boolean),
    queryFn: async (): Promise<SourceConfigurationType> => {
      if (handler && id) {
        const { data } =
          await sourceConfigurations.getSourceConfigurationConfigHandler(
            id,
            handler,
          );
        return data;
      }
      throw new Error('Invalid parameters: ID and/or handler are missing');
    },
    enabled: !!id,
    retry: false,
    staleTime: 1000 * 60 * 5,
  });

  return { sourceConfig, isLoading, isError, refetch };
}
