import { SetupModeType } from '@providers/WorkspaceProvider';

//? on: setup mode is enabled (admin), off: setup mode is disabled (member), null: individual role
export const ROLES: { [key: string]: SetupModeType[] } = {
  HOME: [null, 'off'],
  SEARCH: [null, 'off'],
  LIVE: [null, 'off'],
  ASSISTANTS: [null, 'off'],
  HISTORY: [null, 'off'],
  COMMANDS: ['on'],
  COMMANDS_EDITOR: ['on'],
  CONNECTORS: ['on'],
  USERS: ['on'],
  KNOWLEDGE_BASE: ['on', 'off', null],
  GET_APP: [null, 'off', 'on'],
  SSO: ['on'],
  UPGRADE_ACCOUNT: [null, 'on', 'off'],
};

export const ROLES_BY_PATHNAME: {
  [key: string]: SetupModeType[];
} = {
  '/': ROLES.HOME,
  '/app/search': ROLES.SEARCH,
  '/app/live': ROLES.LIVE,
  '/app/live/session': ROLES.LIVE,
  '/app/assistants': ROLES.ASSISTANTS,
  '/app/assistants/session': ROLES.ASSISTANTS,
  '/app/history': ROLES.HISTORY,
  '/app/commands': ROLES.COMMANDS,
  '/app/commands/editor/*': ROLES.COMMANDS_EDITOR,
  '/app/settings/connectors': ROLES.CONNECTORS,
  '/app/settings/knowledge-base': ROLES.KNOWLEDGE_BASE,
  '/get-the-app': ROLES.GET_APP,
  '/app/workspace/users': ROLES.USERS,
  '/app/settings/sso/*': ROLES.SSO,
  '/app/upgrade-account': ROLES.UPGRADE_ACCOUNT,
};
