import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  width: theme.spacing(9),
  height: theme.spacing(9),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.background.paper,
}));

export default StyledButton;
