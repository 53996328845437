import { useRef } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import CONFIG from '@components/workspace/setup/sections/steps/upload-avatar-workspace/config';
import Wrapper from '@components/workspace/setup/sections/steps/upload-avatar-workspace/preview/wrapper';
import { StyledButton } from '@components/workspace/setup/sections/steps/upload-avatar-workspace/styles';

import { useTranslation } from '@desygner/ui-common-translation';

import Uppy, { Meta, UppyFile } from '@uppy/core';
import { FileInput } from '@uppy/react';

type Props = {
  uppy: Uppy<Meta, Record<string, never>>;
  avatarBlob?: UppyFile<Meta, Record<string, never>> | null;
};

export default function PreviewUppyImageFile({ uppy, avatarBlob }: Props) {
  const { t } = useTranslation();

  const { handleSetCurrentCreatedWorkspaceDetails } = useWorkspaceSetup();

  const inputRef = useRef<FileInput<Meta, Record<string, never>> | null>(null);

  function flushImage() {
    //TODO: here we should remove image from S3 bucket as well
    const files = uppy.getFiles();
    if (files.length > 0) {
      uppy.removeFile(files[0].id);
      if (inputRef.current) {
        (inputRef.current as unknown as HTMLInputElement).value = '';
      }
    }
    handleSetCurrentCreatedWorkspaceDetails({ avatarBlob: null });
  }

  if (avatarBlob == null) {
    return (
      <Wrapper>
        <Box
          sx={{
            backgroundColor: 'background.secondary',
            width: CONFIG.THUMBNAIL_SIZE,
            height: CONFIG.THUMBNAIL_SIZE,
            borderRadius: '50%',
          }}
        />
        <StyledButton
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
        >
          <FileInput
            uppy={uppy}
            ref={inputRef}
          />
          {t(
            'page.workspace.createWorkspaceModal.step2.form.file.chooseImage',
            {
              defaultValue: 'Choose image',
            },
          )}
        </StyledButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Box
        sx={{
          borderRadius: '50%',
          objectFit: 'cover',
        }}
        component="img"
        src={avatarBlob.preview}
        alt="preview"
        width={CONFIG.THUMBNAIL_SIZE}
        height={CONFIG.THUMBNAIL_SIZE}
      />
      <Button
        variant="outlined"
        color="error"
        onClick={flushImage}
        startIcon={<DeleteIcon />}
      >
        {t('page.workspace.createWorkspaceModal.step2.form.button.remove', {
          defaultValue: 'Remove',
        })}
      </Button>
    </Wrapper>
  );
}
