import Skeleton from '@mui/material/Skeleton';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';

import { ColumnProps } from '@components/pages/users/config';

type Props = {
  headItems: string[];
  numberOfRows: number;
  headItemsConfig: ColumnProps[];
};

const SKELETON_CELL_HEIGHT = 28;

export default function LoadingSkeleton({
  headItems,
  headItemsConfig,
  numberOfRows,
}: Props) {
  return Array.from({ length: numberOfRows }, (_, index) => (
    <MuiTableRow key={index}>
      {headItems.map((item, index) => (
        <MuiTableCell
          key={item}
          sx={{
            ...(headItemsConfig[index]?.width
              ? {
                  width: headItemsConfig[index]?.width,
                }
              : {}),
            ...(headItemsConfig[index]?.align
              ? {
                  textAlign: headItemsConfig[index]?.align,
                }
              : {}),
          }}
        >
          <Skeleton
            key={item}
            variant="rectangular"
            sx={{
              height: SKELETON_CELL_HEIGHT,
            }}
          />
        </MuiTableCell>
      ))}
    </MuiTableRow>
  ));
}
