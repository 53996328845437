import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ListItem from '@components/pages/assistants-session/chat-history/list-item';
import LoadingSkeleton from '@components/pages/assistants-session/chat-history/loading-skeleton';
import useGetHistoryBlocksByThreadIdLazily from '@components/pages/hooks/useGetHistoryBlocksByThreadIdLazily';
import useGetOneThreadLazily from '@components/pages/hooks/useGetOneThreadLazily';

import { AssistantHistoryBlockType } from '@shared-types/assistants';

export default function ChatHistory() {
  const { state } = useLocation() as { state?: { isUserRedirected: boolean } };

  const { oneThreadData } = useGetOneThreadLazily();

  const { historyBlocksQueryData, isHistoryBlocksLoading } =
    useGetHistoryBlocksByThreadIdLazily<AssistantHistoryBlockType>({
      threadId: oneThreadData?.id,
    });

  useEffect(() => {
    //? reset isUserRedirected from the location state so the user do not get blocked and we show history blocks on refresh
    //TODO: later we might need to create a custom hook to handle this scenarios
    window.history.replaceState(null, '');
  }, []);

  if (!state?.isUserRedirected && isHistoryBlocksLoading) {
    return <LoadingSkeleton />;
  }

  if (historyBlocksQueryData?.length === 0) {
    return null;
  }

  return historyBlocksQueryData?.map(({ id, owner, text }, i) => {
    return (
      <ListItem
        key={i}
        id={id}
        owner={owner}
        text={text}
      />
    );
  });
}
