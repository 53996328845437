import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

export default function useInitWebSocket() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'socket/init' });

    return () => {
      dispatch({ type: 'socket/disconnect' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
