import LinksBlock from '@components/pages/search/components/shared-blocks/links';

import { RootType } from '@state/store';

import { LinksType } from '@shared-types/search-result/types';

import { useSelector } from 'react-redux';

export default function StreamingLinks() {
  const link = useSelector<RootType, LinksType>(
    (state) => state.websocket.WSResponse.searchResult.layout.link,
  );

  if (link == null) return null;

  return <LinksBlock links={link.links} />;
}
