import useGetTrainingSetByAlias from '@components/pages/live/hooks/useGetSmartTagIdByAlias';

export default function useCollectAllSmartTags() {
  const { data: actionPointsLogger, status: actionPointsLoggerStatus } =
    useGetTrainingSetByAlias('Action Points Logger');

  const { data: conversationModeration, status: conversationModerationStatus } =
    useGetTrainingSetByAlias('Conversation Moderation');

  const { data: minutesTracker, status: minutesTrackerStatus } =
    useGetTrainingSetByAlias('Minutes Tracker');

  const {
    data: salesConversationAnalyser,
    status: salesConversationAnalyserStatus,
  } = useGetTrainingSetByAlias('Sales Conversation Analyser');

  return {
    areCollectedSmartTagsSuccessfullyLoaded: [
      actionPointsLoggerStatus,
      conversationModerationStatus,
      minutesTrackerStatus,
      salesConversationAnalyserStatus,
    ].every((tag) => tag === 'success'),
    collectedSmartTags: {
      actionPointsLogger: {
        title: 'Action Points Logger',
        items: actionPointsLogger || [],
      },
      conversationModeration: {
        title: 'Conversation Moderation',
        items: conversationModeration || [],
      },
      minutesTracker: {
        title: 'Minutes Tracker',
        items: minutesTracker || [],
      },
      salesConversationAnalyser: {
        title: 'Sales Conversation Analyser',
        items: salesConversationAnalyser || [],
      },
    },
  };
}
