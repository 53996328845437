import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Text from '@components/text';

export const StyledText = styled(Text)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  textAlign: 'center',
}));

export const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  display: 'inline-flex',
  top: 0,
  left: 0,
  transform: 'rotate(-90deg)',
}));

export const StyledWrapperBox = styled(Box)<{ size: number | string }>(
  ({ size }) => ({
    position: 'relative',
    display: 'inline-flex',
    height: 36,
    width: Number(size),
    overflow: 'hidden',
  }),
);

export const StyledMuiCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  transform: 'rotate(-180deg)!important',
}));
