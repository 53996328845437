/* eslint-disable import/prefer-default-export */
import { BlockStepComponentViewConfiguration } from '@components/pages/command-designer/extensions/block-step/blockStepComponentViewConfiguration';
import { BlockStep } from '@components/pages/command-designer/extensions/block-step/blockStepExtension';

import {
  createContainerStepComponentViewFactory,
  createTaskStepComponentViewFactory,
  StepComponentView,
  StepComponentViewContext,
  StepComponentViewFactory,
  StepContext,
} from 'sequential-workflow-designer';

export const createBlockStepComponentViewFactory = (
  cfg: BlockStepComponentViewConfiguration,
): StepComponentViewFactory => {
  const containerStepComponentViewFactory =
    createContainerStepComponentViewFactory(cfg);
  const taskStepComponentViewFactory = createTaskStepComponentViewFactory(
    false,
    cfg,
  );

  return (
    parentElement: SVGElement,
    stepContext: StepContext<BlockStep>,
    viewContext: StepComponentViewContext,
  ): StepComponentView => {
    const { step } = stepContext;

    return step.isExpanded
      ? containerStepComponentViewFactory(
          parentElement,
          stepContext,
          viewContext,
        )
      : taskStepComponentViewFactory(parentElement, stepContext, viewContext);
  };
};
