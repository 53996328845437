import { Link } from 'react-router-dom';

import Text from '@components/text';

import { useTranslation } from '@desygner/ui-common-translation';

import {
  StyledMuiLink,
  StyledStack,
} from '@layouts/main/sections/toolbar/components/legal-links/styles';
import paths from '@router/paths';

export default function LegalLinks() {
  const { t } = useTranslation();

  return (
    <StyledStack
      direction="row"
      gap={3.75}
    >
      <StyledMuiLink
        component={Link}
        target="_blank"
        to={paths.pp.pathname}
        sx={{
          textAlign: 'right',
        }}
      >
        <Text variant="textXs">
          {t('layout.toolbar.buttons.pp', {
            defaultValue: 'Privacy Policy',
          })}
        </Text>
      </StyledMuiLink>
      <StyledMuiLink
        component={Link}
        target="_blank"
        to={paths.tos.pathname}
        sx={{
          textAlign: 'left',
        }}
      >
        <Text variant="textXs">
          {t('layout.toolbar.buttons.tos', {
            defaultValue: 'Terms of Service',
          })}
        </Text>
      </StyledMuiLink>
    </StyledStack>
  );
}
