import { useEffect, useRef, useState } from 'react';

import { payments } from '@lib/agent';
import SocketFactory from '@lib/socketFactory';

import { queryClient } from '@providers/ReactQueryProvider';

import useAuth from '@hooks/useAuth';
import useQueryAfterAuthenticationLoaded from '@hooks/useQueryAfterAuthenticationLoaded';
import useSearchParamsState from '@hooks/useSearchParamsState';

import { WSEvents } from '@state/middleware/types';

import { CreditUsageResponseType } from '@shared-types/agent';

import sendCreditsUsedToAnalytics from '@analytics/sendCreditsUsedToAnalytics';

type CreditsUsageType = {
  creditAsPercentage: number;
  knowledgeCapacityPercentage: number;
  remainingCredits: number;
};

type CreditsUpdateHandlerType = (data: Partial<CreditsUsageType>) => void;

const subscribe = (() => {
  let subscribedUuid: string | null = null;
  let handler: CreditsUpdateHandlerType | null = null;
  const socketInstance = SocketFactory.create();

  socketInstance.socket.connect();

  socketInstance.socket.on(WSEvents.MESSAGE, (message) => {
    if (handler && message.type === 'credits') {
      const data = message.data as CreditUsageResponseType;
      const totalCredits = data.initialQuantity;
      const remainingCredits = data.quantity;
      const creditAsPercentage = (remainingCredits / totalCredits) * 100;

      handler({ creditAsPercentage, remainingCredits });
    }
  });

  return (uuid: string, callback: CreditsUpdateHandlerType) => {
    if (subscribedUuid === uuid) return;

    if (subscribedUuid) {
      socketInstance.socket.off(`${subscribedUuid}_state_update`);
    }

    subscribedUuid = uuid;
    handler = callback;

    const channel = `${uuid}_state_update`;
    socketInstance.socket.emit('subscribe', channel);
  };
})();

export default function useGetCredit() {
  const [, setSearchParams] = useSearchParamsState('action', '');
  //? This is a reference to the previous credit amount, so we make sure not to send the same amount to analytics multiple times
  const previousCreditRef = useRef<number | null>(null);
  const { me } = useAuth();

  useEffect(() => {
    if (me?.uuid)
      return subscribe(me.uuid, (updatedCredits) => {
        const prev = queryClient.getQueryData(['credits']);
        prev &&
          queryClient.setQueryData(['credits'], { ...prev, ...updatedCredits });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: creditsData,
    isLoading: isCreditLoading,
    isError: isCreditError,
  } = useQueryAfterAuthenticationLoaded({
    queryKey: ['credits'],
    queryFn: async () => {
      try {
        const response = await payments.getCredit();
        const totalCredits = response.data.initialQuantity;
        const remainingCredits = response.data.quantity;
        const creditAsPercentage = (remainingCredits / totalCredits) * 100;
        const remainingCapacity =
          response.data.storageLimit - response.data.storageUsed;
        const knowledgeCapacityPercentage =
          (remainingCapacity / response.data.storageLimit) * 100;
        const usedCredits = totalCredits - remainingCredits;

        if (
          previousCreditRef.current === null ||
          previousCreditRef.current !== usedCredits
        ) {
          previousCreditRef.current = usedCredits;

          sendCreditsUsedToAnalytics({
            usedAmount: usedCredits,
            totalAmount: totalCredits,
            userUUID: me!.uuid,
          });
        }

        if (creditAsPercentage <= 0) {
          setSearchParams('upgrade-account');
        }

        return {
          creditAsPercentage,
          knowledgeCapacityPercentage:
            knowledgeCapacityPercentage < 0 ? 0 : knowledgeCapacityPercentage,
          remainingCredits,
        };
      } catch (error) {
        console.error(error);
      }
    },
    options: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

  return { creditsData, isCreditLoading, isCreditError };
}
