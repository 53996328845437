import { RemoteSourcesOptions } from '@uppy/remote-sources';

const FILE_TYPES = [
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'audio/webm',
  'audio/x-m4a',
  'audio/x-wav',
  'audio/x-ms-wma',
  'text/plain',
  'text/csv',
  'text/html',
  'text/markdown',
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/json',
  'application/xml',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const INNER_DRAG_DROP_CLASSLIST = '.uppy-DragDrop-inner'; //? This is a class name that is used in the uppy library
const UPPY_DASHBOARD_ADD_FILES_LIST_CLASSLIST = '.uppy-Dashboard-AddFiles-list'; //? This is a class name that is used in the uppy library

const MAX_FILE_SIZE = {
  PAID: 300 * 1024 * 1024, // 300MB
  NOT_PAID: 100 * 1024 * 1024, // 100MB
};

const MAX_NUMBER_OF_FILES = 6;

const REMOTE_SOURCES: RemoteSourcesOptions['sources'] = [
  'Dropbox',
  'OneDrive',
  'Zoom',
  'Box',
];

const CONFIG = {
  FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_NUMBER_OF_FILES,
  INNER_DRAG_DROP_CLASSLIST,
  UPPY_DASHBOARD_ADD_FILES_LIST_CLASSLIST,
  REMOTE_SOURCES,
} as const;

export default CONFIG;
