import { styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import ListItem from '@mui/material/ListItem';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&:last-of-type': {
    borderRadius: theme.shape.borderRadius * 4,
  },

  '&::before': {
    display: 'none',
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  height: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  borderWidth: 1,
  borderStyle: 'solid',
  cursor: 'pointer',
  borderColor: theme.palette.background.secondary,
  backgroundColor: theme.palette.background.paper,
  textAlign: 'center',

  '&:hover': {
    borderColor: theme.palette.primary.light,
  },
}));

export const StyledListItemImage = styled('img')(({ theme }) => ({
  display: 'inline-block',
}));
