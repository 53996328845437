import { queryClient } from '@providers/ReactQueryProvider';

import { HistoryBlocksDataType } from '@shared-types/search-result/history-blocks';

export default function useGetUploadedImageForSearchByThreadId(id: string) {
  return queryClient
    .getQueryData<HistoryBlocksDataType>(['uploadImage', id])
    ?.filter((item) => item.role === 'user')
    .map((item) => ({
      file: item.file,
      preview: item?.preview,
    }))
    .at(-1);
}
