import Container from '@mui/material/Container';

import { StyledBgGradient } from '@components/pages/styles';

import Toolbar from '@layouts/main/sections/toolbar';

export default function FullScreenLayout({
  children,
}: React.PropsWithChildren) {
  return (
    <Container maxWidth={false}>
      <StyledBgGradient aria-hidden />
      <Toolbar>{children}</Toolbar>
    </Container>
  );
}
