//TODO: there are some static CSS rule sets that need to be dynamic.
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

import usePromptHistoryState from '@components/pages/hooks/usePromptHistoryState';
import useRedirectHistoryState from '@components/pages/hooks/useRedirectHistoryState';
import SearchBar from '@components/pages/search/components/search-bar';
import Layout from '@components/pages/search/components/shared-blocks/layout';
import SmartLoading from '@components/pages/search/components/smart-loading';
import StreamingAnswer from '@components/pages/search/sections/web-socket-blocks/streaming-answer';
import StreamingFollowups from '@components/pages/search/sections/web-socket-blocks/streaming-followups';
import StreamingInfo from '@components/pages/search/sections/web-socket-blocks/streaming-info';
import StreamingLinks from '@components/pages/search/sections/web-socket-blocks/streaming-links';
import StreamingPhotos from '@components/pages/search/sections/web-socket-blocks/streaming-photos';
import StreamingPrompt from '@components/pages/search/sections/web-socket-blocks/streaming-prompt';
import StreamingSinglePhotosCol from '@components/pages/search/sections/web-socket-blocks/streaming-single-photos-col';

import { queryClient } from '@providers/ReactQueryProvider';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useAppSettings from '@hooks/useAppSettings';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

import { imageUploaded } from '@state/slices/search-result';

export default function SearchResult() {
  const { getQueryParamByKey } = useQueryParams();

  const threadId = getQueryParamByKey('t');

  const dispatch = useAppDispatch();

  const { historyStatePrompt } = usePromptHistoryState();

  const { isUserRedirected, clearHistoryStateRedirect } =
    useRedirectHistoryState();

  const isWSLoading = useAppSelector((state) => state.websocket.isWSLoading);

  const askAIErrorStatus = useAppSelector(
    (state) => state.searchResult.askAIErrored?.status,
  );

  const searchMode = useAppSelector((state) => state.searchResult.mode);

  const { userGeolocation } = useAppSettings();

  const { getNamespacedQueryKey } = useWorkspace();

  const currentAudioPlayingId = useAppSelector(
    (state) => state.searchResult.currentAudioPlayingId,
  );

  const audioMetadata = useAppSelector(
    (state) => state.searchResult.audioMetadata,
  );

  const responseStatus = useAppSelector(
    (state) => state.websocket.WSMessageStatus,
  );

  const imageKey = useAppSelector((state) => state.searchResult.image);

  useBlocker(({ currentLocation, nextLocation }) => {
    if (
      currentAudioPlayingId &&
      audioMetadata &&
      audioMetadata[currentAudioPlayingId]?.isPlaying &&
      currentLocation.pathname !== nextLocation.pathname
    ) {
      dispatch({
        type: 'searchResult/audioMetadata',
        payload: {
          id: currentAudioPlayingId,
          type: 'pause',
        },
      });
    }

    return false;
  });

  useEffect(() => {
    if (threadId == null || historyStatePrompt == null || !isUserRedirected) {
      return;
    }

    dispatch({
      type: 'searchResult/askAIEmitted',
      payload: {
        thread: threadId,
        mode: searchMode,
        image: imageKey?.currentImageKey,
        prompt: historyStatePrompt,
        context: {
          latLng: [userGeolocation.lat, userGeolocation.lng],
        },
      },
    });
    dispatch(
      imageUploaded({
        threadId: imageKey?.threadId || null,
        currentImageKey: imageKey?.currentImageKey,
        previews: [],
      }),
    );
    //? revalidate threads for search in history
    queryClient.invalidateQueries({
      queryKey: getNamespacedQueryKey('threads', 'search'),
    });
    clearHistoryStateRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserRedirected, threadId]);

  if (typeof askAIErrorStatus === 'number') {
    return <SearchBar />;
  }

  if (isWSLoading) return <SmartLoading />;

  if (responseStatus === 'finished' || responseStatus == null) {
    return <SearchBar />;
  }
  return (
    <Layout
      answer={<StreamingAnswer />}
      info={<StreamingInfo />}
      followups={<StreamingFollowups />}
      prompt={<StreamingPrompt />}
      links={<StreamingLinks />}
      photos={<StreamingPhotos />}
      singlePhotosCol={<StreamingSinglePhotosCol />}
      searchForm={<SearchBar />}
    />
  );
}
