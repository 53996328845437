import { alpha, styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Stack, { StackProps } from '@mui/material/Stack';
import MuiTableCell from '@mui/material/TableCell';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 0,
  width: '100%',
  overflow: 'auto',
  borderRadius: `${theme.spacing(4, 4, 0, 0)}!important`,
}));

export const StyledMuiTableHeadCell = styled(MuiTableCell)(({ theme }) => ({
  position: 'relative',
  '&:not(:last-child)::after': {
    content: '""',
    width: theme.spacing(0.25),
    height: theme.spacing(5.5),
    backgroundColor: theme.palette.divider,
    display: 'block',
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.light,
  color: theme.palette.text.secondary,
  border: '1px solid',
  borderColor: theme.palette.divider,
  marginInlineStart: theme.spacing(-3), //? overlap avatar with previous avatar
}));

export const StyledStackedMemberships = styled(Stack, {
  shouldForwardProp: (props) => props !== 'isCollected',
})<StackProps & { isCollected: boolean }>(({ theme, isCollected }) => {
  const isThemeDark = theme.palette.mode === 'dark';
  const color = isThemeDark
    ? theme.palette.common.white
    : theme.palette.common.black;

  return {
    display: 'inline-flex',
    ...(isCollected && {
      position: 'relative',
      '&::after': {
        content: '""',
        top: 0,
        marginLeft: theme.spacing(-3),
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: alpha(theme.palette.background.default, 0.8),
        position: 'absolute',
        zIndex: 1,
      },
      '&::before': {
        content: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          color,
        )}" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2M9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9zm9 14H6V10h12zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2"/></svg>')`,
        position: 'absolute',
        zIndex: 2,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    }),
  };
});
