import { isRouteErrorResponse,useRouteError } from 'react-router-dom';

import ErrorTemplate from '@components/error-template';

import { useTranslation } from '@desygner/ui-common-translation';

const WIDTH = 504;
const HEIGHT = 264;

export default function Error() {
  const error = useRouteError();

  const { t } = useTranslation();

  console.error(error);

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <ErrorTemplate
        title={t('response.errors.pages.notFound.title', {
          defaultValue: 'Page not found',
        })}
        subtitle={t('response.errors.pages.notFound.subtitle', {
          defaultValue: 'The page you are looking for does not exist.',
        })}
        img={
          <img
            height={HEIGHT}
            width={WIDTH}
            src={'https://assets.knowz.com/assets/svg/404.svg'}
            alt="page not found"
          />
        }
      />
    );
  }

  if (isRouteErrorResponse(error) && error.status === 401) {
    return (
      <ErrorTemplate
        title={t('response.errors.pages.unauthorized.title', {
          defaultValue: 'Access Denied. You are not authorized.',
        })}
        subtitle={t('response.errors.pages.unauthorized.subtitle', {
          defaultValue:
            'Looks like you do not have permission to access this page.',
        })}
        img={
          <img
            height={HEIGHT}
            width={WIDTH}
            src={'https://assets.knowz.com/assets/svg/401.svg'}
            alt="unauthorized"
          />
        }
      />
    );
  }

  return (
    <ErrorTemplate
      title={t('response.errors.pages.serverError.title', {
        defaultValue: 'Uh oh!  There is a glitch in the matrix.',
      })}
      subtitle={t('response.errors.pages.serverError.subtitle', {
        defaultValue:
          'We are working to fix it. Check back soon for everything to be back to normal.',
      })}
      img={
        <img
          height={HEIGHT}
          width={WIDTH}
          src={'https://assets.knowz.com/assets/svg/500.svg'}
          alt="server side error"
        />
      }
    />
  );
}
