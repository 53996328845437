import axiosApiInstance from '@lib/agent/services/api/axiosInstance';

import { SetupModeType } from '@providers/WorkspaceProvider';

import { MembershipType } from '@shared-types/memberships';
import { WorkspaceResponseType } from '@shared-types/workspace';

export function setReadingOwnerHeader(
  workspace: WorkspaceResponseType | null,
  membership: MembershipType | null | undefined,
  setupMode: SetupModeType,
) {
  const headers = axiosApiInstance.defaults.headers.get;

  if (workspace) {
    const owners = [workspace.id];
    if (setupMode === 'off' && membership) owners.push(membership.id);
    headers['Knowz-Reading-Owners'] = owners.join(',');
  } else {
    delete headers['Knowz-Reading-Owners'];
  }
}

export function setCreationOwnerHeader(
  workspace: WorkspaceResponseType | null,
  membership: MembershipType | null | undefined,
  setupMode: SetupModeType,
) {
  const methodHeaders = [
    axiosApiInstance.defaults.headers.post,
    axiosApiInstance.defaults.headers.put,
    axiosApiInstance.defaults.headers.patch,
    axiosApiInstance.defaults.headers.delete,
  ];

  if (workspace) {
    methodHeaders.forEach((headers) => {
      headers['Knowz-Creation-Owner'] =
        setupMode === 'off' && membership ? membership.id : workspace.id;
    });
  } else {
    methodHeaders.forEach((headers) => {
      delete headers['Knowz-Creation-Owner'];
    });
  }
}
