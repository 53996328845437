//TODO: for translation service we will have an issue with Yup validation messages
import * as Yup from 'yup';

const renameSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(60, 'Max length is 60 characters')
    .required('Name is required'),
});

export default renameSchema;
