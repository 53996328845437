import { alpha, styled } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export const StyledGradientBackgroundBox = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  cursor: 'default',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  paddingInline: theme.spacing(4),
  paddingBlock: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  background: `
      linear-gradient(
        to right,
        rgba(83, 177, 237, 0.17),
        rgba(137, 95, 242, 0.17),
        rgba(30, 26, 249, 0.17)
      )
    `, //TODO: design system
  flexGrow: 1,
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  height: 'auto',
  backgroundColor: alpha(theme.palette.background.paper, 0.1),
  paddingBlock: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.textSm.fontSize,
  lineHeight: theme.typography.textSm.lineHeight,
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'background-color 1s ease',
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
  flexGrow: 1,
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing(2.5),
}));

export const StyledSettingsStack = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  alignItems: 'center',
  color: theme.palette.text.disabled,
  paddingBlock: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadius * 2,
  paddingInline: theme.spacing(3.5),
  gap: theme.spacing(1.5),
  transition: 'background-color 1s ease',
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.secondary, 0.03),
    color: alpha(theme.palette.text.primary, 0.8),
  },
  '&:hover svg path': {
    stroke: alpha(theme.palette.text.primary, 0.8),
  },
}));
