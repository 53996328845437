import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiMenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

import {
  StyledLi,
  StyledStack,
} from '@components/pages/live-session/sections/content/components/transcript/card/dropdown/menu-item/styles';
import Text from '@components/text';

import HelpRoundedIcon from '~icons/knowz-iconify/help-rounded';
import UserIcon from '~icons/knowz-iconify/user';
import WaveIcon from '~icons/knowz-iconify/wave';

type Props = {
  voiceMatch: {
    name: string | null;
    speaker?: string;
    toggleModal: VoidFunction;
  };
};

export default function MenuItem({
  voiceMatch: { toggleModal, name, speaker },
}: Props) {
  return (
    <>
      <StyledLi component="li">
        <Stack
          direction="row"
          gap={3}
          sx={{
            alignItems: 'center',
          }}
        >
          <ListItemIcon>
            <StyledStack>
              <UserIcon />
            </StyledStack>
          </ListItemIcon>
          <Stack>
            <Text
              variant="textSm"
              weight="semibold"
            >
              {name ?? speaker}
            </Text>
            {/* <Text variant="textXs">Unknown</Text> //TODO: setup later with other forms of Voice Match */}
          </Stack>
        </Stack>
      </StyledLi>
      <Divider />
      <MuiMenuItem
        sx={{ py: 3 }}
        onClick={toggleModal}
      >
        <ListItemIcon>
          <WaveIcon />
        </ListItemIcon>
        <Stack
          gap={3}
          direction="row"
        >
          <ListItemText
            primaryTypographyProps={{
              variant: 'textSm',
            }}
            primary="Voice Match Speaker"
          />
          <ListItemIcon>
            <HelpRoundedIcon />
          </ListItemIcon>
        </Stack>
      </MuiMenuItem>
    </>
  );
}
