import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { clarity } from 'react-microsoft-clarity';

export function initializeGA() {
  ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_ID);
}

export function initializeGTM() {
  TagManager.initialize({
    gtmId: import.meta.env.VITE_GTM_ID,
    auth: import.meta.env.VITE_GTM_AUTH,
    preview: import.meta.env.VITE_GTM_PREVIEW,
  });
}

export function initializeClarity() {
  clarity.init(import.meta.env.VITE_CLARITY_ID);
}
