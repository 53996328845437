import { Theme } from '@mui/material';

export default function ImageListItem(theme: Theme) {
  return {
    MuiImageListItem: {
      styleOverrides: {
        root: {
          img: {
            borderRadius: theme.shape.borderRadius * 2,
          },
        },
      },
    },
  };
}
