export default function Toolbar() {
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0!important',
        },
      },
    },
  };
}
