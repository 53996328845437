import CommandDesigner from '@components/pages/command-designer';
import { StyledBgGradient } from '@components/pages/styles';

export default function CommandDesignerPage() {
  return (
    <>
      <StyledBgGradient aria-hidden />
      <CommandDesigner />
    </>
  );
}
