export function getClientHeight(node: HTMLDivElement) {
  const rects = node.getClientRects();

  if (rects.length && rects[0].height) {
    return rects[0].height;
  }

  return node.clientHeight;
}

export type SizesMapperType = {
  averageSize: number | null;
  knownSizes: Map<number, number>;
};

export function getVisibleItemsWindow(
  list: HTMLDivElement | null,
  totalItems: number,
  sizes: SizesMapperType,
  minBlockHight: number,
) {
  if (!list) return { firstVisibleItem: -1, lastVisibleItem: -1 };
  const scrollHeight = list.scrollHeight;
  const scrollTop = list.scrollTop;
  const clientHeight = list.clientHeight;

  // TODO: performance can easily be improved (e.g. infer closer numbers and use averages)
  let firstVisibleItem = -1;
  let itemPosition = 0;

  const avgSize = sizes.averageSize ?? minBlockHight;
  const absScrollTop = scrollTop > 0 ? scrollTop : -scrollTop; // Review this if we want to support natural scroll (not revert)
  const absScrollBottom = absScrollTop + clientHeight;

  do {
    firstVisibleItem++;
    itemPosition += sizes.knownSizes.get(firstVisibleItem) ?? avgSize;
  } while (itemPosition < absScrollTop && firstVisibleItem < totalItems - 1);

  let lastVisibleItem = totalItems;
  itemPosition = scrollHeight;

  do {
    lastVisibleItem--;
    itemPosition -= sizes.knownSizes.get(lastVisibleItem) ?? avgSize;
  } while (
    itemPosition > absScrollBottom &&
    lastVisibleItem > firstVisibleItem
  );

  return { firstVisibleItem, lastVisibleItem };
}
