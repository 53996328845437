import Text from '@components/text';

import { useTranslation } from '@desygner/ui-common-translation';

export default function Transcript() {
  const { t } = useTranslation();
  return (
    <Text variant="textMd">
      {t('comingSoon', {
        defaultValue: 'Coming soon',
      })}
    </Text>
  );
}
