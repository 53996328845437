import { useRef, useState } from 'react';

import Box from '@mui/material/Box';

import { LOW_CREDIT_PERCENTAGE } from '@layouts/components/credit-usage/credit-quantity';
import {
  StyledMuiCircularProgress,
  StyledStarIcon,
} from '@layouts/main/sections/header/sections/user-account-popover/credit-circular-progress-with-avatar/circular-progress/styles';

type Props = {
  size: number;
  value?: number;
};

const SHOW_START_ICON_SIZE_THRESHOLD = 50;
const THICKNESS = 4.75;
const START_OFFSET = {
  X: -3,
  Y: 2,
};
const ICON_CENTER = {
  X: 19.19 / 2,
  Y: 18.28 / 2,
};

export default function CircularProgress({ value, size }: Props) {
  const circularProgressRef = useRef<SVGGeometryElement | null>(null);
  const sizeAttributes = {
    width: `${size}px!important`,
    height: `${size}px!important`,
  };

  if (value === undefined) {
    return (
      <StyledMuiCircularProgress
        sx={sizeAttributes}
        variant="indeterminate"
      />
    );
  }

  const showStartIcon = size > SHOW_START_ICON_SIZE_THRESHOLD && value > 5;
  const r = size / 2;
  const p = value / 100;
  const rad = p * 2 * Math.PI;
  const sin = Math.sin(rad);
  const cos = Math.cos(rad);
  const x = r + sin * r + sin * START_OFFSET.X - ICON_CENTER.X;
  const y = r - cos * r + cos * START_OFFSET.Y - ICON_CENTER.Y;

  const colors =
    value < LOW_CREDIT_PERCENTAGE
      ? { start: '#FF1E1E', stop: '#FF48A0' }
      : { start: '#36BFFA', stop: '#0040C1' };

  return (
    <Box sx={{ position: 'relative' }}>
      {showStartIcon && <StyledStarIcon sx={{ top: y, left: x }} />}
      <StyledMuiCircularProgress
        variant="determinate"
        sx={{
          ...sizeAttributes,
          color: 'background.card.main',
        }}
        thickness={THICKNESS}
        value={100}
      />
      <svg
        width={0}
        height={0}
      >
        <defs>
          <linearGradient
            id="credit-gradient"
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={colors.start}
            />
            <stop
              offset="100%"
              stopColor={colors.stop}
            />
          </linearGradient>
        </defs>
      </svg>

      <StyledMuiCircularProgress
        variant="determinate"
        ref={circularProgressRef}
        value={value < 1 ? 1 : value}
        thickness={THICKNESS}
        sx={{
          ...sizeAttributes,
          'svg circle': { stroke: 'url(#credit-gradient)' },
          strokeLinecap: 'round',
        }}
      />
    </Box>
  );
}
