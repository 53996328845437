import { memberships } from '@lib/agent';

import useWorkspace from '@hooks/useWorkspace';

import { TFunction, useTranslation } from '@desygner/ui-common-translation';

import { SourceConfigurationType } from '@shared-types/sources';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

export default useCreateMembershipAliasOnSSO;

export function useCreateMembershipAliasOnSSO() {
  const { t } = useTranslation();
  const { workspace } = useWorkspace();

  return useMutation({
    mutationFn: createMembershipAliasOnSSO,
    mutationKey: ['createMembershipAliasOnSSO', workspace?.id],
    onError: (error) => onError(t, error),
  });
}

async function createMembershipAliasOnSSO(body: SourceConfigurationType) {
  return await memberships.createMembershipAliasOnSSO(body);
}

function onError(t: TFunction<'translation', undefined>, error: unknown) {
  toast.error(
    t('response.errors.code.520', {
      defaultValue: 'Unknown error. Please try again later.',
    }),
  );
  console.error(error);
}
