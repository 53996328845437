import { useEffect, useRef } from 'react';

import { useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import 'chartjs-adapter-date-fns';

import {
  Chart,
  ChartData,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import { format } from 'date-fns';

type Props = {
  title?: string;
  data: ChartData;
} & BoxProps;

// matrix https://chartjs-chart-matrix.pages.dev/integration.html
Chart.register(LinearScale, MatrixController, MatrixElement, TimeScale);
Chart.register(Tooltip, Title);

export default function MatrixChart(props: Props) {
  const { data, title, ...rest } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);
  const theme = useTheme();

  useEffect(() => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (ctx) {
      chartRef.current = new Chart(ctx, {
        type: 'matrix',
        data,
        options: {
          aspectRatio: 1.8,
          scales: {
            y: {
              type: 'time',
              offset: true,
              time: {
                unit: 'day',
                round: 'day',
                isoWeekday: 1,
                parser: 'i',
                displayFormats: {
                  day: 'iiiiii',
                },
              },
              reverse: true,
              position: 'right',
              ticks: {
                maxRotation: 0,
                autoSkip: true,
                padding: 1,
                font: {
                  size: 9,
                },
              },
              grid: {
                display: false,
                // drawBorder: false,
                tickLength: 0,
              },
            },
            x: {
              type: 'time',
              position: 'bottom',
              offset: true,
              time: {
                unit: 'week',
                round: 'week',
                isoWeekday: 1,
                displayFormats: {
                  week: 'MMM dd',
                },
              },
              ticks: {
                maxRotation: 0,
                autoSkip: true,
                font: {
                  size: 9,
                },
              },
              grid: {
                display: false,
                // drawBorder: false,
                tickLength: 0,
              },
            },
          },
          plugins: {
            title: {
              display: !!title,
              text: title,
              color: theme.palette.text.primary,
            },
            legend: {
              display: false,
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                title() {
                  return '';
                },
                label(context) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  const { v, d } = context.dataset.data[context.dataIndex]; //TODO
                  const date = format(new Date(d), 'MMM dd, yyyy');

                  if (v === 0) return `${date}: no activity`;
                  if (v < 0.2) return `${date}: low activity`;
                  if (v < 0.5) return `${date}: medium activity`;
                  if (v < 0.7) return `${date}: high activity`;
                  return `${date}: very high activity`;
                },
              },
            },
          },
        },
      });
    }

    return () => {
      chartRef.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box {...rest}>
      <canvas ref={canvasRef}></canvas>
    </Box>
  );
}
