import FollowupsBlock from '@components/pages/search/components/shared-blocks/followups';

import { RootType } from '@state/store';

import { FollowupsType } from '@shared-types/search-result/types';

import { useSelector } from 'react-redux';

export default function Followups() {
  const followups = useSelector<RootType, FollowupsType>(
    (state) => state.websocket.WSResponse.searchResult.followups,
  );
  const isWSLoading = useSelector<RootType, boolean>(
    (state) => state.websocket.isWSLoading,
  );
  const WSMessageStatus = useSelector<RootType, 'started' | 'finished' | null>(
    (state) => state.websocket.WSMessageStatus,
  );

  if (followups.followupsData == null) {
    return null;
  }

  return (
    followups.followupsData.length > 0 && (
      <FollowupsBlock
        followups={followups.followupsData}
        disabled={isWSLoading || WSMessageStatus === 'started'}
      />
    )
  );
}
