import { NavLink } from 'react-router-dom';

import { styled } from '@mui/material';

const StyledLink = styled(NavLink)(() => ({
  display: 'flex',
  justifyContent: 'center',
  userSelect: 'none',
  //? the .active class comes from NavLink more here => https://beta.reactrouter.com/en/dev/start/tutorial#active-link-styling
  '&.active': {
    pointerEvents: 'none',
  },
}));

export default StyledLink;
