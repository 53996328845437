import { useMemo } from 'react';

import { Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import BasicBreadcrumb from '@components/pages/knowledge-base/components/breadcrumb/BasicBreadcrumb';
import CondensedBreadcrumb from '@components/pages/knowledge-base/components/breadcrumb/CondensedBreadcrumb';
import { BreadcrumbItemType } from '@components/pages/knowledge-base/components/breadcrumb/types';

import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';

import Cube from '~icons/knowz-iconify/cube';

const BASE_BREADCRUMB: BreadcrumbItemType = {
  name: 'Knowledge Base',
  path: '',
  icon: <Cube fontSize="1.3em" />,
};

const BREADCRUMB_HEIGHT = 35;

type Props = {
  knowledgeCapacityIndicator: React.ReactNode;
};

export default function Breadcrumb({ knowledgeCapacityIndicator }: Props) {
  const { getQueryParamByKey } = useQueryParams();

  const path = getQueryParamByKey<string | null>('path');

  const { folderData, isFolderDataPending } = useGetFolderDataByPath({
    path,
  });

  const isItRootFolder = path === null;

  const calculatedBreadcrumb: BreadcrumbItemType[] = useMemo(
    () => {
      if (isItRootFolder || folderData == null) {
        return [BASE_BREADCRUMB];
      }
      const path = folderData.path;
      const breadcrumb = folderData.breadcrumb.split('/');
      return [
        BASE_BREADCRUMB,
        ...(folderData && path.length > 0
          ? path.split('/').map((path, i) => ({
              name: breadcrumb[i] || folderData.name,
              path,
            }))
          : []),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderData?.id],
  );

  if (isFolderDataPending) {
    return (
      <Skeleton
        width={250}
        height={BREADCRUMB_HEIGHT}
      />
    );
  }

  return (
    <Stack
      direction="row"
      gap={2}
      sx={{
        height: BREADCRUMB_HEIGHT,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBlockEnd: 5,
      }}
    >
      {calculatedBreadcrumb.length > 3 ? (
        <CondensedBreadcrumb items={calculatedBreadcrumb} />
      ) : (
        <BasicBreadcrumb items={calculatedBreadcrumb} />
      )}
      {knowledgeCapacityIndicator}
    </Stack>
  );
}
