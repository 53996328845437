import { useState } from 'react';

import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

import Drawer from '@components/pages/live/sections/cards/knowz-assistant-card/drawer';
import {
  StyledChip,
  StyledGradientBackgroundBox,
  StyledSettingsStack,
  StyledStack,
} from '@components/pages/live/sections/cards/knowz-assistant-card/style';
import { StyledCard } from '@components/pages/live/styles';
import Switch from '@components/switch';
import Text from '@components/text';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useResponsive from '@hooks/useResponsive';

import { selectedAssistantsUpdated } from '@state/slices/live';

import { useTranslation } from '@desygner/ui-common-translation';

import AnnotationDots from '~icons/knowz-iconify/annotation-dots';
import Settings from '~icons/knowz-iconify/settings';

const IMAGE_SIZE = 40;

export default function KnowzAssistantCard() {
  const isDesktop = useResponsive('up', 'md');
  const theme = useTheme();
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const selectedAssistants = useAppSelector(
    (state) => state.live.selectedAssistants,
  );
  const dispatch = useAppDispatch();
  function handleDrawerToggle() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  function handleAssistantsSwitch(
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    dispatch(
      selectedAssistantsUpdated({
        factChecker: checked,
        knowzBot: checked,
      }),
    );
  }

  return (
    <StyledCard>
      <StyledChip
        label="Recommended"
        variant="outlined"
        size="small"
      />
      <Stack
        gap={4}
        mt={2}
      >
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
          gap={4}
        >
          <Stack
            direction="row"
            sx={{ alignItems: 'center', flexWrap: 'wrap' }}
            gap={3}
          >
            <img
              src="https://assets.knowz.com/assets/svg/ai-orb.svg"
              alt="knowz live"
              width={IMAGE_SIZE}
              height={IMAGE_SIZE}
              loading="lazy"
            />
            <Text
              variant="textMd"
              color="text.secondary"
            >
              {t('page.live.grids.mode.cards.knowzAssistant.title', {
                defaultValue: 'Include KnowZ Assistant',
              })}
            </Text>
          </Stack>
          <Stack
            direction="row"
            gap={5}
          >
            <Switch
              checked={Object.values(selectedAssistants).some(Boolean)}
              onChange={handleAssistantsSwitch}
            />
            <Drawer
              onClose={handleDrawerToggle}
              open={isDrawerOpen}
            />
          </Stack>
        </Stack>
        <Text
          variant="textMd"
          color="text.secondary"
        >
          {t('page.live.grids.mode.cards.knowzAssistant.description', {
            defaultValue:
              'Add KnowZ AI Assistant to any live meeting for expert advice, real-time suggestions, note-taking, and guidance. With direct access to your workspace knowledge base, KnowZ makes organizing and updating data easy.',
          })}
        </Text>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={5}
        >
          <StyledGradientBackgroundBox direction="row">
            <StyledStack direction="row">
              {isDesktop && (
                <AnnotationDots
                  color={theme.palette.primary.main}
                  fontSize="1.1em"
                  style={{
                    flexShrink: 0,
                  }}
                />
              )}
              <Text
                variant="textSm"
                alignItems="center"
              >
                {t(
                  'page.live.grids.mode.cards.knowzAssistant.summonKnowz.segment1',
                  {
                    defaultValue:
                      'Summon Knowz AI anytime during sessions by saying',
                  },
                )}
              </Text>
            </StyledStack>
            <StyledStack direction="row">
              <StyledChip label="Hey Knowz" />
              {isDesktop && (
                <>
                  <Text variant="textSm">
                    {t(
                      'page.live.grids.mode.cards.knowzAssistant.summonKnowz.segment2',
                      {
                        defaultValue: 'or just',
                      },
                    )}
                  </Text>
                  <StyledChip label="Knowz" />
                </>
              )}
            </StyledStack>
          </StyledGradientBackgroundBox>

          <StyledSettingsStack
            direction="row"
            onClick={handleDrawerToggle}
          >
            <Settings
              style={{
                fontSize: '0.8em',
              }}
            />
            {isDesktop && (
              <Text
                variant="textSm"
                weight="semibold"
              >
                {t('page.live.grids.mode.cards.knowzAssistant.CustomizeBtn', {
                  defaultValue: 'Customize',
                })}
              </Text>
            )}
          </StyledSettingsStack>
        </Stack>
      </Stack>
    </StyledCard>
  );
}
