import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonBase } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import SimpleDropdown from '@components/dropdown/simple';
import Listitem from '@components/invitation-form/components/listitem';
import WorkspaceOwner from '@components/invitation-form/components/workspace-owner';
import emailSchema from '@components/invitation-form/schema';
import FormProvider from '@components/react-hook-form/FormProvider';
import RHFInputField from '@components/react-hook-form/RHFInputField';
import Text from '@components/text';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';

import useCreateInvitation from '@hooks/useCreateInvitation';
import useQueryParams from '@hooks/useQueryParams';

import { useTranslation } from '@desygner/ui-common-translation';

import paths from '@router/paths';
import SendIcon from '~icons/knowz-iconify/send';
import PQueue from 'p-queue';
import { useForm } from 'react-hook-form';

const defaultValues = {
  email: '',
  role: 'viewer',
};

const MEMBERS_LIMIT_LENGTH = 50;

const pQ = new PQueue({ concurrency: 5 });

type Props = {
  onSubmitHandler?: VoidFunction;
  secondaryButton?: React.ReactNode;
  heading?: React.ReactNode;
  shouldShowWorkspaceOwner?: boolean;
  skippable: boolean;
};

export default function InvitationForm({
  onSubmitHandler,
  secondaryButton,
  heading,
  shouldShowWorkspaceOwner = true,
  skippable,
}: Props) {
  const { t } = useTranslation();

  const { removeQueryParamByKey } = useQueryParams();

  const [formState, setFormState] = useState<'submitting' | 'submitted' | null>(
    null,
  );

  const navigateTo = useNavigate();

  const { handleSetMemberToInvite, membersToInvite, handleReset } =
    useWorkspaceSetup();

  const methods = useForm({
    defaultValues,
  });

  const { createInvitationAsync } = useCreateInvitation();

  const shouldSkip = skippable && membersToInvite.length === 0;

  const {
    getValues,
    setValue,
    setError,
    resetField,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = methods;

  async function handleSetMembersToInvite() {
    const emailField = getValues('email');
    const role = getValues('role');

    const isEmailValid = await emailSchema.isValid({
      email: emailField,
    });

    if (emailField.length === 0) {
      return setError('email', {
        type: 'required',
        message: t(
          'page.workspace.createWorkspaceModal.step3.form.email.required',
          {
            defaultValue: 'Email is required',
          },
        ),
      });
    }

    if (!isEmailValid) {
      return setError('email', {
        type: 'validate',
        message: t(
          'page.workspace.createWorkspaceModal.step3.form.email.invalid',
          {
            defaultValue: 'Invalid email',
          },
        ),
      });
    }

    if (
      emailField &&
      membersToInvite.some((member) => member.email === emailField)
    ) {
      return setError('email', {
        type: 'validate',
        message: t(
          'page.workspace.createWorkspaceModal.step3.form.email.duplication',
          {
            defaultValue: 'This email is already in the list',
          },
        ),
      });
    }

    if (membersToInvite.length >= MEMBERS_LIMIT_LENGTH) {
      setError('email', {
        type: 'max',
        message: t('page.workspace.createWorkspaceModal.step3.form.email.max', {
          defaultValue: 'You can only add emails up to 50 members at a time',
        }),
      });
      return;
    }

    handleSetMemberToInvite({
      email: emailField,
      role,
    });

    resetField('email');
  }

  function handleSetMembersToInviteOnEnter(
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    if (e.key === 'Enter') handleSetMembersToInvite();
  }

  async function onSubmit() {
    if (shouldSkip) {
      removeQueryParamByKey('action');
      handleReset();
      navigateTo(paths.users.pathname);
      return;
    }

    setFormState('submitting');
    membersToInvite.forEach((member) => {
      pQ.add(
        async () =>
          await createInvitationAsync({
            email: member.email,
            role: member.role,
          })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              onSubmitHandler && onSubmitHandler();
            }),
      );
    });

    pQ.on('idle', () => {
      setFormState('submitted');
    });
  }

  return (
    <Container disableGutters>
      <FormProvider methods={methods}>
        {heading}
        <Stack
          gap={3}
          sx={{ width: '100%', justifyContent: 'flex-end' }}
        >
          <Stack
            direction="row"
            gap={2}
          >
            <RHFInputField
              name="email"
              fullWidth
              type="text"
              onKeyDown={handleSetMembersToInviteOnEnter}
              placeholder={t(
                'page.workspace.createWorkspaceModal.step3.form.email.placeholder',
                {
                  defaultValue: 'Ex: member@gmail.com',
                },
              )}
              endAdornment={
                <SimpleDropdown
                  disablePortal
                  trigger={{
                    element: (
                      <ButtonBase
                        color="inherit"
                        disableRipple
                        disableTouchRipple
                      >
                        {watch().role}
                        <ExpandMoreIcon />
                      </ButtonBase>
                    ),
                  }}
                  options={['owner', 'editor', 'contributor', 'viewer'].map(
                    (value) => ({
                      value: value,
                      text: <Text variant="textSm">{value}</Text>,
                      onClick: () => setValue('role', value),
                    }),
                  )}
                />
              }
            />
            <Button
              variant="contained"
              size="small"
              sx={{
                alignItems: 'center',
              }}
              disabled={!isDirty}
              onClick={handleSetMembersToInvite}
              startIcon={<SendIcon />}
            >
              {t(
                'page.workspace.createWorkspaceModal.step3.form.button.invite',
                {
                  defaultValue: 'Invite',
                },
              )}
            </Button>
          </Stack>
          {shouldShowWorkspaceOwner && <WorkspaceOwner />}
          <Listitem />
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            marginBlockStart: 6,
          }}
        >
          {secondaryButton}
          {shouldSkip ? (
            <Button
              variant="contained"
              onClick={onSubmitHandler}
            >
              {t('page.workspace.createWorkspaceModal.skipButton', {
                defaultValue: 'Skip',
              })}
            </Button>
          ) : (
            <LoadingButton
              disabled={
                formState === 'submitting' ||
                (!skippable && membersToInvite.length === 0)
              }
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              sx={{
                margin: '0 0 0 auto',
                display: 'block',
              }}
            >
              {t('page.workspace.createWorkspaceModal.step3.form.button.done', {
                defaultValues: 'Done',
              })}
            </LoadingButton>
          )}
        </Stack>
      </FormProvider>
    </Container>
  );
}
