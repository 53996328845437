import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import Modal from '@components/modal';
import {
  StyledBox,
  StyledBox2,
  StyledBox3,
  StyledStack,
  StyledStack2,
} from '@components/pages/command-designer/sections/toolbox/styles';
import {
  StyledCheckbox,
  StyledCheckboxIcon,
  StyledIntermittentCheckbox,
} from '@components/pages/styles';
import Text from '@components/text';

import { CommandBlockType } from '@lib/agent';

import { useDeleteOneCommand, useUpdateOneCommand } from '@hooks/useCommands';

import { CommandType } from '@shared-types/agent';

import { confirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';

interface AddPackageModalProps {
  id: string | undefined;
  packageModalOpen: boolean;
  setPackageModalOpen: (open: boolean) => void;
  command: CommandType | undefined;
  setActualCommand: (command: CommandType) => void;
  actualBlocks: number[];
  setActualBlocks: React.Dispatch<React.SetStateAction<number[]>>;
  blockPackages: Record<string, CommandBlockType[]> | undefined;
  updateToolboxConfiguration: (blocks: CommandBlockType[]) => void;
}

const AddPackageModal: React.FC<AddPackageModalProps> = ({
  id,
  packageModalOpen,
  setPackageModalOpen,
  command,
  setActualCommand,
  actualBlocks,
  setActualBlocks,
  blockPackages,
  updateToolboxConfiguration,
}) => {
  const theme = useTheme();

  const { mutateAsync: mutateDeleteOneCommandAsync } = useDeleteOneCommand();
  const { mutateAsync: mutateUpdateOneCommandAsync } = useUpdateOneCommand();

  const handleParentCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    packageName: string,
  ) => {
    if (event.target.checked)
      setActualBlocks([
        ...actualBlocks,
        ...blockPackages![packageName].map(
          (block: CommandBlockType) => block.id!,
        ),
      ]);
    else {
      setActualBlocks(
        actualBlocks.filter((id) => {
          return !blockPackages![packageName].some(
            (block: CommandBlockType) => id === block.id,
          );
        }),
      );
    }
    event.stopPropagation();
  };

  const handleChildCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    block: CommandBlockType,
  ) => {
    setActualBlocks((prevBlocks) =>
      event.target.checked
        ? [...prevBlocks, Number(block.id)]
        : prevBlocks.filter((id) => id !== Number(block.id)),
    );
  };

  const handleSave = async () => {
    const response = await mutateUpdateOneCommandAsync({
      id: id!,
      commandBlocks: actualBlocks,
    });

    if (response.status === 200) {
      setActualCommand(response.data);
      setPackageModalOpen(false);
      updateToolboxConfiguration(response.data.commandBlocks);
    } else {
      toast.error('Error saving packages');
    }
  };

  const handleClose = () => {
    if (command)
      setActualBlocks(
        command.commandBlocks.map(
          (commandBlock: CommandBlockType) => commandBlock.id!,
        ),
      );
    setPackageModalOpen(false);
  };

  const handleDelete = async (block: CommandBlockType) => {
    await confirm({
      title: 'Delete Block',
      description:
        'You are about to delete the block definition. ' +
        'Are you sure you want to proceed?',
    });
    mutateDeleteOneCommandAsync(block.id!);
  };

  if (!blockPackages || Object.keys(blockPackages).length === 0) {
    return (
      <Dialog
        open={packageModalOpen}
        onClose={() => handleClose()}
      >
        <DialogTitle>
          <Text variant="textLg">No blocks created yet</Text>
        </DialogTitle>
        <DialogContent>
          <Text variant="textMd">
            You need to create Customs Blocks before adding them to the toolbox.
          </Text>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Modal
      width="70vw"
      height="70vh"
      open={packageModalOpen}
      paddingType="md"
      onClose={() => handleClose()}
    >
      <StyledBox>
        <StyledBox2>
          {blockPackages &&
            Object.keys(blockPackages).map((packageName) => (
              <Accordion
                sx={{ backgroundColor: theme.palette.background.card.light }}
                key={packageName}
              >
                <AccordionSummary
                  sx={{
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <StyledStack direction={'row'}>
                    <Text
                      variant="textLg"
                      sx={{ alignSelf: 'center' }}
                    >
                      {packageName}
                    </Text>
                    <Checkbox
                      color="primary"
                      icon={<StyledCheckboxIcon />}
                      checkedIcon={<StyledCheckbox />}
                      indeterminateIcon={<StyledIntermittentCheckbox />}
                      checked={blockPackages[packageName]
                        .map((block: CommandBlockType) => block.id!)
                        .every((val) => actualBlocks.includes(val))}
                      onChange={(event) =>
                        handleParentCheckbox(event, packageName)
                      }
                      onClick={(event) => event.stopPropagation()}
                    />
                  </StyledStack>
                </AccordionSummary>
                <AccordionDetails>
                  {blockPackages[packageName].map((block: CommandBlockType) => (
                    <StyledStack2
                      key={block.id}
                      direction={'row'}
                    >
                      <Box>
                        <Text variant="textMd">{block.name}</Text>
                        <Text variant="textSm">{block.description}</Text>
                      </Box>
                      <Box>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(block)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <Checkbox
                          color="primary"
                          icon={<StyledCheckboxIcon />}
                          checkedIcon={<StyledCheckbox />}
                          indeterminateIcon={<StyledIntermittentCheckbox />}
                          checked={actualBlocks.includes(Number(block.id))}
                          onChange={(event) =>
                            handleChildCheckbox(event, block)
                          }
                        />
                      </Box>
                    </StyledStack2>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </StyledBox2>
        <StyledBox3>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={() => handleSave()}>Save</Button>
        </StyledBox3>
      </StyledBox>
    </Modal>
  );
};

export default AddPackageModal;
