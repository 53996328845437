import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import IconButton from '@mui/material/IconButton';

import { CopyToClipboard } from 'react-copy-to-clipboard';

type Props = {
  code: string;
};
function CopyButton({ code }: Props) {
  return (
    <IconButton>
      <CopyToClipboard
        text={code}
        onCopy={() => alert('Copied!')}
      >
        <ContentCopyOutlinedIcon />
      </CopyToClipboard>
    </IconButton>
  );
}

export default CopyButton;
