import { TypographyProps } from '@mui/material/Typography';

import { TextWeightType } from '@components/text/types';

const WEIGHT_MAPPINGS: Record<TextWeightType, TypographyProps['fontWeight']> = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const CONFIG = {
  WEIGHT_MAPPINGS,
} as const;

export default CONFIG;
