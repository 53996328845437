import Skeleton from '@mui/material/Skeleton';

const HEIGHT = 50;

export default function Prompt() {
  return (
    <Skeleton
      height={HEIGHT}
      variant="rounded"
    />
  );
}
