import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';

export default function useCloseDrawerOnRouteChange() {
  const { toolbarExpansionStatus, handleToggleToolbar } = useToolbar();
  const { pathname } = useLocation();

  useEffect(() => {
    if (toolbarExpansionStatus === 'on') {
      handleToggleToolbar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return null;
}
