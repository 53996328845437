import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const StyledLi = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 4),
})) as typeof Box;

export const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.main,
  borderRadius: '50%',
  width: theme.typography.pxToRem(32),
  height: theme.typography.pxToRem(32),
  justifyContent: 'center',
  alignItems: 'center',
}));
