import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import CONFIG from '@components/workspace/config';
import useWorkspaceSetup from '@components/workspace/setup/hooks/useWorkspaceSetup';
import ModalWrapper from '@components/workspace/setup/sections/modal-stepper/ModalWrapper';

export default function ModalStepper({ children }: React.PropsWithChildren) {
  const { activeStep, handleCloseModal } = useWorkspaceSetup();

  return (
    <ModalWrapper
      width="100%"
      height="100%"
    >
      <Box sx={{ textAlign: 'right' }}>
        <IconButton
          onClick={handleCloseModal}
          sx={{ marginBlockEnd: 6 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack gap={6}>
        <Stepper activeStep={activeStep}>
          {CONFIG.STEPS.map((step) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Container disableGutters>{children}</Container>
      </Stack>
    </ModalWrapper>
  );
}
