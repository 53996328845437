const DROPDOWN = {
  HEIGHT: 300,
  WIDTH: 318,
};

const INVITED_TO = {
  AUTOCOMPLETE: {
    HEIGHT: 60,
  },
};

const CONFIG = {
  DROPDOWN,
  INVITED_TO,
} as const;

export default CONFIG;
