import { sources } from '@lib/agent';
import getDataWithPagination from '@lib/getDataWithPagination';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';

import { PaginationType } from '@shared-types/pagination';

type Props = PaginationType & {
  query?: string;
  folderId: number | null;
};

export default function useGetSourcesDataByQuery({
  limit,
  page,
  folderId,
  query,
}: Props) {
  const {
    data: filteredSourcesData,
    isLoading: isFilteredSourcesDataLoading,
    isError: isFilteredSourcesDataError,
    isPending: isFilteredSourcesDataPending,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['filtered sources', { page, query, folderId }],
    queryFn: async () => {
      try {
        const { data, headers } = await sources.getAll({
          query,
          folder: folderId,
          limit,
        });

        const totalCount = headers['x-total-count'] as string;

        const dataWithPagination = getDataWithPagination({
          data,
          totalCount,
          pageParam: page,
          limit,
        });

        return dataWithPagination;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching sources: ${error}`);
      }
    },
    options: {
      enabled: !!query,
    },
  });

  return {
    filteredSourcesData,
    isFilteredSourcesDataLoading,
    isFilteredSourcesDataError,
    isFilteredSourcesDataPending,
  };
}
