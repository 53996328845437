/* eslint-disable import/prefer-default-export */
import CloseIcon from '@mui/icons-material/Close';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import Text from '@components/text';
import Textarea from '@components/textarea';

import {
  CodeBlockEditorDescriptor,
  useCodeBlockEditorContext,
} from '@mdxeditor/editor';

export const MuiCodeEditorDescriptor: CodeBlockEditorDescriptor = {
  match: () => true,
  priority: 0,
  Editor: (props) => {
    const { setCode, parentEditor, lexicalNode } = useCodeBlockEditorContext();
    const { language } = props;

    return (
      <Box
        sx={{
          my: 3,
          position: 'relative',

          '& textarea': {
            boxShadow: (theme) =>
              `0 0 0 1px ${alpha(theme.palette.brand.purple.main, 0.5)}`,
          },
        }}
        onKeyDown={(e) => {
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        {language && (
          <Text
            sx={{
              position: 'absolute',
              backgroundColor: 'background.default',
              color: (theme) => theme.palette.brand.purple.main,
              padding: (theme) => theme.spacing(0.5, 2),
              borderRadius: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(11),
              top: (theme) => '-' + theme.spacing(3),
            }}
            variant="textXs"
          >
            {language}
          </Text>
        )}

        <IconButton
          onClick={(e) => {
            e.preventDefault();
            parentEditor.update(() => {
              lexicalNode.remove();
            });
          }}
          size="small"
          sx={{
            position: 'absolute',
            backgroundColor: 'background.default',
            color: (theme) => theme.palette.brand.purple.main,
            right: (theme) => theme.spacing(2),
            top: (theme) => '-' + theme.spacing(4),
          }}
        >
          <CloseIcon />
        </IconButton>

        <Textarea
          data-gramm="false"
          data-gramm_editor="false"
          data-enable-grammarly="false"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          defaultValue={props.code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
      </Box>
    );
  },
};
