import { styled } from '@mui/material';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent!important',
  justifyContent: 'left',
  paddingInline: theme.spacing(3),
  alignItems: 'flex-start',
}));

export default StyledButton;
