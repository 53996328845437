import { NavLink } from 'react-router-dom';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import CONFIG_MAIN_LAYOUT from '@layouts/main/config';

export const StyledGlobalToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  justifyContent: 'center',
}));

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  padding: 0,
  //? the .active class comes from NavLink more here => https://beta.reactrouter.com/en/dev/start/tutorial#active-link-styling
  '&.active': {
    svg: {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.text.primary, //TODO: design system
    },
  },
}));

export const StyledToolbarBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  top: 0,
  alignItems: 'center',
  height: '100svh',
  zIndex: theme.zIndex.drawer,
}));

export const ToolbarGridContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateAreas: '"toolbar"',
  height: '100%',
}));

export const StyledExpandedToolbarGridItem = styled(Box)(() => ({
  gridArea: 'toolbar',
  alignSelf: 'center',
  height: `calc(100% - ${
    CONFIG_MAIN_LAYOUT.HEADER.PADDING_X + CONFIG_MAIN_LAYOUT.HEADER.PADDING_Y
  }px)`,
  minHeight: `calc(100% - ${
    CONFIG_MAIN_LAYOUT.HEADER.PADDING_X + CONFIG_MAIN_LAYOUT.HEADER.PADDING_Y
  }px)`,
  minWidth: 'unset!important',
})) as typeof Box;

export const StyledMiniDesktopToolbarGridItem = styled(Box)(() => ({
  gridArea: 'toolbar',
  alignSelf: 'center',
})) as typeof Box;
