import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(({ theme }) => ({
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
  position: 'fixed',
  backgroundColor: theme.palette.background.card.light,
  borderRadius: theme.shape.borderRadius * 4,
}));

export default StyledStack;
