import { auth } from '@lib/agent';

import { useTranslation } from '@desygner/ui-common-translation';

import { VerifyEmailBodyType } from '@shared-types/auth';

import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

const verifyEmail = async (data: VerifyEmailBodyType) =>
  await auth.verifyEmail(data);

export default function useVerifyEmail() {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: verifyEmail,
    onError: async (error) => {
      if (isAxiosError(error) && error.response?.status === 429) {
        toast.error(
          t('response.errors.code.429', {
            defaultValue: 'Too many requests. Please try again later.',
          }),
        );
        return;
      }
      if (isAxiosError(error) && error.response?.status === 400) {
        toast.error(
          t('response.errors.wrongCodeVerification', {
            defaultValue: 'Invalid code. Please try again.',
          }),
        );
        return;
      }
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Something went wrong. Please try again later.',
        }),
      );
      console.error(error);
    },
  });
}
