import Editor from '@components/pages/command-designer/sections/root-editor/Editor';

import Providers from '@providers/index';

import { DialogsProvider } from '@toolpad/core/useDialogs';

export default function RootEditor(props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDefinitionChange: (definition: any) => void;
  commandId: string | number;
}) {
  return (
    <Providers>
      <DialogsProvider>
        <Editor {...props}></Editor>
      </DialogsProvider>
    </Providers>
  );
}
