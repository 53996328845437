import { useState } from 'react';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import RHFInputField from '@components/react-hook-form/RHFInputField';

type Props = {
  name: string;
  disabled: boolean;
  helperText?: string;
  placeholder: string;
};

export default function PasswordField({
  name,
  disabled,
  helperText,
  placeholder,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <RHFInputField
      placeholder={placeholder}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      name={name}
      disabled={disabled}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      helperText={helperText} //TODO:
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={() => setShowPassword(!showPassword)}
            edge="end"
          >
            {showPassword ? (
              <RemoveRedEyeIcon fontSize="small" />
            ) : (
              <VisibilityOffIcon fontSize="small" />
            )}
          </IconButton>
        </InputAdornment>
      }
    />
  );
}
