import { Close } from '@mui/icons-material'; //Todo replace with knowz-icon
import SearchIcon from '@mui/icons-material/Search';
import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';

import FormProvider from '@components/react-hook-form/FormProvider';
import RHFSearchModeSelect from '@components/search-form/react-hook-form-search-mode-select';
import RHFUploadSelect from '@components/search-form/react-hook-form-upload-select';
import searchFormSchema from '@components/search-form/schema';
import {
  STAR_SIZE,
  StyledIconButton,
  StyledImage,
  StyledImageBox,
  StyledPreviewContainer,
  StyledRHFInputField,
  StyledSearchButton,
  StyledSearchFormWrapper,
  StyledStarBox,
  StyledStarStack,
} from '@components/search-form/styles';
import Text from '@components/text';

import { storage } from '@lib/agent';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useGetCredit from '@hooks/useGetCredit';

import { imageUploaded } from '@state/slices/search-result';

import { useTranslation } from '@desygner/ui-common-translation';

import { AskAISearchType } from '@shared-types/search-result/types';

import { yupResolver } from '@hookform/resolvers/yup';
import CreditUsage from '@layouts/components/credit-usage';
import CircularProgress from '@layouts/main/sections/header/sections/user-account-popover/credit-circular-progress-with-avatar/circular-progress';
import StarIcon from '~icons/knowz-iconify/star';
import { SubmitHandler, useForm } from 'react-hook-form';

type SearchFormValuesType = {
  name: string;
  mode: AskAISearchType['mode'];
  image?: string;
};

type SearchFormType = {
  onSubmit: SubmitHandler<SearchFormValuesType>;
  sx?: SxProps;
};

export default function SearchForm({ onSubmit, sx }: SearchFormType) {
  const { t } = useTranslation();

  const { creditsData } = useGetCredit();

  const mode = useAppSelector((state) => state.searchResult.mode);

  const uploadedImages = useAppSelector((state) => state.searchResult.image);

  const dispatch = useAppDispatch();

  const defaultValues: SearchFormValuesType = {
    name: '',
    mode,
    image: undefined,
  };

  const methods = useForm({
    resolver: yupResolver(searchFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
  } = methods;

  async function handleRemoveImage(removeName: string) {
    if (!uploadedImages) return;
    const { previews } = uploadedImages;
    const updatedUploadImages = previews.filter(
      (image) => image.name !== removeName,
    );

    const imageToRemove = previews.find((image) => image.name === removeName);

    dispatch(
      imageUploaded({
        threadId: uploadedImages.threadId,
        previews: updatedUploadImages,
      }),
    );

    if (!imageToRemove) return;

    await storage.deleteFile({ key: imageToRemove.key });
  }

  return (
    <StyledSearchFormWrapper sx={sx}>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit((args) => {
          onSubmit(args);
          reset();
        })}
      >
        <Stack
          direction="row"
          gap={2}
          sx={{
            alignItems: 'center',
            marginBlockEnd: 2.5,
            marginBlockStart: 1, //TODO: remove this when upload and screen implemented
            justifyContent: 'flex-end',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={3}
          >
            <CreditUsage
              variant="tooltip"
              tooltipPlacement="top"
            >
              <StyledStarStack>
                <StyledStarBox>
                  <CircularProgress
                    size={STAR_SIZE}
                    value={creditsData?.creditAsPercentage}
                  />
                </StyledStarBox>
                <StarIcon fontSize="1rem" />
              </StyledStarStack>
            </CreditUsage>

            <RHFUploadSelect />
          </Stack>

          <RHFSearchModeSelect name="mode" />
        </Stack>

        <StyledRHFInputField
          role="search"
          type="text"
          name="name"
          disabled={isSubmitting}
          autoComplete="off"
          helperText={undefined} //? we don't want to show the error message
          placeholder={t('page.home.hero.search.placeholder', {
            defaultValue: 'Ask anything...',
          })}
          endAdornment={
            <StyledSearchButton
              variant="outlined"
              color="inherit"
              endIcon={<SearchIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || isSubmitting}
              disableRipple
              disableTouchRipple
              disableFocusRipple
            >
              <Text
                component="span"
                variant="textSm"
                sx={{ display: { xs: 'none', md: 'block' } }}
              >
                {t('page.home.hero.search.button', {
                  defaultValue: 'Uncover secrets',
                })}
              </Text>
            </StyledSearchButton>
          }
        />

        {uploadedImages && uploadedImages.previews.length > 0 && (
          <StyledPreviewContainer>
            {uploadedImages.previews.map((image) => (
              <StyledImageBox key={image.name}>
                <StyledImage
                  src={image.preview}
                  alt={image.name}
                />
                <StyledIconButton
                  size="small"
                  onClick={() => handleRemoveImage(image.name)}
                >
                  <Close fontSize="small" />
                </StyledIconButton>
              </StyledImageBox>
            ))}
          </StyledPreviewContainer>
        )}
      </FormProvider>
    </StyledSearchFormWrapper>
  );
}
