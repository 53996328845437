import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';

import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';

type Props<TData, TError> = {
  queryKey: QueryKey;
  queryFn: QueryFunction<TData, QueryKey>;
  options?: Omit<
    UseQueryOptions<TData, TError, unknown, QueryKey>,
    'queryKey' | 'queryFn'
  >;
};

export default function useQueryAfterWorkspaceLoaded<TData, TError>({
  queryKey,
  queryFn,
  options,
}: Props<TData, TError>) {
  const { isLoaded: isWorkspaceLoaded, getNamespacedQueryKey } = useWorkspace();
  const { isUserAuthenticated } = useAuth();

  const enabled = options?.enabled ?? true;

  return useQuery({
    queryKey: getNamespacedQueryKey(...queryKey),
    queryFn,
    ...(options as Omit<
      UseQueryOptions<TData, TError>,
      'queryKey' | 'queryFn'
    >),
    enabled: enabled && isWorkspaceLoaded && isUserAuthenticated,
    staleTime: Infinity,
  });
}
