import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.common.white
      : theme.palette.common.black,
}));

export default StyledButton;
