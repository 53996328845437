import { KnowzStepDefinitionType } from '@lib/step/types';

const RequestStepDefinition: KnowzStepDefinitionType = {
  order: 405,
  group: 'Request',
  step: {
    componentType: 'task',
    type: 'request',
    name: 'Request',
  },
  contextTypes: [
    'ref-json',
    'ref-string',
    'ref-source',
    'ref-thread',
    'ref-history-block',
  ],
  propertyTypes: [
    {
      id: 'curl',
      name: 'cURL Command',
      description:
        'Write or paste a cURL instruction. You can inline the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'output',
      name: 'Request output',
      description:
        'Assign a name to the result of the request for later reference it in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-json'],
      isOptional: true,
    },
  ],
};

export default RequestStepDefinition;
