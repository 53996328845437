import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

import useAssistantsSession from '@layouts/assistants-session/hooks/useAssistantsSession';

type Props = {
  title: string;
};

export default function Heading({ title }: Props) {
  const { toggleHistoryDrawer } = useAssistantsSession();

  return (
    <>
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Text
          variant="textSm"
          weigh="semibold"
        >
          {title}
        </Text>
        <IconButton onClick={toggleHistoryDrawer}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider
        orientation="horizontal"
        sx={{ my: 4 }}
      />
    </>
  );
}
