import { sourceConfigurations } from '@lib/agent';

import useWorkspace from '@hooks/useWorkspace';

import { TFunction, useTranslation } from '@desygner/ui-common-translation';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

export default useDeleteSourceConfigurationConfigParams;

export function useDeleteSourceConfigurationConfigParams() {
  const { t } = useTranslation();
  const { workspace } = useWorkspace();

  return useMutation({
    mutationFn: deleteSourceConfigurationConfigParams,
    mutationKey: ['deleteOneSourceConfiguration', workspace?.id],
    onError: (error) => onError(t, error),
  });
}

async function deleteSourceConfigurationConfigParams(id: number) {
  return await sourceConfigurations.deleteOneSourceConfiguration(id);
}

function onError(t: TFunction<'translation', undefined>, error: unknown) {
  toast.error(
    t('response.errors.code.520', {
      defaultValue: 'Unknown error. Please try again later.',
    }),
  );
  console.error(error);
}
