import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';

import Text from '@components/text';

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  paddingBlockStart: theme.spacing(3),
  position: 'fixed',
  zIndex: theme.zIndex.modal,
  top: theme.spacing(22.5),
  right: theme.spacing(2.5),
  marginInlineStart: theme.spacing(2.5),
  maxWidth: theme.spacing(140),
  maxheight: theme.spacing(140),
  paddingBlockEnd: theme.spacing(3),
  overflowY: 'auto',
  border:
    theme.palette.mode === 'light'
      ? `${theme.spacing(0.5)} solid ${theme.palette.grey[100]}`
      : `${theme.spacing(0.5)} solid ${theme.palette.grey[900]}`,
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.common.white
      : theme.palette.common.black,
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));

export const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[300],
  '&:hover': {
    color: theme.palette.grey[100],
  },
}));

export const StylePreviewTitle = styled(Text)(({ theme }) => ({
  marginBlockEnd: theme.spacing(1),
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[700]
      : theme.palette.common.white,
}));

export const StyledPreviewFilePath = styled(Text)(({ theme }) => ({
  display: 'flex',
  gap: 1,
  alignItems: 'center',
  wordWrap: 'break-word',
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[600]
      : theme.palette.grey[200],
}));

export const StylePreviewText = styled(Text)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[600]
      : theme.palette.grey[200],
}));

export const StyleDismissTypography = styled(Text)(({ theme }) => ({
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[800]
      : theme.palette.grey[200],
}));

export const StyledRedirectButton = styled(Button)(({ theme }) => ({
  color: theme.palette.brand.lightBlue.main,
}));

export const StyledRedirectTypography = styled(Text)(({ theme }) => ({
  color: theme.palette.brand.lightBlue.main,
}));

export const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  fontSize: theme.typography.textMd.fontSize,
  marginInlineStart: theme.spacing(1),
  alignSelf: 'flex-start',
  color:
    theme.palette.mode === 'light'
      ? theme.palette.grey[500]
      : theme.palette.grey[200],
  '&:hover': {
    color: theme.palette.brand.lightBlue.main,
  },
}));
