import { useLocation, useNavigate } from 'react-router-dom';

import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import LoadingFolderButton from '@components/pages/knowledge-base/components/loading-folder-button';
import Text from '@components/text';

import useQueryParams from '@hooks/useQueryParams';

import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';

import { useDraggable, useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { CellContext } from '@tanstack/react-table';
import qs from 'qs';

export default function Name({
  getValue,
  row,
}: CellContext<FolderType | SourceType, unknown>) {
  const name = getValue<string>();
  const isItAFolder = 'breadcrumb' in row.original;
  const { pathname } = useLocation();
  const navigateTo = useNavigate();
  const { allQueryParams } = useQueryParams();
  const {
    attributes,
    listeners,
    transform,
    setNodeRef: setDraggableRef,
  } = useDraggable({
    id: row.original.id,
    data: {
      type: isItAFolder ? 'folder' : 'source',
      name: row.original.name,
    },
  });
  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id: row.original.id,
    data: {
      type: isItAFolder ? 'folder' : 'source',
      name: row.original.name,
    },
  });

  const setNodeRef = (node: HTMLElement | null) => {
    setDraggableRef(node);
    setDroppableRef(node);
  };

  if (isItAFolder) {
    const path = (row.original as FolderType).path;

    return (
      <Stack
        direction="row"
        gap={2}
        {...attributes}
        ref={setNodeRef}
        {...listeners}
        sx={{
          opacity: isOver ? 0.5 : 1,
          cursor: 'pointer',
          transform: CSS.Translate.toString(transform),
        }}
      >
        <LoadingFolderButton
          onClick={() => {
            navigateTo({
              pathname,
              search: qs.stringify({
                ...allQueryParams,
                page: 1, //? reset page to 1 when folder is clicked
                path,
              }),
            });
          }}
        >
          <Stack
            direction="row"
            gap={1.5}
            sx={{
              alignItems: 'center',
            }}
          >
            {isItAFolder && <FolderOutlinedIcon sx={{ fontSize: '1.5em' }} />}
            <Text variant="textSm">{name}</Text>
          </Stack>
        </LoadingFolderButton>
      </Stack>
    );
  }

  //? then it is a file
  return (
    <Box
      {...attributes}
      ref={setNodeRef}
      {...listeners}
      sx={{
        cursor: 'pointer',
        transform: CSS.Translate.toString(transform),
      }}
    >
      {name}
    </Box>
  );
}
