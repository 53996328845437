import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import MuiTooltip from '@mui/material/Tooltip';

import Content from '@components/pages/knowledge-base/components/capacity-indicator/tooltip/content';
import Heading from '@components/pages/knowledge-base/components/capacity-indicator/tooltip/heading';
import ProgressBar from '@components/pages/knowledge-base/components/capacity-indicator/tooltip/progress-bar';

import useQueryParams from '@hooks/useQueryParams';

type Props = {
  children: React.ReactElement;
};

export default function Tooltip({ children }: Props) {
  const { setQueryParam } = useQueryParams();

  function handleOpenUpgradeAccountModal() {
    setQueryParam('action', 'upgrade-account');
  }

  return (
    <MuiTooltip
      enterTouchDelay={0}
      placement="bottom"
      title={
        <ButtonBase
          disableRipple
          disableTouchRipple
          onClick={handleOpenUpgradeAccountModal}
        >
          <Stack
            gap={2}
            sx={{
              p: 1.5,
              textAlign: 'left',
            }}
          >
            <Heading />
            <ProgressBar />
            <Content />
          </Stack>
        </ButtonBase>
      }
      arrow
    >
      {children}
    </MuiTooltip>
  );
}
