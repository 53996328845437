import { auth } from '@lib/agent';

import { useTranslation } from '@desygner/ui-common-translation';

import { SendEmailVerificationBodyType } from '@shared-types/auth';

import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

const sendEmailVerification = async (data: SendEmailVerificationBodyType) =>
  await auth.sendEmailVerification(data);

export default function useSendEmailVerification() {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: ['sendEmailVerification'],
    mutationFn: sendEmailVerification,
    onError: (error) => {
      if (
        isAxiosError(error) &&
        error.response &&
        error.response.status >= 500 &&
        error.response.status < 600
      ) {
        toast.error(
          t('response.errors.code.520', {
            defaultValue: 'Something went wrong. Please try again later.',
          }),
        );
      }
      console.error(error);
    },
  });
}
