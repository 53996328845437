// TODO: too many ts errors
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';

import FormField from '@components/pages/sso/components/FormField';
import FormProvider from '@components/react-hook-form/FormProvider';

// import { CommandTriggerType } from '@shared-types/agent'; TODO
import { DialogProps } from '@toolpad/core';
import { useForm } from 'react-hook-form';

export default function TriggerPicker({
  open,
  onClose,
  payload: eventType,
}: DialogProps) {
  const formMethods = useForm<{ id: number }>();
  const { handleSubmit } = formMethods;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function onSubmit(data: any) {
    if (data.id) data.id = parseInt(data.id);
    if (data.threadId) data.threadId = parseInt(data.threadId);

    onClose({ eventType, data });
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => onClose()}
    >
      <FormProvider
        methods={formMethods}
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle>
          {eventType.charAt(0).toUpperCase() + eventType.slice(1)} Trigger
          options
        </DialogTitle>

        <DialogContent>
          {eventType === 'source' && (
            <FormGroup>
              <FormField
                element="text"
                name="id"
                label="Source ID"
              />
            </FormGroup>
          )}

          {eventType === 'semantic' && (
            <FormGroup>
              <FormField
                element="text"
                name="id"
                label="History Block ID"
              />
              <FormField
                element="text"
                name="threadId"
                label="Thread ID"
              />
            </FormGroup>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => onClose()}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Trigger Command
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
