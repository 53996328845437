import SourcePreviewButton from '@components/source-preview/components/source-preview-button';
import SourcePreviewCard from '@components/source-preview/components/source-preview-card';

type Props = {
  sourceId?: number;
  shouldShowButton?: boolean;
};

export default function SourcePreview({
  sourceId,
  shouldShowButton = false,
}: Props) {
  return (
    <>
      {shouldShowButton && <SourcePreviewButton sourceId={sourceId} />}
      <SourcePreviewCard />
    </>
  );
}
