/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

import CONFIG from '@components/pages/assistants/config';

export const StyledStack = styled(Stack)(({ theme }) => ({
  width: CONFIG.ASSISTANTS.DRAWER.MOBILE.WIDTH,
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    width: CONFIG.ASSISTANTS.DRAWER.DESKTOP.WIDTH,
  },
}));
