import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 600],
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius * 100,
  height: theme.spacing(10),
  width: theme.spacing(10),
  display: 'flex',
  fontSize: '1.2em',
})) as typeof ListItem;

export const StyledAddWorkspaceIcon = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
  color: theme.palette.primary.light,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius * 100,
}));
