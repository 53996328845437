/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';

import {
  CreditUsageResponseType,
  StripeRequestParamsType,
} from '@shared-types/agent';
import { SubscriptionResponseType } from '@shared-types/payment';

export const payments = {
  getCredit: () =>
    requests(axiosApiInstance).get<CreditUsageResponseType>(
      '/v1/entities/credits',
    ),
  getStripePaymentLink: ({ priceId, mode }: StripeRequestParamsType) =>
    requests(axiosApiInstance).get<{ url: string }>(
      `/v1/payment/gateway/stripe/checkout-session?price_id=${priceId}&mode=${mode}`,
    ),
  getStripePortalLink: () =>
    requests(axiosApiInstance).get<{ url: string }>(
      '/v1/payment/gateway/stripe/portal-session',
    ),
  getSubscription: () =>
    requests(axiosApiInstance).get<SubscriptionResponseType>(
      '/v1/entities/subscription/me',
    ),
};
