import * as Yup from 'yup';

const verifyName = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .matches(/^(?!knowz ai)/, {
      message: 'Name cannot be "Knowz AI"',
    }), //TODO: translate
});

export default verifyName;
