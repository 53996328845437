import { Theme } from '@mui/material';

export default function Tooltip(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  const bgColor = isLight
    ? theme.palette.common.black
    : theme.palette.common.white;
  const color = isLight
    ? theme.palette.common.white
    : theme.palette.common.black;

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: theme.spacing(2.5),
          backgroundColor: bgColor,
          color,
          boxShadow: theme.customShadows.z8,
          maxWidth: 330,
          fontSize: theme.typography.textXs.fontSize,
          fontWeight: theme.typography.fontWeightSemibold,
          borderRadius: theme.spacing(3),
        },
        arrow: {
          color: bgColor,
        },
      },
    },
  };
}
