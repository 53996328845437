import { styled } from '@mui/material';

const StyledClickableImage = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius * 2,
  height: '100%',
  width: '100%',
}));

export default StyledClickableImage;
