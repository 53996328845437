import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';

import useRoomMetadata from '@components/pages/live-session/hooks/useRoomMetadata';
import Card from '@components/pages/live-session/sections/content/components/feed/list-item/card';
import Selector from '@components/pages/live-session/sections/content/components/feed/selector';
import HugeScrollableList from '@components/pages/live-session/sections/content/components/huge-scrollable-list';
import ContentLayout from '@components/pages/live-session/sections/content/content-layout';

import useAppSelector from '@hooks/useAppSelector';

const HEIGHT_OF_FEED_HEADER = 59;

export default function Feed() {
  const feeds = useAppSelector((state) => state.liveSession.feeds);
  const { roomStatus } = useRoomMetadata();

  return (
    <ContentLayout headerSlot={<Selector />}>
      {roomStatus === 'loading' && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: `calc(100% - ${HEIGHT_OF_FEED_HEADER}px)` }}
        >
          <LinearProgress sx={{ width: '60%' }} />
        </Stack>
      )}
      {feeds.length === 0 && (
        <Stack
          sx={{
            width: '100%',
            height: `calc(100% - ${HEIGHT_OF_FEED_HEADER}px)`,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src="https://assets.knowz.com/assets/svg/feed--empty-state.svg"
            alt="feed"
            width={200}
            height={200}
          />
        </Stack>
      )}

      <HugeScrollableList
        sx={{ maxHeight: `calc(100% - ${HEIGHT_OF_FEED_HEADER}px)`, px: 1 }}
        gap={3}
        totalItems={feeds.length}
        minBlockHight={50}
        renderItem={({ index }) => {
          const feed = feeds[feeds.length - index - 1];
          if (!feed) return <Box />;

          return (
            <Card
              {...feed}
              key={`${feed.blockId}-${feeds.length - index}`}
            />
          );
        }}
      />
    </ContentLayout>
  );
}
