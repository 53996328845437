export default class ImageUpload {
  threadId: string;
  previews: Array<{
    name: string;
    type: string;
    size: number;
    preview: string;
    key: string;
  }>;

  constructor(
    threadId: string,
    previews: Array<{
      name: string;
      type: string;
      size: number;
      preview: string;
      key: string;
    }>,
  ) {
    this.threadId = threadId;
    this.previews = previews;
  }
}
