import { alpha } from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';

import RenameModal from '@components/pages/knowledge-base/components/modals/rename';

import useQueryParams from '@hooks/useQueryParams';

import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';

import { flexRender, Row as ReactTableRow } from '@tanstack/react-table';

export default function Row({
  getVisibleCells,
  original,
}: ReactTableRow<FolderType | SourceType>) {
  const { getQueryParamByKey } = useQueryParams();
  const highlightedId = getQueryParamByKey<string>('source');
  const canBeHighlighted = String(original.id) === highlightedId;
  const isOptimisticallyAdded = original.inCache;
  const isItAFolder = 'breadcrumb' in original;

  return (
    <MuiTableRow
      sx={{
        backgroundColor: canBeHighlighted
          ? (theme) => alpha(theme.palette.info.main, 0.3)
          : undefined,
        ...(isOptimisticallyAdded && {
          pointerEvents: 'none',
          opacity: 0.5,
        }),
      }}
    >
      <RenameModal
        name={original.name}
        id={original.id}
        type={isItAFolder ? 'folder' : 'source'}
      />
      {getVisibleCells().map((cell) => {
        return (
          <MuiTableCell key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </MuiTableCell>
        );
      })}
    </MuiTableRow>
  );
}
