import { Skeleton } from '@mui/material';

import Slot from '@components/pages/knowledge-base/components/table/action-drawer/panels/details/Slot';

const HEIGHT_SIZE = 30;

export default function LoadingSkeleton() {
  return (
    <Slot
      name={
        <Skeleton
          variant="text"
          width={100}
          height={HEIGHT_SIZE}
        />
      }
      format={
        <Skeleton
          variant="text"
          width={80}
          height={HEIGHT_SIZE}
        />
      }
      createdAt={
        <Skeleton
          variant="text"
          width={80}
          height={HEIGHT_SIZE}
        />
      }
      createdBy={
        <Skeleton
          variant="text"
          width={85}
          height={HEIGHT_SIZE}
        />
      }
      location={
        <Skeleton
          variant="text"
          width={100}
          height={HEIGHT_SIZE}
        />
      }
      size={
        <Skeleton
          variant="text"
          width={70}
          height={HEIGHT_SIZE}
        />
      }
      updatedAt={
        <Skeleton
          variant="text"
          width={100}
          height={HEIGHT_SIZE}
        />
      }
    />
  );
}
