import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

import RHFInputField from '@components/react-hook-form/RHFInputField';

export const STAR_SIZE = 26;

export const StyledStarStack = styled(Stack)(() => ({
  width: STAR_SIZE,
  height: STAR_SIZE,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledStarBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  height: STAR_SIZE,
  width: STAR_SIZE,
}));

export const StyledSearchButton = styled(LoadingButton)(({ theme }) => ({
  '&, &:hover': {
    backgroundColor: alpha(theme.palette.neutral.paper, 0.1),
  },
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.common.white
      : theme.palette.common.black,
  border: `1px solid ${
    theme.palette.mode === 'dark'
      ? alpha(theme.palette.common.white, 0.06)
      : alpha(theme.palette.common.black, 0.06)
  }`,
  margin: theme.spacing(1),
  boxSizing: 'border-box',
  height: theme.spacing(10),
  whiteSpace: 'nowrap',
  fontSize: theme.typography.textSm.fontSize,
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius * 10,
  minWidth: theme.spacing(42.25),
  [theme.breakpoints.down('md')]: {
    minWidth: theme.spacing(10),
  },
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(1),
  },
  padding: '0!important',
  '.MuiButton-endIcon': {
    margin: 0,
  },
}));

export const StyledSearchFormWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: '1px solid',
  borderColor: theme.palette.background.card.main,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 6,
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
});

export const StyledRHFInputField = styled(RHFInputField)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 10,
  paddingInlineEnd: 0,
  paddingInlineStart: theme.spacing(2),
  height: theme.spacing(12),
  backgroundColor:
    theme.palette.mode === 'dark'
      ? alpha(theme.palette.common.white, 0.05)
      : alpha(theme.palette.common.black, 0.06),
}));

export const StyledPreviewContainer = styled(Stack)(({ theme }) => ({
  flexWrap: 'wrap',
  marginBlockStart: theme.spacing(1),
  marginBlockEnd: theme.spacing(0.5),
  gap: theme.spacing(0.25),
}));

export const StyledImageBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: theme.spacing(35),
  height: theme.spacing(25),
  overflow: 'hidden',
  marginBlockStart: theme.spacing(1),
  borderRadius: theme.shape.borderRadius * 4,
  borderColor: alpha(theme.palette.common.white, 0.5),
  borderStyle: 'solid',
}));

export const StyledImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'fill',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-0.5),
  right: theme.spacing(-0.5),
  padding: theme.spacing(0.5),
  width: theme.spacing(7),
  height: theme.spacing(7),
  marginInline: theme.spacing(0.5),
  marginBlock: theme.spacing(0.5),
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.common.white,
  border: `${theme.spacing(0.5)} solid ${alpha(
    theme.palette.common.white,
    0.5,
  )}`,
  '&:hover': {
    backgroundColor: alpha(theme.palette.error.main, 0.9),
  },
}));
