import Stack from '@mui/material/Stack';

import AssistantsSessionLayout from '@layouts/assistants-session';
import FullScreenLayout from '@layouts/full-screen';
import KnowledgeBaseLayout from '@layouts/knowledge-base';
import MainLayout from '@layouts/main';
import Header from '@layouts/main/sections/header';
import ToolbarToggle from '@layouts/main/sections/toolbar/components/toolbar-toggle';

type Props = React.PropsWithChildren<{
  variant?: 'main' | 'assistants-session' | 'knowledge-base' | 'full-screen';
}>;

export default function Layout({ variant, children }: Props) {
  if (variant === 'main') {
    return <MainLayout>{children}</MainLayout>;
  }

  if (variant === 'assistants-session') {
    return <AssistantsSessionLayout>{children}</AssistantsSessionLayout>;
  }

  if (variant === 'knowledge-base') {
    return <KnowledgeBaseLayout>{children}</KnowledgeBaseLayout>;
  }

  if (variant === 'full-screen') {
    return <FullScreenLayout>{children}</FullScreenLayout>;
  }

  return (
    <Stack>
      <Header ToolbarToggle={<ToolbarToggle />} />
      {children}
    </Stack>
  );
}
