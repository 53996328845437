import { useContext } from 'react';

import { AssistantsDrawerContext } from '@components/pages/assistants/providers/AssistantsDrawerProvider';

export default function useAssistantsDrawer() {
  const context = useContext(AssistantsDrawerContext);

  if (!context) {
    throw new Error(
      'useAssistantsDrawer must be used within a AssistantsDrawerContext',
    );
  }

  return context;
}
