import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';

import { CellContext } from '@tanstack/react-table';

export default function Location({
  row,
}: CellContext<FolderType | SourceType, unknown>) {
  const isItAFolder = 'breadcrumb' in row.original;
  const source = row.original as SourceType;
  const knowzSource = isItAFolder || source.sourceConfiguration == null;

  return (
    <span>{knowzSource ? 'Knowz' : source.sourceConfiguration?.handler}</span>
  );
}
