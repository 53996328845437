/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';

import {
  LiveLiveRequestType,
  LiveLiveResponseType,
  TrainingSetsRequestType,
  TrainingSetsResponseType,
  UpdateSpeakerNameByReferenceRequestType,
} from '@shared-types/live';

export const live = {
  createLiveToken: (body: LiveLiveRequestType) =>
    requests(axiosApiInstance).post<LiveLiveResponseType>(
      '/v1/actions/live/side-kick',
      body,
    ),
  getTrainingSetByAlias: ({ alias }: TrainingSetsRequestType) =>
    requests(axiosApiInstance).get<TrainingSetsResponseType>(
      `/v1/entities/training-sets?alias=${alias}`,
    ),
  updateSpeakerNameByReference: ({
    threadId,
    ...body
  }: UpdateSpeakerNameByReferenceRequestType) =>
    requests(axiosApiInstance).patch(
      `/v1/entities/threads/${threadId}/speakers`,
      body,
    ),
};
