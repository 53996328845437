export default function Typography() {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          displayXxl: 'h1',
          displayXl: 'h2',
          displayLg: 'h3',
          displayMd: 'h4',
          displaySm: 'h5',
          displayXs: 'h6',
          textXl: 'p',
          textLg: 'p',
          textMd: 'p',
          textSm: 'p',
          textXs: 'p',
        },
      },
    },
  };
}
