import React from 'react';
import { Icon } from '@mui/material';
import PlusSquare from '~icons/knowz-iconify/plus-square';

import Text from '@components/text';
import { StyledButton } from './styles';

interface AddPackageButtonProps {
  setPackageModalOpen: (open: boolean) => void;
}

const AddPackageButton: React.FC<AddPackageButtonProps> = ({
  setPackageModalOpen,
}) => {
  return (
    <StyledButton
      fullWidth
      size="xlarge"
      startIcon={<PlusSquare />}
      onClick={() => setPackageModalOpen(true)}
    >
      Configure Packages
    </StyledButton>
  );
};

export default AddPackageButton;
