import { useCallback, useState } from 'react';

export default function useToggleState(initialValue: boolean) {
  const [state, setState] = useState(initialValue);

  const toggle = useCallback(() => {
    setState(!state);
  }, [state]);

  return { state, toggle };
}
