import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { useTranslation } from '@desygner/ui-common-translation';

import { styled } from '@mui/system';

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function DataNotFound() {
  const { t } = useTranslation();

  return (
    <StyledStack>
      <Alert severity="info">
        {t('pages.knowledge.components.table.notFound', {
          defaultValue: 'No data found',
        })}
      </Alert>
    </StyledStack>
  );
}
