// TODO: ts errors, need to fix
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Stack, { StackProps } from '@mui/material/Stack';
import Box from '@mui/material/Stack';

import {
  getClientHeight,
  getVisibleItemsWindow,
  SizesMapperType,
} from '@components/pages/live-session/sections/content/components/huge-scrollable-list/util';

import { debounce } from 'lodash';

type HugeScrollableListPropsType = StackProps & {
  totalItems: number;
  renderItem: ({ index }: { index: number }) => React.ReactNode;
  minBlockHight: number;
};

type ItemsMapType = Map<number, React.ReactNode>;

/**
 * The window size multiplier determines how many items we render,
 * based on the space for rendering. For example, if 5 items fit in
 * screen, and we set a window size of 2, then we will render 10 items
 *
 * The bigger the window, the more nodes in screen, but less re-renders
 */
const WINDOW_SIZE_MULTIPLIER = 2;
const ITEM_SIZE_CALCULATION_DEBOUNCE = 100;
const AVERAGE_SIZE_CALCULATION_DEBOUNCE = 100;
const SCROLLING_DEBOUNCE = 100;

export default function HugeScrollableList({
  totalItems,
  renderItem,
  minBlockHight,
  sx,
  ...props
}: HugeScrollableListPropsType) {
  const listRef = useRef<HTMLDivElement>(null);
  const spaceBeforeRef = useRef<HTMLDivElement>(null);
  const spaceAfterRef = useRef<HTMLDivElement>(null);

  const sizes: SizesMapperType = useMemo(
    () => ({
      averageSize: null,
      knownSizes: new Map<number, number>(),
    }),
    [],
  );

  const [scrollTopOnRerender, setScrollTopOnRerender] = useState<number | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(true); // Loading state for initial batch
  const [renderStartIndex, setRenderStartIndex] = useState<number | null>(null);
  const [renderEndIndex, setRenderEndIndex] = useState<number | null>(null);
  const [renderedItems, setRenderedItems] = useState<ItemsMapType>(
    new Map<number, React.ReactNode>(),
  );

  const setRenderWindow = useCallback(
    (start: number, end: number) => {
      if (WINDOW_SIZE_MULTIPLIER < 1) {
        throw new Error('WINDOW_SIZE_MULTIPLIER must be larger than 1');
      }

      const sizeInView = end - start;
      const windowSize = sizeInView * WINDOW_SIZE_MULTIPLIER;
      const idealMargin = Math.ceil((windowSize - sizeInView) / 2);
      const limit = totalItems - 1;

      const renderStartIndex =
        start - idealMargin < 0 ? 0 : start - idealMargin;
      const renderEndIndex =
        renderStartIndex + windowSize > limit
          ? limit
          : renderStartIndex + windowSize;

      setRenderStartIndex(renderStartIndex);
      setRenderEndIndex(renderEndIndex);
    },
    [totalItems],
  );

  // Trigger window render if required
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce(() => {
      const { firstVisibleItem, lastVisibleItem } = getVisibleItemsWindow(
        listRef.current,
        totalItems,
        sizes,
        minBlockHight,
      );
      if (firstVisibleItem < 0) return;
      const limit = totalItems - 1;

      // Adding some margins for not rendering "last minute"
      const margin = 2;
      const firstVMarginItem =
        firstVisibleItem - margin < 0 ? 0 : firstVisibleItem - margin;

      const lastVMarginItem =
        lastVisibleItem + margin >= limit ? limit : lastVisibleItem + margin;

      const shouldShowOtherItems =
        renderStartIndex === null ||
        renderEndIndex === null ||
        firstVMarginItem < renderStartIndex ||
        lastVMarginItem > renderEndIndex;

      if (shouldShowOtherItems && firstVMarginItem < renderStartIndex!) {
        setScrollTopOnRerender(listRef.current?.scrollTop || null);
      }

      shouldShowOtherItems &&
        setRenderWindow(firstVMarginItem, lastVMarginItem);
    }, SCROLLING_DEBOUNCE),
    [renderStartIndex, renderEndIndex, setRenderWindow],
  );

  // Attach scroll event
  useEffect(() => {
    const $list = listRef.current;
    if (!$list || isLoading) return;

    $list.addEventListener('scroll', handleScroll);

    return () => {
      $list.removeEventListener('scroll', handleScroll);
    };
  }, [listRef, isLoading, handleScroll]);

  // Update before and after spaces upon rerendering or sizes modification
  const updateSpaces = useCallback(() => {
    const $list = listRef.current;
    const $spaceBefore = spaceBeforeRef.current;
    const $spaceAfter = spaceAfterRef.current;

    if ($list && $spaceBefore && $spaceAfter) {
      const avgSize = sizes.averageSize ?? minBlockHight;
      const renderedIndexes = [...renderedItems.keys()];
      const totalItemsBefore = renderedIndexes[0];
      const totalItemsAfter =
        totalItems - renderedIndexes[renderedIndexes.length - 1] - 1; // TODO: figure why this 2 is needed (instead of 1)

      let scrollPosition = 0;

      for (let i = 0; i < totalItemsBefore; i++) {
        scrollPosition += sizes.knownSizes.get(i) ?? avgSize;
      }

      $spaceBefore.style['flex-basis'] = `${scrollPosition}px`;
      // $spaceBefore.style['background'] = `red`;
      $spaceAfter.style['flex-basis'] = `${totalItemsAfter * avgSize}px`;
      // $spaceAfter.style['background'] = `blue`;
    }
  }, [renderedItems, totalItems, minBlockHight, sizes]);

  const updateKnownAverageSize = debounce(() => {
    const knownTotalSize = [...sizes.knownSizes.values()].reduce(
      (acc, i) => i + acc,
      0,
    );

    sizes.averageSize = sizes.knownSizes.size
      ? knownTotalSize / sizes.knownSizes.size
      : null;
  }, AVERAGE_SIZE_CALCULATION_DEBOUNCE);

  const updateKnownItemSize = useMemo(() => {
    const ongoingDebounces = new Map<HTMLDivElement, () => void>();

    return (item: HTMLDivElement) => {
      let handler = ongoingDebounces.get(item);
      if (!handler) {
        handler = debounce(() => {
          ongoingDebounces.delete(item);
          const $list = listRef.current;
          if (!$list) return;
          const index =
            Array.prototype.indexOf.call($list.childNodes, item) - 1; // -1 because of the spaceBefore
          const renderedIndexes = [...renderedItems.keys()];

          if (index < 0 || typeof renderedIndexes[index] === 'undefined')
            return;
          sizes.knownSizes.set(renderedIndexes[index], getClientHeight(item));
          updateKnownAverageSize();
        }, ITEM_SIZE_CALCULATION_DEBOUNCE);

        ongoingDebounces.set(item, handler);
      }

      handler();
    };
  }, [renderedItems, sizes.knownSizes, updateKnownAverageSize]);

  useEffect(() => {
    const $list = listRef.current;
    if (!$list) return;

    const closestItem = (node: Node): Node | null => {
      if (node.parentElement === $list) return node;
      return node.parentElement ? closestItem(node.parentElement) : null;
    };

    const observer = new MutationObserver((mutationList) => {
      const items = mutationList.reduce((acc: Node[], m) => {
        const item = closestItem(m.target);
        if (item && !acc.includes(item)) acc.push(item);
        return acc;
      }, []);

      for (const item of items) {
        updateKnownItemSize(item as HTMLDivElement);
      }

      if (scrollTopOnRerender && mutationList.find((m) => m.target === $list)) {
        const addedNodes = mutationList.reduce((acc, m) => {
          if (m.addedNodes) {
            acc.push(...m.addedNodes);
          }
          return acc;
        }, []);

        const size = addedNodes.reduce((acc, n) => {
          acc += getClientHeight(n);
          return acc;
        }, 0);

        const scrollDiff = $list.scrollTop - (scrollTopOnRerender - size);
        if (scrollDiff > 0) {
          $list.scrollTo({ top: $list.scrollTop - scrollDiff });
        }

        setScrollTopOnRerender(null);
      }
    });

    observer.observe($list, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRef, updateKnownItemSize]);

  // Render items window
  useEffect(() => {
    if (renderStartIndex !== null && renderEndIndex !== null) {
      const renderedItems: ItemsMapType = new Map<number, React.ReactNode>();

      for (let index = renderStartIndex; index <= renderEndIndex; index++) {
        renderedItems.set(index, renderItem({ index }));
      }

      setRenderedItems(renderedItems);
    }
  }, [renderItem, renderStartIndex, renderEndIndex]);

  // Update spaces on render
  useEffect(() => {
    const $list = listRef.current;
    if ($list) updateSpaces();
  }, [renderedItems, updateSpaces]);

  // Initial load
  useEffect(() => {
    let wasOverwritten = false;
    const timestamp = Date.now();
    const timeout = 5000;

    if (!isLoading) return;

    (async () => {
      while (!listRef.current || !totalItems) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        if (shouldTerminate()) return;
      }

      let i = 1;
      do {
        const expectedRdrTime = ITEM_SIZE_CALCULATION_DEBOUNCE * 2;
        const totalKnownSizesPreRender = sizes.knownSizes.size;
        setRenderWindow(0, i++);

        while (sizes.knownSizes.size <= totalKnownSizesPreRender) {
          await new Promise((resolve) => setTimeout(resolve, expectedRdrTime));
          if (shouldTerminate()) return;
        }

        const isScreenFull =
          [...sizes.knownSizes.values()].reduce((acc, i) => i + acc, 0) >=
          listRef.current.clientHeight;

        if (isScreenFull) {
          setIsLoading(false);
          break;
        }
      } while (!shouldTerminate());
    })();

    function shouldTerminate() {
      if (Date.now() - timestamp > timeout) {
        setIsLoading(false);
        console.error('Initial load timeout');

        return true;
      }

      return wasOverwritten;
    }

    return () => {
      wasOverwritten = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, setRenderWindow]);

  // Potential rerender on added or removed items
  useEffect(() => {
    const $list = listRef.current;
    if (!$list || isLoading) return;
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItems]);

  return (
    <Stack
      ref={listRef}
      direction="column-reverse"
      sx={{ overflowY: isLoading ? 'hidden' : 'auto', ...sx }}
      {...props}
    >
      <Box
        sx={{ flexGrow: 0, flexShrink: 0 }}
        ref={spaceBeforeRef}
      ></Box>
      {[...renderedItems.values()]}
      <Box
        sx={{ flexGrow: 0, flexShrink: 0 }}
        ref={spaceAfterRef}
      ></Box>
    </Stack>
  );
}
