import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import axiosDesygnerInstance from '@lib/agent/services/desygner/axiosInstance';

import { UserType } from '@shared-types/auth';

import { jwtDecode } from 'jwt-decode';

export function isValidToken(token: string) {
  try {
    const decoded = getDecodedToken(token);
    const currentTime = Date.now() / 1000;

    if (!decoded) {
      throw new Error('Token is not verified');
    }

    return decoded.exp > currentTime;
  } catch (error) {
    console.error(error);
    return false;
  }
}

// temp solution for sending token to extension
// TODO: Will have to change the address to VITE_KNOWZ_WEBSITE_URL for PROD
export function sendTokenToExtension(token: string) {
  // Change this to VITE_KNOWZ_WEBSITE_URL for production
  window.postMessage(
    { type: 'FROM_PAGE', jwt: token },
    `${import.meta.env.VITE_KNOWZ_WEBSITE_URL}/*`,
  );
}

export function setSession(jwt: string | null) {
  if (jwt) {
    localStorage.setItem('token', jwt);
    axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    axiosDesygnerInstance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  } else {
    localStorage.removeItem('token');
    delete axiosApiInstance.defaults.headers.common.Authorization;
    delete axiosDesygnerInstance.defaults.headers.common.Authorization;
  }
}

export function checkUserAuthType(token: string) {
  const decodedJWT = getDecodedToken(token);

  if (!token || !decodedJWT) return null;

  return decodedJWT.auth_type;
}

export function getDecodedToken(token: string | null): UserType | null {
  if (!token) {
    return null;
  }

  return jwtDecode(token);
}
