import Text from '@components/text';

type Props = {
  prompt?: string;
};

export default function Prompt({ prompt }: Props) {
  return (
    <Text
      variant="displaySm"
      component="h1"
    >
      {prompt}
    </Text>
  );
}
