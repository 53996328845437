import { useEffect, useRef, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import FormProvider from '@components/react-hook-form/FormProvider';
import Text from '@components/text';
import FormHeading from '@components/user-authentication/components/form-heading';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import useValidateResetToken from '@components/user-authentication/hooks/useValidateResetToken';
import ResetPassword from '@components/user-authentication/sections/steps/reset-password';

import { useTranslation } from '@desygner/ui-common-translation';

import { Controller, useForm } from 'react-hook-form';

type ValidateCodeFormType = {
  code: string[];
};

const CODE_LENGTH = 6;

const defaultValues = {
  code: Array(CODE_LENGTH).fill(''),
};

export default function ValidateCode() {
  const { t } = useTranslation();
  const { email, handleSetResetToken } = useUserAuth();
  const { mutateAsync: validateResetToken, isPending: isValidatePending } =
    useValidateResetToken();
  const [isCodeValid, setIsCodeValid] = useState(false);

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const methods = useForm<ValidateCodeFormType>({
    defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, control, setValue, watch, getValues } = methods;

  const allFieldsFilled = watch('code').every((value) => value !== '');

  useEffect(function () {
    inputRefs.current = inputRefs.current.slice(0, CODE_LENGTH);
  }, []);

  function handleKeyDown(
    index: number,
    event: React.KeyboardEvent<HTMLDivElement>,
  ) {
    const value = watch(`code.${index}`);

    if (event.key === 'Backspace') {
      event.preventDefault();
      if (value) {
        setValue(`code.${index}`, '');
      } else if (index > 0) {
        setValue(`code.${index - 1}`, '');
        inputRefs.current[index - 1]?.focus();
      }
    }
  }

  function handleInputChange(
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const value = event.target.value.slice(-1);
    if (value) {
      setValue(`code.${index}`, value);
      if (index < CODE_LENGTH - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  }

  function handlePaste(event: React.ClipboardEvent, focusedIndex: number) {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text');
    const currentValues = getValues('code');

    //? Keep values before focused index, then add pasted chars
    const updatedValues = [
      ...currentValues.slice(0, focusedIndex),
      ...pastedData.split(''),
      ...currentValues.slice(focusedIndex + pastedData.length),
    ].slice(0, CODE_LENGTH);

    updatedValues.forEach((value, index) => {
      setValue(`code.${index}`, value);
    });

    const lastIndex = Math.min(
      focusedIndex + pastedData.length - 1,
      CODE_LENGTH - 1,
    );
    inputRefs.current[lastIndex]?.focus();
  }

  async function onSubmit(data: ValidateCodeFormType) {
    const token = data.code.join('');
    handleSetResetToken(token);
    try {
      await validateResetToken({ email, token });
      setIsCodeValid(true);
    } catch (error) {
      setIsCodeValid(false);
      console.error('Error validating code', error);
    }
  }
  if (isCodeValid) {
    return <ResetPassword />;
  }

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeading
        heading={t('layout.auth.desktop.modal.steps.validateCode.form.label', {
          defaultValue: 'Enter code',
        })}
        withBackButton
      />
      <Stack gap={5}>
        <Text variant="textMd">
          {t(
            'layout.auth.desktop.modal.steps.validateCode.description.segment1',
            {
              defaultValue: 'We sent a code to your email ',
            },
          )}
          {email}.
          {t(
            'layout.auth.desktop.modal.steps.validateCode.description.segment2',
            {
              defaultValue:
                ' Please enter your 6-digit code to verify your identity. Your code will expire in 10 minutes.',
            },
          )}
        </Text>
        <Box
          gap={4}
          display="flex"
        >
          {defaultValues.code.map((_, index) => (
            <Controller
              key={index}
              name={`code.${index}`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    '& input': {
                      textAlign: 'center',
                    },
                  }}
                  disabled={isValidatePending}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => handleInputChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={(e) => handlePaste(e, index)}
                />
              )}
            />
          ))}
        </Box>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          fullWidth
          disabled={!allFieldsFilled}
          loading={isValidatePending}
        >
          {t('layout.auth.desktop.modal.steps.validateCode.form.button', {
            defaultValue: 'Verify',
          })}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
