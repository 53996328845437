import { Theme } from '@mui/material';

export default function Input(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius * 2,
          backgroundColor: theme.palette.background.secondary,
          height: 40,
          gap: theme.spacing(1),
          svg: {
            fontSize: theme.typography.pxToRem(19),
            flexShrink: 0,
            color: theme.palette.text.disabled,
          },
        },
        input: {
          padding: theme.spacing(2.5, 3.5),
          fontSize: theme.typography.textSm.fontSize,
          fontWeight: theme.typography.textSm.fontWeight,
        },
        inputAdornedStart: {
          paddingLeft: theme.spacing(2),
        },
        inputAdornedEnd: {
          paddingInlineEnd: theme.spacing(2),
        },
      },
    },
  };
}
