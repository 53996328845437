const createFriendlyNameManager = () => {
  const knownNames: { [speaker: string]: string } = {};

  const setFriendlyName = (speaker: string) => {
    if (!knownNames[speaker]) {
      knownNames[speaker] = `Speaker ${Object.keys(knownNames).length + 1}`;
    }
    return knownNames[speaker];
  };

  const getKnownNameBySpeakerId = (speaker: string) => {
    return knownNames[speaker];
  };

  return { setFriendlyName, getKnownNameBySpeakerId };
};

const friendlyNameManager = createFriendlyNameManager();

export default friendlyNameManager;
