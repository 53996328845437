import { LoaderFunctionArgs, matchPath } from 'react-router-dom';
import { redirect } from 'react-router-dom';

import { ROLES_BY_PATHNAME } from '@lib/roles';

import { SetupModeType } from '@providers/WorkspaceProvider';

import paths from '@router/paths';
import qs from 'qs';

export default async function protectRoutes({ request }: LoaderFunctionArgs) {
  const setupModeFromLS = window.localStorage.getItem('setupMode');
  let setupMode: SetupModeType | null = null;
  const urlParams = new URL(request.url).searchParams;
  const allQueryParams = qs.stringify(Object.fromEntries(urlParams.entries()));

  if (setupModeFromLS) {
    setupMode = JSON.parse(setupModeFromLS) as SetupModeType;
  }

  const url = new URL(request.url);

  const foundPath = Object.keys(ROLES_BY_PATHNAME).find((path) =>
    matchPath(path, url.pathname),
  ) as keyof typeof ROLES_BY_PATHNAME;

  const canAccess = ROLES_BY_PATHNAME[foundPath].includes(setupMode);

  if (!canAccess && setupMode === 'on') {
    return redirect(`${paths.knowledgeBase.pathname}?${allQueryParams}`);
  }

  if (!canAccess && setupMode === 'off') {
    return redirect(`${paths.home.pathname}?${allQueryParams}`);
  }

  return 'ok';
}
