import {
  SelectedAssistantsType,
  SmartTagGroupIdType,
} from '@shared-types/live';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type InitialStateType = {
  selectedAssistants: SelectedAssistantsType;
  groupedTrainingSets: { [key in SmartTagGroupIdType]: number[] };
};

const initialState = (): InitialStateType => {
  const serializedState = window.localStorage.getItem('live');
  if (serializedState === null) {
    return {
      selectedAssistants: {
        factChecker: true,
        knowzBot: true,
        noteTaker: true,
      },
      groupedTrainingSets: {
        actionPointsLogger: [],
        conversationModeration: [],
        minutesTracker: [],
        salesConversationAnalyser: [],
      },
    };
  }
  return JSON.parse(serializedState);
};

const live = createSlice({
  name: 'live',
  initialState,
  reducers: {
    selectedAssistantsUpdated: (
      state,
      action: PayloadAction<SelectedAssistantsType>,
    ) => ({
      ...state,
      selectedAssistants: action.payload,
    }),
    groupedTrainingSetsUpdated: (
      state,
      action: PayloadAction<InitialStateType['groupedTrainingSets']>,
    ) => ({
      ...state,
      groupedTrainingSets: {
        ...state.groupedTrainingSets,
        ...action.payload,
      },
    }),
  },
});

export const { groupedTrainingSetsUpdated, selectedAssistantsUpdated } =
  live.actions;

export default live.reducer;
