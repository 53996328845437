import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';

export const StyledChip = styled(Chip)(({ theme }) => ({
  background: `
      linear-gradient(
        to right,
        rgba(83, 177, 237, 0.17),
        rgba(137, 95, 242, 0.17),
        rgba(30, 26, 249, 0.17)
      )`,
  border: '1px solid rgba(83, 177, 237, 0.17)',
  height: 'initial',

  '& .MuiChip-label': {
    padding: theme.spacing(0.25, 2),
  },
})) as typeof Chip;

export const StyledEmptyHistoryStack = styled(Stack)(({ theme }) => ({
  maxHeight: '100%',
  padding: theme.spacing(4),
  paddingRight: 0,
  overflowY: 'auto',
}));

export const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0, 4),
  overflowY: 'auto',

  '& > *': {
    transition: 'opacity 0.4s',
  },

  '&:hover': {
    '& > *': {
      [theme.breakpoints.up('md')]: {
        opacity: 0.7,
      },
    },

    '& > *:hover': {
      opacity: 1,
      transition: 'opacity 0.2s',
    },
  },
})) as typeof List;
