import { Theme } from '@mui/material';

export default function Table(theme: Theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.card.main,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
          borderBottomColor: theme.palette.divider,
        },
      },
    },
  };
}
