import Skeleton from '@mui/material/Skeleton';

const HEIGHT = 45;
const WIDTH = 200;

export default function SkeletonLoading() {
  return (
    <Skeleton
      height={HEIGHT}
      variant="text"
      width={WIDTH}
    />
  );
}
