import { useState } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';

import KnowzAssistantCard from '@components/pages/live/sections/cards/knowz-assistant-card';
import SmartTagsCard from '@components/pages/live/sections/cards/smart-tags-card';
import History from '@components/pages/live/sections/history';
import Recorder, {
  MOBILE_HEIGHT,
} from '@components/pages/live/sections/recorder';
import {
  StyledBox,
  StyledPaper,
  StyledText,
} from '@components/pages/live/styles';
import Wrapper from '@components/pages/live/Wrapper';
import { StyledContainerForFixedHeader } from '@components/pages/styles';
import Text from '@components/text';

import useResponsive from '@hooks/useResponsive';

import { useTranslation } from '@desygner/ui-common-translation';

export default function Live() {
  const { t } = useTranslation();
  const isItDesktop = useResponsive('up', 'md');
  const [section, setSection] = useState<'history' | 'config'>('history');

  if (isItDesktop) {
    return (
      <Wrapper>
        <StyledPaper component={Stack}>
          <Stack>
            <Stack direction="row">
              <StyledText
                variant="textXl"
                weight="semibold"
              >
                {t('page.live.grids.transcribe.title', {
                  defaultValue: 'Live',
                })}
              </StyledText>

              <Box
                sx={{
                  paddingTop: 5.5,
                  paddingLeft: 1,
                  filter: (theme) =>
                    theme.palette.mode === 'light' ? 'invert(1)' : 'none',
                }}
              >
                <img
                  src="https://assets.knowz.com/assets/svg/beta.svg"
                  alt="knowz live"
                  width={28}
                />
              </Box>
            </Stack>
            <History />
          </Stack>

          <StyledBox>
            <Stack
              gap={4}
              sx={{ minHeight: '100%' }}
            >
              <Text
                variant="textXl"
                weight="semibold"
                sx={{ marginBottom: 4 }}
              >
                {t('page.live.grids.mode.title', {
                  defaultValue: 'Configure your Session',
                })}
              </Text>
              <KnowzAssistantCard />
              <SmartTagsCard />
            </Stack>
          </StyledBox>
        </StyledPaper>

        <Recorder />
      </Wrapper>
    );
  }

  return (
    <>
      <StyledContainerForFixedHeader
        disableGutters
        maxWidth={false}
        sx={{ paddingBlockEnd: `${MOBILE_HEIGHT}px` }}
      >
        <Stack
          justifyContent="space-between"
          direction="row"
          sx={{ px: 4, marginBlockEnd: 4 }}
        >
          <Stack direction="row">
            <Text
              variant="textXl"
              weight="semibold"
            >
              {t('page.live.grids.transcribe.title', {
                defaultValue: 'Live',
              })}
            </Text>

            <Box
              sx={{
                paddingTop: 1.5,
                paddingLeft: 1,
                filter: (theme) =>
                  theme.palette.mode === 'light' ? 'invert(1)' : 'none',
              }}
            >
              <img
                src="https://assets.knowz.com/assets/svg/beta.svg"
                alt="knowz live"
                width={28}
              />
            </Box>
          </Stack>

          {section === 'history' && (
            <ButtonBase
              disableRipple
              disableTouchRipple
              onClick={() => setSection('config')}
            >
              <Text
                variant="textSm"
                weight="semibold"
              >
                {t('page.live.button.configure', {
                  defaultValue: 'Configure New Session',
                })}
              </Text>
              <ChevronRightIcon sx={{ marginRight: '-8px' }} />
            </ButtonBase>
          )}

          {section === 'config' && (
            <ButtonBase
              disableRipple
              disableTouchRipple
              onClick={() => setSection('history')}
            >
              <Text
                variant="textSm"
                weight="semibold"
              >
                {t('page.live.button.history', {
                  defaultValue: 'Live History',
                })}
              </Text>
              <ChevronRightIcon sx={{ marginRight: '-8px' }} />
            </ButtonBase>
          )}
        </Stack>

        {section === 'history' && <History />}

        {section === 'config' && (
          <Stack
            gap={4}
            sx={{ px: 4, marginBottom: 4 }}
          >
            <Text
              variant="textXl"
              weight="semibold"
            >
              {t('page.live.grids.mode.title', {
                defaultValue: 'Configure your Session',
              })}
            </Text>
            <KnowzAssistantCard />
            <SmartTagsCard />
          </Stack>
        )}
      </StyledContainerForFixedHeader>

      <Recorder />
    </>
  );
}
