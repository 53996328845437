import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledStackWrapper = styled(Stack)(({ theme }) => ({
  width: theme.typography.pxToRem(359),
  [theme.breakpoints.up('md')]: {
    width: theme.typography.pxToRem(480),
  },
}));

export const StyledStack = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
}));
