import { Theme } from '@mui/material';

export default function Radio(theme: Theme) {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          color: theme.palette.action.disabled,
        },
      },
    },
  };
}
