import PopoverProvider from '@layouts/main/sections/toolbar/components/knowledge-base-popover/providers/PopoverProvider';

type Props = {
  type: 'expanded toolbar' | 'collapsed toolbar' | 'mobile';
};

export default function KnowledgeBasePopover({ type }: Props) {
  return (
    <PopoverProvider>
      <PopoverProvider.TriggerButton
        buttonType={type === 'expanded toolbar' ? 'button' : 'iconButton'}
      />
      <PopoverProvider.Popover>
        <PopoverProvider.Popover.Header />
        <PopoverProvider.Popover.Content>
          <PopoverProvider.Popover.Content.Tabs />
        </PopoverProvider.Popover.Content>
        <PopoverProvider.Popover.Footer />
      </PopoverProvider.Popover>
    </PopoverProvider>
  );
}
