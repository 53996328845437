import { useState } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';

import StyledClickableImage from '@components/pages/search/components/image-dialog/styles';

import { PhotoType } from '@shared-types/search-result/types';

export default function ImageDialog({
  url,
  alt,
  contextLink,
  displayLink,
}: PhotoType) {
  const [openImage, setOpenImage] = useState(false);
  function brokenImageHandler(
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) {
    event.currentTarget.parentElement!.style.display = 'none';
  }

  function toggleImageHandler() {
    setOpenImage(!openImage);
  }
  return (
    <>
      <StyledClickableImage
        role="button"
        onClick={toggleImageHandler}
        src={url}
        alt={alt}
        onError={brokenImageHandler}
      />
      <Dialog
        open={openImage}
        onClose={toggleImageHandler}
      >
        <Box sx={{ position: 'relative' }}>
          {contextLink && displayLink && (
            <Link
              to={contextLink}
              target="_blank"
            >
              <Chip
                label={displayLink}
                variant="filled"
                color="info"
                sx={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                }}
              />
            </Link>
          )}
          <img
            loading="lazy"
            src={url}
            alt={alt}
            onError={brokenImageHandler}
          />
        </Box>
      </Dialog>
    </>
  );
}
