import { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';

import FormProvider from '@components/react-hook-form/FormProvider';
import RHFCheckbox from '@components/react-hook-form/RHFCheckbox';
import Text from '@components/text';
import FormHeading from '@components/user-authentication/components/form-heading';
import useLogin from '@components/user-authentication/hooks/useLogin';
import useSendResetToken from '@components/user-authentication/hooks/useSendResetToken';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { enterPasswordSchema } from '@components/user-authentication/schemas';
import PasswordField from '@components/user-authentication/sections/steps/components/password-field';
import StyledLinkLoadingButton from '@components/user-authentication/sections/steps/styles';
import ValidateCode from '@components/user-authentication/sections/steps/validate-code';

import { setSession } from '@lib/jwt';

import { queryClient } from '@providers/ReactQueryProvider';

import useAcceptInvitation from '@hooks/useAcceptInvitation';
import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const defaultValues = {
  password: '',
  rememberMe: false,
};

type EnterPasswordFormType = {
  password: string;
  rememberMe: boolean;
};

export default function EnterPassword() {
  const [showResetPasswordValidator, setShowResetPasswordValidator] =
    useState(false);

  const { email } = useUserAuth();

  const { handleUpdateMe } = useAuth();

  const { mutateAsync: mutateAsyncLogin, isPending: isLoginPending } =
    useLogin();

  const { t } = useTranslation();

  const { removeQueryParamsByKeys, getQueryParamByKey } = useQueryParams();

  const { handleIsUserAuthenticated, handleAttemptedToLogin } = useAuth();

  const methods = useForm<EnterPasswordFormType>({
    resolver: yupResolver(enterPasswordSchema),
    defaultValues,
  });

  const { resetWorkspaceToIndividualRole } = useWorkspace();

  const { mutateAcceptInvitationAsync } = useAcceptInvitation({
    onSuccess: () => {
      toast.success(
        t('page.knowledgeBase.member.membership.accepted', {
          defaultValue: 'You have successfully accepted this invitation.',
        }),
      );
    },
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = methods;

  const isBusy = isLoginPending || isSubmitting;

  const invitationToken = getQueryParamByKey<string | null>('token');

  const {
    mutateAsync: mutateAsyncSendResetToken,
    isPending: isSendResetTokenPending,
    isError: isSendResetTokenError,
  } = useSendResetToken();

  async function onSubmit(data: EnterPasswordFormType) {
    const { data: loginData } = await mutateAsyncLogin({
      email,
      password: data.password,
      auth_type: 'email',
      remember_me: data.rememberMe,
    });

    window.localStorage.removeItem('fingerPrintId');
    resetWorkspaceToIndividualRole();
    setSession(loginData.token);
    handleUpdateMe(loginData.token);
    handleIsUserAuthenticated(true);
    handleAttemptedToLogin(true);
    removeQueryParamsByKeys(['action', 'subscription']);

    if (invitationToken) {
      await mutateAcceptInvitationAsync({
        token: invitationToken,
      });
    }

    queryClient.refetchQueries();
  }

  async function handleResetPassword() {
    await mutateAsyncSendResetToken({ email, request_type: 'code' });
    if (isSendResetTokenError) return;
    setShowResetPasswordValidator(true);
  }

  return showResetPasswordValidator ? (
    <ValidateCode />
  ) : (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormHeading
        heading={t('layout.auth.desktop.modal.steps.enterPassword.label')}
        withBackButton
      />
      <Stack gap={5}>
        <Text variant="textMd">
          {t(
            'layout.auth.desktop.modal.steps.enterPassword.form.password.label',
            {
              defaultValue: 'Enter your password',
            },
          )}
        </Text>
        <PasswordField
          placeholder={t(
            'layout.auth.desktop.modal.steps.enterPassword.form.password.label',
            { defaultValue: 'Enter your password' },
          )}
          name="password"
          disabled={isBusy}
          helperText={errors.password?.message}
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <RHFCheckbox
            name="rememberMe"
            label="Remember me"
          />
          <StyledLinkLoadingButton
            onClick={handleResetPassword}
            disabled={isSendResetTokenPending}
            loading={isSendResetTokenPending}
          >
            {t(
              'layout.auth.desktop.modal.steps.enterPassword.form.rememberMe',
              {
                defaultValue: 'Forgot password?',
              },
            )}
          </StyledLinkLoadingButton>
        </Stack>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          fullWidth
          disabled={!isDirty || isBusy}
          loading={isBusy}
        >
          {t('layout.auth.desktop.modal.steps.enterPassword.form.button', {
            defaultValue: 'login',
          })}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
