import Editor from '@components/pages/command-designer/sections/step-editor/Editor';

import Providers from '@providers/index';

import { DialogsProvider } from '@toolpad/core';

export default function StepEditor() {
  return (
    <Providers>
      <DialogsProvider>
        <Editor />
      </DialogsProvider>
    </Providers>
  );
}
