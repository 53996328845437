import ToolbarContents, {
  ToolbarOptionType,
} from '@components/pages/command-designer/dialogs/smart-prompt-editor/ToolbarContents';

import { StepType } from '@lib/step/types';

import { insertDirective$, usePublisher } from '@mdxeditor/editor';

export default function DirectiveToolbarContents({ step }: { step: StepType }) {
  const insertDirective = usePublisher(insertDirective$);

  function handleOptionsClick({ value, type, name }: ToolbarOptionType) {
    value
      ? insertDirective({
          name: 'context',
          type: 'textDirective',
          attributes: { type },
          children: [{ type: 'text', value }], // TODO: fix missing type TextDirective
        })
      : insertDirective({
          name: 'secret',
          type: 'textDirective',
          children: [{ type: 'text', value: name }],
        });
  }

  return (
    <ToolbarContents
      step={step as StepType}
      onClick={handleOptionsClick}
    />
  );
}
