import { live } from '@lib/agent/endpoints/live';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';

import { useTranslation } from '@desygner/ui-common-translation';

import { SmartTagGroupType } from '@shared-types/live';

import { toast } from 'react-toastify';

export default function useGetTrainingSetByAlias(alias: SmartTagGroupType) {
  const { t } = useTranslation();

  return useQueryAfterWorkspaceLoaded({
    queryKey: ['training-set', alias],
    queryFn: async () => {
      try {
        const { data } = await live.getTrainingSetByAlias({
          alias,
        });
        return data;
      } catch (error) {
        toast.error(
          t('response.errors.code.520', {
            defaultValue: 'Unknown error. Please try again later.',
          }),
        );
        console.error(error);
      }
    },
  });
}
