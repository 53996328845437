import {
  StyledPaper,
  StyledStack,
} from '@components/pages/live-session/sections/content/content-layout/styles';

type Props = React.PropsWithChildren<{
  headerSlot: React.ReactNode;
}>;

export default function ContentLayout({ headerSlot, children }: Props) {
  return (
    <StyledPaper square>
      <StyledStack
        direction="row"
        gap={4}
        justifyContent="space-between"
      >
        {headerSlot}
      </StyledStack>
      {children}
    </StyledPaper>
  );
}
