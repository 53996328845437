import { styled } from '@mui/material';
import List from '@mui/material/List';

import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import { StyledGlobalToolbar } from '@layouts/main/sections/toolbar/styles';

export const StyledDesktopMiniToolbar = styled(StyledGlobalToolbar)(
  ({ theme }) => ({
    padding: theme.spacing(1.25),
    borderRadius: theme.shape.borderRadius * 10,
    background: theme.palette.background.card.light,
    width: CONFIG_TOOLBAR.TOOLBAR_MINI.DESKTOP.WIDTH,
    border: `1px solid ${theme.palette.divider}`,
  }),
);

export const StyledListStack = styled(List)(() => ({
  width: '100%',
  alignSelf: 'flex-start',
})) as typeof List;

export const StyledList = styled(List)(({ theme }) => ({
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    width: theme.spacing(9.5),
  },
})) as typeof List;
