import { styled } from '@mui/material';
import Chip, { chipClasses } from '@mui/material/Chip';

import { SharedStyledSlide } from '@components/pages/styles';

export const StyledExpandedSlider = styled(SharedStyledSlide, {
  shouldForwardProp: (props) => props !== 'isCreditLow',
})<{ isCreditLow: boolean }>(({ theme, isCreditLow }) => ({
  '& .MuiSlider-track': {
    background: isCreditLow
      ? 'linear-gradient(to left,#FF1E1E, #FF48A0)' //T ODO: design system
      : 'linear-gradient(to left,#36BFFA, #0040C1)', // TODO: design system
  },

  [theme.breakpoints.up('md')]: {
    height: theme.spacing(2),
    width: '100%',
  },
}));

export const StyledChip = styled(Chip, {
  shouldForwardProp: (props) => props !== 'isOutOfCredit',
})<{ isOutOfCredit: boolean }>(({ theme, isOutOfCredit }) => ({
  backgroundColor: theme.palette.grey[50],
  border: '1px solid #E9EAEB', // TODO: design system, theme.palette.grey[200] should be #E9EAEB
  borderRadius: '6px',
  color: '#252B37', // TODO: design system
  padding: theme.spacing(0.5, 2),
  height: '24px',
  fontSize: theme.typography.textSm.fontSize,
  fontWeight: theme.typography.textSm.fontWeight,
  lineHeight: 1,

  ...(isOutOfCredit
    ? {
        backgroundColor: '#FEF3F2', // TODO: design system
        borderColor: '#FECDCA', // TODO: design system
        color: theme.palette.mode === 'dark' ? '#B42318' : '#B42318', // TODO: design system
      }
    : {}),

  [`& .${chipClasses.label}`]: {
    padding: 0,
  },
}));
