//TODO: for now we only show first info from the whole array since we are waiting for a proper UX/UI design.
import InfoBlock from '@components/pages/search/components/shared-blocks/info';

import { RootType } from '@state/store';

import { InfoType } from '@shared-types/search-result/types';

import { useSelector } from 'react-redux';

export default function StreamingInfo() {
  const infos = useSelector<RootType, InfoType>(
    (state) => state.websocket.WSResponse.searchResult.layout.infos,
  );

  if (infos == null || infos.infosData.length === 0) return null;

  return <InfoBlock text={infos.infosData[0].text} />;
}
