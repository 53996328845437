import { BASE_FONT_SIZE, convertPxToRem, responsiveFontSize } from '@lib/fonts';

const FONT_FAMILY = 'Inter, sans-serif';

const typography = {
  fontFamily: FONT_FAMILY,
  fontSize: BASE_FONT_SIZE,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemibold: 600,
  fontWeightBold: 700,
  displayXxl: {
    fontSize: convertPxToRem(72),
    fontWeight: 600,
    lineHeight: 1.25,
  },
  displayXl: {
    fontSize: convertPxToRem(60),
    fontWeight: 600,
    lineHeight: 1.25,
  },
  displayLg: {
    fontSize: convertPxToRem(48),
    fontWeight: 600,
    lineHeight: 1.25,
    ...responsiveFontSize({ xs: 30, sm: 30, md: 48 }),
  },
  displayMd: {
    fontSize: convertPxToRem(36),
    fontWeight: 600,
    lineHeight: 1.25,
    ...responsiveFontSize({ xs: 24, sm: 24, md: 36 }),
  },
  displaySm: {
    fontSize: convertPxToRem(30),
    fontWeight: 600,
    lineHeight: 1.25,
  },
  displayXs: {
    fontSize: convertPxToRem(24),
    fontWeight: 600,
    lineHeight: 1.25,
  },
  textXl: {
    fontSize: convertPxToRem(20),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  textLg: {
    fontSize: convertPxToRem(18),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  textMd: {
    fontSize: convertPxToRem(16),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  textSm: {
    fontSize: convertPxToRem(14),
    fontWeight: 400,
    lineHeight: 1.5,
  },
  textXs: {
    fontSize: convertPxToRem(12),
    fontWeight: 400,
    lineHeight: 1.5,
  },
} as const;

export default typography;
