import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import ForwardIcon from '@mui/icons-material/Forward';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';

import Text from '@components/text';

import getOrdinal from '@lib/getOrdinalNumber';

import useAuth from '@hooks/useAuth';

import { useTranslation } from '@desygner/ui-common-translation';

import useUserFunnelType from '@layouts/components/credit-usage/hooks/useUserFunnelType';
import paths from '@router/paths';
import { getDay } from 'date-fns';

const SKELETON_WIDTH = '100%';
const SKELETON_HEIGHT = 20;

export default function CreditCopy({
  showButton,
  color,
}: {
  showButton?: boolean;
  color?: 'default' | 'inverted';
}) {
  const { me, isUserAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { userFunnelType } = useUserFunnelType();

  // TODO: use this once t() helper supports placeholders
  const nextUpdatePlaceholder = useMemo((): string | null => {
    if (me?.subscription) {
      return (
        getDay(me.subscription.next_update) +
        getOrdinal(getDay(me.subscription.next_update))
      );
    }

    return null;
  }, [me]);

  if (!isUserAuthenticated || !me) {
    return Array.from({ length: 2 }).map((_, index) => (
      <Skeleton
        key={index}
        width={SKELETON_WIDTH}
        height={SKELETON_HEIGHT}
      />
    ));
  }

  return (
    <Stack gap={1}>
      <Text
        variant="textXs"
        weight="semibold"
      >
        {t(`layout.toolbar.buyAICredit.cta.title.${userFunnelType}`)}
      </Text>

      <Stack
        direction="row"
        gap={1}
      >
        <Text
          variant="textXs"
          {...(color === 'inverted' ? {} : { color: 'text.secondary' })}
        >
          {t(`layout.toolbar.buyAICredit.cta.body.${userFunnelType}`)}
        </Text>

        {showButton && (
          <Stack justifyContent="flex-end">
            <IconButton
              component={NavLink}
              to={paths.upgradeAccount.pathname}
              size="fit"
              sx={{
                '&.active': {
                  visibility: 'hidden',
                },
              }}
            >
              {/* TODO: icon */}
              <ForwardIcon sx={{ height: '12px', width: '12px' }} />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
