import { createContext, useEffect, useReducer } from 'react';

import useGeolocation from '@hooks/useGeoLocation';

import { ActionMap } from '@shared-types/utils';

type AppSettingsContextType = {
  userGeolocation: {
    lat: number;
    lng: number;
  };
};

enum Types {
  SET_USER_GEOLOCATION = 'SET_USER_GEOLOCATION',
}

type AppSettingsState = {
  userGeolocation: {
    lat: number;
    lng: number;
  };
};

const initialState: AppSettingsState = {
  userGeolocation: {
    lat: 0,
    lng: 0,
  },
};

type AppSettingsPayload = {
  [Types.SET_USER_GEOLOCATION]: {
    lat: number;
    lng: number;
  };
};

export type AppSettingsActions =
  ActionMap<AppSettingsPayload>[keyof ActionMap<AppSettingsPayload>];

export const AppSettingsContext = createContext<AppSettingsContextType | null>(
  null,
);

export default function AppSettingsProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { position } = useGeolocation({ lat: 0, lng: 0 });

  useEffect(() => {
    dispatch({ type: Types.SET_USER_GEOLOCATION, payload: position });
  }, [position]);

  return (
    <AppSettingsContext.Provider value={state}>
      {children}
    </AppSettingsContext.Provider>
  );
}

function reducer(state: AppSettingsState, action: AppSettingsActions) {
  switch (action.type) {
    case Types.SET_USER_GEOLOCATION: {
      if (
        state.userGeolocation.lat === action.payload.lat &&
        state.userGeolocation.lng === action.payload.lng
      ) {
        return state;
      }

      return {
        ...state,
        userGeolocation: action.payload,
      };
    }
  }
}
