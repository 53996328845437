import { Theme } from '@mui/material';

export default function AppBar(theme: Theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          padding: theme.spacing(4, 4),
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
    },
  };
}
