import { useNavigate } from 'react-router-dom';

import { memberships } from '@lib/agent';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import useWorkspace from '@hooks/useWorkspace';

import { useTranslation } from '@desygner/ui-common-translation';

import paths from '@router/paths';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

export default function useGetWorkspaceById() {
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  const { workspace } = useWorkspace();

  const {
    data: workspaceData,
    isLoading: isWorkspaceDataLoading,
    isError: isWorkspaceDataError,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['workspace', workspace?.id],
    queryFn: async () => {
      if (workspace === null) return;

      try {
        const { data } = await memberships.getOneWorkspaceById({
          workspaceId: workspace.id,
        });
        return data;
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(
            t('response.errors.code.403', {
              defaultValue: 'Not allowed to access this resource.',
            }),
          );

          return navigateTo(paths.home.pathname);
        }
        throw new Error(`An error occurred while fetching workspace: ${error}`);
      }
    },
  });

  return {
    workspaceData,
    isWorkspaceDataLoading,
    isWorkspaceDataError,
  };
}
