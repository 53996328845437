import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const NUMBER_OF_LINES = 5;

export default function Info() {
  return (
    <Card>
      <Stack gap={4}>
        {Array.from({ length: NUMBER_OF_LINES }).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
          />
        ))}
      </Stack>
    </Card>
  );
}
