import { useContext } from 'react';

import { WorkspaceSetupContext } from '@components/workspace/setup/providers/WorkspaceSetupProvider';

export default function useWorkspaceSetup() {
  const context = useContext(WorkspaceSetupContext);

  if (!context) {
    throw new Error(
      'useWorkspaceSetup must be used within a WorkspaceSetupContext',
    );
  }

  return context;
}
