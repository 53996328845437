import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';

import Dropdown from '@components/dropdown/simple';
import { DropdownOptionsProps } from '@components/dropdown/types';

export default function UserOptions({
  options,
}: {
  options: DropdownOptionsProps;
}) {
  const theme = useTheme();

  return (
    <Dropdown
      sx={{
        popperSx: {
          zIndex: theme.zIndex.tooltip,
        },
      }}
      trigger={{
        element: (
          <Button
            variant="text"
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
            startIcon={<MoreVertIcon />}
            sx={{
              color: 'text.secondary',
              minWidth: 0,
              '&.MuiButton-sizeMedium': {
                p: 1,
              },
              '&.MuiButton-colorPrimary:hover': {
                color: theme.palette.primary.main,
                backgroundColor: 'transparent!important',
              },
              '& .MuiButton-startIcon': {
                mr: 0,
                ml: 0,
              },
            }}
          />
        ),
      }}
      placement="left-start"
      options={options}
    />
  );
}
