import { threads } from '@lib/agent';

import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';

export default function useGetOneThreadLazily() {
  const { threadId: threadIdQueryParam } = useGetThreadIdFromQueryParam();

  const { data: oneThreadData, isLoading: isOneThreadDataLoading } =
    useQueryAfterWorkspaceLoaded({
      queryKey: ['thread', threadIdQueryParam],
      queryFn: async () => {
        try {
          const { data: threadData } = await threads.getThreadById(
            threadIdQueryParam!,
          );

          return threadData;
        } catch (error) {
          console.error(error);
        }
      },
    });

  return {
    oneThreadData,
    isOneThreadDataLoading,
  };
}
