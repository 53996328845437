import { useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';

import StyledCard from '@components/pages/knowledge-base/components/modals/add-knowledge-base/styles';
import FileUpload from '@components/search-form/react-hook-form-upload-select/components/file-upload';
import CONFIG from '@components/search-form/react-hook-form-upload-select/components/file-upload/config';

import useAppSelector from '@hooks/useAppSelector';

import { useTranslation } from '@desygner/ui-common-translation';

import '@uppy/webcam/dist/style.min.css';

import PlusSquareIcon from '~icons/knowz-iconify/plus-square';
import { toast } from 'react-toastify';

export default function RHFUploadSelect() {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const { t } = useTranslation();

  const uploadedImages = useAppSelector(
    (state) => state.searchResult.image?.previews,
  );

  function handleIsFileUploadModalOpen() {
    if (
      uploadedImages &&
      uploadedImages?.length >= CONFIG.MAX_NUMBER_OF_FILES
    ) {
      toast.error(
        t('response.error.maxNumberOfFiles', {
          defaultValue: 'You have reached the maximum number of files.',
        }),
      );
      return;
    }
    setIsFileUploadModalOpen(true);
  }

  function handleIsFileUploadModalClose() {
    setIsFileUploadModalOpen(false);
  }
  return (
    <Box>
      <IconButton onClick={handleIsFileUploadModalOpen}>
        <PlusSquareIcon style={{ fontSize: '1.1rem' }} />
      </IconButton>
      <Modal
        open={isFileUploadModalOpen}
        onClose={handleIsFileUploadModalClose}
      >
        <StyledCard>
          <FileUpload setIsFileUploadModalOpen={setIsFileUploadModalOpen} />
        </StyledCard>
      </Modal>
    </Box>
  );
}
