import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AutocompleteWithCarousel from '@components/autocomplete-with-carousel';
// import Dropdown from '@components/dropdown';
import EllipsisText from '@components/ellipsis-typography';
import CONFIG from '@components/pages/assistants/config';
import useAssistantsDrawer from '@components/pages/assistants/hooks/useAssistantsDrawer';
import LoadingSkeleton from '@components/pages/assistants/sections/loading-skeleton';
import {
  StyledListItem,
  // StyledListItemImage,
  StyledListItemStack,
  StyledStack,
} from '@components/pages/assistants/styles';
import useGetAllAssistants from '@components/pages/hooks/useGetAllAssistants';
import {
  StyledCenteredContent,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import Text from '@components/text';

import { queryClient } from '@providers/ReactQueryProvider';

import useCreateOneThread from '@hooks/useCreateOneThread';
import useResponsive from '@hooks/useResponsive';

import { useTranslation } from '@desygner/ui-common-translation';

import paths from '@router/paths';

const ASSISTANTS_IMAGE_SIZE = 200;

export default function Main() {
  const { t } = useTranslation();
  const isItMobileOrTablet = useResponsive('down', 'md');
  const [selectedAssistant, setSelectedAssistant] = useState<string | null>(
    null,
  );
  const {
    data: allAssistantsData,
    status: getAllAssistantsStatus,
    isError: isGetAllAssistantsError,
  } = useGetAllAssistants();

  const isGetAllAssistantsLoading = getAllAssistantsStatus === 'pending';

  const {
    handleSetAddNewAssistantsDrawer,
    // handleSetIntegrateAssistantsDrawer,
  } = useAssistantsDrawer();

  const navigateTo = useNavigate();
  const {
    mutateAsync: mutateCreateOneThreadAsync,
    isPending: isCreateOneThreadPending,
  } = useCreateOneThread();

  async function handleCreateThread() {
    const name = new Date().toISOString();
    queryClient.invalidateQueries({ queryKey: ['assistant'] }); //? invalidate the assistant query since if user uses back button and change the assistant, the assistant query will not be updated
    const { data } = await mutateCreateOneThreadAsync({
      name,
      type: 'assistant',
      bot: Number(selectedAssistant),
    });

    navigateTo(`${paths.assistantsSession.pathname}?t=${data.id}`, {
      state: { assistantId: selectedAssistant, isUserRedirected: true },
    });
  }

  const AddNewAssistant = (
    <StyledListItem
      key="create new assistants"
      components={{ Root: 'div' }}
      sx={{
        height: CONFIG.ASSISTANTS.CARD.HEIGHT,
      }}
    >
      <Button
        sx={{ height: '100%', width: '100%' }}
        onClick={() => handleSetAddNewAssistantsDrawer(true)}
      >
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AddIcon color="action" />
          <Text
            variant="textMd"
            sx={{ color: 'text.secondary' }}
          >
            {t('page.assistants.addNewAssistants', {
              defaultValue: 'Add New Assistants',
            })}
          </Text>
        </Stack>
      </Button>
    </StyledListItem>
  );

  if (
    !isGetAllAssistantsLoading &&
    !isGetAllAssistantsError &&
    !allAssistantsData?.length
  ) {
    return (
      <Container>
        <StyledViewportHeightStack>
          <StyledCenteredContent gap={8}>
            <img
              src="https://assets.knowz.com/assets/svg/assistants.svg"
              alt="knowz live"
              width={ASSISTANTS_IMAGE_SIZE}
              height={ASSISTANTS_IMAGE_SIZE}
            />
            <Text
              component="h1"
              variant="displaySm"
            >
              {t('page.assistants.hero.title', {
                defaultValue: 'Add AI assistants to your platform',
              })}
            </Text>

            <LoadingButton
              startIcon={<AddIcon />}
              variant="contained"
              loading={false}
              size={isItMobileOrTablet ? 'medium' : 'large'}
              onClick={() => handleSetAddNewAssistantsDrawer(true)}
            >
              {t('page.assistants.addNewAssistants', {
                defaultValue: 'Add New Assistants',
              })}
            </LoadingButton>
          </StyledCenteredContent>
        </StyledViewportHeightStack>
      </Container>
    );
  }

  return (
    <Container>
      <StyledViewportHeightStack>
        <StyledCenteredContent gap={8}>
          <img
            src="https://assets.knowz.com/assets/svg/assistants.svg"
            alt="knowz live"
            width={ASSISTANTS_IMAGE_SIZE}
            height={ASSISTANTS_IMAGE_SIZE}
          />
          <Text
            component="h1"
            variant="displayMd"
          >
            {t('page.assistants.hero.title', {
              defaultValue: 'Add AI assistants to your platform',
            })}
          </Text>
          <LoadingButton
            startIcon={<QuickreplyOutlinedIcon />}
            variant="contained"
            loading={isCreateOneThreadPending}
            size={isItMobileOrTablet ? 'medium' : 'large'}
            disabled={selectedAssistant == null}
            onClick={handleCreateThread}
          >
            {t('page.assistants.startConversation', {
              defaultValue: 'Start Conversation',
            })}
          </LoadingButton>
          <StyledStack gap={5}>
            <AutocompleteWithCarousel
              onChange={(_, newValue) =>
                setSelectedAssistant(newValue?.id.toString() || null)
              }
              ChipProps={{
                size: isItMobileOrTablet ? 'small' : 'medium',
              }}
              firstSlide={AddNewAssistant}
              options={
                !isGetAllAssistantsLoading && !isGetAllAssistantsError
                  ? allAssistantsData!
                  : []
              }
              disabled={isGetAllAssistantsLoading}
              loading={isGetAllAssistantsLoading}
              loadingText={<LoadingSkeleton />}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    type="text"
                    disabled={isGetAllAssistantsLoading}
                    size={isItMobileOrTablet ? 'small' : 'medium'}
                    variant="outlined"
                    autoComplete="off"
                    placeholder={t('page.assistants.options.placeholder', {
                      defaultValue: 'Search for assistants...',
                    })}
                  />
                );
              }}
              renderOption={(props, option, { selected }) => {
                const { key, ...otherProps } =
                  props as React.HTMLAttributes<HTMLLIElement> & {
                    key: string;
                  };
                return (
                  <StyledListItem
                    {...otherProps}
                    key={key}
                    slots={{
                      root: 'div',
                    }}
                    sx={{
                      borderColor: selected
                        ? 'primary.light'
                        : 'background.secondary',
                      height: CONFIG.ASSISTANTS.CARD.HEIGHT,
                    }}
                  >
                    <StyledListItemStack
                      sx={{
                        height: CONFIG.ASSISTANTS.CARD.HEIGHT,
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                      gap={2}
                    >
                      {/* <StyledListItemImage
                        src={
                          'https://assets.knowz.com/assets/svg/60x60.svg' //TODO: change to option.image later
                        }
                        alt={option.name}
                        loading="lazy"
                        width={CONFIG.ASSISTANTS.IMAGE.SIZE}
                        height={CONFIG.ASSISTANTS.IMAGE.SIZE}
                      /> */}
                      <Stack
                        direction="row"
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <EllipsisText
                          variant="textSm"
                          weight="semibold"
                          component="h2"
                          lineClamp={2}
                          title={option.name}
                        >
                          {option.name}
                        </EllipsisText>
                        {/* <Dropdown
                          trigger={
                            <IconButton
                              disableRipple
                              disableFocusRipple
                              sx={{
                                p: 0,
                                ':hover': { bgcolor: 'transparent' },
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                          options={[
                            {
                              text: 'Integrate Assistants',
                              onClick: (_, selectedValue) => {
                                handleSetIntegrateAssistantsDrawer(
                                  selectedValue,
                                );
                              },
                              value: option.id.toString(),
                            },
                          ]}
                          placement="auto-start"
                        /> */}
                      </Stack>
                      <EllipsisText
                        variant="textMd"
                        color="text.disabled"
                        lineClamp={2}
                        title={option.description}
                      >
                        {option.description}
                      </EllipsisText>
                    </StyledListItemStack>
                  </StyledListItem>
                );
              }}
              getOptionLabel={(option) => option.name}
              noOptionsText={t('page.assistants.options.noOptions', {
                defaultValue: 'No assistants found',
              })}
            />
          </StyledStack>
        </StyledCenteredContent>
      </StyledViewportHeightStack>
    </Container>
  );
}
