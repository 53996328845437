import { SearchModeType } from '@shared-types/search-result/types';

import TagManager from 'react-gtm-module';

type Props = {
  prompt: string;
  mode: SearchModeType;
  userUUID: string;
};

export default function searchQuerySubmittedToAnalytics({
  prompt,
  mode,
  userUUID,
}: Props) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'search_query_submitted',
      eventProps: {
        prompt,
        mode,
        user_uuid: userUUID,
      },
    },
  });
}
