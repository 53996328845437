import { auth } from '@lib/agent';

import { useTranslation } from '@desygner/ui-common-translation';

import type { SendResetTokenType } from '@shared-types/auth';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

async function sendResetToken(data: SendResetTokenType) {
  await auth.sendResetToken(data);
}

export default function useSendResetToken() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: sendResetToken,
    onSuccess: () => {
      toast.success(
        t('layout.auth.desktop.modal.steps.sendResetToken.response.success', {
          defaultValue: 'Your code has been successfully sent.',
        }),
      );
    },
    onError: (response) => {
      toast.error(
        t('layout.auth.desktop.modal.steps.sendResetToken.response.error', {
          defaultValue: response.message,
        }),
      );
      console.error(response);
    },
  });
}
