import { assistants } from '@lib/agent';

import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';

export default function useGetOneAssistantByBotId(id?: number) {
  const {
    data: oneAssistantData,
    isLoading: isGetOneAssistantLoading,
    isFetching: isGetOneAssistantFetching,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['assistant', id],
    queryFn: async () => {
      try {
        const { data: assistantsData } = await assistants.getOne(String(id));
        return assistantsData;
      } catch (error) {
        console.error(error);
      }
    },
    options: {
      enabled: !!id,
    },
  });

  return {
    oneAssistantData,
    isGetOneAssistantLoading,
    isGetOneAssistantFetching,
  };
}
