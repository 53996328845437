import { styled } from '@mui/material';

import Text from '@components/text';

import { BagItemType } from '@lib/step/types';

import { useTranslation } from '@desygner/ui-common-translation';

import { v4 as uid } from 'uuid';

const StyledOptionsSelector = styled('div')`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.palette.background.card.main};
  border-radius: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const StyledOption = styled('div')`
  flex-grow: 1;
  text-align: center;
  padding: ${({ theme }) => theme.spacing(2)};
  user-select: none;
  cursor: pointer;

  &.disabled {
    background: ${({ theme }) => theme.palette.background.card.main};
    pointer-events: none;
    cursor: initial;

    * {
      color: ${({ theme }) => theme.palette.neutral.grey};
    }
  }

  &:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.palette.background.card.main};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.background.card.main};

    * {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export type SelectorType = {
  label: BagItemType;
  enabled: boolean;
};

export function OptionsSelector({
  types,
  onClick,
}: {
  types: SelectorType[] | BagItemType[];
  onClick: (type: BagItemType) => void;
}) {
  const { t } = useTranslation();

  if (types.length === 1) {
    return (
      <StyledOptionsSelector>
        <StyledOption
          key={uid()}
          onClick={() => onClick((types[0] as SelectorType).label || types[0])}
        >
          <Text variant="textMd">{t('flow_editor.add_option')}</Text>
        </StyledOption>
      </StyledOptionsSelector>
    );
  }

  types = types.map((t) =>
    typeof t === 'string' ? { label: t, enabled: true } : t,
  );

  return (
    <StyledOptionsSelector>
      {types.map((t) => (
        <StyledOption
          key={uid()}
          onClick={() => onClick(t.label)}
          className={t.enabled ? undefined : 'disabled'}
        >
          <Text variant="textMd">{t.label}</Text>
        </StyledOption>
      ))}
    </StyledOptionsSelector>
  );
}
