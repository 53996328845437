import { Theme } from '@mui/material';

export default function FormControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          gap: theme.spacing(2),
          margin: 0,
        },
        label: {
          color: theme.palette.text.secondary,
        },
      },
    },
  };
}
