import { PropsWithChildren } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';

import useGetCredit from '@hooks/useGetCredit';

import CreditActions from '@layouts/components/credit-usage/credit-actions';
import CreditCopy from '@layouts/components/credit-usage/credit-copy';
import CreditQuantity from '@layouts/components/credit-usage/credit-quantity';
import useUserFunnelType from '@layouts/components/credit-usage/hooks/useUserFunnelType';
import { StyledBox } from '@layouts/components/credit-usage/styles';

type CreditUsagePropsType = PropsWithChildren & {
  variant?: 'gradient' | 'tooltip' | 'default';
  hideTooltip?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
};

export default function CreditUsage({
  children,
  variant,
  hideTooltip,
  tooltipPlacement,
}: CreditUsagePropsType) {
  const { userFunnelType } = useUserFunnelType();
  const { creditsData } = useGetCredit();

  if (variant === 'tooltip' && !children) {
    throw new Error('Children are not allowed with this variant');
  }

  if (variant === 'tooltip') {
    const showButton =
      userFunnelType === 'visitor' ||
      (creditsData?.creditAsPercentage || 0) < 25;

    return (
      <MuiTooltip
        placement={tooltipPlacement || 'bottom'}
        slotProps={{
          tooltip: {
            sx: {
              p: 4,
              ...(hideTooltip ? { display: 'none' } : {}),
            },
          },
        }}
        title={
          <RawCreditUsage
            showButton={showButton}
            color="inverted"
          >
            {!showButton && (
              <Stack
                direction="row"
                sx={{ mt: 2 }}
                gap={2}
              >
                <CreditActions />
              </Stack>
            )}
          </RawCreditUsage>
        }
        arrow
      >
        <Box>{children}</Box>
      </MuiTooltip>
    );
  }

  if (variant === 'gradient') {
    return (
      <StyledBox>
        <RawCreditUsage>
          <Stack
            sx={{ mt: 2 }}
            gap={2}
          >
            <CreditActions />
          </Stack>
        </RawCreditUsage>
      </StyledBox>
    );
  }

  return (
    <Box sx={{ px: 2 }}>
      <RawCreditUsage
        titleSlot={
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              // TODO
            }}
            size="small"
          >
            {/* TODO: icon */}
            <HelpOutlineIcon sx={{ fontSize: '1.1rem' }} />
          </IconButton>
        }
        showButton={true}
      />
    </Box>
  );
}

type RawPropsType = PropsWithChildren & {
  titleSlot?: React.ReactElement;
  showButton?: boolean;
  color?: 'default' | 'inverted';
};

function RawCreditUsage({
  children,
  titleSlot,
  showButton,
  color,
}: RawPropsType) {
  return (
    <Stack gap={2}>
      <CreditQuantity
        slot={titleSlot}
        color={color}
      />
      <CreditCopy
        showButton={showButton}
        color={color}
      />
      {children}
    </Stack>
  );
}
