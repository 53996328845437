import { KnowzStepDefinitionType } from '@lib/step/types';

const BlockStepDefinition: KnowzStepDefinitionType = {
  order: 301,
  group: 'Logic',
  step: {
    componentType: 'block',
    type: 'block',
    name: 'Custom Block',
    sequence: [],
    propertyTypes: [],
    isExpanded: true,
    owner: null,
    blockId: null,
  },
  propertyTypes: [],
};

export default BlockStepDefinition;
