import { CircularProgress, styled } from '@mui/material';

import StarIcon from '~icons/knowz-iconify/star';

export const StyledMuiCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  transform: 'rotate(-90deg)',
}));

export const StyledStarIcon = styled(StarIcon)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 1,
  transition: 'left 0.4s, top 0.4s',
}));
