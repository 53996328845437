import { Slide, useScrollTrigger } from '@mui/material';

interface Props {
  window?: () => Window;
  children?: React.ReactElement<unknown>;
}

export default function HideOnScroll(props: Props) {
  const { children } = props;

  const trigger = useScrollTrigger();

  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
    >
      {children ?? <div />}
    </Slide>
  );
}
