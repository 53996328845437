import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useReducer,
} from 'react';

import Drawer from '@components/pages/assistants/sections/drawer';
import Main from '@components/pages/assistants/sections/main';

import { ActionMap } from '@shared-types/utils';

type ContextType = {
  isAddNewAssistantsOpen: boolean;
  openIntegrateAssistantsById: string | null;
  handleSetAddNewAssistantsDrawer: (value: boolean) => void;
  handleSetIntegrateAssistantsDrawer: (value: string | null) => void;
  handleResetDrawers: VoidFunction;
};

export const AssistantsDrawerContext = createContext<ContextType | null>(null);

type Drawer = {
  openIntegrateAssistantsById: string | null;
  isAddNewAssistantsOpen: boolean;
};

const initialState: Drawer = {
  openIntegrateAssistantsById: null,
  isAddNewAssistantsOpen: false,
};

enum Types {
  INTEGRATE_ASSISTANTS = 'INTEGRATE_ASSISTANTS',
  ADD_NEW_ASSISTANTS = 'ADD_NEW_ASSISTANTS',
  RESET_DRAWERS = 'RESET_DRAWERS',
}

type AssistantsPayload = {
  [Types.INTEGRATE_ASSISTANTS]: {
    openIntegrateAssistantsById: string | null;
  };
  [Types.ADD_NEW_ASSISTANTS]: {
    isAddNewAssistantsOpen: boolean;
  };
  [Types.RESET_DRAWERS]: boolean;
};

export type DrawerActions =
  ActionMap<AssistantsPayload>[keyof ActionMap<AssistantsPayload>];

function reducer(state: Drawer, action: DrawerActions): Drawer {
  switch (action.type) {
    case Types.INTEGRATE_ASSISTANTS:
      return {
        ...state,
        openIntegrateAssistantsById: action.payload.openIntegrateAssistantsById,
      };
    case Types.ADD_NEW_ASSISTANTS:
      return {
        ...state,
        isAddNewAssistantsOpen: action.payload.isAddNewAssistantsOpen,
      };
    case Types.RESET_DRAWERS:
      return {
        ...state,
        isAddNewAssistantsOpen: false,
        openIntegrateAssistantsById: null,
      };
    default:
      return state;
  }
}

export default function AssistantsDrawerProvider({
  children,
}: PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSetAddNewAssistantsDrawer = useCallback(() => {
    handleResetDrawers();
    dispatch({
      type: Types.ADD_NEW_ASSISTANTS,
      payload: {
        isAddNewAssistantsOpen: !state.isAddNewAssistantsOpen,
      },
    });
  }, [state.isAddNewAssistantsOpen]);

  function handleSetIntegrateAssistantsDrawer(value: string | null) {
    handleResetDrawers();
    dispatch({
      type: Types.INTEGRATE_ASSISTANTS,
      payload: {
        openIntegrateAssistantsById: value,
      },
    });
  }

  function handleResetDrawers() {
    dispatch({
      type: Types.RESET_DRAWERS,
      payload: true,
    });
  }

  const memoizedValue = useMemo(() => {
    return {
      ...state,
      handleResetDrawers,
      handleSetIntegrateAssistantsDrawer,
      handleSetAddNewAssistantsDrawer,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSetAddNewAssistantsDrawer, handleSetIntegrateAssistantsDrawer]);

  return (
    <AssistantsDrawerContext.Provider value={memoizedValue}>
      {children}
    </AssistantsDrawerContext.Provider>
  );
}

AssistantsDrawerProvider.Drawer = Drawer;
AssistantsDrawerProvider.Main = Main;
