import { currentAudioPlayingIdUpdated } from '@state/slices/search-result';
import { RootType } from '@state/store';

import muxjs from 'mux.js';
import { Dispatch, UnknownAction } from 'redux';

export const audioStore: Map<string, HTMLAudioElement> = new Map();

let incompleteData = new Uint8Array(0);
let transmuxerFlushTimeout: NodeJS.Timeout | null = null;
let sourceBuffer: SourceBuffer;
let currentId: string | null = null;
const queue: Uint8Array[] = [];

type TextToSpeechType = {
  id: string;
  dispatch: Dispatch<UnknownAction>;
  state: RootType;
};

const transmuxer = new muxjs.mp4.Transmuxer({
  keepOriginalTimestamps: true,
  duration: 0,
  alignGopsAtEnd: true,
});

export function handleTextToSpeech({ id, dispatch, state }: TextToSpeechType) {
  // const isPlayed = false; //? auto-play the audio
  const mediaSource = new MediaSource();
  const audio = new Audio();
  dispatch(currentAudioPlayingIdUpdated(id));
  currentId = id;
  if (!audioStore.has(id)) {
    audioStore.set(id, audio);
  }

  document.body.appendChild(audio);
  audio.src = URL.createObjectURL(mediaSource);
  audio.style.display = 'block';
  //Todo srcObject to the MediaStream directly
  //? https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL_static
  audio.style.display = 'none';
  incompleteData = new Uint8Array(0);
  audio.controls = true;

  mediaSource.addEventListener('sourceopen', () => {
    const mimeCodec = 'audio/mp4; codecs="mp4a.40.2"';
    if (MediaSource.isTypeSupported(mimeCodec)) {
      sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
      sourceBuffer.mode = 'sequence';

      sourceBuffer.addEventListener('updateend', () => {
        if (queue.length > 0 && !sourceBuffer.updating) {
          const buffer = queue.shift();
          sourceBuffer.appendBuffer(buffer!);
        }
      });

      sourceBuffer.addEventListener('error', (e) => {
        console.error('SourceBuffer error:', e);
      });
    } else {
      console.error('MIME type or codec not supported');
    }
  });
}

transmuxer.on('data', (segment) => {
  const combinedSegment = new Uint8Array(
    segment.initSegment.byteLength + segment.data.byteLength,
  );
  combinedSegment.set(segment.initSegment, 0);
  combinedSegment.set(segment.data, segment.initSegment.byteLength);

  if (sourceBuffer && !sourceBuffer.updating) {
    sourceBuffer.appendBuffer(combinedSegment);
  } else {
    queue.push(combinedSegment);
  }

  //? auto-play the audio
  //TODO: we can implement auto-play feature here
  // if (id && !isPlayed) {
  //   isPlayed = true;
  //   audio
  //     .play()
  //     .then(() => {
  //       dispatch({
  //         type: 'searchResult/audioMetadata',
  //         payload: { id, type: 'play' },
  //       });
  //     })
  //     .catch((e) => {
  //       console.error('Playback error:', e);
  //     });
  // }
});

transmuxer.on('error', (e) => {
  console.error('Transmuxer error:', e);
});

function extractADTSFrames(buffer: Uint8Array) {
  const frames = [];
  let i = 0;

  while (i < buffer.length - 1) {
    if (buffer[i] === 0xff && (buffer[i + 1] & 0xf0) === 0xf0) {
      if (i + 5 >= buffer.length) {
        break;
      }

      const frameLength =
        ((buffer[i + 3] & 0x03) << 11) |
        (buffer[i + 4] << 3) |
        ((buffer[i + 5] & 0xe0) >> 5);

      if (i + frameLength > buffer.length) {
        break;
      }

      const frame = buffer.subarray(i, i + frameLength);
      frames.push(frame);
      i += frameLength;
    } else {
      i++;
    }
  }

  return frames;
}

export function handleIncomingData({
  data,
  id: incomingId,
}: {
  data: ArrayBuffer | Uint8Array | { type: string; data: number[] };
  id: string;
}) {
  let buffer;
  if (data instanceof ArrayBuffer) {
    buffer = new Uint8Array(data);
  } else if (data instanceof Uint8Array) {
    buffer = data;
  } else if (data.type === 'Buffer' && Array.isArray(data.data)) {
    buffer = Uint8Array.from(data.data);
  } else {
    console.error('Unsupported data format:', data);
    return;
  }

  if (currentId !== incomingId) {
    audioStore.delete(incomingId);
    return;
  }

  //? concatenate incomplete data from previous chunk
  const combinedBuffer = new Uint8Array(incompleteData.length + buffer.length);
  combinedBuffer.set(incompleteData, 0);
  combinedBuffer.set(buffer, incompleteData.length);

  //? extract ADTS frames from the combined buffer
  const frames = extractADTSFrames(combinedBuffer);
  if (frames.length === 0) {
    // eslint-disable-next-line no-restricted-syntax
    console.warn('No ADTS frames found in the data');
    //? all data is incomplete, store it for next time
    incompleteData = combinedBuffer;
    return;
  }

  //? determine where the last complete frame ends
  let lastFrameEndIndex = 0;
  frames.forEach((frame) => {
    lastFrameEndIndex += frame.length;
  });

  //? store any remaining data as incomplete data for next chunk
  incompleteData = combinedBuffer.subarray(lastFrameEndIndex);

  //? store any remaining data as incomplete data for next chunk
  incompleteData = combinedBuffer.subarray(lastFrameEndIndex);

  //? push extracted frames to the transmuxer
  frames.forEach((frame) => {
    transmuxer.push(frame);
  });

  if (!transmuxerFlushTimeout) {
    transmuxerFlushTimeout = setTimeout(() => {
      transmuxer.flush();
      transmuxerFlushTimeout = null;
    }, 5); //? adjust the interval as needed
  }
}
