import { Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import Markdown from '@components/markdown';
import CONFIG from '@components/pages/assistants-session/config';
import StyledStack from '@components/pages/assistants-session/styles';

import { AssistantHistoryType } from '@shared-types/assistants';

export default function ListItem({ owner, text }: AssistantHistoryType) {
  const isItAssistant = Boolean(owner);

  return (
    <Stack
      gap={6}
      role="listitem"
    >
      {isItAssistant ? (
        <StyledStack isAi={false}>
          <Avatar
            src=""
            alt="user"
            sx={{
              width: CONFIG.CHAT.AVATAR_SIZE,
              height: CONFIG.CHAT.AVATAR_SIZE,
            }}
          />
          <Markdown
            sx={{
              backgroundColor: 'background.card.light',
              p: 4,
              maxWidth: {
                xs: '100%',
                md: CONFIG.CHAT.MAX_WIDTH,
              },
              borderRadius: 2,
              borderTopRightRadius: 0,
            }}
          >
            {text}
          </Markdown>
        </StyledStack>
      ) : (
        <StyledStack isAi>
          <Markdown
            sx={{
              backgroundColor: 'background.card.light',
              p: 4,
              maxWidth: {
                xs: '100%',
                md: CONFIG.CHAT.MAX_WIDTH,
              },
              borderRadius: 2,
              borderTopLeftRadius: 0,
            }}
          >
            {text}
          </Markdown>
        </StyledStack>
      )}
    </Stack>
  );
}
