import HistoryBlocks from '@components/pages/search/sections/history-blocks';
import SearchResult from '@components/pages/search/sections/web-socket-blocks';
import { StyledContainerForFixedHeader } from '@components/pages/styles';
import SourcePreviewProvider from '@components/source-preview/providers/SourcePreviewProvider';

export default function Search() {
  return (
    <SourcePreviewProvider>
      <StyledContainerForFixedHeader
        disableGutters
        maxWidth={false}
      >
        <HistoryBlocks />
        <SearchResult />
      </StyledContainerForFixedHeader>
    </SourcePreviewProvider>
  );
}
