import { useTheme } from '@mui/material';

import usePromptHistoryState from '@components/pages/hooks/usePromptHistoryState';
import { FormType } from '@components/pages/search/types';
import SearchForm from '@components/search-form';

import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useAppSettings from '@hooks/useAppSettings';
import useQueryParams from '@hooks/useQueryParams';

import { imageUploaded } from '@state/slices/search-result';

export default function SearchBar() {
  const { getQueryParamByKey } = useQueryParams();

  const threadId = getQueryParamByKey('t');

  const { setNewHistoryStatePrompt } = usePromptHistoryState();

  const dispatch = useAppDispatch();

  const theme = useTheme();

  const { userGeolocation } = useAppSettings();

  const image = useAppSelector((state) => state.searchResult.image);

  function handleFetchProgrammatically({ name, mode }: FormType) {
    //Todo: check if image has threaId else use original
    if (threadId == null) return;
    setNewHistoryStatePrompt(name);
    dispatch({
      type: 'searchResult/askAIEmitted',
      payload: {
        thread: threadId,
        prompt: name,
        mode: mode,
        image: image?.previews[0]?.key,
        context: {
          latLng: [userGeolocation.lat, userGeolocation.lng],
        },
      },
    });
    dispatch(
      imageUploaded({
        threadId: String(threadId),
        previews: [],
      }),
    );
  }

  return (
    <SearchForm
      onSubmit={handleFetchProgrammatically}
      sx={{
        position: 'fixed',
        zIndex: theme.zIndex.fab,
        margin: {
          xs: theme.spacing(0, 2),
          md: theme.spacing(0, '12%'),
        },
        width: {
          md: theme.spacing(132.5),
        },
        bottom: {
          xs: theme.spacing(22.5),
          md: theme.spacing(5),
        },
        left: {
          xs: 0,
          md: 'inherit',
        },
        right: {
          xs: 0,
          md: 'inherit',
        },
      }}
    />
  );
}
