/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const StyledBox = styled(Box)(() => ({
  position: 'relative', // Make sure it's relative to place the star icon correctly
  display: 'inline-block',
  width: '56px',
  height: '56px',
}));
