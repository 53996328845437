import { Theme } from '@mui/material';

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.card.light,
          padding: theme.spacing(3),
        },
      },
    },
  };
}
