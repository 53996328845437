import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

import ArcProgress from '@components/pages/knowledge-base/components/capacity-indicator/acr-progress-with-tooltip/arc-progress';
import Tooltip from '@components/pages/knowledge-base/components/capacity-indicator/tooltip';
import { ArcProgressWithTooltipType } from '@components/pages/knowledge-base/components/capacity-indicator/types';

import HelpRoundedIcon from '~icons/knowz-iconify/help-rounded';

export default function ArcProgressWithTooltip({
  withTooltip,
  ...otherProps
}: ArcProgressWithTooltipType) {
  const theme = useTheme();

  if (withTooltip) {
    return (
      <Tooltip>
        <Stack direction="row">
          <ArcProgress {...otherProps} />
          <HelpRoundedIcon color={theme.palette.text.secondary} />
        </Stack>
      </Tooltip>
    );
  }

  return <ArcProgress {...otherProps} />;
}
