import Drawer from '@mui/material/Drawer';

import useAssistantsDrawer from '@components/pages/assistants/hooks/useAssistantsDrawer';

export default function Wrapper({ children }: React.PropsWithChildren) {
  const {
    isAddNewAssistantsOpen,
    openIntegrateAssistantsById,
    handleResetDrawers,
  } = useAssistantsDrawer();

  const isDrawerOpen =
    isAddNewAssistantsOpen || Boolean(openIntegrateAssistantsById);

  function handleCloseDrawerOnMobile() {
    handleResetDrawers();
  }

  return (
    <Drawer
      open={isDrawerOpen}
      anchor="right"
      onClose={handleCloseDrawerOnMobile}
    >
      {children}
    </Drawer>
  );
}
