import {
  StyledAvatar,
  StyledStackedMemberships,
} from '@components/pages/knowledge-base/components/table/styles';
import Text from '@components/text';

import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';

import { CellContext } from '@tanstack/react-table';

const NUMBER_OF_MEMBERSHIPS_TO_SHOW = 3;

export default function Permission({
  getValue,
  row,
}: CellContext<FolderType | SourceType, unknown>) {
  const memberships = getValue<SourceType['examplesMemberships']>();
  const isCollected = row.original.isCollected;
  const isItAFolder = 'breadcrumb' in row.original;
  const totalMemberships =
    !('breadcrumb' in row.original) && row.original.totalMemberships;

  if (isItAFolder || !totalMemberships) return '-';

  return (
    <StyledStackedMemberships
      isCollected={isCollected}
      direction="row"
    >
      {memberships
        ?.slice(0, NUMBER_OF_MEMBERSHIPS_TO_SHOW)
        .map((membership, i) => {
          const user = membership.user;
          return (
            <StyledAvatar
              key={i}
              src={user?.profilePicture ?? ''}
              alt={user?.firstName ?? ''}
            >
              {user?.firstName ? user?.firstName[0].toUpperCase() : ''}
            </StyledAvatar>
          );
        })}
      {totalMemberships > NUMBER_OF_MEMBERSHIPS_TO_SHOW && (
        <StyledAvatar>
          <Text variant="textXs">
            +{totalMemberships - NUMBER_OF_MEMBERSHIPS_TO_SHOW}
          </Text>
        </StyledAvatar>
      )}
    </StyledStackedMemberships>
  );
}
