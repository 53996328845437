import { useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { sliderClasses } from '@mui/material/Slider';
import Stack from '@mui/material/Stack';

import Text from '@components/text';

import useGetCredit from '@hooks/useGetCredit';

import { useTranslation } from '@desygner/ui-common-translation';

import {
  StyledChip,
  StyledExpandedSlider,
} from '@layouts/components/credit-usage/credit-quantity/styles';
import StarIcon from '~icons/knowz-iconify/star';

const SKELETON_WIDTH = 35;
const SKELETON_HEIGHT = 20;

export const LOW_CREDIT_PERCENTAGE = 25;

export default function CreditQuantity({
  slot,
  color,
}: {
  slot?: React.ReactElement;
  color?: 'default' | 'inverted';
}) {
  const { creditsData, isCreditLoading } = useGetCredit();
  const theme = useTheme();
  const { t } = useTranslation();

  const isCreditLow =
    (creditsData?.creditAsPercentage || 0) < LOW_CREDIT_PERCENTAGE;

  const isOutOfCredit = creditsData?.creditAsPercentage === 0;

  const label = isOutOfCredit
    ? t('layout.toolbar.buyAICredit.label.out')
    : isCreditLow
      ? t('layout.toolbar.buyAICredit.label.low')
      : undefined;

  return (
    <Stack
      sx={{ width: '100%' }}
      gap={3}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          gap={1}
        >
          <Text
            variant="textSm"
            weight="semibold"
          >
            {t('layout.toolbar.buyAICredit.credits', {
              defaultValue: 'Credits',
            })}
          </Text>

          {slot}
        </Stack>

        {isCreditLoading || typeof creditsData === 'undefined' ? (
          <Skeleton
            variant="rectangular"
            width={SKELETON_WIDTH}
            height={SKELETON_HEIGHT}
          />
        ) : (
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            sx={{ minHeight: 24 }}
          >
            {label && (
              <StyledChip
                isOutOfCredit={isOutOfCredit}
                label={label}
              />
            )}

            <Text
              variant="textSm"
              weight="semibold"
            >
              {creditsData.remainingCredits.toLocaleString()}
            </Text>

            <StarIcon
              width={20}
              height={20}
            />
          </Stack>
        )}
      </Stack>

      <StyledExpandedSlider
        isCreditLow={isCreditLow}
        value={creditsData?.creditAsPercentage || 0}
        orientation="horizontal"
        sx={{
          ...(color === 'inverted'
            ? {
                [`& .${sliderClasses.rail}`]:
                  theme.palette.mode !== 'dark'
                    ? {
                        opacity: 0.3,
                        backgroundColor: '#E9EAEB', // TODO: design system
                      }
                    : {
                        opacity: 1,
                        backgroundColor: 'rgba(0,0,0,0.20)',
                      },
              }
            : {}),
        }}
      />
    </Stack>
  );
}
