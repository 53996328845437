import { styled } from '@mui/material';

import Text from '@components/text';

import Markdown from 'react-markdown';

export const StyledMarkdown = styled(Markdown)(({ theme }) => ({
  '*': {
    revert: 'all',
  },
  h2: {
    fontSize: theme.typography.textLg.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.8,
  },
  h3: {
    fontSize: theme.typography.textMd.fontSize,
    fontWeight: theme.typography.textMd.fontWeight,
    lineHeight: 1.8,
  },
  a: {
    color: theme.palette.mode === 'dark' && theme.palette.primary.light,
  },
  p: {
    fontSize: theme.typography.textMd.fontSize,
    lineHeight: 2,
  },
  strong: {
    fontSize: theme.typography.textMd.fontSize,
    fontWeight: theme.typography.textMd.fontWeight,
    lineHeight: 1.8,
  },
}));

export const StyledText = styled(Text)(({ theme }) => ({
  fontWeight: theme.typography.textMd.fontWeight,
  color:
    theme.palette.mode === 'light'
      ? theme.palette.brand.blue.main
      : theme.palette.primary.light,
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));
